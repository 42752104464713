import React from "react";
import {Card, Table} from "tabler-react";
import {withI18n} from 'react-i18next';
import LinksProvider from "../../../services/http/LinksProvider";
import DateService from "../../../services/utils/DateService";
import Amount from "../../common/amount/Amount";
import StrongAuthService from "../../../services/utils/StrongAuthService";

const PotsList = ({pots, t}) => {

  const headerItems = [
    {content: t('pots.application')},
    {content: t('pots.reference')},
    {content: t('pots.name')},
    {content: t('pots.collected-amount')},
    {content: t('pots.organizer')},
    {content: t('pots.creation-date')},
  ];


  const renderLogo = (pot) => {
    const isWeddingList = (pot) = pot && pot.category === 7;

    return isWeddingList
      ? <img src={`/assets/images/logo-m1l.svg`}
             alt={'m1l'}
      />
      : <img src={`/assets/images/logo-cnt.svg`}
             alt={'cnt'}
      />;
  };


  const itemRow = (pot) => {
    return {
      key: pot.reference,
      item: [
        {content: (<>{renderLogo(pot)}</>)},
        {
          content: (
            <a href={LinksProvider.formatUrl(LinksProvider.ROUTES.POT, {id: pot.reference})}>{pot.reference}</a>)
        },
        {content: (<span>{pot.name}</span>)},
        {
          content: StrongAuthService.showSecureSensitiveDataWithOTPForM1L() ? (
            <Amount amount={pot.collected_amount}/>) : '-'
        },
        {
          content: (
            <a
              href={LinksProvider.formatUrl(LinksProvider.ROUTES.USER, {id: pot.organizer_email})}>{`${pot.organizer_first_name} ${pot.organizer_last_name}`}</a>)
        },
        {content: (<span>{DateService.formatDate(pot.creation_date)}</span>)},
      ]
    };
  };


  return (
    <Card title={t('pots.list-pots', {count: pots.length})}>
      <Card.Body>
        <Table responsive
               className="card-table"
               headerItems={headerItems}
               bodyItems={pots.map(itemRow)}
        />
      </Card.Body>
    </Card>
  );
};

export default withI18n()(PotsList);
