/**
 * InspirationListsService
 * @module Services/domain/InspirationListsService
 * @description Loads inspiration lists data from the server
 */

import HttpService from "../http/HttpService";
import LinksProvider from "../http/LinksProvider";

const self = {};



/**
 * get inspiration lists
 * @author Seif Khiari
 * @alias getAll
 * @memberof module:Services/http/InspirationListsService
 * @param   {string} limit The search query limit
 * @param   {string} offset The search query offset
 * @param   {string|number} type The search query type
 * @returns {Promise<InspirationList[]>}  A promise containing the search result
 */
self.getAll = (limit: string, offset: string, type: string|number): Promise<InspirationList[]> => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.INSPIRATION_LISTS.ALL_LISTS, {
      limit: limit,
      offset: offset,
      type: type
    }));
};

/**
 * creates inspiration list
 * @author Seif Khiari
 * @alias create
 * @memberof module:Services/http/InspirationListsService
 * @param   {object} values the inspiration list info
 * @returns {Promise<InspirationList>}  A promise containing the created inspiration list
 */
self.create = (values : any): Promise<InspirationList> => {
  return HttpService
    .post(LinksProvider.formatFullUrl(LinksProvider.API.INSPIRATION_LISTS.LISTS), values)
    .then(data => data.inspiration_list);
};

/**
 * returns inspiration list info
 * @author Seif Khiari
 * @alias get
 * @memberof module:Services/http/InspirationListsService
 * @param   {string} reference the inspiration list reference
 * @returns {Promise<InspirationList>}  A promise containing the inspiration list
 */
self.get = (reference : string): Promise<InspirationList> => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.INSPIRATION_LISTS.LIST, {reference}))
    .then(data => data.inspiration_list);
};

/**
 * updates inspiration list
 * @author Seif Khiari
 * @alias update
 * @memberof module:Services/http/InspirationListsService
 * @param   {string} reference the inspiration list reference
 * @param   {object} values the inspiration list info to update
 * @returns {Promise<InspirationList>}  A promise containing the updated inspiration list
 */
self.update = (reference : string, values : any): Promise<InspirationList> => {
  return HttpService
    .put(LinksProvider.formatFullUrl(LinksProvider.API.INSPIRATION_LISTS.LIST, {reference}), values)
    .then(data => data.inspiration_list);
};

/**
 * add product to inspiration list
 * @author Seif Khiari
 * @alias addProduct
 * @memberof module:Services/http/InspirationListsService
 * @param   {string} reference the inspiration list reference
 * @param   {object} productReference the product reference
 * @returns {Promise<InspirationList>}  A promise containing the updated inspiration list
 */
self.addProduct = (reference : string, productReference : string): Promise<InspirationList> => {
  return HttpService
    .post(LinksProvider.formatFullUrl(LinksProvider.API.INSPIRATION_LISTS.ADD_PRODUCT, {reference, productReference}))
    .then(data => data.inspiration_list);
};

/**
 * delete product to inspiration list
 * @author Seif Khiari
 * @alias deleteProduct
 * @memberof module:Services/http/InspirationListsService
 * @param   {string} reference the inspiration list reference
 * @param   {object} productReference the product reference
 * @returns {Promise<InspirationList>}  A promise containing the updated inspiration list
 */
self.deleteProduct = (reference : string, productReference : string): Promise<InspirationList> => {
  return HttpService
    .delete(LinksProvider.formatFullUrl(LinksProvider.API.INSPIRATION_LISTS.DELETE_PRODUCT, {reference, productReference}));
};

/**
 * updates inspiration list product(s))
 * @author Seif Khiari
 * @alias updateProducts
 * @memberof module:Services/http/InspirationListsService
 * @param   {string} reference the inspiration list reference
 * @param   {object} values object containing products references
 * @returns {Promise<InspirationList>}  A promise containing the updated inspiration list
 */
self.updateProducts = (reference : string, values : any): Promise<InspirationList> => {
  return HttpService
    .post(LinksProvider.formatFullUrl(LinksProvider.API.INSPIRATION_LISTS.UPDATE_PRODUCTS, {reference}), values);
};

/**
 * creates inspiration list
 * @author Seif Khiari
 * @alias create
 * @memberof module:Services/http/InspirationListsService
 * @param   {object} values the inspiration list info
 * @returns {Promise<InspirationList>}  A promise containing the created inspiration list
 */
self.create = (values : any): Promise<Publication> => {
  return HttpService
    .post(LinksProvider.formatFullUrl(LinksProvider.API.INSPIRATION_LISTS.LISTS), values)
    .then(data => data.inspiration_list);
};


export default self;
