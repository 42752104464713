// @flow
import * as React from "react";
import CurrencyService from '../../../services/utils/CurrencyService'
import _ from 'lodash'

function Amount({amount}): React.Node {

  return (
      <span>{_.isUndefined(amount) || _.isNull(amount) ? '-' : `${CurrencyService.centsToEuro(amount)} €`} </span>
  );
}

export default Amount;