import React, {useState} from 'react'
import {Button, Card, Col, Row} from "react-bootstrap";
import {withI18n} from 'react-i18next';
import {DocumentAdditionalStatus} from "../../../../models/VerificationDocument";
import KycService from "../../../../services/domain/KycService";
import DocumentStatus from "../document-status/DocumentStatus";
import ErrorHandler from "../../../../services/error/ErrorHandler";
import {useToasts} from 'react-toast-notifications';

const DocumentPreview = ({t, document}) => {

  const {addToast} = useToasts();
  const [model] = useState(document);
  const deleteDocument = () => {
    return KycService.deleteDocument(model.reference)
        .then(() => {
          window.location.reload()
        })
        .catch(error => {
            ErrorHandler.handleError(addToast, t, error);
        })
  };

  return (<Card className="card-profile">
        <Card.Body className="text-center">
          <div>
            <Row className="mb-2">
              <Col sm={12} className="mb-2">
                {t('kyc-document.status')} : <DocumentStatus status={model.status}/>
              </Col>
              <Col sm={12} className="mb-2">
                {t('kyc-document.rejection-reason')} : {DocumentAdditionalStatus[model.additional_status]}
              </Col>
              <Col sm={12} className="mb-2">
                {t('kyc-document.message')} : {model.rejection_reason}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col sm={12}>
                <embed src={model.url} width="100%" height="800"
                       type="application/pdf"/>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Button onClick={deleteDocument} className="w-50"
                        variant="outline-danger">{t('kyc-document.delete-document')}</Button>
              </Col>
            </Row>
          </div>
        </Card.Body>
      </Card>
  );

};

export default withI18n()(DocumentPreview);
