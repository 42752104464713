import React, {Fragment} from "react";
import {Card, Loader, Form} from "tabler-react";
import {Modal} from "react-bootstrap";
import {withI18n} from 'react-i18next';
import {useEffect, useState} from "react";
import {Col, Container, Row} from 'reactstrap';
import LinksProvider from "../../../services/http/LinksProvider";
import {
  CommercialPartnerCategory, CommercialPartnerPriority,
  CommercialPartnerStatus, CommercialPartnerRegion,
} from "../../../models/CommercialPartner";
import Editable from "../../common/editable/Editable";
import CommercialPartnersService from "../../../services/domain/CommercialPartnersService";
import CommercialPartnerPhotoUpdate from "../commercial-partner-photo-update/CommercialPartnerPhotoUpdate";
import ErrorHandler from "../../../services/error/ErrorHandler";
import {useToasts} from "react-toast-notifications";
import DraftEditor from "../../common/editor/DraftEditor";

const CommercialPartnerUpdate = ({reference, t}) => {

  const {addToast} = useToasts();

  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState(null);


  const loadData = () => {
    setLoading(true);
    CommercialPartnersService.get(reference)
      .then(data => {
        setModel(data);
      })
      .catch(error => {
        window.location = LinksProvider.formatUrl(LinksProvider.ROUTES.COMMERCIAL_PARTNERS);
        ErrorHandler.handleError(addToast, t, error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadData()
  }, []);


  const updatePartner = (request) => {
    setLoading(true);
    return CommercialPartnersService.update(model.reference, request)
      .then(commercial_partner => {
        setModel({...model, ...commercial_partner});
        return commercial_partner;
      })
      .catch(err => {
        ErrorHandler.handleError(addToast, t, err);
        loadData();
      })
      .finally(() => setLoading(false));
  };

  const editInfoPartner = (field) => {
    return (val) => {

      const request = {};
      if (field.indexOf('.') != -1) {
        const array = field.split('.');
        model[array[0]][array[1]] = val;
        request[array[0]] = {};
        request[array[0]][array[1]] = val;
      } else {
        model[field] = val;
        request[field] = val;
        if (field === "category" || field === "status" || field === "region" || field === "priority") {
          model[field] = parseInt(val);
          request[field] = parseInt(val);
        }
      }
      setModel({...model});
      updatePartner(request);
    }
  };

  return (<Fragment>
    <Card.Header>
      <Modal.Title> {t('partners.update')}</Modal.Title>
    </Card.Header>
    <Card.Body>
      {loading && (<Container><Loader/></Container>)}
      {!loading && model && (<Container>
        <Row>
          <Col xs="12" sm="12">
            <Form.Group label={t('partners.name')} className={"text-left"}>
              <Editable
                initialValue={model.name}
                undefinedText={t('globals.no-value')}
                isValueClickable={true}
                onSubmit={editInfoPartner('name')}
                type="textfield"/>
            </Form.Group>
          </Col>
          <Col xs="12" sm="12">
            <Form.Group label={t('partners.description')} className={"text-left"}>
              <DraftEditor value={model['description']}
                                 editorName="description"
                                 editorId="description"
                                 onValueChange={(value) => model['description'] = value}
                                 onSubmit={editInfoPartner('description')}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs="4" sm="4">
            <Form.Group label={t('partners.status')} className={"text-left"}>
              <Editable
                initialValue={model.status}
                undefinedText={t('globals.no-value')}
                isValueClickable={true}
                onSubmit={editInfoPartner('status')}
                type="select" options={Object.keys(CommercialPartnerStatus).map(key => {
                return {
                  label: t(`partners.status-${CommercialPartnerStatus[key]}`), value: CommercialPartnerStatus[key]
                }
              })}/>
            </Form.Group>
          </Col>
          <Col xs="4" sm="4">
            <Form.Group label={t('partners.category')} className={"text-left"}>
              <Editable
                initialValue={model.category}
                undefinedText={t('globals.no-value')}
                isValueClickable={true}
                onSubmit={editInfoPartner('category')}
                type="select" options={Object.keys(CommercialPartnerCategory).map(key => {
                return {
                  label: t(`partners.category-${CommercialPartnerCategory[key]}`), value: CommercialPartnerCategory[key]
                }
              })}/>
            </Form.Group>
          </Col>
          <Col xs="4" sm="4">
            <Form.Group label={t('partners.region')} className={"text-left"}>
              <Editable
                initialValue={model.region ?? -1}
                undefinedText={t('globals.no-value')}
                isValueClickable={true}
                onSubmit={editInfoPartner('region')}
                type="select" options={Object.keys(CommercialPartnerRegion).map(key => {
                return {
                  label: t(`partners.region-${CommercialPartnerRegion[key]}`), value: CommercialPartnerRegion[key]
                }
              })}/>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs="4" sm="4">
            <Form.Group label={t('partners.priority')} className={"text-left"}>
              <Editable
                initialValue={model.priority}
                undefinedText={t('globals.no-value')}
                isValueClickable={true}
                onSubmit={editInfoPartner('priority')}
                type="select" options={Object.keys(CommercialPartnerPriority).map(key => {
                return {
                  label: t(`partners.priority-${CommercialPartnerPriority[key]}`), value: CommercialPartnerPriority[key]
                }
              })}/>
            </Form.Group>
          </Col>
          <Col xs="4" sm="4">
            <Form.Group label={t('partners.url')} className={"text-left"}>
              <Editable
                initialValue={model.url}
                undefinedText={t('globals.no-value')}
                isValueClickable={true}
                onSubmit={editInfoPartner('url')}
                type="textarea"/>
            </Form.Group>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center w-100">
          <Col xs="6" sm="6" className="mt-2">
            <CommercialPartnerPhotoUpdate
              partnerReference={model.reference}
              url={model.photo_url}
            />
          </Col>
        </Row>
      </Container>)}
    </Card.Body>
  </Fragment>);
};

export default withI18n()(CommercialPartnerUpdate);
