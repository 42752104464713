// @flow
import * as React from "react";
import SiteWrapper from "../../SiteWrapper";
import {Button, Grid, Loader, Page} from "tabler-react";
import {withI18n} from "react-i18next";
import SearchForm from "../../components/common/search-form/SearchForm";
import LinksProvider from "../../services/http/LinksProvider";
import {useEffect, useState} from "react";
import ProductsService from "../../services/domain/ProductsService";
import ProductInfo from "../../components/products/product-info/ProductInfo";
import ErrorHandler from "../../services/error/ErrorHandler";
import {useToasts} from 'react-toast-notifications';

function ProductsDetailsPage({t, match}): React.Node {

    const [product, setProduct] = useState(null);
    const [productsCount, setProductsCount] = useState(0);
    const [loadingProduct, setLoadingProduct] = useState(false);
    const {addToast} = useToasts();

    useEffect(() => {
        document.title = `${t('navbar.product')} : ${match.params.id}`;
        loadData();
    }, [t, match.params.id]);

    const loadData = () => {
        setLoadingProduct(true);
        return ProductsService.get(match.params.id)
          .then(product => setProduct(product))
          .finally(() => setLoadingProduct(false))
    };

    return (
        <SiteWrapper>
            <Page.Content>
                <Page.Header
                    title={t('navbar.product')}
                />
                <Page.Main>

                    {loadingProduct && <Loader/>}

                    {(!loadingProduct && product) && (
                        <ProductInfo reference={product.reference}/>
                    )}
                </Page.Main>
            </Page.Content>
        </SiteWrapper>
    );
}

export default withI18n()(ProductsDetailsPage);
