import React, {useState, useEffect} from "react";
import {
  Card, Loader, Grid, Form
} from "tabler-react";
import {withI18n} from 'react-i18next';
import EmptyContent from "../common/empty-content/EmptyContent";
import UsersService from "../../services/domain/UsersService";
import LinksProvider from "../../services/http/LinksProvider";
import {useToasts} from "react-toast-notifications";
import ErrorHandler from '../../services/error/ErrorHandler';
import CreditCard from "./CreditCard";
import {CreditCardParent} from "../../models/CreditCard";
import Separator from "../common/seperator/Separator";

function CreditCards({t, userEmail, parent = CreditCardParent.USER}): React.Node {
  const [loading, setLoading] = useState(false);
  const [credit_cards, setCreditCards] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const {addToast} = useToasts();

  const loadData = () => {
    setLoading(true);

    Promise.all([UsersService.search(userEmail), UsersService.getCreditCardsByEmail(userEmail)])
      .then(([user_data, credit_cards_data]) => {
        setCreditCards(credit_cards_data);
        setUserInfo(user_data);
      })
      .catch(err => {
        ErrorHandler.handleError(addToast, t, err);
      })
      .finally(() => setLoading(false));
  };

  const mapCreditCardToBlock = (credit_card, index, original_array) => {
    return (
      <React.Fragment>
        <CreditCard credit_card={credit_card} user={userInfo} t={t}/>
        {(index < original_array.length - 1) && (<Separator/>)}
      </React.Fragment>
    );
  };

  useEffect(() => {
    loadData();
  }, [userEmail]);

  return (
    <Card>
      <Card.Header>
        <Card.Title>{t('credit-cards.title')}</Card.Title>
      </Card.Header>
      <Card.Body className="fixed-height-card-body">
        {userInfo && !loading && (
          <React.Fragment>
            {(!credit_cards || !credit_cards.length) && userInfo && userInfo.xpollens_external_reference && (
              <Grid.Row width={12}>
                <Grid.Col width={4}>
                  <Form.Group className={'testClass'} label={t('credit-cards.user-xpollens-external-reference')}>
                    <a target={'_blank'}
                       href={`${LinksProvider.formatUrl(LinksProvider.XPOLLENS.BACK_OFFICE_URL, {id: userInfo.xpollens_external_reference})}`}
                    >{userInfo.xpollens_external_reference}</a>
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>)}

            {(!credit_cards || !credit_cards.length) ? (
              <EmptyContent
                text={parent === CreditCardParent.USER ? t('users.no-credit-cards') : t('pots.no-credit-cards')}/>
            ) : ''}
            <React.Fragment>
              {!!(credit_cards && credit_cards.length) && credit_cards.map((credit_card, index, original_array) => {
                return mapCreditCardToBlock(credit_card, index, original_array);
              })}
            </React.Fragment>
          </React.Fragment>
        )}
        {loading && <Loader/>}
      </Card.Body>
    </Card>
  );
}

export default withI18n()(CreditCards);
