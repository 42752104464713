// @flow

export const Channel = {
  UNKNOWN : 0,
  DESKTOP  : 1,
  TABLET : 2,
  MOBILE : 3
};

export type RequestInformation = {
  uuid : string,
  ip_address : string,
  user_agent? : string,
  channel : number,
  country? : string,
  city? : string,
  postal_code? : string
};

export const TARGET_APP = {
  CNT: 'CNT',
  M1L: 'M1L'
};