import React, {useState, useEffect} from "react";

import DateService from '../../../services/utils/DateService'
import {
  Badge, Button, Card, Loader, Table,
} from "tabler-react";
import {withI18n} from 'react-i18next';
import EmptyContent from "../../common/empty-content/EmptyContent";
import PotsService from "../../../services/domain/PotsService";
import LinksProvider from "../../../services/http/LinksProvider";
import {MinifiedParticipation, ParticipationStatus} from "../../../models/Participation";
import Amount from "../../common/amount/Amount";
import RefundAllParticipationsModal
  from "../../modals/refund-all-participations-modal/RefundAllParticipationsModal";
import StrongAuthService from "../../../services/utils/StrongAuthService";

function PotParticipations({pot, t}): React.Node {

  const [loading, setLoading] = useState(false);
  const [participations, setParticipations] = useState(null);
  const [refundModalShown, setRefundModalShown] = useState(false);

  const headerItems = [
    {content : t('participations.reference')},
    {content : t('participations.payer')},
    {content : t('participations.amount')},
    {content : t('participations.creation-date')},
    {content : t('participations.validation-date')},
    {content : t('participations.status')}
  ];

  const showRefundButton = () => {
    if (!participations || !participations.length){
      return false;
    }

    return participations.filter(p => p.status == ParticipationStatus.BANK_OK).length > 0;
  };

  const afterRefund = () => {
    loadData()
  };

  const loadData = () => {
    setLoading(true);
    PotsService.participations(pot.reference)
        .then(participations => {
          setParticipations(participations);
        })
        .finally(() => setLoading(false));
  };

  const participationStatusBadge = (participation : MinifiedParticipation) => {
    switch (participation.status) {
      case ParticipationStatus.NOT_PAYED :
        return (<Badge color="primary">{t('users.not-payed')}</Badge>);
      case ParticipationStatus.BANK_ERROR:
        return (<Badge color="danger">{t('users.bank-error')}</Badge>);
      case ParticipationStatus.REFUNDED :
        return (<Badge color="warning">{t('users.refunded')}</Badge>);
      case ParticipationStatus.REFUNDED_AFTER_3DS_FAIL :
        return (<Badge color="warning">{t('users.refunded-after-3ds-fail')}</Badge>);
      case ParticipationStatus.BANK_OK :
        return (<Badge color="success">{t('users.bank-ok')}</Badge>)
    }
  };

  const oneRow = (participation : MinifiedParticipation) => {
    return {
      key : participation.reference,
      item : [
        {content : (<a href={LinksProvider.formatUrl(LinksProvider.ROUTES.PARTICIPATION, {id : participation.reference})}>{participation.reference}</a>)},
        {content : (<a href={LinksProvider.formatUrl(LinksProvider.ROUTES.USER, {id : participation.payer_email})}>{participation.payer_email}</a>)},
        {content : <Amount amount={participation.amount}></Amount>},
        {content : DateService.formatTime(participation.creation_date)},
        {content : DateService.formatTime(participation.validation_date)},
        {content : (participationStatusBadge(participation))}
      ]
    };
  };

  useEffect(() => {
    loadData();
  }, [pot]);

  return (

      <Card>
        <Card.Header>
          <Card.Title>{t('pots.participations')}</Card.Title>
          <Card.Options>
            {showRefundButton() && (
                <Button color="danger" size="sm" onClick={() => setRefundModalShown(true)}>{t('pots.refund-all')}</Button>
            )}
          </Card.Options>
        </Card.Header>
        <Card.Body className="fixed-height-card-body">

          <RefundAllParticipationsModal
              afterRefund={afterRefund}
              reference={pot.reference}
              show={refundModalShown}
              onHide={() => setRefundModalShown(false)}/>

          {((!participations || !participations.length) && !loading) ? (
              <EmptyContent text={t('pots.no-participations')}/>
          ) : ''}
          {((participations && participations.length) && !loading) ? (
              <Table responsive
                     className="card-table"
                     headerItems={headerItems}
                     bodyItems={participations.map(oneRow)}/>
          ) : ''}
          {loading && <Loader/>}
        </Card.Body>
      </Card>
  );
}

export default withI18n()(PotParticipations);
