// @flow
import * as React from "react";
import SiteWrapper from "../../SiteWrapper";
import {Loader, Page} from "tabler-react";
import {withI18n} from "react-i18next";
import {useEffect, useState} from "react";
import CommercialBannersService from "../../services/domain/CommercialBannersService";
import CreateCommercialBannerModal
  from '../../components/modals/create-commercial-banner-modal/CreateCommercialBannerModal';
import CommercialBannersActiveList
  from "../../components/commercial-banners/commercial-banners-active-list/CommercialBannersActiveList";
import CommercialBannersHistoryList
  from "../../components/commercial-banners/commercial-banners-history-list/CommercialBannersHistoryList";
import ErrorHandler from "../../services/error/ErrorHandler";
import {useToasts} from 'react-toast-notifications';


function CommercialBannersPage({t}): React.Node {
  const [createModalShown, setCreateModalShown] = useState(false);
  const [activeBanners, setActiveBanners] = useState(null);
  const [archivedBanners, setArchivedBanners] = useState([]);
  const {addToast} = useToasts();

  const [loadingActif, setLoadingActif] = useState(true);
  const [loadingHistory, setLoadingHistory] = useState(true);


  useEffect(() => {
    document.title = t('navbar.commercial-banners');
    loadData();
  }, [t]);


  const loadData = () => {
    setLoadingActif(true);
    setLoadingHistory(true);
    CommercialBannersService.getActive()
      .then(banners => {
        if (banners && banners.length) {
          setActiveBanners(banners);
        }
      })
      .catch(error => {
        console.log("error on loading actif banners : ", error);
        ErrorHandler.handleError(addToast, t, error);
      })
      .finally(() => setLoadingActif(false));

    return CommercialBannersService.getHistory({sort: 'end_date ASC'})
      .then(arcBanners => {
        if (arcBanners && arcBanners.length) {
          setArchivedBanners(arcBanners);
        }
      })
      .catch(error => {
        console.log("error on loading history banners : ", error);
        ErrorHandler.handleError(addToast, t, error);
      })
      .finally(() => setLoadingHistory(false))
  };

  return (
    <SiteWrapper>
      <Page.Content>
        <Page.Header
          title={t('navbar.commercial-banners')}
        />
        {loadingActif && <Loader/>}

        {(!loadingActif) && (
          <CommercialBannersActiveList
            banners={activeBanners}
          >
          </CommercialBannersActiveList>
        )}

        <br/>
        <br/>

        {loadingHistory && <Loader/>}

        {(!loadingHistory) && (
          <CommercialBannersHistoryList
            banners={archivedBanners}
          >

          </CommercialBannersHistoryList>
        )}

        <CreateCommercialBannerModal
          show={createModalShown}
          dialogClassName="modal-lg"
          onHide={() => setCreateModalShown(false)}
          onError={(error) => ErrorHandler.handleError(addToast, t, error)}
        />
      </Page.Content>
    </SiteWrapper>
  );
}

export default withI18n()(CommercialBannersPage);
