import React, {useState, useEffect} from "react";
import {Button, Card, Grid, Form} from "tabler-react";
import ReportService from "../../../services/domain/ReportService";
import {withI18n} from "react-i18next";
import LinksProvider from "../../../services/http/LinksProvider";
import DateService from "../../../services/utils/DateService";
import DatePicker from "react-datepicker";
import {CreType} from "../../../models/Cre";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ErrorHandler from "../../../services/error/ErrorHandler";
import {useToasts} from "react-toast-notifications";

const CreReport = ({t, creType}) => {
  const today = new Date();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date(today.getFullYear(), today.getMonth(), 1));
  const [endDate, setEndDate] = useState(today);
  const reportType = creType === CreType.M1L ? CreType.M1L : CreType.CNT;
  const reportName = reportType === CreType.M1L ? t('reports.cre-report-m1l') : t('reports.cre-report');

  const {addToast} = useToasts()

  useEffect(() => {

  }, [t]);

  const downloadReport = () => {
    setLoading(true);
    const downloadUrl = reportType === CreType.M1L
      ? LinksProvider.API.REPORT.CRE_REPORT_M1L
      : LinksProvider.API.REPORT.CRE_REPORT;
    ReportService.downloadReport(LinksProvider.formatFullUrl(downloadUrl, {
      start_date: moment(startDate).toISOString(),
      end_date: moment(endDate).toISOString()
    }))
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', reportName + ' ' + DateService.formatDateISO(startDate) + '__' + DateService.formatDateISO(endDate) + '.csv');
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => setLoading(false));
  };

  const sendReportByEmail = () => {
    setLoading(true);
    ReportService.sendReportByEmail(LinksProvider.formatFullUrl(
      LinksProvider.API.REPORT.CRE_REPORT_M1L_SEND_BY_EMAIL,
      {start_date: moment(startDate).toISOString(), end_date: moment(endDate).toISOString()}
    )).then(response => {
      addToast(t(`reports.sending`), {
        appearance: 'success', autoDismiss: true
      });
    }).catch(err => {
      ErrorHandler.handleError(addToast, t, err);
    }).finally(() => setLoading(false));
  };

  return (
    <React.Fragment>
      <Card>
        <Card.Header>
          <Card.Title>{reportName}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Grid.Row>
            <Grid.Col width={4}>
              <Form.Group label={t('reports.start-date')} className={"text-left"}>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="dd/MM/yyyy"
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col width={4}>
              <Form.Group label={t('reports.end-date')} className={"text-left"}>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  dateFormat="dd/MM/yyyy"
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col width={4} className="d-flex align-items-center">
              <Button
                color="primary"
                onClick={creType === CreType.M1L ? sendReportByEmail : downloadReport}
                loading={loading}
              >
                {reportType === CreType.M1L ? t('reports.send-by-email') : t('reports.download')}
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
      </Card>
    </React.Fragment>)
};

export default withI18n()(CreReport);
