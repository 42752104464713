import Keycloak from "keycloak-js";
import AuthService from "./AuthService";

let self = {};
let keyCloakObject = null;

const keyCloakParams = {
  "auth-server-url": process.env.REACT_APP_KEYCLOAK_URL,
  "url": process.env.REACT_APP_KEYCLOAK_URL,
  "ssl-required": process.env.REACT_APP_KEYCLOAK_SSL_REQUIRED || "none",
  "confidential-port": 0,
  "cors": true,
  "enable-cors": true,
  "cors-allowed-headers": "Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Authorization, Access-Control-Request-Headers",
  "allowed_headers": "Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Authorization, Access-Control-Request-Headers"
};

const keyCloakParamsWithoutOTP = {
  "realm": process.env.REACT_APP_KEYCLOAK_WITHOUT_OTP_REALM,
  "clientId": process.env.REACT_APP_KEYCLOAK_WITHOUT_OTP_CLIENT_ID,
  "resource": process.env.REACT_APP_KEYCLOAK_WITHOUT_OTP_CLIENT_ID,
  "credentials": {
    "secret": process.env.REACT_APP_KEYCLOAK_WITHOUT_OTP_SECRET,
  },
  ...keyCloakParams
}

const keyCloakParamsWithOTP = {
  "realm": process.env.REACT_APP_KEYCLOAK_WITH_OTP_REALM,
  "clientId": process.env.REACT_APP_KEYCLOAK_WITH_OTP_CLIENT_ID,
  "resource": process.env.REACT_APP_KEYCLOAK_WITH_OTP_CLIENT_ID,
  "credentials": {
    "secret": process.env.REACT_APP_KEYCLOAK_WITH_OTP_SECRET,
  },
  ...keyCloakParams
}

self.siginOptions = [{
  value: "SSO_avec_OTP",
  label: "Utilisateur siège",
  keyCloakParams: keyCloakParamsWithOTP,
  roles: ["ROLE_ADMIN", "ROLE_CUSTOMER_SERVICE", "ROLE_MARKETING_SERVICE"]
},
{
  value: "SSO_sans_OTP",
  label: "Utilisateur magasin",
  keyCloakParams: keyCloakParamsWithoutOTP,
  roles: ["ROLE_MAGASIN"]
}];

self.getInstance = (params) => {
  if (!keyCloakObject) {
    keyCloakObject = Keycloak(params);
  }
  return keyCloakObject;
};

self.initInstance = (params) => {
  return self.getInstance(params).init({
    onLoad: 'login-required',
    "checkLoginIframe": false
  });
};

self.signinClient = async (params) => {
  return new Promise(async (resolve, reject) => {
    await self.initInstance(params).success(async (authenticated) => {
      if (authenticated) {
        console.log('authenticated***', authenticated)
        await self.getInstance(params).loadUserProfile()
          .success(async (user) => {
            await AuthService.getAndSaveServerTokenFromKeycloakToken(self.getInstance(params)).then(data => {
              resolve(true);
              if (data && window) {
                window.location.reload();
              }
            })
              .catch(error => {
                console.log(error);
                AuthService.logout();
              });
          });
      } else {
        resolve(false);
      }
    })
      .error((error) => {
        console.log("error keycloak", error);
      });
  })
};

self.logOut = () => {
  self.getInstance().logout()
    .success((data) => {
      console.log("logout success: ", data);
      return true;
    })
    .error((data) => {
      console.log("logout failure: ", data);
      return false;
    });
};

export default self;
