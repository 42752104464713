// @flow
import * as React from "react";
import SiteWrapper from "../../SiteWrapper";
import {Loader, Page} from "tabler-react";
import {withI18n} from "react-i18next";
import {useEffect, useState} from "react";
import InspirationListsService from "../../services/domain/InspirationListsService";
import InspirationListInfo from "../../components/inspiration-lists/inspiration-list-info/InspirationListInfo";

function InspirationListsDetailsPage({t, match}): React.Node {

  const [inspirationList, setInspirationList] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = `${t('navbar.inspiration-list')} : ${match.params.id}`;
    loadData();
  }, [t, match.params.id]);

  const loadData = () => {
    setLoading(true);
    return InspirationListsService.get(match.params.id)
      .then(list => setInspirationList(list))
      .finally(() => setLoading(false))
  };

  return (
    <SiteWrapper>
      <Page.Content>
        <Page.Header
          title={t('navbar.inspiration-list')}
        />
        <Page.Main>

          {loading && <Loader/>}

          {(!loading && inspirationList) && (
            <InspirationListInfo inspirationList={inspirationList} />
          )}
        </Page.Main>
      </Page.Content>
    </SiteWrapper>
  );
}

export default withI18n()(InspirationListsDetailsPage);
