// @flow

import * as React from "react";
import {useEffect} from "react";
import css from './DashboardPage.module.scss';
import {Grid, Page,} from "tabler-react";

import SiteWrapper from "../../SiteWrapper";
import CreatedPotsReport from "../../components/dashboard/created-pots-report/CreatedPotsReport";
import ActivatedPotsReport from "../../components/dashboard/activated-pots-report/ActivatedPotsReport";
import {withI18n} from 'react-i18next';
import TotalCollectedAmountReport
  from "../../components/dashboard/total-collected-amount-report/TotalCollectedAmountReport";
import TotalGiftCardGeneratedAmountReport
  from "../../components/dashboard/total-gift-card-generated-amount-report/TotalGiftCardGeneratedAmountReport";
import ParticipantsNumberReport from "../../components/dashboard/participants-number-report/ParticipantsNumberReport";
import VolumeTransformedInCC from "../../components/dashboard/volume-transformed-in-cc/VolumeTransformedInCC";
import {OriginApp} from "../../models/User";

function DashboardPage({t}): React.Node {

  useEffect(() => {
    document.title = t('navbar.dashboard');
  }, []);

  return (
    <SiteWrapper>
      <Page.Content title={t('navbar.dashboard')} className={css.container_100}>
        <Page.Header title={t('dashboard.pots')}/>
        <Grid.Row>
          <Grid.Col width={6} xs={12} sm={6} lg={4}>
            <CreatedPotsReport origin={OriginApp.CNT}/>
          </Grid.Col>
          <Grid.Col width={6} xs={12} sm={6} lg={4}>
            <ActivatedPotsReport origin={OriginApp.CNT}/>
          </Grid.Col>
          <Grid.Col width={6} xs={12} sm={6} lg={4}>
            <TotalCollectedAmountReport origin={OriginApp.CNT}/>
          </Grid.Col>
          <Grid.Col width={6} xs={12} sm={6} lg={4}>
            <TotalGiftCardGeneratedAmountReport/>
          </Grid.Col>
          <Grid.Col width={6} xs={12} sm={6} lg={4}>
            <ParticipantsNumberReport origin={OriginApp.CNT}/>
          </Grid.Col>
        </Grid.Row>
        <Page.Header title={t('dashboard.lists')}/>
        <Grid.Row>
          <Grid.Col width={6} xs={12} sm={6} lg={4}>
            <CreatedPotsReport origin={OriginApp.M1L}/>
          </Grid.Col>
          <Grid.Col width={6} xs={12} sm={6} lg={4}>
            <ActivatedPotsReport origin={OriginApp.M1L}/>
          </Grid.Col>
          <Grid.Col width={6} xs={12} sm={6} lg={4}>
            <TotalCollectedAmountReport origin={OriginApp.M1L}/>
          </Grid.Col>
          <Grid.Col width={6} xs={12} sm={6} lg={4}>
            <ParticipantsNumberReport origin={OriginApp.M1L}/>
          </Grid.Col>
          <Grid.Col width={6} xs={12} sm={6} lg={4}>
            <VolumeTransformedInCC origin={OriginApp.M1L}/>
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
    </SiteWrapper>
  );
}

export default withI18n()(DashboardPage);
