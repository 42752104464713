export const DocumentType = {
  OTHER: "OTHER",
  IDENTITY: "IDENTITY",
  ADDRESS: "ADDRESS"
};

export const DocumentStatusLabel = {
  NO_ACTION: 'NO_ACTION',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  VERIFIED: 'VERIFIED',
  TREATED: 'TREATED'
};

export const DocumentAdditionalStatus = {
  NOT_VERIFIED: 0,
  VALID: 1,
  FALSIFIED: 2,
  UNREADABLE_OR_CUT: 3,
  ITEM_MISSING_OR_OUT_OF_SCOPE: 4,
  NOT_CONSISTENT_DATA: 5,
  NOT_CONSISTENT_UNKNOWN: 6,
  EXPIRED: 6,
  VALID_BUT_NOT_COMPLETELY: 7,
  OUT_OF_SCOPE: 8
}
