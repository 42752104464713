import HttpService from "../http/HttpService";
import LinksProvider from "../http/LinksProvider";

let self = {};

  self.upload = (file) => {
    const formData = new FormData();
    formData.append("file", file);

    return HttpService
        .post(
            LinksProvider.API.FILE.FILES,
            formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then((result) => result.file);
  }

  self.delete = (file) => {
    return HttpService
        .delete(
            LinksProvider.formatUrl(LinksProvider.API.FILE.FILE, {file}))
        .then((res) => res.files);
  }

  self.send = (email, files) => {
    return HttpService
        .post(LinksProvider.formatUrl(LinksProvider.API.FILE.SEND, {email}), {files})
        .then((res) => res.user);
  }

  self.resend = (email, reference, type) => {
    return HttpService
      .post(LinksProvider.formatUrl(LinksProvider.API.FILE.RESEND, {email, reference, type}))
      .then((res) => res.files)
  }
export default self;
