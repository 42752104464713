import React from "react";

import {Card, Form, Loader, Table} from "tabler-react";
import {withI18n} from 'react-i18next';
import DateService from "../../../services/utils/DateService";
import {useState} from "react";
import {Figure} from "react-bootstrap";
import {CommercialBannerType} from "../../../models/CommercialBanner";
import css from "./CommercialBannerHistoryList.module.scss";
import CommercialBannersService from "../../../services/domain/CommercialBannersService";
import EmptyContent from "../../common/empty-content/EmptyContent";

const CommercialBannersHistoryList = ({banners, t}) => {

  const [historyBanners, setHistoryBanners] = useState(banners);
  const [historySearchKey, setHistorySearchKey] = useState(null);
  const [typeSearchKey, setTypeSearchKey] = useState(null);
  const [loading, setLoading] = useState(false);


  const loadBanners = (type) => {
    setLoading(true);
    console.log("type", type);
    console.log("typeSearchKey", typeSearchKey);
    return CommercialBannersService.searchHistory({
      search: historySearchKey,
      type: type !== undefined && type != null ? type : typeSearchKey
    })
      .then(histBanners => {
        setHistoryBanners(histBanners);
      })
      .catch(error => {
        console.log("error on loading history banners search result: ", error);
      })
      .finally(() => setLoading(false));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    loadBanners()
  };

  const onChange = (e) => {
    setHistorySearchKey(e.target.value);
  };

  const onTypeChange = (e) => {
    console.log(e.target.value);
    e.preventDefault();
    setTypeSearchKey(e.target.value);
    loadBanners(e.target.value)
  };

  const headerItems = [
    {content: t('commercial-banners.image')},
    {content: t('commercial-banners.title')},
    {content: t('commercial-banners.url')},
    {content: t('commercial-banners.banner-start-date')},
    {content: t('commercial-banners.banner-end-date')},
    {content: t('commercial-banners.banner-number')},
  ];

  const itemRow = (banner, index) => {
    return {
      key: banner.reference + index,
      item: [
        {
          content: (
            <span>
              {(banner.photo_url) ?
                (
                  <Figure>
                    <Figure.Image
                      alt={t('commercial-banners.image')}
                      width={171}
                      height={180}
                      src={banner.photo_url}/>
                  </Figure>
                ) : (
                  <div>&emsp;N/A</div>
                )}
            </span>)
        },
        {content: (<span>{banner.title}</span>)},
        {content: (<a href={banner.url} target="_blank">{banner.url}</a>)},
        {content: (<span>{DateService.formatDate(banner.start_date)}</span>)},
        {content: (<span>{DateService.formatDate(banner.end_date)}</span>)},
        {content: (<span>{banner.type + 1}</span>)},
      ]
    };
  };


  return (
    <Card>
      <Card.Header>
        <Card.Title>{t('commercial-banners.banners-history', {count: historyBanners.length})}</Card.Title>
        <Card.Options>
          <React.Fragment>
            <form onSubmit={onSubmit} className={css.search_history_form}>
              <Form.Input type="search" value={historySearchKey} onChange={onChange} icon="search"
                          placeholder={t('commercial-banners.search-history')}/>
            </form>
          </React.Fragment>
          <React.Fragment>
            <div className={css.search_type_text}>
              {t('commercial-banners.banner-number-short')}
            </div>
            <form onSubmit={onSubmit}>
              <Form.Group>
                <Form.Select onChange={onTypeChange}>
                  <option defaultValue value="">
                    Tous
                  </option>
                  {Object.values(CommercialBannerType).map(type =>
                    (<option value={type}>
                      {type + 1}
                    </option>)
                  )}
                </Form.Select>
              </Form.Group>
            </form>
          </React.Fragment>
        </Card.Options>
      </Card.Header>
      <Card.Body>
        {loading && <Loader/>}

        {((!historyBanners || !historyBanners.length) && !loading) ? (
          <EmptyContent text={t('commercial-banners.no-banners-found')}/>
        ) : ''}
        {((historyBanners && historyBanners.length) && !loading) ? (
          <Table responsive
                 className="card-table"
                 headerItems={headerItems}
                 bodyItems={historyBanners.map(itemRow)}/>
        ) : ''}

      </Card.Body>
    </Card>
  )
};

export default withI18n()(CommercialBannersHistoryList);
