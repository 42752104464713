import React, {useState, useEffect} from "react";

import DateService from '../../services/utils/DateService'
import {
    Badge, Card, Loader, Table,
} from "tabler-react";
import {withI18n} from 'react-i18next';
import EmptyContent from "../common/empty-content/EmptyContent";

import PotsService from "../../services/domain/PotsService";
import UsersService from "../../services/domain/UsersService";
import LinksProvider from "../../services/http/LinksProvider";
import {MinifiedGiftCard, GiftCardStatus, GiftCardType, GiftCardParent} from "../../models/GiftCard";
import Amount from "../common/amount/Amount";
import GiftCardModal from "../modals/gift-card-modal/GiftCardModal";

function GiftCards({reference, t, parent=GiftCardParent.POT}): React.Node {

    const [loading, setLoading] = useState(false);
    const [gift_cards, setGiftCards] = useState(null);
    const [modalGiftCard, setModalGiftCard] = useState(null);
    const [giftCardModalShown, setGiftCardModalShown] = useState(false);

    const headerItems = [
        {content : t('gift-cards.reference')},
        {content : t('gift-cards.card-number')},
        {content : t('gift-cards.type')},
        {content : t('gift-cards.expiration-date')},
        {content : t('gift-cards.balance')},
        {content : parent === GiftCardParent.POT ? t('gift-cards.buyer-email') : t('gift-cards.pot-reference')},
        {content : t('gift-cards.status')}
    ];

    const loadData = () => {
        setLoading(true);
        if (parent === GiftCardParent.POT) {
            PotsService.giftCards(reference)
                .then(gift_cards => {
                    setGiftCards(gift_cards);
                })
                .finally(() => setLoading(false));
        } else {
            UsersService.giftCards(reference)
                .then(gift_cards => {
                    setGiftCards(gift_cards);
                })
                .finally(() => setLoading(false));
        }
    };

    const GiftCardStatusBadge = (gift_card : MinifiedGiftCard) => {
        switch (gift_card.status) {
            case GiftCardStatus.NEW :
                return (<Badge color="primary">{t('gift-cards.status-new')}</Badge>);
            case GiftCardStatus.EXPIRED:
                return (<Badge color="danger">{t('gift-cards.status-expired')}</Badge>);
            case GiftCardStatus.UNKNOWN:
                return (<Badge color="warning">{t('gift-cards.status-unknown')}</Badge>);
            case GiftCardStatus.ACTIVE:
                return (<Badge color="success">{t('gift-cards.status-active')}</Badge>)
        }
    };

    const showGiftCardModal = (gift_card, event) => {
        event.preventDefault();
        setModalGiftCard(gift_card);
        setGiftCardModalShown(true);
    };

    const oneRow = (gift_card : MinifiedGiftCard) => {
        return {
            key : gift_card.reference,
            item : [
                {content : (<a style={gift_card.balance > 0 ? {} : {color: "red"}} href='#' onClick={event => showGiftCardModal(gift_card, event)}>{gift_card.reference}</a>)},
                {content : gift_card.card_number},
                {content : gift_card.type === GiftCardType.GL ? t('gift-cards.type-gl') : t('gift-cards.type-bhv')},
                {content : DateService.formatDate(gift_card.expiration_date)},
                {content : (<Amount amount={gift_card.balance}></Amount>)},
                {content : parent === GiftCardParent.POT ? (<a href={LinksProvider.formatUrl(LinksProvider.ROUTES.USER, {id : gift_card.buyer_email})}>{gift_card.buyer_email}</a>)
                    : (<a href={LinksProvider.formatUrl(LinksProvider.ROUTES.POT, {id : gift_card.pot_reference})}>{gift_card.pot_reference}</a>)
                },
                {content : (GiftCardStatusBadge(gift_card))}
            ]
        };
    };

    useEffect(() => {
        loadData();
    }, [reference]);

    return (

    <Card>
        <Card.Header>
            <Card.Title>{t('gift-cards.title')}</Card.Title>
        </Card.Header>
        <Card.Body className="fixed-height-card-body">
            {modalGiftCard && (<GiftCardModal
                giftCard={modalGiftCard}
                show={giftCardModalShown}
                onHide={() => {setGiftCardModalShown(false)}}
                reloadData={loadData}
                parent={parent}
                key={modalGiftCard}/>)}
            {((!gift_cards || !gift_cards.length) && !loading) ? (
                <EmptyContent text={parent === GiftCardParent.POT ? t('pots.no-gift-cards') : t('users.no-gift-cards')}/>
            ) : ''}
            {((gift_cards && gift_cards.length) && !loading) ? (
                <Table responsive
                className="card-table"
                headerItems={headerItems}
                bodyItems={gift_cards.map(oneRow)} />
            ) : ''}
            {loading && <Loader/>}
        </Card.Body>
    </Card>
    );
    }

export default withI18n()(GiftCards);