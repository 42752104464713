export type CreditCard = {
  reference: string,
  name: string,
  type: string,
  status: number,
  pan_hint: string,
  bank_id: string,
  opposed_reason: string,
  card_block: boolean,
  internet_payment_block: boolean,
  foreign_payment_block: boolean,
  expiration_date: Date,
  order_date: Date
};

export type MinifiedCreditCard = {
  reference: string,
  name: string,
  type: string,
  status: string,
  pan_hint: string,
  opposed_reason: string,
  card_block: boolean,
  expiration_date: Date,
  order_date: Date
};

export const CreditCardStatus = {
  ORDERED: 'ORDERED',
  SENT: 'SENT',
  ACTIVATED: 'ACTIVATED',
  EXPIRED: 'EXPIRED',
  OPPOSED: 'OPPOSED',
  FAILED: 'FAILED',
  DEACTIVATED: 'DEACTIVATED'
};

export const CARD_OPPOSED_REASON = {
  NOT_OPPOSED: '0',
  SPECIAL_CONDITIONS: '7',
  SUSPECTED_FRAUD: '34',
  LOST: '41',
  STOLEN: '43',
  ACCOUNTS_NOT_STOCKED: '51'
};

export const CreditCardType = {
  CLASSIC_VIRTUAL: 1,
  CLASSIC_PHYSICAL: 2,
  PREMIUM_VIRTUAL: 3,
  PREMIUM_PHYSICAL: 4
};

export const CreditCardParent = {
  POT: 0,
  USER: 1
};

export const CreditCardBlockStatus = {
  ACTIVE: false,
  BLOCKED: true
}
