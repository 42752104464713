// @flow
import LinksProvider from "../http/LinksProvider";
import {NavLink, withRouter} from "react-router-dom";
import type {NavItem} from "../../models/NavBar";
import AuthService from '../domain/AuthService';

const self = {};

self.getNavBarItems = (t): Array<NavItem> => {
  const navItems = [{
    value: t('navbar.home'),
    to: LinksProvider.ROUTES.HOME,
    icon: "home",
    LinkComponent: withRouter(NavLink),
    useExact: true
  }, {
    value: t('navbar.dashboard'),
    to: LinksProvider.ROUTES.DASHBOARD,
    icon: "activity",
    LinkComponent: withRouter(NavLink),
    useExact: true,
    authorization: 'AUTH_BO_DASHBOARD'
  }, {
    value: t('navbar.users'),
    to: LinksProvider.ROUTES.USERS,
    icon: "users",
    LinkComponent: withRouter(NavLink),
    authorization: 'AUTH_BO_USERS_CONSULTER'
  }, {
    value: t('navbar.pots'),
    to: LinksProvider.ROUTES.POTS,
    icon: "package",
    LinkComponent: withRouter(NavLink),
    authorization: 'AUTH_BO_POTS_CONSULTER'
  }, {
    value: t('navbar.participations'),
    to: LinksProvider.ROUTES.PARTICIPATIONS,
    icon: "credit-card",
    LinkComponent: withRouter(NavLink),
    authorization: 'AUTH_BO_PARTICIPATIONS'
  }, {
    value: t('navbar.promo-codes'),
    to: LinksProvider.ROUTES.PROMO_CODES,
    icon: "dollar-sign",
    LinkComponent: withRouter(NavLink),
    authorization: 'AUTH_BO_PROMO_CODES'
  }, {
    value: t('navbar.kyc'),
    to: process.env.REACT_APP_KYC_IFRAME === 'true' ? process.env.REACT_APP_KYC_BO_URL + LinksProvider.ROUTES.KYC : LinksProvider.ROUTES.KYC,
    icon: "file",
    LinkComponent: process.env.REACT_APP_KYC_IFRAME === 'true' ? undefined : withRouter(NavLink),
    authorization: 'AUTH_BO_KYC',
    useExact: process.env.REACT_APP_KYC_IFRAME === 'true' ? true : undefined,
    target: process.env.REACT_APP_KYC_IFRAME === 'true' ? '_blank' : undefined
  }, {
    value: t('navbar.products'),
    to: LinksProvider.ROUTES.PRODUCTS,
    icon: "shopping-bag",
    LinkComponent: withRouter(NavLink),
    authorization: 'AUTH_BO_PRODUCTS'
  }, {
    value: t('navbar.commercial-banners'),
    to: LinksProvider.ROUTES.COMMERCIAL_BANNERS,
    icon: "flag",
    LinkComponent: withRouter(NavLink),
    authorization: 'AUTH_BO_COMMERCIAL_BANNERS'
  }, {
    value: t('navbar.reports'),
    to: LinksProvider.ROUTES.REPORTS,
    icon: "clipboard",
    LinkComponent: withRouter(NavLink),
    authorization: 'AUTH_REPORT_MANAGER'
  }, {
    value: t('navbar.publications'),
    to: LinksProvider.ROUTES.PUBLICATIONS,
    icon: "file-text",
    LinkComponent: withRouter(NavLink),
    authorization: 'AUTH_EDITORIAL_MANAGER'
  },
  {
    value: t('navbar.inspiration-lists'),
    to: LinksProvider.ROUTES.INSPIRATION_LISTS,
    icon: "list",
    LinkComponent: withRouter(NavLink),
    authorization: 'AUTH_INSPIRATION_LIST_ADMIN'
  },
  {
    value: t('navbar.partners'),
    to: LinksProvider.ROUTES.COMMERCIAL_PARTNERS,
    icon: "navigation",
    LinkComponent: withRouter(NavLink),
    authorization: 'AUTH_PARTNER_ADMIN'
  }];

  const principal = AuthService.getPrincipal();

  if (!principal) {
    return [navItems[0]];
  }

  return navItems.filter(item => !item.authorization
      || AuthService.userAuthorizations.includes(item.authorization));
};

export default self;
