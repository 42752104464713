// @flow
import React, {Fragment, useEffect, useState} from "react";

import DateService from '../../../services/utils/DateService'
import {Card, Loader, Table,} from "tabler-react";
import {withI18n} from 'react-i18next';
import EmptyContent from "../../common/empty-content/EmptyContent";
import UsersService from "../../../services/domain/UsersService";
import LinksProvider from "../../../services/http/LinksProvider";
import Amount from "../../common/amount/Amount";
import StrongAuthService from "../../../services/utils/StrongAuthService";
import {PotCategory} from "../../../models/Pot";

function UserCashOuts({email, t}): React.Node {

  const [loading, setLoading] = useState(false);
  const [cashOuts, setCashOuts] = useState(null);

  const headerItems = [{content: t('cash-outs.reference')}, {content: t('cash-outs.pot')}, {content: t('cash-outs.amount')},
    {content: t('cash-outs.fee')}, {content: t('cash-outs.transferred-amount')}, {content: t('cash-outs.status')},
    {content: t('cash-outs.iban')}, {content: t('cash-outs.creation-date')}];

  const loadData = () => {
    setLoading(true);
    UsersService.getCashOuts(email)
        .then(cashOuts => {
          setCashOuts(cashOuts);
        })
        .finally(() => setLoading(false));
  };

  const oneRow = (cashOut: cashOut) => {
    return {
      key: cashOut.reference, item: [{
        content: (<span>{cashOut.reference}</span>)
      }, {
        content: (
            <a href={LinksProvider.formatUrl(LinksProvider.ROUTES.POT, {id: cashOut.pot_reference})}>{cashOut.pot_reference}</a>)
      }, {
        content: (<Amount amount={cashOut.amount}/>)
      },

        {
          content: (<Amount amount={cashOut.fee_amount}/>)
        }, {
          content: (<Amount amount={cashOut.amount - cashOut.fee_amount}/>)
        }, {
          content: (<div>
          <span className={`badge ${cashOut.status
          == 1 ? 'badge-danger' : 'badge-success'}`}>{t(`cash-outs.status-${cashOut.status}`)}</span>
          </div>)
        }, {

          content: (<React.Fragment>
            <span>{cashOut.rib_iban}</span>
            {cashOut.is_banned && (<span className="badge badge-danger">{t('ribs.banned')}</span>)}
          </React.Fragment>)
        }, {
          content: DateService.formatTime(cashOut.creation_date)
        }]
    };
  };

  useEffect(() => {
    loadData();
  }, []);

  return (<Fragment>
    <Card>
      <Card.Header>
        <Card.Title>{t('cash-outs.title')}</Card.Title>
      </Card.Header>
      <Card.Body className="fixed-height-card-body">
        {((!cashOuts || !cashOuts.length) && !loading) ? (
            <EmptyContent text={t('users.no-cash-outs')}/>) : ''}
        {((cashOuts && cashOuts.length) && !loading) ? (<Table responsive
                                                               className="card-table"
                                                               headerItems={headerItems}
                                                               bodyItems={cashOuts.map(oneRow)}/>) : ''}
        {loading && <Loader/>}
      </Card.Body>
    </Card>
  </Fragment>);
}

export default withI18n()(UserCashOuts);
