// @flow

export type Participation = {
  reference: string,
  status: number,
  amount: number,
  require_3ds: boolean,
  treatment_date: Date,
  hide_identity: boolean,
  hide_amount: boolean,
  refund_date: Date,
}

export type MinifiedParticipation = {
  reference: string,
  status: number,
  amount: number,
  require_3ds: boolean,
  treatment_date: Date,
  creation_date : Date
}

export const ParticipationStatus = {
  NOT_PAYED : 0,
  BANK_ERROR : 1,
  BANK_OK : 2,
  REFUNDED : 3,
  REFUNDED_AFTER_3DS_FAIL : 4
};

export const CardError = {
  NO_ERROR : 0,
  THE_MERCHANT_NEEDS_TO_CONTACT_THE_CARD_HOLDERS_BANK : 1,
  PAYMENT_REFUSED : 2,
  PAYMENT_CANCELLED_BY_THE_CLIENT : 3,
  HOLDER_NOT_3DS_ENROLLED : 4,
  THREE_DS_AUTH_ERROR : 5,
  SYSTEM_PAY_TECHNICAL_ERROR : 6,
  PSP_TRANSACTION_NOT_FOUND : 7,
  REQUEST_ERROR_DALENYS : 10,
  REFERENCE_ERROR_DALENYS : 11,
  SHOP_CONFIG_ERROR_DALENYS : 12,
  CARD_HOLDER_BANK_ERROR_DALENYS : 13,
  SYSTEM_ERROR_DALENYS : 14,
  ANTI_FRAUD_ENGINE_ERROR_DALENYS : 15,
  WAIT_FOR_3D_SECURE : 16,
  UNKNOWN: 99
};

export const CardExecCode = {
  SUCCESSFUL : '0000',
  THREE_DS_REQUIRED : '0001',
  REDIRECT_TRANSACTION : '0002',
  PENDING_TRANSACTION : '0003',
  PARTIALLY_ACCEPTED : '0004',
  MISSING_PARAMETER : '1001',
  INVALID_PARAMETER : '1002',
  HASH_ERROR : '1003',
  UNSUPPORTED_PROTOCOL : '1004',
  BAD_REQUEST: '1005',
  FORBIDDEN_PARAMETERS : '1006',
  DIFFERENT_AMOUNT : '1007',
  ALIAS_NOT_FOUND : '2001',
  REFERENCE_NOT_FOUND : '2002',
  REFERENCE_NOT_COMPLETED : '2003',
  REFERENCE_NOT_REFUNDABLE : '2004',
  REFERENCE_NOT_CAPTURABLE : '2005',
  REFERENCE_NOT_FINISHED : '2006',
  INVALID_CAPTURE_AMOUNT : '2007',
  INVALID_REFUND_AMOUNT : '2008',
  EXPIRED_AUTHORIZATION : '2009',
  SCHEDULE_NOT_FOUND : '2010',
  SCHEDULE_INTERRUPTED : '2011',
  SCHEDULE_FINISHED : '2012',
  FILE_NOT_FOUND : '2013',
  INVALID_FILE : '2014',
  REFERENCE_NOT_VOIDABLE : '2015',
  RESOURCE_ALREADY_EXISTS : '2016',
  OPERATION_NOT_AVAILABLE : '2017',
  HFTOKEN_NOT_FOUND : '2018',
  INVALID_VOID_AMOUNT : '2019',
  ALIAS_EXPIRED : '2020',
  DISABLED_ACCOUNT : '3001',
  UNAUTHORIZED_IP : '3002',
  UNAUTHORIZED_TRANSACTION : '3003',
  TRANSACTION_LIMIT_EXCEEDED : '3004',
  TRANSACTION_DECLINED_BY_BANKING_NETWORK : '4001',
  INSUFFICIENT_FUNDS : '4002',
  CARD_DECLINED_BY_BANKING_NETWORK : '4003',
  FRAUD_SUSPICION : '4005',
  THREE_DS_FAILED : '4008',
  THREE_DS_EXPIRED : '4009',
  INVALID_TRANSACTION : '4010',
  DUPLICATED_TRANSACTION : '4011',
  INVALID_CARD_DATA : '4012',
  TRANSACTION_DECLINED_BY_BANKING_NETWORK_HOLDER : '4013',
  CARD_NOT_ENROLLED_THREE_DS : '4014',
  EXPIRED_TRANSACTION : '4015',
  TRANSACTION_DECLINED_PAYMENT_TERMINAL : '4016',
  FORM_EXPIRATION : '4017',
  RECURRING_PAYMENT : '4018',
  BANK_WILL_DECLINE_TRANSACTIONS : '4019',
  OPERATION_NOT_ALLOWED : '4021',
  EXCHANGE_PROTOCOL_FAILURE : '5001',
  BANKING_NETWORK_ERROR : '5002',
  TIME_OUT : '5004',
  THREE_DS_DISPLAY_ERROR : '5005',
  UNEXPECTED_RESPONSE : '5006',
  DECLINED_BY_MERCHANT : '6001',
  TRANSITION_DECLINED : '6002',
  CARD_HOLDER_DISPUTED : '6003',
  DECLINED_BY_PLATFORM : '6004',
  THREE_DS_UNAVAILABLE : '6005',
  BLOCKED_PAYMENT_METHOD : '6006',
  OPERATION_PROHIBITED : '6007'
};