export type GiftCard = {
    reference: string,
    status: number,
    amount: number,
    balance: number,
    expiration_date: Date,
    activation_date: Date,
    creation_date: Date,
    buyer_email: string,
    pot_reference: string,
    type: number,
    card_number: string,
    bonus: number
};

export type MinifiedGiftCard = {
    status: number,
    balance: number,
    expiration_date: Date,
    type: number,
    buyer_email: string,
    card_number: string
};

export const GiftCardStatus = {
    NEW : 0,
    ACTIVE : 1,
    EXPIRED : 2,
    UNKNOWN : 3
};

export const GiftCardType = {
    GL : 0,
    BHV : 1
};

export const GiftCardParent = {
    POT: 0,
    USER: 1
};