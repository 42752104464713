import React, {useEffect, useState} from "react";


import {
  Alert,
  Badge, Button,
  Card, Dimmer, Form, Grid, Icon, ProgressCard, StatsCard, Tooltip
} from "tabler-react";
import Editable from "../../common/editable/Editable";
import {withI18n} from "react-i18next";
import PromoCodesService from "../../../services/domain/PromoCodesService";
import ConfirmModal from "../../modals/confirm-modal/ConfirmModal";
import {useToasts} from "react-toast-notifications";
import Amount from "../../common/amount/Amount";
import PromoCodeAddAsBannerModal from "../../modals/promo-code-add-as-banner-modal/PromoCodeAddAsBannerModal";
import DateService from "../../../services/utils/DateService";
import ErrorHandler from "../../../services/error/ErrorHandler";
import CurrencyService from "../../../services/utils/CurrencyService";



const PromoCodeInfo = ({t, promoCode}) => {

  const [model, setModel] = useState(promoCode);
  const [loading, setLoading] = useState(false);
  const [confirmationModalShown, setConfirmationModalShown] = useState(false);
  const [promoCodeBannerModalShown, setPromoCodeBannerModalShown] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const {addToast} = useToasts();


  const loadData = () => {
    setLoading(true);
    PromoCodesService.get(promoCode.reference)
      .then(promoCode => {
        setModel(promoCode)
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadData();
  }, [t]);

  const updatePromoCode = (request) => {
    setLoading(true);
    return PromoCodesService.update(promoCode.reference, request)
      .then(promoCode => {
        loadData();
      }).catch(error => {
          ErrorHandler.handleError(addToast, t, error);
        })
      .finally(() => setLoading(false));
  };

  const editPromoCodeInfo = (field) => {
    return (val) => {
      const request = {};

      if (field == "expiration_date" || field == "activation_date") {
          request[field] = DateService.formatDateISO(val);
        } else if(field == "amount" || field == "min_collected_amount") {
        request[field] = val * 100;
      } else {
        request[field] = val;
      }
      setModel({...model});
      updatePromoCode(request);
    }
  };

  const enable = () => {
    setConfirmationText(t('promo-codes.enable-confirm'));
    setConfirmationModalShown(true);
  };

  const disable = () => {
    setConfirmationText(t('promo-codes.disable-confirm'));
    setConfirmationModalShown(true);
  };

  const unmarkAsBanner = () => {
    return PromoCodesService.unmarkAsBanner(promoCode.reference)
        .then(promoCode => {
          loadData();
        })
        .finally(() => setLoading(false));
  }


  const changeActivity = () => {
    const request = {is_active: !model.is_active};
    return updatePromoCode(request).then((data) => {
      loadData();
      setConfirmationModalShown(false);
      return data;
    });
  };

  return (
    <Grid.Row cards deck>
      <ConfirmModal
        confirmationText={confirmationText}
        onConfirm={changeActivity}
        show={confirmationModalShown}
        onHide={() => setConfirmationModalShown(false)}/>
      <PromoCodeAddAsBannerModal
          afterUpdate={()=> window.location.reload()}
          promoCodePoolType={model.pool_type}
          promoCodeReference={model.reference}
          show={promoCodeBannerModalShown}
          onHide={() => setPromoCodeBannerModalShown(false)}/>
      <Grid.Col width={12}>
        <Card>
          <Card.Header>
            <Card.Title>
              {model.reference}
              &nbsp;
              {(model.is_banner !=null) ? (<span><Icon name="flag" /> {t('promo-codes.banner-of-'+ model.is_banner)}</span>) : null}
            </Card.Title>
            <Card.Options>
              {/* TODO disable promocode */}
              {model.is_banner == null && (
                  <Grid.Col>
                    <Button color="warning" size="sm" onClick={() => setPromoCodeBannerModalShown(true)}>
                      {t('promo-codes.mark-as-banner')}
                    </Button>
                  </Grid.Col>
              )}
              {model.is_banner != null && (
                  <Grid.Col>
                    <Button color="warning" size="sm" onClick={unmarkAsBanner}>
                      {t('promo-codes.unmark-as-banner')}
                    </Button>
                  </Grid.Col>
              )}
              {model.is_active && (
                  <Grid.Col>
                    <Button color="danger" size="sm" onClick={disable}>
                      {t('promo-codes.disable')}
                    </Button>
                  </Grid.Col>
              )}
              {!model.is_active && (
                  <Grid.Col>
                    <Button color="success" size="sm" onClick={enable}>
                      {t('promo-codes.enable')}
                    </Button>
                  </Grid.Col>
              )}
            </Card.Options>
          </Card.Header>
          <Card.Body>
            <Dimmer active={loading} loader>
            <Grid.Row>


              <Grid.Col width={4}>
                <Form.Group label={t('promo-codes.pool-type')}>
                  {t(`promo-codes.pool_type_${model.pool_type}`)}
                </Form.Group>
              </Grid.Col>

              <Grid.Col width={4}>
                <Form.Group label={t('promo-codes.activation-date')}>
                  {model.eligible_uses < 1 && (
                    <Editable
                      initialValue={model.activation_date}
                      isValueClickable={true}
                      undefinedText={t('globals.no-value')}
                      onSubmit={editPromoCodeInfo('activation_date')}
                      type="date"/>
                  )}
                  {model.eligible_uses >= 1 && DateService.formatDate(model.activation_date)}
                </Form.Group>
              </Grid.Col>

              <Grid.Col width={4}>
                <Form.Group label={t('promo-codes.expiration-date')}>
                  {model.eligible_uses < 1 && (
                    <Editable
                      initialValue={model.expiration_date}
                      isValueClickable={true}
                      undefinedText={t('globals.no-value')}
                      onSubmit={editPromoCodeInfo('expiration_date')}
                      type="date"/>
                    )}
                  {model.eligible_uses >= 1 && DateService.formatDate(model.expiration_date)}
                </Form.Group>
              </Grid.Col>


              <Grid.Col width={4}>
                <Form.Group label={t('promo-codes.amount')}>
                  {model.actual_uses < 1 && (
                    <Editable
                      initialValue={CurrencyService.centsToEuro(model.amount)}
                      isValueClickable={true}
                      undefinedText={t('globals.no-value')}
                      onSubmit={editPromoCodeInfo('amount')}
                      type="textfield"/>
                  )}

                  {model.actual_uses >= 1 &&(
                    <Amount amount={model.amount}></Amount>
                  )}
                </Form.Group>
              </Grid.Col>

              <Grid.Col width={4}>
                <Form.Group label={t('promo-codes.min-participant')}>
                  {model.actual_uses < 1 && (
                    <Editable
                      initialValue={model.min_participants_number}
                      isValueClickable={true}
                      undefinedText={t('globals.no-value')}
                      onSubmit={editPromoCodeInfo('min_participants_number')}
                      type="textfield"/>
                  )}
                  {model.actual_uses >= 1 && (<React.Fragment>{model.min_participants_number ? model.min_participants_number : t('globals.no-value')}</React.Fragment>)}
                </Form.Group>
              </Grid.Col>

              <Grid.Col width={4 }>
                <Form.Group label={t('promo-codes.min-amount')}>
                  {model.actual_uses < 1 && (
                    <Editable
                      initialValue={CurrencyService.centsToEuro(model.min_collected_amount)}
                      isValueClickable={true}
                      undefinedText={t('globals.no-value')}
                      onSubmit={editPromoCodeInfo('min_collected_amount')}
                      type="textfield"/>
                  )}
                  {model.actual_uses >= 1 &&(
                      <Amount amount={model.min_collected_amount}></Amount>
                  )}
                </Form.Group>
              </Grid.Col>

              <Grid.Col width={4}>
                <Form.Group label={t('promo-codes.limit-date')}>
                  {model.actual_uses < 1 && (
                    <Editable
                      initialValue={model.duration_before_reaching_threshold}
                      isValueClickable={true}
                      undefinedText={t('globals.no-value')}
                      onSubmit={editPromoCodeInfo('duration_before_reaching_threshold')}
                      type="textfield"/>
                  )}
                  {model.actual_uses >= 1 && (<React.Fragment>{model.duration_before_reaching_threshold ? model.duration_before_reaching_threshold : t('globals.no-value')}</React.Fragment>)}
                </Form.Group>
              </Grid.Col>


                <Grid.Col width={4}>
                  <Form.Group label={t('promo-codes.quantity')}>
                    {model.eligible_uses < 1 && (
                    <Editable
                      initialValue={model.quantity}
                      isValueClickable={true}
                      undefinedText={t('globals.no-value')}
                      onSubmit={editPromoCodeInfo('quantity')}
                      type="textfield"/>
                    )}

                    {model.eligible_uses >= 1 &&(
                      model.quantity
                    )}
                  </Form.Group>
                </Grid.Col>




              <Grid.Col width={4}>
                <Form.Group label={t('promo-codes.used-active')}>
                   {model.eligible_uses}
                </Form.Group>
              </Grid.Col>

              <Grid.Col width={4}>
                <Form.Group label={t('promo-codes.used-incative')}>
                  {model.non_eligible_uses}
                </Form.Group>
              </Grid.Col>

              <Grid.Col width={4}>
                <Form.Group label={t('promo-codes.offered-pot')}>
                   {model.offered_pot_uses}
                </Form.Group>
              </Grid.Col>

              {(model.is_banner !=null) ? (
                  <Grid.Col width={12}>
                    <Form.Group label={t('promo-codes.banner-text')}>
                      {model.banner_text}
                    </Form.Group>
                  </Grid.Col>
              ) : null}

            </Grid.Row>
            </Dimmer>
          </Card.Body>
        </Card>
      </Grid.Col>
    </Grid.Row>
  )
};

export default withI18n()(PromoCodeInfo);