// @flow
import * as React from "react";
import {useEffect} from "react";
import SiteWrapper from "../../SiteWrapper";
import {Page} from "tabler-react";
import {withI18n} from 'react-i18next';
import SearchForm from "../../components/common/search-form/SearchForm";
import LinksProvider from "../../services/http/LinksProvider";

function UsersPage({t}): React.Node {

  useEffect(() => {
    document.title = t('navbar.users')
  }, [t]);

  return (<SiteWrapper>
    <Page.Content>
      {/*
        {authenticated && keycloak && (
*/}
      <Page.Header
          title={t('navbar.users')}
          options={(<SearchForm placeholder={t('users.search-placeholder')}
                                route={LinksProvider.ROUTES.USER}/>)}/>
      {/*
        )}
*/}
    </Page.Content>)

  </SiteWrapper>);

}

export default withI18n()(UsersPage);
