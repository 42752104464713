/**
 * RequestInterceptor
 * @module Services/http/RequestInterceptor
 * @description Returns a RequestInterceptor function that'll intercept every http request
 */

import AuthService from '../domain/AuthService';

/**
 * Intercepts every http request and adds the jwt access token to the header
 * @author Sameh Bellez
 * @alias RequestInterceptor
 * @memberof module:Services/http/RequestInterceptor
 * @param   {object} config The request config
 * @returns {object}  The request config
 */
function RequestInterceptor(config){
  config.keepOriginalResponse = config.headers['Keep-Original-Response'];
  delete config.headers['Keep-Original-Response'];
  config.headers['Content-Type'] = 'application/json';
  AuthService.isAuthenticated() && (config.headers['Authorization'] = `Bearer ${AuthService.getToken()}`);
  return config;
}
export default RequestInterceptor;