// @flow
import * as React from "react";
import SiteWrapper from "../../SiteWrapper";
import {Button, Grid, Loader, Page} from "tabler-react";
import {withI18n} from "react-i18next";
import {useEffect, useState} from "react";
import {useToasts} from 'react-toast-notifications';
import GiftCardReport from "../../components/reports/gift-card-report/GiftCardReport";
import PromoCodeCardReport from "../../components/reports/promo-code-card-report/PromoCodeCardReport";
import CreReport from "../../components/reports/cre-report/CreReport";
import KycRemediationUpload from "../../components/kyc/kyc-remediation-upload";
import ReportService from "../../services/domain/ReportService";
import InfoWidget from "../../components/common/info-widget/InfoWidget";
import Amount from "../../components/common/amount/Amount";
import {CreType} from "../../models/Cre";


function ReportsPage({t}): React.Node {
    const {addToast} = useToasts();
    const [loading, setLoading] = useState(true);
    const [workingCapital, setWorkingCapital] = useState(null);


    useEffect(() => {
        document.title = t('navbar.reports');
        ReportService.getWorkingCapital()
            .then(account => setWorkingCapital(account.amount));
    }, [t]);

    return (
        <SiteWrapper>
            <Page.Content>
                <Page.Header
                    title={t('navbar.reports')}
                />
                <Grid.Row>
                    <Grid.Col sm={12} lg={6}>
                        <InfoWidget color="yellow" icon="dollar-sign"
                                    content={<Amount amount={workingCapital}/>}
                                    text={t('reports.working-capital')}/>
                    </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                    <GiftCardReport/>
                </Grid.Row>
                <Grid.Row>
                    <PromoCodeCardReport/>
                </Grid.Row>
                <Grid.Row>
                    <CreReport/>
                </Grid.Row>
                <Grid.Row>
                    <KycRemediationUpload/>
                </Grid.Row>
                {/* <Grid.Row>
                    <CreReport creType={CreType.M1L}/>
                </Grid.Row> */}
            </Page.Content>
        </SiteWrapper>
    );
}

export default withI18n()(ReportsPage);
