// @flow
import * as React from "react";
import {useState, useEffect} from "react";
import ReactMapboxGl, { Layer, Feature, Marker } from "react-mapbox-gl";
import mapBoxSdk from 'mapbox';



function Location({city, country, marker}): React.Node {

  const [feature, setFeature] = useState(null);
  const [loading, setLoading] = useState(false);

  const initMap = () => {

    let mapBoxClient = new mapBoxSdk(process.env.REACT_APP_MAP_BOX_TOKEN);
    setLoading(true);
    mapBoxClient.geocodeForward(`${city}, ${country}`)
        .then(function(response) {
          if (response && response.entity && response.entity.features && response.entity.features.length) {
            setFeature(response.entity.features[0]);
          }
        })
        .finally(() => setLoading(false));
  };

  useEffect(() => {
    initMap();
  }, []);


  const Map = ReactMapboxGl({
    accessToken: process.env.REACT_APP_MAP_BOX_TOKEN,
    interactive : false
  });

  return (
      (feature && !loading) && (<Map
          style="mapbox://styles/mapbox/light-v10"
          center={feature.center}
          zoom={[10]}
          containerStyle={{
            height: "100%",
            width: "100%"
          }}>
        <Marker
            coordinates={feature.center}
            anchor="bottom">
          <img src={marker} width="25px"/>
        </Marker>
      </Map>)

  );
}

export default Location;