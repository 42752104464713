import React from "react"
import {Form} from "tabler-react";
import DateService from '../../../services/utils/DateService'

export default class DateTime extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    let date = this.props.value && DateService.isValid(this.props.value) ? DateService.formatTime(this.props.value) : this.props.value;
    return (
        <React.Fragment>
          <Form.MaskedInput
              value={date}
              onChange={e => this.props.setNewValue(e.target.value)}
              size="sm" className="mr-1"
              placeholder="JJ/MM/AAAA HH:mm"
              mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, " ", /\d/ , /\d/, ":", /\d/ , /\d/ ]}/>
          {this.props.controls}
        </React.Fragment>
    )
  }
}