import React, {useState, useEffect} from "react";

import DateService from '../../../services/utils/DateService'
import {
  Badge, Card, Loader, Table,
} from "tabler-react";
import {withI18n} from 'react-i18next';
import EmptyContent from "../../common/empty-content/EmptyContent";
import UsersService from "../../../services/domain/UsersService";
import LinksProvider from "../../../services/http/LinksProvider";
import type {MinifiedPot} from "../../../models/Pot";
import {PotStatus} from "../../../models/Pot";
import Amount from "../../common/amount/Amount";


function UserOfferedPots({email, t}): React.Node {

  const [loading, setLoading] = useState(false);
  const [pots, setPots] = useState(null);

  const headerItems = [
    {content: t('pots.reference')},
    {content: t('pots.name')},
    {content: t('pots.collected-amount')},
    {content: t('pots.creation-date')},
    {content: t('pots.status')}
  ];

  const loadData = () => {
    setLoading(true);
    UsersService.offeredPots(email)
      .then(pots => {
        setPots(pots);
      })
      .finally(() => setLoading(false));
  };

  const potStatusBadge = (pot: MinifiedPot) => {
    switch (pot.status) {
      case PotStatus.ARCHIVED :
        return (<Badge color="warning">{t('pots.archived')}</Badge>);
      case PotStatus.DELETED :
        return (<Badge color="danger">{t('pots.deleted')}</Badge>);
      case PotStatus.DEACTIVATED :
        return (<Badge color="danger">{t('pots.deactivated')}</Badge>);
      case PotStatus.RUNNING :
        return (<Badge color="success">{t('pots.running')}</Badge>)
    }
  };

  const onePotLine = (pot: MinifiedPot) => {
    return {
      key: pot.reference,
      item: [
        {
          content: (
            <a href={LinksProvider.formatUrl(LinksProvider.ROUTES.POT, {id: pot.reference})}>{pot.reference}</a>)
        },
        {content: pot.name},
        {content: (<Amount amount={pot.collected_amount}></Amount>)},
        {content: DateService.formatTime(pot.creation_date)},
        {content: (potStatusBadge(pot))}
      ]
    };
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Card title={t('users.offered-pots')}>
      <Card.Body className="fixed-height-card-body">
        {((!pots || !pots.length) && !loading) ? (
          <EmptyContent text={t('users.no-offered-pots')}/>
        ) : ''}
        {((pots && pots.length) && !loading) ? (
          <Table responsive
                 className="card-table"
                 headerItems={headerItems}
                 bodyItems={pots.map(onePotLine)}/>
        ) : ''}
        {loading && <Loader/>}
      </Card.Body>
    </Card>
  );
}

export default withI18n()(UserOfferedPots);
