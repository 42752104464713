/**
 * CurrencyService
 * @module Services/utils/CurrencyService
 * @description Offers utility functions to handle currencies
 */


const self = {};


/**
 * Converts cents to euro
 * @author Sameh Bellez
 * @alias centsToEuro
 * @memberof module:Services/utils/CurrencyService
 * @param   {number} amount The amount in cents
 * @returns {number}  The amount in euros
 */
self.centsToEuro = (amount: number): number => {
  const res = (amount / 100).toFixed(2);

  return res.toString()
    .replace('.00', '')
    .replace(',00', '');
};

/**
 * Converts euro to cents
 * @author Seif Khiari
 * @alias EuroToCents
 * @memberof module:Services/utils/CurrencyService
 * @param   {number} amount The amount in euros
 * @returns {number}  The amount in cents
 */
self.EuroToCents = (amount: number): number => {
  return parseFloat(`${amount}`).toFixed(2) * 100;
};


export default self;