import React, {Fragment} from "react";
import {Card, Loader, Page, Table, Tag, Badge, Grid} from "tabler-react";
import {Image, Button, ButtonToolbar, Figure} from "react-bootstrap";
import {withI18n} from 'react-i18next';
import DateService from "../../../services/utils/DateService";
import {useEffect, useState} from "react";
import TablePagination from "../../common/table-pagination.js/TablePagination";
import CommercialPartnersService from "../../../services/domain/CommercialPartnersService";
import ErrorHandler from "../../../services/error/ErrorHandler";
import {useToasts} from "react-toast-notifications";
import LinksProvider from "../../../services/http/LinksProvider";
import {CommercialPartnerStatus} from "../../../models/CommercialPartner";

const PublicationsList = ({partners, searchQueryCount, t}) => {

  const {addToast} = useToasts();

  const partnersPerPage = 8;
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [listPartners, setListPartners] = useState(partners);
  const [queryCount, setQueryCount] = useState(searchQueryCount);
  const [pageCount, setPageCount] = useState(Math.ceil(queryCount / partnersPerPage));

  const handlePageClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
    loadData(partnersPerPage, index * partnersPerPage)
  };

  const loadData = (limit, offset) => {
    setLoading(true);
    CommercialPartnersService.getAll(limit, offset)
      .then(data => {
        setListPartners(data.commercial_partners);
        setQueryCount(data.original_result_count);
        setPageCount(Math.ceil(queryCount / partnersPerPage));
      })
      .finally(() => setLoading(false));
  };

  const handleToggleStatus = (reference, status) => {
    setLoading(true);
    return CommercialPartnersService.update(reference, {status})
      .then(async data => {
        await loadData();
      })
      .catch(error => ErrorHandler.handleError(addToast, t, error))
      .finally(() => {
        setLoading(false);
      });
  };

  const headerItems = [
    {content: t('partners.preview-photo')},
    {content: t('partners.name')},
    {content: t('partners.creation-date')},
    {content: t('partners.status')},
    {content: null}
  ];

  const itemRow = (partner) => {
    return {
      key: partner.reference,
      item: [
        {
          content: (
            <span>
              {(partner.photo_url) ?
                (
                  <Figure>
                    <Figure.Image
                      alt={t('partners.preview-photo')}
                      width={171}
                      height={180}
                      src={partner.photo_url}/>
                  </Figure>
                ) : (
                  <div>&emsp;N/A</div>
                )}
            </span>)
        },
        {content: (<span>{partner.name}</span>)},
        {content: (<span>{DateService.formatDate(partner.creation_date)}</span>)},
        {
          content: (<span><Tag
            color={partner.status === CommercialPartnerStatus.INACTIVE ? 'orange' : 'green'}>&emsp;</Tag></span>)
        },
        {
          content: (
            <Grid.Row className="flex-column">
              <Button className="mt-1" variant="primary" onClick={()=> {handleToggleStatus(partner.reference, partner.status === CommercialPartnerStatus.ACTIVE ? CommercialPartnerStatus.INACTIVE : CommercialPartnerStatus.ACTIVE)}}>
                {t(partner.status === CommercialPartnerStatus.ACTIVE ? 'partners.deactivate' : 'partners.activate')}
              </Button>
              <Button className="mt-1" variant="primary" onClick={() => {window.location = LinksProvider.formatUrl(LinksProvider.ROUTES.COMMERCIAL_PARTNERS) + '?reference=' + partner.reference}}>
                {t('partners.modify')}
              </Button>
            </Grid.Row>)
        }
      ]
    };
  };


  return (
    <Fragment>
      <Card title={t('partners.list-partners', {count: queryCount})}>
        {loading && <Loader/>}

        {(!loading) && (
          <Card.Body>
            <Table responsive
                   className="card-table"
                   headerItems={headerItems}
                   verticalAlign="center"
                   bodyItems={listPartners.map(itemRow)}/>
          </Card.Body>
        )}
        <Card.Footer>
          <TablePagination
            pageCount={pageCount}
            currentPage={currentPage}
            handlePageClick={handlePageClick}
          />
        </Card.Footer>
      </Card>
    </Fragment>

  )
};

export default withI18n()(PublicationsList);
