import * as React from 'react'

import {Card, ListGroup, ListGroupItem} from "react-bootstrap";
import {withI18n} from 'react-i18next';
import {DocumentType} from "../../../../models/VerificationDocument";
import UserDocument from "../user-document/UserDocument";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUser} from '@fortawesome/free-solid-svg-icons';
import KycService from "../../../../services/domain/KycService";


const UserDocuments = ({t, documents, user, onUserUpdated}) => {

  const getDocumentsWithType = (docs, type) => {
    return docs.filter(doc => doc.type == type);
  };

  const [identityDocuments, setIdentityDocuments] = React.useState(
      getDocumentsWithType(documents, DocumentType.IDENTITY));

  const loadData = () => {
    KycService.getRefusedUser(user.email)
        .then((data) => {
          setIdentityDocuments(
              getDocumentsWithType(data.verification_documents, DocumentType.IDENTITY));
          onUserUpdated(data);
        })
  };

  return (
      <React.Fragment>
        {(identityDocuments && identityDocuments.length > 0) && (
            <Card className="card-profile mb-3">
              <Card.Header className="h-25">
                <FontAwesomeIcon icon={faUser}/>&nbsp;&nbsp;
                {t('kyc-document.identity-documents')}
              </Card.Header>
              <Card.Body className="text-center">
                <ListGroup className="list-group-flush">
                  {identityDocuments.map((document, index) => (
                      <ListGroupItem key={index}>
                        <UserDocument refreshData={() => loadData()} document={document}
                                      user={user}></UserDocument>
                      </ListGroupItem>
                  ))}
                </ListGroup>
              </Card.Body>
            </Card>
        )}
      </React.Fragment>
  );

};

export default withI18n()(UserDocuments);
