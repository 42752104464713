import React from 'react';
import {withI18n} from "react-i18next";
import css from "./InputError.module.scss";

import {ErrorMessage} from "formik";

const InputError = ({name, t, className}) => {

  const render = (error) => {
    return (<div className={className || css.error_message}>{t(`errors.E_${error.toUpperCase()}_${name.toUpperCase()}`)}</div>)
  };

  return (
      <ErrorMessage name={name} component="div" render={render}/>)
};

export default withI18n()(InputError);