import React from "react"
import {Input} from "reactstrap";

export default class Select extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const options = this.props.options.map((value) => {
      return <option value={value.value}>{value.label}</option>
    });

    if (!this.props.value && this.props.value !== 0) {
      this.props.setNewValue(this.props.options[0].value);
    }

    return (<React.Fragment>
          <Input value={this.props.value} onChange={e => this.props.setNewValue(e.target.value)}
                 type="select" sbSize="sm" className="mr-1">{options}</Input>
          {this.props.controls}
        </React.Fragment>)
  }
}
