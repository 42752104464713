// @flow
import * as React from "react";
import {Form} from "tabler-react";
import {useState, useEffect} from "react";
import {Redirect} from "react-router-dom";
import LinksProvider from '../../../services/http/LinksProvider';
import css from './SearchForm.module.scss';

function SearchForm(props): React.Node {

  const [search, setSearch] = useState(props.value ? decodeURIComponent(props.value) : null);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    setRedirect(false);
  });

  const onSubmit = (e) => {
    e.preventDefault();
    if (search === undefined || search === null) {
      return false;
    }
    setRedirect(true);
    return false;
  };

  const onChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    redirect ?
      (props.injectParam ?
          (window.location = LinksProvider.formatUrl(props.route) + '?search=' + search) :
          (<Redirect push to={LinksProvider.formatUrl(props.route, {id: encodeURIComponent(search)})}/>)
      ) :
      (<React.Fragment>
        <form onSubmit={onSubmit} className={css.search_form}>
          <Form.Input type="search" value={search} onChange={onChange} icon="search" placeholder={props.placeholder}/>
        </form>
      </React.Fragment>)
  );
}

export default SearchForm;
