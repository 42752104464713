// @flow
import * as React from "react";
import {useEffect, useState} from "react";
import SiteWrapper from "../../SiteWrapper";
import {Loader, Page} from "tabler-react";
import {withI18n} from "react-i18next";
import SearchForm from "../../components/common/search-form/SearchForm";
import LinksProvider from "../../services/http/LinksProvider";
import PotsService from "../../services/domain/PotsService";
import PotInfo from "../../components/pots/pot-info/PotInfo";
import PotParticipations from "../../components/pots/pot-participations/PotParticipations";
import PotGiftCards from "../../components/gift-cards/GiftCards";
import PotCashOuts from "../../components/pots/pot-cash-outs/PotCashOuts";
import ListProducts from "../../components/pots/list-products/ListProducts";
import {CreditCardParent} from "../../models/CreditCard";
import CreditCards from "../../components/credit-cards/CreditCards";
import AuthService from '../../services/domain/AuthService';

function PotDetailsPage({t, match}): React.Node {

    const [pot, setPot] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isWeddingList, setIsWeddingList] = useState(null);

    useEffect(() => {
        document.title = `${t('navbar.pots')} : ${match.params.id}`;
        loadData();
    }, [t, match.params.id]);

    const loadData = () => {
        setLoading(true);
        PotsService.get(match.params.id)
            .then(pot => {
                setPot(pot);
            })
            .finally(() => setLoading(false));
    };

    return (<SiteWrapper>
        <Page.Content>
            <Page.Header
                title={t('navbar.pots')}
                options={(<SearchForm value={match.params.id}
                                      placeholder={t('pots.search-placeholder')}
                                      route={LinksProvider.ROUTES.POTS}
                                      injectParam={true}
                />)}
            />
            <Page.Main>

                {loading && <Loader/>}

                {(!loading && pot) && (<React.Fragment>
                    <PotInfo reference={pot.reference} userEmail={pot.organizer_email}
                             onSetIsWeddingList={(value) => setIsWeddingList(value)}
                    />
                    {AuthService.userAuthorizations.includes('AUTH_BO_POTS') &&
                      <React.Fragment>
                        <PotParticipations pot={pot}/>
                        <PotCashOuts reference={pot.reference}
                                    onTransferAdded={() => loadData()}
                        />
                        <PotGiftCards reference={pot.reference}/>
                      </React.Fragment>
                    }
                    {isWeddingList && <ListProducts reference={pot.reference}/>}
                    {AuthService.userAuthorizations.includes('AUTH_BO_POTS') &&
                      <CreditCards userEmail={pot.organizer_email} parent={CreditCardParent.POT}/>
                    }

                </React.Fragment>)}

            </Page.Main>
        </Page.Content>
    </SiteWrapper>);
}

export default withI18n()(PotDetailsPage);
