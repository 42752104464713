// @flow
import * as React from "react";
import {Modal} from "react-bootstrap";
import {Button, Form, Grid, Loader, Page} from "tabler-react";
import {withI18n} from 'react-i18next';
import {useEffect, useState} from "react";
import ErrorHandler from "../../../services/error/ErrorHandler";
import {useToasts} from 'react-toast-notifications';
import ProductsService from "../../../services/domain/ProductsService";
import EmptyContent from "../../common/empty-content/EmptyContent";
import LinksProvider from "../../../services/http/LinksProvider";
import Amount from "../../common/amount/Amount";
import StrongAuthService from "../../../services/utils/StrongAuthService";
import {PotCategory} from "../../../models/Pot";

function ListProductParticipantsListModal(props): React.Node {

    const [loading, setLoading] = useState(false);
    const [participants, setParticipants] = useState([]);
    const {addToast} = useToasts();

    useEffect(() => {
        loadParticipants();
    }, []);

    const loadParticipants = () => {
        setLoading(true);
        ProductsService.getListProductsParticipationItems(props.listReference, props.productReference)
            .then(participants => setParticipants(participants))
            .catch(error => ErrorHandler.handleError(addToast, props.t, error))
            .finally(() => setLoading(false))
    }

    return (
        <Modal {...props} size="lg" centered>
            <Modal.Header>
                <Modal.Title>{props.t('products.participants-product-list')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading && <Loader/>}
                {!loading && !!(!participants || participants.length === 0) &&
                <EmptyContent text={props.t('products.no-product-participants')}/>}
                {!loading && !!(participants && participants.length) && (
                    participants.map(participant =>
                        <Grid.Row>
                          <Grid.Col width={4}>
                            <Form.Group label={props.t('participations.participant-email')}>
                              <a
                                href={LinksProvider.formatUrl(LinksProvider.ROUTES.USER, {id: participant.payer_email})}>{participant.payer_email}
                              </a>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col width={4}>
                            <Form.Group label={props.t('participations.amount')}>
                              {StrongAuthService.showSecureSensitiveDataWithOTPForM1L() ? (<Amount amount={participant.amount}/>) : '-'}
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col width={4}>
                            <Form.Group label={props.t('participations.participation-reference')}>
                              <a
                                href={LinksProvider.formatUrl(LinksProvider.ROUTES.PARTICIPATION, {id: participant.participation_reference})}>{participant.participation_reference}
                              </a>
                            </Form.Group>
                          </Grid.Col>
                        </Grid.Row>)
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide} closeButton color="warning">{props.t('modals.close')}</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default withI18n()(ListProductParticipantsListModal);
