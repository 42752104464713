import React, {useState, useEffect} from "react";
import {
  Badge,
  Button,
  Card, Dimmer, Form, Grid, Icon, Loader,
} from "tabler-react";
import {withI18n} from "react-i18next";

import ProductsService from "../../../services/domain/ProductsService";
import InspirationListsService from "../../../services/domain/InspirationListsService";
import Select from 'react-select';
import ErrorHandler from "../../../services/error/ErrorHandler";
import LinksProvider from "../../../services/http/LinksProvider";
import Editable from "../../common/editable/Editable";
import DateService from "../../../services/utils/DateService";
import {useToasts} from "react-toast-notifications";
import {ProductType} from "../../../models/Product";
import {InspirationListType} from "../../../models/InspirationList";
import {PotStatus} from "../../../models/Pot";

const ProductInspirationLists = ({t, productReference, productType}) => {

  const {addToast} = useToasts();

  const [model, setModel] = useState(null);
  const [loading, setLoading] = useState(false);
  const [inspirationLists, setInspirationLists] = useState([]);
  const [productInspirationLists, setProductInspirationLists] = useState([]);

  useEffect(() => {
    loadData();
  }, [productReference]);

  const getInspirationListTypeFromProductType = (productType) => {
    switch (productType) {
      case ProductType.GIFT :
        return InspirationListType.GIFT;
      case ProductType.MARRIAGE :
        return InspirationListType.MARRIAGE;
      case ProductType.GIFT_MARRIAGE :
        return [InspirationListType.GIFT, InspirationListType.MARRIAGE];
      default:
        return InspirationListType.MARRIAGE;
    }
  }

  const getDefaultValues = () => {
    return productInspirationLists && productInspirationLists.length > 0 && inspirationLists && inspirationLists.length > 0 ?
      inspirationLists.filter(a => productInspirationLists.some(b => a.reference === b.reference)).map(list => { return {value :list.reference, label: list.name}})
      :
      [];
  };

  const getOptions = () => {
    return inspirationLists && inspirationLists.length > 0 ? inspirationLists.map(list => { return {value :list.reference, label: list.name}}) : [];
  };

  const handleChange = (option, action) => {
    if (action.action === "select-option") {
      setLoading(true);
      InspirationListsService.addProduct(action.option.value, productReference)
        .then(async inspiration_list => {
          await loadData();
        })
        .catch(error => ErrorHandler.handleError(addToast, t, error))
        .finally(() => setLoading(false))
    } else if (action.action === "remove-value") {
      setLoading(true);
      InspirationListsService.deleteProduct(action.removedValue.value, productReference)
        .then(async inspiration_list => {
          await loadData();
        })
        .catch(error => ErrorHandler.handleError(addToast, t, error))
        .finally(() => setLoading(false))
    }
  }


  const loadData = async () => {
    setLoading(true);
    await ProductsService.getAttachedInspirationLists(productReference)
      .then(lists => {
        setProductInspirationLists(lists && lists.length > 0 ? lists : []);
      });
    await InspirationListsService.getAll(undefined, undefined, `${getInspirationListTypeFromProductType(productType)}`)
      .then(data => {
        setInspirationLists(data.lists);
      })
      .finally(() => setLoading(false))
  };

  return (<React.Fragment>
    <Form.Group label={t('products.inspiration-lists')} className={"text-left"}>
      {loading && (<Loader/>)}
      {(!loading) && (<React.Fragment className="mt-3">
        <Select
          defaultValue={getDefaultValues()}
          isMulti
          name="colors"
          options={getOptions()}
          onChange={handleChange}
          className="basic-multi-select"
          classNamePrefix="select"
        />
      </React.Fragment>)}
    </Form.Group>
  </React.Fragment>)
};

export default withI18n()(ProductInspirationLists);
