import React, {Fragment} from "react";
import css from "./ProductsList.scss";
import {Card, Loader, Page, Table, Tag, Badge} from "tabler-react";
import {Image, Button, ButtonToolbar, Figure, InputGroup} from "react-bootstrap";
import {withI18n} from 'react-i18next';
import LinksProvider from "../../../services/http/LinksProvider";
import DateService from "../../../services/utils/DateService";
import Amount from "../../common/amount/Amount";
import {useEffect, useState} from "react";
import TablePagination from "../../common/table-pagination.js/TablePagination";
import ProductsService from "../../../services/domain/ProductsService";
import ProductInfo from "../product-info/ProductInfo";
import _ from "lodash";
import {Form} from 'react-bootstrap'
import {useToasts} from "react-toast-notifications";

const ProductsList = ({products, searchQueryCount, searchCriteria, t}) => {
  const productsPerPage = 10;
  const [loading, setLoading] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [listProducts, setListProducts] = useState(products);
  const [queryCount, setQueryCount] = useState(searchQueryCount);
  const [checked, setChecked] = useState({});
  const [pageCount, setPageCount] = useState(Math.ceil(queryCount / productsPerPage));
  const {addToast} = useToasts();

  useEffect(() => {
    let checkedProductsList = {};
    if (products && products.length) {
      products.map((product) =>
        checkedProductsList[product.reference] = false)
    }
    setChecked(checkedProductsList);
  }, []);

  useEffect(() => {
    console.log("showActions has changed", showActions);
  }, [showActions]);

  const handlePageClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
    loadData(productsPerPage, index * productsPerPage)
  };

  const loadData = (limit, offset) => {
    setLoading(true);
    ProductsService.search(searchCriteria, limit, offset)
      .then(data => {
        setListProducts(data.products);
        setQueryCount(data.original_result_count);
        setPageCount(Math.ceil(queryCount / productsPerPage));

      })
      .finally(() => setLoading(false));
  };

  const selectAll = (event) => {
    _.each(products, (p) => {
      checked[p.reference] = event.target.checked;
    });
    setChecked(Object.assign({}, checked));
    setShowActions(event.target.checked);
  };

  const handleChange = (event) => {
    checked[event.target.value] = event.target.checked;
    let numberSelectedItems = Object.values(checked).filter(v => v).length;
    document.querySelector('#select-all').checked = numberSelectedItems === products.length;
    setChecked(Object.assign({}, checked));
    setShowActions(numberSelectedItems > 0);
  };

  const handleDelete = (event) => {
    let selectedProducts = Object.keys(checked).filter(key => checked[key]);
    selectedProducts.forEach((reference) => {
      let product = {reference: reference, is_deleted: true};
      ProductsService.update(product.reference, product)
        .then(data => {
          loadData(productsPerPage, 0);
          setCurrentPage(0);
        })
        .catch(error => {
          console.log(error);
        })
        .finally();
    });
  };

  const handleDeactivate = (event) => {
    let selectedProducts = Object.keys(checked).filter(key => checked[key]);
    selectedProducts.forEach((reference) => {
      let product = {reference: reference, status: 0};
      ProductsService.update(product.reference, product)
        .then(data => {
          loadData(productsPerPage, 0);
          setCurrentPage(0);
        })
        .catch(error => {
          console.log(error);
        });
    });
  };

  const handleActivate = (event) => {
    let selectedProducts = Object.keys(checked).filter(key => checked[key]);
    selectedProducts.forEach((reference) => {
      let product = {reference: reference, status: 1};
      ProductsService.update(product.reference, product)
        .then(data => {
          loadData(productsPerPage, 0);
          setCurrentPage(0);
        })
        .catch(error => {
          console.log(error);
        });
    });
  };

  const isProductVisible = (product) => {
    return !product.deletion_date && product.status === 1 && DateService.nowIsBetween(product.validity_start_date, product.validity_end_date);
  };

  const headerItems = [
    {content: t('products.order')},
    {content: (<input id="select-all" type={"checkbox"} name={"selectAllProducts"} onChange={selectAll}/>)},
    {content: t('products.reference')},
    {content: t('products.name')},
    {content: t('products.cover-photo')},
    {content: t('products.brand')},
    {content: t('products.price')},
    {content: t('products.environement')},
    {content: t('products.validity-start-date')},
    {content: t('products.validity-end-date')},
    {content: t('products.creation-date')},
    {content: t('products.status')},
    {content: t('products.visible')},
  ];

  const PRODUCT_BADGE_STATUS_MAP = {
    0: 'orange',
    1: 'green',
    2: 'red'
  }

  
  let timeoutFn;
  const itemRow = (product) => {

    return {
      key: product.reference,
      item: [
        {
          content: (
            <>
              <InputGroup className="mb-3">
              <Form.Control defaultValue={product.order} type="number" style={{ minWidth: '70px'}} onChange={(e) => {
                clearTimeout(timeoutFn);
                const value = e?.target?.value
                if (value) {
                  timeoutFn = setTimeout(() => {
                    ProductsService.update(product.reference, {order: value})
                      .then(data => {
                        addToast(t(`product.update-success`), {
                          appearance: 'success', autoDismiss: true
                        });
                      })
                      .catch(error => {
                        console.log(error);
                      })
                      .finally(()=>{
                        
                      });
                }, 1000);
                }
              }}/>
              <InputGroup.Text style={{ minWidth: '70px'}} >/ {queryCount * 100}</InputGroup.Text>
            </InputGroup>
            </>
          )
        },
        {
          content: (<input
            type="checkbox"
            name="productsSelection"
            checked={checked[product.reference]}
            onChange={handleChange}
            value={product.reference}/>)
        },
        {
          content: (<a
            href={LinksProvider.formatUrl(LinksProvider.ROUTES.PRODUCT, {id: encodeURIComponent(product.reference)})}>{product.reference}</a>)
        },
        {content: (<span>{product.name}</span>)},
        {
          content: (
            <span>
              {(product.cover_photo && product.cover_photo.storage_service_url) ?
                (
                  <Figure>
                    <Figure.Image
                      alt={t('products.cover-photo')}
                      width={171}
                      height={180}
                      src={product.cover_photo.storage_service_url}/>
                  </Figure>
                ) : (
                  <div>&emsp;N/A</div>
                )}
            </span>)
        },
        {content: (<span>{product.brand}</span>)},
        {content: (<Amount amount={product.price}/>)},
        {
          content: (
            <span>{product.type && product.type == 0 ? 'Cadeau' : product.type == 1 ? 'Liste' : product.type == 2 ? 'Cadeau/Liste' : 'Cadeau'}</span>)
        },
        {content: (<span>{DateService.formatDate(product.validity_start_date)}</span>)},
        {content: (<span>{DateService.formatDate(product.validity_end_date)}</span>)},
        {content: (<span>{DateService.formatDate(product.creation_date)}</span>)},
        {
          content: (<span><Tag
            color={product.deletion_date ? 'red' : PRODUCT_BADGE_STATUS_MAP[product.status] || 'green'}>&emsp;</Tag></span>)
        },
        {
          content: (<span>
            {isProductVisible(product) ? (
                <Badge color="success">
                  {t('commercial-banners.visible')}
                </Badge>
            ) : (
                <Badge color="secondary">
                  {t('commercial-banners.not-visible')}
                </Badge>
            )}
          </span>)
        },
      ]
    };
  };


  return (
    <Fragment>
      <Card title={t('products.list-products', {count: queryCount})}>
        {loading && <Loader/>}

        {(!loading) && (
          <Card.Body>

            <h3><Badge color="secondary">Actions: </Badge></h3>
            <ButtonToolbar>
              <Button disabled={!showActions} variant="success"
                      onClick={handleActivate}>Activer</Button>&emsp;
              <Button disabled={!showActions} variant="warning"
                      onClick={handleDeactivate}>Brouillon</Button>&emsp;
              <Button disabled={!showActions} variant="danger"
                      onClick={handleDelete}>Supprimer</Button>&emsp;
            </ButtonToolbar>


            <Table responsive
                   className="card-table"
                   headerItems={headerItems}
                   bodyItems={listProducts.map(itemRow)}/>
          </Card.Body>
        )}
        <Card.Footer>
          <TablePagination
            pageCount={pageCount}
            currentPage={currentPage}
            handlePageClick={handlePageClick}
          />
        </Card.Footer>
      </Card>
    </Fragment>

  )
};

export default withI18n()(ProductsList);
