import React from 'react';
import { Popover, OverlayTrigger, Button, Row, Col } from "react-bootstrap";
import { Form } from "tabler-react";
import { useEffect, useState } from "react";
import { withI18n } from 'react-i18next';
import css from './ProductsFilter.module.scss'
import LinksProvider from "../../../services/http/LinksProvider";
import ProductsService from "../../../services/domain/ProductsService";

function ProductsFilter({ searchCriteria, t }) {
    const [categories, setCategories] = useState(searchCriteria.categories && searchCriteria.categories.length ? searchCriteria.categories.split(',') : []);
    const [subCategories, setSubCategories] = useState(searchCriteria.sub_categories && searchCriteria.sub_categories.length ? searchCriteria.sub_categories.split(',') : []);
    const [placement, setPlacemennt] = useState(typeof searchCriteria.placement !== 'undefined' ? searchCriteria.placement : null);
    const [type, setType] = useState(typeof searchCriteria.type !== 'undefined' ? searchCriteria.type : null);
    const [validityStart, setValidityStart] = useState(searchCriteria.validity_start_date ? searchCriteria.validity_start_date : null);
    const [validityEnd, setValidityEnd] = useState(typeof searchCriteria.validity_end_date ? searchCriteria.validity_end_date : null);
    const [creationDateSort, setCreationDateSort] = useState(typeof searchCriteria.creation_date_sort !== 'undefined' ? searchCriteria.creation_date_sort : null);
    const [order, setOrder] = useState(typeof searchCriteria.order !== 'undefined' ? searchCriteria.order : null);
    const [minPrice, setMinPrice] = useState(searchCriteria.min_price ? searchCriteria.min_price / 100 : null);
    const [maxPrice, setMaxPrice] = useState(searchCriteria.max_price ? searchCriteria.max_price / 100 : null);
    const [status, setStatus] = useState(typeof searchCriteria.status !== 'undefined' ? searchCriteria.status : null);

    const [categoriesList, setCategoriesList] = useState([]);
    const [subCategoriesList, setsubCategoriesList] = useState({});

    useEffect(() => {
        ProductsService.getCategoriesAndSubCategories()
            .then((res) => {
                const result = Object.groupBy(res, ({ reference }) => reference);
                setCategoriesList(Object.keys(result));
                setsubCategoriesList(result);
            })
            .catch((err) => {
                console.error(err);
            });
    }, []);

    const filterSubmit = (e) => {
        e.preventDefault();
        const criteria = {
            search: searchCriteria.search,
            categories: categories ? categories.join(",") : null,
            sub_categories: subCategories ? subCategories.join(",") : null,
            placement: placement,
            type: type,
            minPrice: minPrice * 100,
            maxPrice: maxPrice * 100,
            status: status,
            order: order,
            validityStart: validityStart,
            validityEnd: validityEnd,
            creationDateSort: creationDateSort
        };
        console.log('*********Criteria', JSON.stringify(searchCriteria) , JSON.stringify(criteria))
        const paramString = Object.keys(criteria).map(key => {
            if (criteria[key]) {
                return key + '=' + criteria[key]
            }
        }).join('&');
        document.body.click();
        window.location = LinksProvider.formatUrl(LinksProvider.ROUTES.PRODUCTS) + '?' + paramString
    };

    const resetFilter = () => {
        window.location = LinksProvider.formatUrl(LinksProvider.ROUTES.PRODUCTS) + (searchCriteria.search ? '?search=' + searchCriteria.search : '');
        document.body.click()
    };

    const popover = (
        <Popover className={css.popover_lg}>
            <Popover.Title as="h3">Filtre Produits</Popover.Title>
            <Popover.Content>
                <Form onSubmit={filterSubmit}>
                    <Form.Group label="Catégories">
                        <Form.SelectGroup
                            canSelectMultiple
                            pills
                            name="categories"
                        >
                            {categoriesList?.map((el, index) => (
                                <Form.SelectGroupItem
                                    key={`cat_key_${el}`}
                                    label={el}
                                    value={el}
                                    onChange={(e) => {
                                        const oldCategories = categories;
                                        const elIndex = oldCategories.indexOf(el);
                                        if (elIndex === -1) {
                                            oldCategories.push(el)
                                        } else {
                                            oldCategories.splice(elIndex, 1);
                                        }
                                        setCategories([...oldCategories]);
                                    }}
                                    checked={categories.includes(el)}
                                />
                            ))}
                        </Form.SelectGroup>
                    </Form.Group>

                    <Form.Group label="Sous Catégories">
                        <Form.SelectGroup
                            canSelectMultiple
                            pills
                            name="sub_categories"
                        >
                            {categories.map(cat => (
                                <>
                              {subCategoriesList[cat]?.map(({subcategory_reference}, index) => (
                                subcategory_reference &&
                                    <Form.SelectGroupItem
                                       key={`sub_cat_key_${subcategory_reference}`}
                                        label={(t(`product.sub_categories.${subcategory_reference}`))}
                                        value={subcategory_reference}
                                        onClick={(e) => {
                                            const oldSubCategories = subCategories;

                                            const elIndex = oldSubCategories.indexOf(subcategory_reference);
                                            if (elIndex === -1) {
                                                oldSubCategories.push(subcategory_reference)
                                            } else {
                                                oldSubCategories.splice(elIndex, 1);
                                            }   

                                            setSubCategories([...oldSubCategories]);
                                        }}
                                        checked={subCategories.includes(subcategory_reference)}
                                    />
                                ))}
                                </>
                            ))}
                        
                        </Form.SelectGroup>
                    </Form.Group>
                    <Form.Group label="Emplacement">
                        <Form.SelectGroup>
                            <Form.SelectGroupItem
                                label="1ère ligne"
                                name="placement"
                                value="0"
                                onChange={(e) => { setPlacemennt(e.target.value) }}
                                onClick={(e) => {
                                    if (e.target.value === placement)
                                        setPlacemennt(null)
                                }}
                                checked={placement == "0"}
                            />
                            <Form.SelectGroupItem
                                label="Haut de page"
                                name="placement"
                                value="1"
                                onChange={(e) => { setPlacemennt(e.target.value) }}
                                onClick={(e) => {
                                    if (e.target.value === placement)
                                        setPlacemennt(null)
                                }}
                                checked={placement == "1"}
                            />
                            <Form.SelectGroupItem
                                label="Milieu de page"
                                name="placement"
                                value="2"
                                onChange={(e) => { setPlacemennt(e.target.value) }}
                                onClick={(e) => {
                                    if (e.target.value === placement)
                                        setPlacemennt(null)
                                }}
                                checked={placement == "2"}
                            />
                            <Form.SelectGroupItem
                                label="Bas de page"
                                name="placement"
                                value="3"
                                onChange={(e) => { setPlacemennt(e.target.value) }}
                                onClick={(e) => {
                                    if (e.target.value === placement)
                                        setPlacemennt(null)
                                }}
                                checked={placement == "3"}
                            />
                        </Form.SelectGroup>
                    </Form.Group>
                    <Form.Group label="Environnement">
                        <Form.SelectGroup>
                            <Form.SelectGroupItem
                                label={t('products.gift')}
                                name="type"
                                value="0"
                                onChange={(e) => {
                                    setType(e.target.value)
                                }}
                                onClick={(e) => {
                                    if (e.target.value === type)
                                        setType(null)
                                }}
                                checked={type == "0"}
                            />
                            <Form.SelectGroupItem
                                label={t('products.list')}
                                name="type"
                                value="1"
                                onChange={(e) => {
                                    setType(e.target.value)
                                }}
                                checked={type == "1"}
                                onClick={(e) => {
                                    if (e.target.value === type)
                                        setType(null)
                                }}
                            />
                            <Form.SelectGroupItem
                                label={t('products.gift-list')}
                                name="type"
                                value="2"
                                onChange={(e) => {
                                    setType(e.target.value)
                                }}
                                onClick={(e) => {
                                    if (e.target.value === type)
                                        setType(null)
                                }}
                                checked={type == "2"}
                            />
                        </Form.SelectGroup>
                    </Form.Group>
                    <Form.Group label="Tri par date de création">
                        <Form.SelectGroup>
                            <Form.SelectGroupItem
                                label={t('products.most-recent')}
                                name="creationDateSort"
                                value="desc"
                                onChange={(e) => {
                                    setCreationDateSort(e.target.value)
                                }}
                                onClick={(e) => {
                                    if (e.target.value === creationDateSort)
                                        setCreationDateSort(null)
                                }}
                                checked={creationDateSort == "desc"}
                            />
                            <Form.SelectGroupItem
                                label={t('products.least-recent')}
                                name="creationDateSort"
                                value="asc"
                                onChange={(e) => {
                                    setCreationDateSort(e.target.value)
                                }}
                                checked={creationDateSort == "asc"}
                                onClick={(e) => {
                                    if (e.target.value === creationDateSort)
                                        setCreationDateSort(null)
                                }}
                            />
                        </Form.SelectGroup>
                    </Form.Group>
                    <Form.Group label="Tri par Ordre">
                    <Form.SelectGroup>
                            <Form.SelectGroupItem
                                label={t('products.highest')}
                                name="order"
                                value="asc"
                                onChange={(e) => {
                                    setOrder(e.target.value)
                                }}
                                onClick={(e) => {
                                    if (e.target.value === order)
                                        setOrder(null)
                                }}
                                checked={order == "asc"}
                            />
                            <Form.SelectGroupItem
                                label={t('products.lowest')}
                                name="order"
                                value="desc"
                                onChange={(e) => {
                                    setOrder(e.target.value)
                                }}
                                checked={order == "desc"}
                                onClick={(e) => {
                                    if (e.target.value === order)
                                        setOrder(null)
                                }}
                            />
                        </Form.SelectGroup>
                    </Form.Group>
                    <Form.Group label="Date début validité">
                        <Form.MaskedInput
                            placeholder="00/00/0000"
                            mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                            name="validity_start_date"
                            onChange={(e) => { setValidityStart(e.target.value) }}
                            value={validityStart ? validityStart : ''}
                        />
                    </Form.Group>
                    <Form.Group label="Date fin validité">
                        <Form.MaskedInput
                            placeholder="00/00/0000"
                            mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                            name="validity_end_date"
                            onChange={(e) => { setValidityEnd(e.target.value) }}
                            value={validityEnd ? validityEnd : ''}
                        />
                    </Form.Group>
                    <Row>
                        <Col xs="12" sm="6">
                            <Form.Group label="Prix min">
                                <Form.Input
                                    value={minPrice}
                                    onChange={(e) => { setMinPrice(e.target.value) }}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs="12" sm="6">
                            <Form.Group label="Prix max">
                                <Form.Input
                                    value={maxPrice}
                                    onChange={(e) => { setMaxPrice(e.target.value) }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group label="Statut">
                        <Form.Radio
                            isInline
                            label="Brouillon"
                            name="status"
                            value="0"
                            onChange={(e) => { setStatus(e.target.value) }}
                            checked={status == "0"}
                        />
                        <Form.Radio
                            isInline
                            label="Actif"
                            name="status"
                            value="1"
                            onChange={(e) => { setStatus(e.target.value) }}
                            checked={status == "1"}
                        />
                    </Form.Group>
                    <br />

                    <Button variant="success" type="submit" >Appliquer</Button>
                    &emsp;
                    <Button variant="secondary" onClick={resetFilter}>Réinitialiser</Button>

                </Form>
            </Popover.Content>
        </Popover>
    );

    return (
        <OverlayTrigger rootClose={true} trigger="click" placement="right" overlay={popover}>
            <Button variant="primary">Filtre</Button>
        </OverlayTrigger>
    );
}

export default withI18n()(ProductsFilter);