import React, {Fragment} from "react";
import {Card, Loader, Page, Table, Form} from "tabler-react";
import {Figure} from "react-bootstrap";
import {withI18n} from 'react-i18next';
import {useEffect, useState} from "react";
import LinksProvider from "../../../services/http/LinksProvider";
import {InspirationListType} from "../../../models/InspirationList";
import {ProductType} from "../../../models/Product";
import ProductsService from "../../../services/domain/ProductsService";
import css from "../../common/search-form/SearchForm.module.scss";

const InspirationListCreateAddProducts = ({listType, onProductsChange, t}) => {

  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState({});
  const [search, setSearch] = useState('');

  useEffect(() => {
    let checkedProductsList = {};
    if (filteredProducts && filteredProducts.length) {
      filteredProducts.map((product) => {if (!checkedProductsList[product.reference]) checkedProductsList[product.reference] = false})
    }
    setChecked(checkedProductsList);
  }, [filteredProducts]);

  useEffect(() => {
    setFilteredProducts(products);
  }, [products]);

  useEffect(() => {
    onProductsChange && onProductsChange(Object.keys(checked).filter(product => !!checked[product]));
  }, [checked]);

  useEffect(() => {
    loadData()
  }, [listType]);

  const getProductTypeFromListType = (type) => {
    switch (type) {
      case InspirationListType.GIFT :
        return [ProductType.GIFT, ProductType.GIFT_MARRIAGE].join(',');
      case InspirationListType.MARRIAGE :
        return [ProductType.MARRIAGE, ProductType.GIFT_MARRIAGE].join(',');
      case InspirationListType.GIFT_MARRIAGE :
        return ProductType.GIFT_MARRIAGE;
      default:
        return [ProductType.MARRIAGE, ProductType.GIFT_MARRIAGE].join(',');
    }
  };

  const loadData = async () => {
    setLoading(true);
    await ProductsService.getInspirationListEligibleProductsByType(getProductTypeFromListType(listType))
      .then(data => {
        if (data) {
          setProducts(data.products && data.products.length ? data.products : []);
        }
      })
      .finally(() => setLoading(false));
  };

  const handleClick = (event) => {
    checked[event.target.value] = event.target.checked;
    setChecked(Object.assign({}, checked));
  };

  const headerItems = [
    {content: (<p>&nbsp;</p>)},
    {content: t('products.cover-photo')},
    {content: t('products.reference')},
    {content: t('products.name')},
    {content: t('products.brand')},
  ];

  const itemRow = (product) => {
    return {
      key: product.reference,
      item: [
        {
          content: (<input
            type="checkbox"
            name="productsSelection"
            checked={checked[product.reference]}
            onChange={handleClick}
            value={product.reference}/>)
        },
        {
          content: (
            <span>
              {(product.cover_photo && product.cover_photo.storage_service_url) ?
                (
                  <Figure>
                    <Figure.Image
                      alt={t('products.cover-photo')}
                      width={171}
                      height={180}
                      src={product.cover_photo.storage_service_url}/>
                  </Figure>
                ) : (
                  <div>&emsp;N/A</div>
                )}
            </span>)
        },
        {
          content: (<a
            target={'_blank'}
            href={LinksProvider.formatUrl(LinksProvider.ROUTES.PRODUCT, {id: encodeURIComponent(product.reference)})}>{product.reference}</a>)
        },
        {content: (<span>{product.name}</span>)},
        {content: (<span>{product.brand}</span>)},
      ]
    };
  };

  const onSearch = (e) => {
    e.preventDefault();
    if (search === undefined || search === null) {
      return false;
    }
    const filteredProduct = !!search ?
      products.filter(product => product.name.toLowerCase().includes(search.toLowerCase()) || product.brand.toLowerCase().includes(search.toLowerCase()))
      : products;
    setFilteredProducts(filteredProduct);
  };

  const onChange = (e) => {
    setSearch(e.target.value);
  };



  return (
    <Fragment>
      {loading && (<Loader/>)}
      {!loading && (<React.Fragment>
        <React.Fragment>
          <form onSubmit={onSearch}
                onKeyPress={event => {
                  if (event.which === 13 /* Enter */) {
                    event.preventDefault();
                    onSearch(event)
                  }
                }}
                className={css.search_form} className="mb-3">
            <Form.Input type="search" value={search} onChange={onChange} icon="search" placeholder={t('products.search')}/>
          </form>
        </React.Fragment>
        {filteredProducts && filteredProducts.length > 0 && (
          <Card>
            <Card.Body className="mh-100">
              <div style={{
              maxHeight: 500,
              overflow: "scroll"}}>
                  <Table responsive
                  className="card-table"
                  headerItems={headerItems}
                  bodyItems={filteredProducts.map(itemRow)}
                  />
               </div>
              </Card.Body>
          </Card>
        )}
      </React.Fragment>)}
    </Fragment>

  )
};

export default withI18n()(InspirationListCreateAddProducts);
