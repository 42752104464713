/**
 * PotsService
 * @module Services/domain/PotsService
 * @description Loads pots data from the server
 */

import HttpService from "../http/HttpService";
import LinksProvider from "../http/LinksProvider";
import {Pots} from "../../models/Pot";
import oboe from "oboe";
import AuthService from "./AuthService";

const self = {};

/**
 * Searches for a user with a specific query
 * @author Paris Romain
 * @alias search
 * @memberof module:Services/http/PotsService
 * @param   {string} query The search query
 * @returns {Promise<Pots>}  A promise containing the search result
 */
self.search = (query: string): Promise<Pots> => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.POTS.SEARCH, {search: query}))
    .then(data => data.pots);
};

/**
 * Gets a pot from its reference
 * @author Romain Paris
 * @alias get
 * @memberof module:Services/http/PotsService
 * @param   {string} reference the pot's reference
 * @returns {Promise<Pots>}  A promise containing the found pot
 */

self.get = (reference: string): Promise<Pot> => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.POTS.POT, {id: reference}))
    .then(data => data.pot);
};

/**
 * Updates Pot information
 * @author Romain Paris
 * @alias update
 * @memberof module:Services/http/PotsService
 * @param   {string} reference The pot reference
 * @param   {any} data The information to update
 * @returns {Promise<Pot>}  A promise containing the updated pot
 */
self.update = (id: string, data: any): Promise<Pot> => {
  return HttpService
    .put(LinksProvider.formatFullUrl(LinksProvider.API.POTS.POT, {id: id}), data)
    .then(data => data.pot);
};

/**
 * Refund all participations
 * @author Sameh Bellez
 * @alias refund
 * @memberof module:Services/http/PotsService
 * @param   {string} reference The pot reference
 * @param   {any} the refund options
 */
self.refund = (id: string, data: any) => {
  return oboe({
    url: LinksProvider.formatFullUrl(LinksProvider.API.POTS.REFUNDS, {id: id}), method: 'POST',          // optional
    headers: {'Authorization': `Bearer ${AuthService.getToken()}`}, body: data
  });
};

/**
 * Gets a pot's participations
 * @author Sameh Bellez
 * @alias participations
 * @memberof module:Services/http/PotsService
 * @param   {string} reference The pot's reference
 * @returns {Promise<MinifiedPot[]>}  A promise containing the list of the pot's participations
 */
self.participations = (reference): Promise<any> => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.POTS.PARTICIPATIONS, {id: reference}))
    .then(data => data.participations);
};

/**
 * Gets a pot's cashOuts
 * @author Sameh Bellez
 * @alias cashOuts
 * @memberof module:Services/http/PotsService
 * @param   {string} reference The pot's reference
 * @returns {Promise<MinifiedPot[]>}  A promise containing the list of the pot's participations
 */
self.getCashOuts = (reference): Promise<any> => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.POTS.CASH_OUTS, {id: reference}))
    .then(data => data.cash_outs);
};

/**
 * Creates a cash out
 * @author Sameh Bellez
 * @alias createCashOut
 * @memberof module:Services/http/PotsService
 * @param   {string} reference The pot's reference
 * @param   {object} request The creation request
 * @returns {Promise<any>}  A promise containing the created transfer
 */
self.createCashOut = (reference, request): Promise<any> => {
  return HttpService
    .post(LinksProvider.formatFullUrl(LinksProvider.API.POTS.CASH_OUTS, {id: reference}), request)
    .then(data => data.cash_out);
};

/**
 * Gets a pot's gift cards
 * @alias giftCards
 * @memberof module:Services/http/PotsService
 * @param   {string} reference The pot's reference
 * @returns {Promise<MinifiedPot[]>}  A promise containing the list of the pot's gift cards
 */
self.giftCards = (reference): Promise<any> => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.POTS.GIFT_CARDS, {id: reference}))
    .then(data => data.gift_cards);
};

/**
 * resends a gift card
 * @alias resendGiftCard
 * @memberof module:Services/http/PotsService
 * @param   {string} giftCardReference The gift card's reference
 * @param   {object} data The body of the request
 * @returns {Promise<MinifiedGiftCard>}  A promise containing the gift card
 */
self.resendGiftCard = (giftCardReference): Promise<any> => {
  return HttpService
    .post(LinksProvider.formatFullUrl(LinksProvider.API.GIFT_CARDS.RESEND, {id: giftCardReference}))
    .then(data => data.gift_card);
};

/**
 * activates a gift card
 * @alias activateGiftCard
 * @memberof module:Services/http/PotsService
 * @param   {string} giftCardReference The gift card's reference
 * @param   {object} data The body of the request
 * @returns {Promise<MinifiedGiftCard>}  A promise containing the gift card
 */
self.activateGiftCard = (giftCardReference): Promise<any> => {
  return HttpService
    .post(LinksProvider.formatFullUrl(LinksProvider.API.GIFT_CARDS.ACTIVATE, {id: giftCardReference}))
    .then(data => data.gift_card);
};

/**
 * check if promocode is consumed
 * @author Sameh Bellez
 * @alias isPromoCodeConsumed
 * @memberof module:Services/domain/PotsService
 * @param {object} pot
 * @returns {boolean}  whether the promo code is consumed or not
 */
self.isPromoCodeConsumed = function (pot) {
  return !pot.promo_code_data || (pot.promo_code_data && (pot.promo_code_consume_date
    && pot.spent_amount
    > pot.collected_amount));
};

/**
 * gets pot total amount left
 * @author Sameh Bellez
 * @alias getAmountLeft
 * @memberof module:Services/domain/PotsService
 * @param {object} pot
 * @returns {number}  The left amount
 */
self.getAmountLeft = function (pot) {
  return pot.total_amount_left;
};

/**
 * Create a custom Pot Product with photos
 * @author Soltane Jerbi
 * @alias createCustomWeddingListProduct
 * @memberof module:Services/http/ProductsService
 * @param   {string} reference The pot to update reference
 * @param   {any} data The product information
 * @returns {Promise<Product>}  A promise containing the created product
 */
self.createCustomWeddingListProduct = (reference: string, data: any): Promise<Product> => {
    return HttpService
        .post(LinksProvider.formatFullUrl(LinksProvider.API.PRODUCTS.LIST_PRODUCTS, {reference}), data)
        .then(data => data.product);
};

/**
 * Updates a Pot Products
 * @author Soltane Jerbi
 * @alias updateWeddingListProducts
 * @memberof module:Services/http/ProductsService
 * @param   {string} reference The pot to update reference
 * @param   {any} data The product information
 * @returns {Promise<Product>}  A promise containing the created product
 */
self.updateWeddingListProducts = (reference: string, data: any): Promise<Product> => {
    return HttpService
        .put(LinksProvider.formatFullUrl(LinksProvider.API.PRODUCTS.LIST_PRODUCTS, {reference}), data)
        .then(data => data.product);
};


export default self;
