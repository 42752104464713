import moment from 'moment';

/**
 * DateService
 * @module Services/utils/DateService
 * @description Offers utility functions to handle dates
 */

const self = {};

/**
 * Formats a date
 * @author Sameh Bellez
 * @alias formatDate
 * @memberof module:Services/utils/DateService
 * @param   {string | date} date to format
 * @returns {string}  The formatted date
 */
self.formatDate = (date: string): string => {
  if (!date) {
    return '-';
  }

  return moment(date, 'DD/MM/YYYY', true).format('DD/MM/YYYY') === 'Invalid date' ?
    moment(date).format('DD/MM/YYYY') :
    moment(date, 'DD/MM/YYYY', true).format('DD/MM/YYYY');

};

/**
 * Formats a dd/mm/yyyy date to ISO Format
 * @author Seif Khiari
 * @alias formatDateISO
 * @memberof module:Services/utils/DateService
 * @param   {string | date} date to format
 * @returns {string}  The formatted date
 */
self.formatDateISO = (date: string): number => {
  if (!date) {
    return undefined;
  }

  if (moment(date, "DD/MM/YYYY", true).isValid()) {
    return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
  } else {
    return moment(date).format('YYYY-MM-DD');
  }
};

/**
 * Formats a dd/mm/yyyy date to mm/yyyy format
 * @author Ghassen Manai
 * @alias formatDateAsMonthAndYear
 * @memberof module:Services/utils/DateService
 * @param   {string | date} date to format
 * @returns {string}  The formatted date
 */
self.formatDateAsMonthAndYear = (date: string): number => {
  if (!date) {
    return undefined;
  }

  if (moment(date, "DD/MM/YYYY", true).isValid()) {
    return moment(date, 'DD/MM/YYYY').format('MM/YYYY');
  } else {
    return moment(date).format('MM/YYYY');
  }
};

/**
 * Parses a date
 * @author Sameh Bellez
 * @alias parseDate
 * @memberof module:Services/utils/DateService
 * @param   {string} date to parse
 * @returns {date}  The parsed date
 */
self.parseDate = (date: string): number => {
  if (!date) {
    return;
  }

  return moment(date, 'DD/MM/YYYY').toDate();
};

/**
 * Checks if a date is valid or not
 * @author Sameh Bellez
 * @alias isValid
 * @memberof module:Services/utils/DateService
 * @param   {string | date} date to format
 * @returns {boolean}  The date validity
 */
self.isValid = (date: string): boolean => {
  return moment(date).isValid();
};

/**
 * Formats a datetime
 * @author Sameh Bellez
 * @alias formatTime
 * @memberof module:Services/utils/DateService
 * @param   {string | date} datetime to format
 * @returns {string}  The formatted datetime
 */
self.formatTime = (date: string): string => {
  if (!date) {
    return '-';
  }
  return moment(date).format('DD/MM/YYYY HH:mm');
};

/**
 * Parses a time
 * @author Sameh Bellez
 * @alias parseTime
 * @memberof module:Services/utils/DateService
 * @param   {string} time to parse
 * @returns {date}  The parsed time
 */
self.parseTime = (date: string): number => {
  if (!date) {
    return;
  }
  return moment(date, 'DD/MM/YYYY HH:mm').toDate();
};

/**
 * test if current date is between two param dates
 * @author Seif Khiari
 * @alias nowIsBetween
 * @memberof module:Services/utils/DateService
 * @param   {string} time to parse
 * @param   {string | date} start date to format
 * @param   {string | date} end date to format
 * @returns {boolean}  the comparaison result
 */
self.nowIsBetween = (startDate: string, endDate: string): number => {
  if (!startDate || !endDate) {
    return false;
  }
  return moment().isBetween(startDate, endDate, 'days', '[]');
};

export default self;
