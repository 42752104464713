/**
 * UsersService
 * @module Services/http/PromoCodesService
 * @description Loads users data from the server
 */

import HttpService from "../http/HttpService";
import LinksProvider from "../http/LinksProvider";
import {PromoCode} from "../../models/PromoCode";

const self = {};



/**
 * Searches for promo-codes with a specific query
 * @author Romain Paris
 * @alias search
 * @memberof module:Services/http/PromoCodesService
 * @param   {string} query The search query
 * @returns {Promise<PromoCode>}  A promise containing the search result
 */

self.search = (query : string): Promise<PromoCode> => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.PROMO_CODES.SEARCH, {search: query}))
    .then(data => data.promo_codes);
};




/**
 * Searches for a promo-code with a specific reference
 * @author Romain Paris
 * @alias update
 * @memberof module:Services/http/PromoCodesService
 * @param   {string} id The code reference
 * @param   {any} data The information to update
 * @returns {Promise<PromoCodes>}  A promise containing the updated promo-code
 */
self.get = (id : string, data : any): Promise<PromoCode> => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.PROMO_CODES.PROMO_CODE, {id: id}), data)
    .then(data => data.promo_code);
};

/**
 * TODO Romain
 * @author Romain Paris
 * @alias update
 * @memberof module:Services/http/PromoCodesService
 * @param   {string} id The code reference
 * @param   {any} data The information to update
 * @returns {Promise<PromoCodes>}  A promise containing the updated promo-code
 */
self.getAll = (query : any): Promise<PromoCode> => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.PROMO_CODES.ALL), query)
    .then(data => data.promo_codes);
};

/**
 * Updates promo-codes information
 * @author Romain Paris
 * @alias update
 * @memberof module:Services/http/PromoCodesService
 * @param   {string} reference The code reference
 * @param   {any} data The information to update
 * @returns {Promise<PromoCodes>}  A promise containing the updated promo-code
 */
self.update = (reference : string, data : any): Promise<PromoCode> => {
  return HttpService
    .put(LinksProvider.formatFullUrl(LinksProvider.API.PROMO_CODES.PROMO_CODE, {id: reference}), data)
    .then(data => data.promo_code);
};

/**
 * Creates a new promo code
 * @author Sameh Bellez
 * @alias create
 * @memberof module:Services/http/PromoCodesService
 * @param   {any} data The request payload
 * @returns {Promise<PromoCode>}  A promise containing the created promo-code
 */
self.create = (data : any): Promise<PromoCode> => {
  return HttpService
    .post(LinksProvider.formatFullUrl(LinksProvider.API.PROMO_CODES.PROMO_CODES), data)
    .then(data => data);
};

/**
 * Marks promocode as banner
 * @author Seif Khiari
 * @alias addAsBanner
 * @memberof module:Services/http/PromoCodesService
 * @param   {any} data The request payload
 * @param   {string} reference promocode reference
 * @returns {Promise<PromoCode>}  A promise containing the updated promo-code
 */
self.addAsBanner = (reference : string, data : any): Promise<PromoCode> => {
  return HttpService
      .put(LinksProvider.formatFullUrl(LinksProvider.API.PROMO_CODES.BANNER, {id: reference}), data)
      .then(data => data.promo_code);
};

/**
 * Unmarks promocode as banner
 * @author Seif Khiari
 * @alias unmarkAsBanner
 * @memberof module:Services/http/PromoCodesService
 * @param   {any} data The request payload
 * @param   {string} reference promocode reference
 * @returns {Promise<PromoCode>}  A promise containing the updated promo-code
 */
self.unmarkAsBanner = (reference : string): Promise<PromoCode> => {
  return HttpService
      .post(LinksProvider.formatFullUrl(LinksProvider.API.PROMO_CODES.UNMARK_BANNER, {id: reference}))
      .then(data => data.promo_code);
};

export default self;