// @flow
import * as React from "react";
import SiteWrapper from "../../SiteWrapper";
import {Button, Loader, Page, Grid} from "tabler-react";
import {withI18n} from "react-i18next";
import {useEffect, useState} from "react";
import EmptyContent from "../../components/common/empty-content/EmptyContent";
import {useToasts} from 'react-toast-notifications';
import CommercialPartnersList from "../../components/commercial-partners/commercial-partners-list/CommercialPartnersList";
import CommercialPartnerCreate from "../../components/commercial-partners/commercial-partner-create/CommercialPartnerCreate";
import CommercialPartnerUpdate from "../../components/commercial-partners/commercial-partner-update/CommercialPartnerUpdate";
import CommercialPartnersService from "../../services/domain/CommercialPartnersService";
import {CommercialPartnerPageView} from "../../models/CommercialPartner";


function CommercialPartnersPage({t}): React.Node {
  const {addToast} = useToasts();

  const [partners, setPartners] = useState(null);
  const [partnerReference, setPartnerReference] = useState(null);
  const [partnerCount, setPartnerCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const [pageView, setPageView] = useState(CommercialPartnerPageView.LIST);

  const partnersPerPage = 8;

  const getParam = (paramName) => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get(paramName)
  };

  useEffect(() => {
    document.title = t('navbar.partners');
    const reference = getParam('reference');
    if (reference) {
      setPageView(CommercialPartnerPageView.MODIFY);
      setPartnerReference(reference)
    }
    if (pageView === CommercialPartnerPageView.LIST) {
      loadData();
    }
  }, [t]);

  const loadData = () => {
    setLoading(true);
    CommercialPartnersService.getAll(partnersPerPage, 0)
      .then(data => {
        if (data) {
          setPartners(data.commercial_partners);
          setPartnerCount(data.original_result_count);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <SiteWrapper>
      <Page.Content>
        <Page.Header
          title={t('navbar.partners')}
          options={(<Grid.Row>
            <Grid.Col>
              {(pageView === CommercialPartnerPageView.CREATE || pageView === CommercialPartnerPageView.MODIFY) && (
                <Button className="mr-1" icon="loader" onClick={() => setPageView(CommercialPartnerPageView.LIST)} color="primary"
                >{t('partners.list')}</Button>
              )}
              {(pageView === CommercialPartnerPageView.LIST || pageView === CommercialPartnerPageView.MODIFY) && (
                <Button className="mr-1" icon="plus" onClick={() => setPageView(CommercialPartnerPageView.CREATE)} color="primary"
                >{t('partners.create')}</Button>
              )}
            </Grid.Col>
          </Grid.Row>)}
        />
        <React.Fragment>
          {(pageView === CommercialPartnerPageView.LIST) && (
            <React.Fragment>
              {loading && <Loader/>}

              {(!loading && partners && partners.length > 0) && (
                <CommercialPartnersList partners={partners} searchQueryCount={partnerCount}/>
              )}

              {(!loading && (!partners || !partners.length)) && (
                <EmptyContent text={t('partners.no-results')}/>
              )}
            </React.Fragment>
          )}
          {(pageView === CommercialPartnerPageView.CREATE) && (
            <React.Fragment>
              {loading && <Loader/>}

              {(!loading) && (
                <CommercialPartnerCreate/>
              )}
            </React.Fragment>
          )}

          {(pageView === CommercialPartnerPageView.MODIFY) && (
            <React.Fragment>
              {loading && <Loader/>}

              {(!loading) && (
                <CommercialPartnerUpdate reference={partnerReference}/>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      </Page.Content>
    </SiteWrapper>
  );
}

export default withI18n()(CommercialPartnersPage);
