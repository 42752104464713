import React from "react"
import PropTypes from "prop-types";
import TextField from "./Text";
import TextArea from "./Textarea";
import Select from "./Select";
import Date from "./Date";
import CreatableSelected from "./CreatableSelected";
import {Form, FormText, Popover, Spinner} from "react-bootstrap";
import {Button, Icon} from "tabler-react";
import _ from "lodash";
import DateService from '../../../services/utils/DateService'
import DateTime from "./DateTime";
import Number from "./Number";


export default class Editable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.initialValue,
      newValue: this.props.initialValue,
      isEditing: false,
      validationText: null,
      isLoading: false,
      type: this.props.type,
    };
    //used for popover mode
    this.clickableLink = React.createRef();
  }

  componentDidMount() {
    if (this.props.ajax && !this.props.validate && !this.props.disabled) {
      console.error(`Editable(${this.props.id}): You provided an ajax prop without a validate prop; 
            ajax function will not be called`)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    //update initial value if the prop got updated
    if (prevProps.initialValue !== this.props.initialValue) {
      this.setState({value: this.props.initialValue, newValue: this.props.initialValue})
    }
  }

  getEditingComponent() {
    let controls = (<React.Fragment>
          <Button type="button" size="sm" color="danger" onClick={(e) => this.onCancel(e)}>
            <Icon prefix="fa" name="remove"/>
          </Button>&nbsp;
          <Button type="submit" size="sm" color="success" icon="check"/>
        </React.Fragment>);
    if (this.state.isLoading) {
      controls = (<div className="my-auto mx-4">
        <Spinner style={{width: "1.5rem", height: "1.5rem"}}/>
      </div>)
    }
    let commonProps = {
      value: this.state.newValue,
      validationText: this.state.validationText,
      controls: controls,
      setNewValue: (newValue) => {
        if (newValue) {
          this.setState({newValue})
        }
      },
      onCancel: () => this.onCancel()
    };
    let component;
    switch (this.props.type) {
      case "textfield":
        component = <TextField {...commonProps}/>;
        break;
      case "select":
        component = <Select {...commonProps} options={this.props.options}/>;
        break;
      case "date":
        component = <Date {...commonProps}/>;
        break;
      case "datetime":
        component = <DateTime {...commonProps}/>;
        break;
      case "number":
        component = <Number {...commonProps}/>;
        break;
      case "textarea":
        return (<Form onSubmit={(e) => this.onSubmit(e, this.state.newValue)}>
          <TextArea {...commonProps}/>
          <div className="d-flex align-items-start">
            <FormText className="mt-0">{this.state.validationText}</FormText>
            {controls}
          </div>
        </Form>);
      case "creatableselect":
        component=
        (<CreatableSelected
          {...commonProps}
          isDisabled={this.props.isDisabled}
          isLoading={this.props.isLoading} 
          selectValue={this.props.value} 
          onChange={this.props.onChange}
          onCreateOption={this.props.onCreateOption} 
          options={this.props.options} 
          isMulti={this.props.isMulti}
          isClearable={this.props.isClearable}
          styles={ {
            container: provided => ({
              ...provided,
              width: 250
            })
          }}
        
         />);
         break;

      default:
        console.error(`Editable(${this.props.id}): "${this.props.type}" is not a valid value for the "type" prop`);
        return null
    }
    return (<Form onSubmit={(e) => this.onSubmit(e, this.state.newValue)}
                  className={this.props.className}>
          <div className="align-items-baseline d-flex">
            {component}
          </div>
          <FormText className="mt-0">{this.state.validationText}</FormText>
        </Form>)
  }

  onCancel() {
    //reset validation text AND new value, all back to initial
    this.setState({validationText: null, newValue: this.state.value, isEditing: false})
  }

  //validation happens here
  onSubmit(event, newValue) {
    event.preventDefault();
    let validationText = this.props.validate ? this.props.validate(newValue) : null;

    if (this.state.type === 'date') {
      if (!newValue || newValue.length === 0) {
        newValue = null;
        this.setState({value: undefined});
      } else if (newValue.length && DateService.formatDate(newValue) === 'Invalid date') {
        validationText = 'Invalid date';
      }
    }
    if (validationText) {
      this.setState({validationText: validationText});
    } else {
      //we always trigger this, as long as the prop is specified
      this.props.onSubmit && this.props.onSubmit(newValue);
      if (this.props.keepInitialValueIfFalsy && !newValue) {
        this.setState({value: this.props.initialValue, isEditing: false});
      } else {
        this.props.validate ? this.onValidated(newValue) : this.setState({
          value: newValue,
          isEditing: false
        });
      }
    }
  }

  onValidated(validValue) {
    if (this.props.onValidated) {
      this.props.onValidated(validValue)
    } else if (!this.props.ajax) {
      console.error(`Editable(${this.props.id}): Specified a validate function without onValidated or ajax`)
    }

    if (this.props.ajax && validValue !== this.state.value) {
      this.ajax(validValue)
    } else {
      this.setState({value: validValue, isEditing: false, validationText: null})
    }
  }

  ajax(validValue) {
    this.setState({isLoading: true});
    let xhr = new XMLHttpRequest();
    //this will call the user's ajax function, allowing him to set up the xhr object however he
    // wants
    this.props.ajax(xhr, validValue, this.props.id);
    //consume the user's on ready state change function to call it later before the editable's
    let onReadyStateChange = xhr.onreadystatechange ? xhr.onreadystatechange : null;
    xhr.onreadystatechange = () => {
      onReadyStateChange && onReadyStateChange();
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          this.setState({
            isLoading: false,
            isEditing: false,
            value: validValue,
            validationText: null
          })
        } else {
          this.setState({isLoading: false, validationText: `Ajax Response ${xhr.status} Error`})
        }
      }
    }
  }

  render() {
    if ((this.state.isEditing || this.props.alwaysEditing) && this.props.mode === "inline") {
      return (this.getEditingComponent())
    } else {

      let value = _.isUndefined(this.state.value)
      || _.isNull(this.state.value)
      || !`${this.state.value}`.trim() ? this.props.undefinedText : this.state.value;
      //format date objects for display, might add a custom format function here later
      value = this.props.type
      === "date"
      && this.state.value ? DateService.formatDate(this.state.value) : value;
      value = this.props.type
      === "datetime"
      && this.state.value ? DateService.formatTime(this.state.value) : value;
      value = this.props.type === "file" && this.state.value ? this.state.value.name : value;
      value = this.props.type
      === "textarea"
      && this.state.value ? this.state.value.replace(new RegExp('\r?\n', 'g'), '<br>') : value;

      value = this.props.type
      === "creatableselect"
      && this.state.value ? this.state.value.map(v => v.label).join(', ') || '-' : value;

      const obj = _.find(this.props.options, {value: value});
      value = this.props.type === "select" && !_.isUndefined(this.state.value) && obj ? obj.label : value;

      let p = "", a = "";
      if (this.props.isValueClickable) {
        if (this.props.disabled) {
          p = value
        } else {
          a = value
        }
      } else {
        p = value;
        a = this.props.disabled ? a : this.props.editText
      }
      //add label if applicable
      p = this.props.label ? `${this.props.label}: ${p}` : p;
      let popover = this.props.mode === "popover" ? (
          <Popover isOpen={this.state.isEditing} placement={this.props.placement}
                   target={this.clickableLink}>
            <Popover.Title>{this.props.label}</Popover.Title>
            <Popover.Content>{this.getEditingComponent()}</Popover.Content>
          </Popover>) : null;

      return (<Form onSubmit={(e) => this.onSubmit(e, this.state.newValue)}
                    className={this.props.className}
                    inline>
            {!(_.isUndefined(p) || _.isNull(p)) && this.props.showText && <p className="my-0"
                                            style={{"whiteSpace": "pre-wrap"}}>{p}</p>}
            {!(_.isUndefined(a) || _.isNull(a)) && <a ref={this.clickableLink} className="ml-1 mt-auto w-100" href="javascript:"
                     onClick={() => this.setState({isEditing: true})}
                     dangerouslySetInnerHTML={{__html: a}}/>}
            {popover}
          </Form>)
    }
  }
}

Editable.defaultProps = {
  type: "textfield",
  mode: "inline",
  undefinedText: 'No Value',
  alwaysEditing: false,
  className: null,
  initialValue: null,
  id: null,
  label: null,
  showText: true,
  disabled: false,
  isValueClickable: false,
  editText: "Edit", //popover
  placement: "top", //functions
  validate: null,
  ajax: null,
  onSubmit: null,
  onValidated: null, //select props
  options: null
};
Editable.propTypes = {
  type: PropTypes.oneOf(["textfield", "textarea", "select", "date", "datetime", "file", "number", "creatableselect"]).isRequired,
  mode: PropTypes.oneOf(["inline", "popover"]).isRequired,
  alwaysEditing: PropTypes.bool,
  className: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  showText: PropTypes.bool,
  disabled: PropTypes.bool,
  isValueClickable: PropTypes.bool,
  editText: PropTypes.string, //popover
  placement: PropTypes.oneOf(["auto", "auto-start", "auto-end", "top", "top-start", "top-end", "right", "right-start", "right-end", "bottom", "bottom-start", "bottom-end", "left", "left-start", "left-end"]), //functions
  validate: PropTypes.func,
  ajax: PropTypes.func,
  onSubmit: PropTypes.func,
  onValidated: PropTypes.func, //select props
  options: PropTypes.array,
};
