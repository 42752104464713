import moment from 'moment';

/**
 * StrongAuthService
 * @module Services/utils/StrongAuthService
 * @description Handles data concealing operations
 */

import {PotCategory} from '../../models/Pot';
import {TARGET_APP} from "../../models/RequestInformation";
import AuthService from "../domain/AuthService";
import KeycloakService from "../domain/KeycloakService";

const self = {};

/**
 * Returns whether sensitive data should be concealed or not depending on pot category
 * @author Ghassen Manai
 * @alias showSecureSensitiveDataWithOTPForM1L
 * @memberof module:Services/utils/StrongAuthService
 * @param   {number} pot_category The category of the concerned pot
 */
self.showSecureSensitiveDataWithOTPForM1L = (): boolean => {
  return process.env.REACT_APP_SECURE_M1L_SENSITIVE_DATA_WITH_OTP === 'false' ||
    KeycloakService.getInstance().realm === process.env.REACT_APP_KEYCLOAK_WITH_OTP_REALM;
};

/**
 * Checks whether or not to prohibit strong auth operations and conceal data depending on the specified "app_origin"
 * @author Ghassen Manai
 * @alias areSensitiveOperationsAllowedForAppOrigin
 * @name StrongAuthService.areSensitiveOperationsAllowedForAppOrigin
 * @memberof module:Services/StrongAuthService
 * @param   {number} app_origin The app origin or target app concerned: {"M1L", "CNT"}
 */
self.allowSensitiveOperationsForAppOrigin = (app_origin) => {
  return (app_origin === TARGET_APP.M1L
    && !self.showSecureSensitiveDataWithOTPForM1L())
    || app_origin !== TARGET_APP.M1L;
};

export default self;
