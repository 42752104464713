import * as React from "react";
import {withI18n} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faClock,
  faEnvelope,
  faFile,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import {DocumentStatusLabel} from "../../../../models/VerificationDocument"

const DocumentStatus = ({t, status}) => {
  switch (status.toUpperCase()) {
    case DocumentStatusLabel.PENDING :
      return (<span className={'badge badge-sm badge-warning'}>
         <FontAwesomeIcon icon={faClock}/>
        &nbsp;&nbsp;
        {t(`kyc-document.status-${status.toLowerCase()}`)}</span>);
    case DocumentStatusLabel.REJECTED :
      return (<span className={'badge badge-sm badge-danger'}>
        <FontAwesomeIcon icon={faTimesCircle}/>
        &nbsp;&nbsp;
        {t(`kyc-document.status-${status.toLowerCase()}`)}</span>);
    case DocumentStatusLabel.VERIFIED :
      return (<span className={'badge badge-sm badge-success'}>
        <FontAwesomeIcon icon={faCheck}/>
        &nbsp;&nbsp;
        {t(`kyc-document.status-${status.toLowerCase()}`)}</span>);
    case DocumentStatusLabel.TREATED :
      return (<span className={'badge badge-sm badge-dark'}>
        <FontAwesomeIcon icon={faEnvelope}/>
        &nbsp;&nbsp;
        {t(`kyc-document.status-${status.toLowerCase()}`)}</span>);
    default:
      return (<span className={'badge badge-sm badge-secondary'}>
        <FontAwesomeIcon icon={faFile}/>
        &nbsp;&nbsp;
        {t(`kyc-document.status-${status.toLowerCase()}`)}</span>);
  }
};

export default withI18n()(DocumentStatus);
