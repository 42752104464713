// @flow
import * as React from "react";
import {useState, useEffect} from "react";
import {Figure, Modal} from "react-bootstrap";
import {Button, Loader, Form, Card, Table} from "tabler-react";
import {withI18n} from 'react-i18next';
import {useToasts} from "react-toast-notifications";
import LinksProvider from "../../../services/http/LinksProvider";
import ProductsService from "../../../services/domain/ProductsService";
import PotsService from "../../../services/domain/PotsService";
import {ProductType} from "../../../models/Product";
import css from "../../common/search-form/SearchForm.module.scss";
import ErrorHandler from "../../../services/error/ErrorHandler";
import _ from 'lodash';
import moment from "moment";

function AddCatalogProductModal({t, listReference, listProducts, listType, show, onHide, onSuccess}) {

  const {addToast} = useToasts();

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');

  useEffect(() => {
    loadData()
  }, [listReference]);

  const searchCriteria = {
    search: undefined,
    categories : undefined,
    sub_categories : undefined,
    type : [ProductType.MARRIAGE, ProductType.GIFT_MARRIAGE].join(','),
    min_price : undefined,
    max_price : undefined,
    placement : undefined,
    status : 1,
    order: undefined,
    validity_start_date : undefined,
    validity_end_date : undefined,
    creation_date_sort : undefined
  };

  const loadData = async () => {
    setLoading(true);
    let [weddingListProducts, allProducts] = await Promise.all([
      ProductsService.getListProducts(listReference),
      ProductsService.search(searchCriteria)
    ]);
    //Get only active products
    allProducts.products = allProducts.products.filter((product) => (moment().isBetween(product.validity_start_date, product.validity_end_date, 'days', '[]')));
    //Merge active products with list products
    allProducts.products = _.uniqBy([...allProducts.products, ...weddingListProducts], 'reference');
    //Check already selected list products
    allProducts.products.forEach((product) =>  _.filter(weddingListProducts, {reference: product.reference}).length > 0 ? product.checked = true : product.checked = false)
    setProducts(allProducts.products);
    setLoading(false);
  };

  const onConfirm = async () => {
    const checkedProducts = products.filter(product => product.checked).map(product => product.reference);
    setLoading(true);
    await PotsService.updateWeddingListProducts(listReference, {products: checkedProducts})
      .then(list => {
        onSuccess();
        onHide({message : t('pots.products-updated'), options : {appearance: 'success', autoDismiss: true}})
      })
      .catch(error => {
        ErrorHandler.handleError(addToast, t, error);
        onHide();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClick = (product) => {
    products.forEach((pr) => {if (pr.reference === product.reference) pr.checked = !pr.checked})
    setProducts([...products]);
  };

  const headerItems = [
    {content: (<p>&nbsp;</p>)},
    {content: t('products.cover-photo')},
    {content: t('products.reference')},
    {content: t('products.name')},
    {content: t('products.brand')},
  ];

  const itemRow = (product) => {
    const product_has_participation = listProducts.some((prd) => (prd.reference === product.reference && prd.has_participation));
    return {
      key: product.reference,
      item: [
        {
          content: (<input
            type="checkbox"
            disabled={product_has_participation}
            name="productsSelection"
            checked={product.checked}
            onChange={() => handleClick(product)}
            value={product.reference}/>)
        },
        {
          content: (
            <span>
              {(product.cover_photo && product.cover_photo.storage_service_url) ?
                (
                  <Figure>
                    <Figure.Image
                      alt={t('products.cover-photo')}
                      width={171}
                      height={180}
                      src={product.cover_photo.storage_service_url}/>
                  </Figure>
                ) : (
                  <div>&emsp;N/A</div>
                )}
            </span>)
        },
        {
          content: (<a
            target={'_blank'}
            href={LinksProvider.formatUrl(LinksProvider.ROUTES.PRODUCT, {id: encodeURIComponent(product.reference)})}>{product.reference}</a>)
        },
        {content: (<span>{product.name}</span>)},
        {content: (<span>{product.brand}</span>)},
      ]
    };
  };

  const onSearch = (e) => {
    e.preventDefault();
    if (search === undefined || search === null) {
      return false;
    }
  };

  const onChange = (e) => {
    setSearch(e.target.value);
  };


   return (<Modal size="lg" show={show} onHide={onHide}>
    <Modal.Header>
      <Modal.Title>
        {t('pots.products')}: {listReference}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {loading && (<Loader/>)}
      {!loading && (<React.Fragment>
        <React.Fragment>
          <form onSubmit={onSearch} className="mb-3">
            <Form.Input type="search" value={search} onChange={onChange} icon="search" placeholder={t('products.search')}/>
          </form>
        </React.Fragment>
        {products && products.filter((product) => product.name.toLowerCase().includes(search.toLowerCase()) || (product.brand && product.brand.toLowerCase().includes(search.toLowerCase()))).length > 0 && (
          <Card>
            <Card.Body className="mh-100">
              <div style={{
                maxHeight: 550,
                overflow: "scroll"
              }}>
                <Table responsive
                       className="card-table"
                       headerItems={headerItems}
                       bodyItems={products.filter((product) => product.name.toLowerCase().includes(search.toLowerCase()) || (product.brand && product.brand.toLowerCase().includes(search.toLowerCase()))).map(itemRow)}
                />
              </div>
            </Card.Body>
        </Card>
        )}
      </React.Fragment>)}
    </Modal.Body>
    <Modal.Footer>
      <Button disabled={loading} onClick={(event) => {onHide(); loadData();}} color="warning" closeButton>{t('modals.cancel')}</Button>
      <Button disabled={loading} onClick={(event) => {onConfirm();}} color="info" closeButton>{t('modals.confirm')}</Button>
    </Modal.Footer>
  </Modal>);
}

export default withI18n()(AddCatalogProductModal);
