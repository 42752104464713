/**
 * CommercialBannersService
 * @module Services/domain/CommercialBannersService
 * @description Loads commercial banners data from the server
 */

import HttpService from "../http/HttpService";
import LinksProvider from "../http/LinksProvider";

const self = {};


/**
 * Create a commercial banner
 * @author Seif Khiari
 * @alias create
 * @memberof module:Services/http/CommercialBannersService
 * @param   {any} data The commercial banner information
 * @returns {Promise<CommercialBanner>}  A promise containing the created commercial banner
 */
self.create = (data: any): Promise<CommercialBanner> => {
  return HttpService
    .post(LinksProvider.formatFullUrl(LinksProvider.API.COMMERCIAL_BANNERS.CREATE), data)
    .then(data => data.commercial_banner);
};

/**
 * find all active commercial banners
 * @author Seif Khiari
 * @alias getActive
 * @memberof module:Services/http/CommercialBannersService
 * @returns {Promise<CommercialBanner>}  A promise containing the result
 */

self.getActive = (): Promise<CommercialBanner> => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.COMMERCIAL_BANNERS.FIND))
    .then(data => data);
};

/**
 * find all commercial banners that have been historized
 * @author Seif Khiari
 * @alias getHistory
 * @memberof module:Services/http/CommercialBannersService
 * @returns {Promise<CommercialBanner>}  A promise containing the result
 */

self.getHistory = (criteria: any): Promise<CommercialBanner> => {
  let params = {
    sort: criteria.sort
  };
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.COMMERCIAL_BANNERS.HISTORY, params))
    .then(data => data);
};

/**
 * find commercial banners that have been historized according to search criteria
 * @author Seif Khiari
 * @alias searchHistory
 * @memberof module:Services/http/CommercialBannersService
 * @returns {Promise<CommercialBanner>}  A promise containing the result
 */
self.searchHistory = (criteria: any): Promise<CommercialBanner> => {
  console.log("search criteria : ", criteria);
  let params = {
    search: criteria.search,
    type: criteria.type
  };
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.COMMERCIAL_BANNERS.FIND_HISTORY, params))
    .then(data => data.commercial_banners);
};

/**
 * disable commercial banner by reference
 * @author Seif Khiari
 * @alias disableBanner
 * @memberof module:Services/http/CommercialBannersService
 * @returns {Promise<CommercialBanner>}  A promise containing the disabled banner
 */

self.disableBanner = (reference): Promise<CommercialBanner> => {
  console.log(reference);
  console.log(LinksProvider.formatFullUrl(LinksProvider.API.COMMERCIAL_BANNERS.DISABLE, {id: reference}));
  return HttpService
    .put(LinksProvider.formatFullUrl(LinksProvider.API.COMMERCIAL_BANNERS.DISABLE, {id: reference}))
    .then(data => data.commercial_banner);
};

export default self;
