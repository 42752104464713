import React from "react"
import {Form} from "tabler-react";
import DateService from '../../../services/utils/DateService'

export default class Date extends React.Component {
  constructor(props) {
    super(props)
  }

  formatDate = (value) => {
    return value && DateService.isValid(value) ? DateService.formatDate(value) : value;
  };

  render() {
    let date = this.formatDate(this.props.value);
    return (
      <React.Fragment>
        <Form.MaskedInput
          value={date}
          onChange={e => this.props.setNewValue(this.formatDate(e.target.value))}
          size="sm" className="mr-1"
          placeholder="JJ/MM/AAAA"
          mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}/>
        {this.props.controls}
      </React.Fragment>
    )
  }
}
