/**
 * UsersService
 * @module Services/domain/UsersService
 * @description Loads users data from the server
 */

import HttpService from "../http/HttpService";
import LinksProvider from "../http/LinksProvider";
import {User} from "../../models/User";
import type {MinifiedPot} from "../../models/Pot";
import {TARGET_APP} from "../../models/RequestInformation";

const self = {};

/**
 * Searches for a user with a specific query
 * @author Sameh Bellez
 * @alias search
 * @memberof module:Services/http/UsersService
 * @param   {string} query The search query
 * @returns {Promise<User>}  A promise containing the search result
 */
self.search = (query: string): Promise<User> => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.USERS.USER, {search: query}))
    .then(data => data.user);
};

/**
 * Updates user information
 * @author Sameh Bellez
 * @alias update
 * @memberof module:Services/http/UsersService
 * @param   {string} email The user's email
 * @param   {any} data The information to update
 * @returns {Promise<User>}  A promise containing the updated user
 */
self.update = (email: string, data: any): Promise<User> => {
  return HttpService
    .put(LinksProvider.formatFullUrl(LinksProvider.API.USERS.USER, {search: email}), data)
    .then(data => data.user);
};

/**
 * Gets a user's owned pots
 * @author Sameh Bellez
 * @alias ownedPots
 * @memberof module:Services/http/UsersService
 * @param   {string} email The user's email
 * @returns {Promise<MinifiedPot[]>}  A promise containing the list of the user's owned pots
 */
self.ownedPots = (email): Promise<MinifiedPot[]> => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.USERS.OWNED_POTS, {email: email}))
    .then(data => data.pots);
};

/**
 * Gets a user's invited pots
 * @author Sameh Bellez
 * @alias invitedPots
 * @memberof module:Services/http/UsersService
 * @param   {string} email The user's email
 * @returns {Promise<MinifiedPot[]>}  A promise containing the list of the user's invited pots
 */
self.invitedPots = (email): Promise<MinifiedPot[]> => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.USERS.INVITED_POTS, {email: email}))
    .then(data => data.pots);
};

/**
 * Gets a user's offered pots
 * @author Sameh Bellez
 * @alias invitedPots
 * @memberof module:Services/http/UsersService
 * @param   {string} email The user's email
 * @returns {Promise<MinifiedPot[]>}  A promise containing the list of the user's offered pots
 */
self.offeredPots = (email): Promise<MinifiedPot[]> => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.USERS.OFFERED_POTS, {email: email}))
    .then(data => data.pots);
};

/**
 * Gets a user's owned wedding lists
 * @author Seif Khiari
 * @alias ownedWeddingLists
 * @memberof module:Services/http/UsersService
 * @param   {string} email The user's email
 * @returns {Promise<MinifiedPot[]>}  A promise containing the list of the user's owned wedding lists
 */
self.ownedWeddingLists = (email): Promise<MinifiedPot[]> => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.USERS.OWNED_WEDDING_LISTS, {email: email}))
    .then(data => data.pots);
};

/**
 * Gets a user's participations
 * @author Sameh Bellez
 * @alias participations
 * @memberof module:Services/http/UsersService
 * @param   {string} email The user's email
 * @returns {Promise<MinifiedPot[]>}  A promise containing the list of the user's participations
 */
self.participations = (email): Promise<MinifiedPot[]> => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.USERS.PARTICIPATIONS, {email: email}))
    .then(data => data.participations);
};

/**
 * Gets a user's ribs
 * @author Hassen Charef
 * @alias ribs
 * @memberof module:Services/http/UsersService
 * @param   {string} email The user's email
 * @returns {Promise<MinifiedPot[]>}  A promise containing the list of the user's participations
 */
self.getRibs = (email): Promise<MinifiedPot[]> => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.USERS.RIBS.GET, {email: email}))
    .then(data => data);
};

/**
 * Gets a user's cashouts
 * @author Sameh Bellez
 * @alias getCashOuts
 * @memberof module:Services/http/UsersService
 * @param   {string} email The user's email
 * @returns {Promise<MinifiedPot[]>}  A promise containing the list of the user's cashouts
 */
self.getCashOuts = (email): Promise<any> => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.USERS.CASH_OUTS, {email: email}))
    .then(data => data.cash_outs);
};

/**
 * Bans a rib
 * @author Hassen Charef
 * @alias ribs
 * @memberof module:Services/http/UsersService
 * @param   {string} rib_reference The Rib's reference
 * @returns {Promise<MinifiedPot[]>}  A promise containing the list of the user's participations
 */
self.banIban = (request): Promise<any> => {
  return HttpService
    .put(LinksProvider.formatFullUrl(LinksProvider.API.USERS.RIBS.BAN), request)
    .then(data => data);
};

/**
 * Gets a user's ribs
 * @author Hassen Charef
 * @alias ribs
 * @memberof module:Services/http/UsersService
 * @param   {string} email the rib owner's email
 * @param   {string} reference The Rib's reference
 * @param   {string} target_app the targeted app for this action {{check RequestInformation.TARGET_APP}}
 * @returns {Promise<>}  A promise containing an undefined result
 */
self.deleteIban = (email, reference, target_app = TARGET_APP.CNT): Promise => {
  return HttpService
    .delete(LinksProvider.formatFullUrl(LinksProvider.API.USERS.RIBS.DELETE, {
      email: email,
      reference: reference,
      target_app: target_app
    }))
    .then(data => data);
};

/**
 * Gets a user's ribs
 * @author Hassen Charef
 * @alias ribs
 * @memberof module:Services/http/UsersService
 * @param   {string} email the rib owner's email
 * @param   {string} reference The Rib's reference
 * @param   {string} target_app the targeted app for this action {{check RequestInformation.TARGET_APP}}
 * @returns {Promise<MinifiedPot[]>}  A promise containing the list of the user's participations
 */
self.addRib = (email, request, target_app = TARGET_APP.CNT): Promise<MinifiedPot[]> => {
  return HttpService
    .post(LinksProvider.formatFullUrl(
      LinksProvider.API.USERS.RIBS.CREATE, {email: email}), {...request, target_app: target_app})
    .then(data => data);
};

/**
 * Gets a user's gitf cardss
 * @alias giftCards
 * @memberof module:Services/http/UsersService
 * @param   {string} email The user's email
 * @returns {Promise<MinifiedPot[]>}  A promise containing the list of the user's gift cards
 */
self.giftCards = (email): Promise<any> => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.USERS.GIFT_CARDS, {id: email}))
    .then(data => data.gift_cards);
};


/**
 * Get user's spouse information
 * @author Antoine Sauvage
 * @alias getUserSpouse
 * @memberof module:Services/http/UserService
 * @param   {string} email The user's email
 * @returns <Spouse{}> A promise containing the request result
 */
self.getUserSpouse = (email): Promise<any> => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.USERS.USER_SPOUSE, {email: email}))
    .then(result => result)
};


/**
 * Update user's spouse information
 * @author Antoine Sauvage
 * @alias updateUserSpouse
 * @memberof module:Services/http/UserService
 * @param   {string} email The user's spouse email
 * @param   {string} email The user's spouse email
 * @returns {promise} A promise containing the request result
 */
self.updateUserSpouse = (email, request) => {
  return HttpService
    .post(LinksProvider.formatFullUrl(LinksProvider.API.USERS.USER_SPOUSE, {email: email}), request)
    .then(result => result)
};

/**
 * Update user's add spouse user action information
 * @author Seif Khiari
 * @alias updateUserAddSpouseUserAction
 * @memberof module:Services/http/UserService
 * @param   {string} email The user email
 * @param   {object} request spouse info
 * @returns {promise} A promise containing the request result
 */
self.updateUserAddSpouseUserAction = (email, request) => {
  return HttpService
    .put(LinksProvider.formatFullUrl(LinksProvider.API.USERS.USER_SPOUSE_USER_ACTION, {email: email}), request)
    .then(result => result)
};

/**
 * Retrieves credit cards information for the specified user email
 * @author Ghassen Manai
 * @alias getCreditCardsByEmail
 * @memberof module:Services/http/UserService
 * @param   {string} email The user email
 * @returns {promise} A promise containing the request result
 */
self.getCreditCardsByEmail = (email) => {
    return HttpService
      .get(LinksProvider.formatFullUrl(LinksProvider.API.USERS.USER_CREDIT_CARDS, {email: email}))
      .then(result => result.credit_cards);
};

/**
 * Retrieves credit cards information for the specified user email
 * @author Hassen Charef
 * @alias getCreditCardsByEmail
 * @memberof module:Services/http/UserService
 * @param   {string} email The user email
 * @returns {promise} A promise containing the request result
 */
self.getSponsorship = (email) => {
    return HttpService
      .get(LinksProvider.formatFullUrl(LinksProvider.API.USERS.SPONSORSHIP, {email: email}))
      .then(result => result.sponsorship);
};

self.generateQrCode = (email) => {
  return HttpService
    .post(LinksProvider.API.USERS.QR_CODE, {email});
}

/**
 * Retrieves user strong authentication status
 * @author Amine Elouaer
 * @alias getStrongAuthStatus
 * @memberof module:Services/http/UserService
 * @param   {string} email The user email
 * @returns {promise} A promise containing the request result
 */

self.getStrongAuthStatus = (email) => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.USERS.STRONG_AUTH, {email :email}))
}


/**
 * Closes user account 
 * @author Amine Elouaer
 * @alias closeAccount
 * @memberof module:Services/http/UserService
 * @param   {string} email The user email
 * @returns {promise} A promise containing the request result
 */

self.closeAccount = (email, body) => {
  return HttpService
    .post(LinksProvider.formatFullUrl(LinksProvider.API.USERS.CLOSE_ACCOUNT, {email :email}) ,body)
}

export default self;
