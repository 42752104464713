import React, { Fragment, useEffect, useState } from "react";
import UsersService from "../../../services/domain/UsersService";
import EmptyContent from "../../common/empty-content/EmptyContent";
import DateService from '../../../services/utils/DateService'
import {
    Badge, Card, Loader, Table,
  } from "tabler-react";
import { withI18n } from "react-i18next";
import FormatService from "../../../services/utils/FormatService";
import { StrongAuth } from "../../../models/User";
function UserStrongAuths({email , t}) {

    const [user, setUser] = useState(null);
    const [loading , setLoading] = useState(false);
    const [strongAuth, setStrongAuth] = useState([]);

    const loadData = () => {
             setLoading(true);
            UsersService.getStrongAuthStatus(email)
            .then((res)=> {
                if (res) {
                    const updatedStatus = res.map( info => ({
                        reference: info?.reference,
                        type: info?.type,
                        status: info?.response_body?.Header?.Status || info?.extra_data?.strong_auth_data?.header?.status,
                        reason : info?.response_body?.Header?.Reason || info?.extra_data?.strong_auth_data?.header?.reason,
                        creation_date: info?.creation_date
                    }));
                    setStrongAuth(updatedStatus);
                }   
            }).finally(()=> setLoading(false))
    }

    const headerItems = [
        {content: t('users.strong-auth-type')},
        {content: t('users.strong-auth-status')},
        {content: t('users.creation-date')}
      ];
    
    const strongAuthStates = {
      TIMEOUT: {
        "badge": "danger",
        "message": "users.strong-auth-expired",
      },
      CANCELED: {
        "badge": "danger",
        "message": "users.strong-auth-refused-client",
      },
      FAILED: {
        "badge": "danger",
        "message": "users.strong-auth-error-client",
      },
    };
    const strongAuthBadge = (strongAuth) => {
    switch (strongAuth?.status) {
        case "Pending" :
        return (<Badge color="warning">{t('users.strong-auth-pending')}</Badge>);
        case "Failed" :
          return (<Badge color={strongAuthStates[strongAuth?.reason]?.["badge"] || 'danger'}>{t(strongAuthStates[strongAuth?.reason]?.["message"]) || 'Raison non définie'}</Badge>);         case "Succeeded" :
        return (<Badge color="success">{t('users.strong-auth-succeeded')}</Badge>);
        case "Expired":
        return (<Badge color="danger">{t('users.strong-auth-expired')}</Badge>)
    }
    }; 

    const oneStrongAuthLine = (strongauth) => {
        return {
          key: strongauth.reference,
          item: [
            {content: t('strong-auth.' + FormatService.flipObject(StrongAuth)[strongauth.type])},
            {content: strongAuthBadge(strongauth)},
            {content: DateService.formatTime(strongauth.creation_date)}
          ]
        };
      };

    useEffect(()=> {
        loadData();
    },[]);

    return(
        <Card title={t('users.af-list')}>
        <Card.Body className="fixed-height-card-body">
          {((!strongAuth || !strongAuth.length) && !loading) ? (
            <EmptyContent text={t('users.no-af')}/>
          ) : ''}
          {((strongAuth && strongAuth.length) && !loading) ? (
            <Table responsive
                   className="card-table"
                   headerItems={headerItems}
                   bodyItems={strongAuth.map(oneStrongAuthLine)}/>
          ) : ''}
          {loading && <Loader/>}
        </Card.Body>
      </Card>
    )
}

export default withI18n()(UserStrongAuths)