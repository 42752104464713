import React, {useState, useEffect} from "react";
import {colors} from "tabler-react";
import C3Chart from "react-c3js";
import {Line} from 'react-chartjs-2';

const SimpleLineChart = ({x_columns, y_columns, label_formatter, y_axis_label_formatter, y_axis_step_size}) => {

    /*const getLabelNumericValue = (label) => {
        return isNaN(label) ? label.replace(/-/g, ''): label;
    };

    const x_columns_steps = x_columns.map(step => getLabelNumericValue(step) - getLabelNumericValue(x_columns[0]) + 1);*/

    const data = {
        labels: x_columns,
        datasets: y_columns.map(column =>
        {
            return {
                label: column.name,
                fill: false,
                lineTension: 0.1,
                backgroundColor: column.color || 'rgba(75,192,192,0.4)',
                borderColor: column.color || 'rgba(75,192,192,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: column.color || 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: column.color || 'rgba(75,192,192,1)',
                pointHoverBorderColor: column.color || 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: column.values
            }
        }
        )
    };

    const options = {
        tooltips: {
            callbacks: {
                label: label_formatter ? label_formatter : value => value.yLabel
            },
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        callback: y_axis_label_formatter ? y_axis_label_formatter : function(label, index, labels) {
                            return label;
                        },
                        min: 0, // it is for ignoring negative step.
                        stepSize: y_axis_step_size
                    }
                }
            ]
        }
    };

    return (
        <React.Fragment>
            <Line data={data} options={options}/>
        </React.Fragment>)
};

export default SimpleLineChart;
