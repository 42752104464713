// @flow
import * as React from "react";
import SiteWrapper from "../../SiteWrapper";
import { Button, Loader, Page, Grid } from "tabler-react";
import { Redirect } from "react-router-dom";
import { withI18n } from "react-i18next";
import { useEffect, useState } from "react";
import SearchForm from "../../components/common/search-form/SearchForm";
import LinksProvider from "../../services/http/LinksProvider";
import ProductsService from "../../services/domain/ProductsService";
import EmptyContent from "../../components/common/empty-content/EmptyContent";
import CreateProductModal from "../../components/modals/create-product-modal/CreateProductModal";
import ErrorHandler from "../../services/error/ErrorHandler";
import DateService from "../../services/utils/DateService"
import { useToasts } from 'react-toast-notifications';
import ProductsList from "../../components/products/products-list/ProductsList";
import ProductsFilter from "../../components/products/products-filter/ProductsFilter";


function ProductsPage({ t }): React.Node {
  const [createModalShown, setCreateModalShown] = useState(false);
  const { addToast } = useToasts();

  const [products, setProducts] = useState(null);
  const [productsCount, setProductsCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const productsPerPage = 10;

  useEffect(() => {
    document.title = t('navbar.products');
    loadData();
  }, [t]);

  const getParam = (paramName) => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get(paramName)
  };

  const loadData = () => {
    setLoading(true);
    let criteria = {
      search: getParam('search'),
      categories: getParam('categories'),
      sub_categories: getParam('sub_categories'),
      type: getParam('type'),
      min_price: getParam('minPrice'),
      max_price: getParam('maxPrice'),
      placement: getParam('placement'),
      status: getParam('status'),
      validity_start_date: DateService.formatDateISO(getParam('validityStart')),
      validity_end_date: DateService.formatDateISO(getParam('validityEnd')),
      creation_date_sort: getParam('creationDateSort'),
      order: getParam('order')
    };
    ProductsService.search(criteria, productsPerPage)
      .then(data => {
        if (data) {
          setProducts(data.products);
          setProductsCount(data.original_result_count);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <SiteWrapper>
      <Page.Content>
        <Page.Header
          title={t('navbar.products')}
          options={(<Grid.Row>
            <Grid.Col><SearchForm value={getParam('search')}
              placeholder={t('products.search-placeholder')}
              injectParam={true}
              route={LinksProvider.ROUTES.PRODUCTS} /></Grid.Col>
            <Grid.Col>
              <ProductsFilter
                searchCriteria={{
                  search: getParam('search'),
                  categories: getParam('categories'),
                  sub_categories: getParam('sub_categories'),
                  type: getParam('type'),
                  min_price: getParam('minPrice'),
                  max_price: getParam('maxPrice'),
                  placement: getParam('placement'),
                  status: getParam('status'),
                  validity_start_date: getParam('validityStart'),
                  validity_end_date: getParam('validityEnd'),
                  creation_date_sort: getParam('creationDateSort'),
                  order: getParam('order')
                }}
              />
            </Grid.Col>
            <Grid.Col><Button color="success" onClick={() => setCreateModalShown(true)}>
              {t('products.create')}
            </Button></Grid.Col>
          </Grid.Row>
          )}
        />
        <CreateProductModal
          show={createModalShown}
          dialogClassName="modal-lg"
          onHide={() => setCreateModalShown(false)}
          onError={(error) => ErrorHandler.handleError(addToast, t, error)}
        />
        {loading && <Loader />}

        {(!loading && products && products.length > 0) && (
          <ProductsList products={products} searchQueryCount={productsCount} searchCriteria={{
            search: getParam('search'),
            categories: getParam('categories'),
            sub_categories: getParam('sub_categories'),
            type: getParam('type'),
            min_price: getParam('minPrice'),
            max_price: getParam('maxPrice'),
            placement: getParam('placement'),
            status: getParam('status'),
            validity_start_date: getParam('validityStart'),
            validity_end_date: getParam('validityEnd'),
            creation_date_sort: getParam('creationDateSort'),
            order: getParam('order')
          }} />
        )}

        {(!loading && (!products || !products.length)) && (
          <EmptyContent text={t('products.no-results')} />
        )}
      </Page.Content>
    </SiteWrapper>
  );
}

export default withI18n()(ProductsPage);
