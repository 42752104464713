// @flow
import * as React from "react";
import {useState, useEffect} from "react";
import RequestInformationService from "../../../services/domain/RequestInformationService";
import {Grid, Loader, Table} from "tabler-react";
import {withI18n} from 'react-i18next';
import Location from "../location/Location";
import css from './RequestInformation.module.scss'
import {useToasts} from 'react-toast-notifications';
import ErrorHandler from "../../../services/error/ErrorHandler";

function RequestInformation({id, t}): React.Node {
  const [information, setInformation] = useState(null);
  const [loading, setLoading] = useState(false);
  const {addToast} = useToasts();


  const loadData = () => {
    setLoading(true);
    RequestInformationService.get(id)
        .then(information => setInformation(information))
        .catch(error => ErrorHandler.handleError(addToast, t, error))
        .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
      <React.Fragment>
        {(information && !loading) && (
            <React.Fragment>
              <Grid.Row>
                <Grid.Col>
                  <Table responsive className={css.request_information_table}>
                    <Table.Body>
                      <Table.Row>
                        <Table.Col>{t('request-information.ip-address')}</Table.Col>
                        <Table.Col>{information.ip_address}</Table.Col>
                      </Table.Row>
                      <Table.Row>
                        <Table.Col>{t('request-information.user-agent')}</Table.Col>
                        <Table.Col>{information.user_agent ? (<code>{information.user_agent}</code>) : t('request-information.unknown')}</Table.Col>
                      </Table.Row>
                      <Table.Row>
                        <Table.Col>{t('request-information.device')}</Table.Col>
                        <Table.Col>{t(`request-information.device-${information.channel}`)}</Table.Col>
                      </Table.Row>
                      <Table.Row>
                        <Table.Col>{t('request-information.country')}</Table.Col>
                        <Table.Col>{information.country || t('request-information.unknown')}</Table.Col>
                      </Table.Row>
                      <Table.Row>
                        <Table.Col>{t('request-information.city')}</Table.Col>
                        <Table.Col>{information.city || t('request-information.unknown')}</Table.Col>
                      </Table.Row>
                      <Table.Row>
                        <Table.Col>{t('request-information.postal-code')}</Table.Col>
                        <Table.Col>{information.postal_code || t('request-information.unknown')}</Table.Col>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Grid.Col>
                  {(information.city && information.country) && (
                    <Grid.Col>
                      <Location city={information.city} country={information.country} marker={`/assets/images/marker-${information.channel}.png`}/>
                    </Grid.Col>
                  )}
              </Grid.Row>



            </React.Fragment>
        )}
        {loading && (<Loader/>)}
      </React.Fragment>
  );
}

export default withI18n()(RequestInformation);