// @flow
import * as React from "react";
import SiteWrapper from "../../SiteWrapper";
import {Loader, Page} from "tabler-react";
import {withI18n} from "react-i18next";
import SearchForm from "../../components/common/search-form/SearchForm";
import LinksProvider from "../../services/http/LinksProvider";
import {useEffect, useState} from "react";
import PotsService from "../../services/domain/PotsService";
import EmptyContent from "../../components/common/empty-content/EmptyContent";
import PotsList from "../../components/pots/pots-list/PotsList";

function PotsPage({t}): React.Node {

    const [pots, setPots] = useState(null);
    const [loading, setLoading] = useState(false);
    const [queryParams, setQueryParams] = useState(new URLSearchParams(window.location.search));

    const loadData = () => {
        console.log("loadData");
        const searchQuery = queryParams.get('search');
        if (searchQuery) {
            setLoading(true);
            PotsService.search(searchQuery)
              .then(pots => {
                  setPots(pots);
              })
              .finally(() => setLoading(false));
        }
    };

    useEffect(() => {
        document.title = t('navbar.pots');
        loadData()
    }, [t]);



    return (
        <SiteWrapper>
            <Page.Content>
                <Page.Header
                    title={t('navbar.pots')}
                    options={(<SearchForm placeholder={t('pots.search-placeholder')}
                                          route={LinksProvider.ROUTES.POTS}
                                          injectParam={true}
                                          value={queryParams.get('search')}
                    />)}
                />

                {loading && <Loader/>}

                {(!loading && pots && pots.length > 0) && (<PotsList pots={pots}/>)}

                {(!loading && (!pots || !pots.length)) && (<EmptyContent text={t('pots.no-results')}/>)}
            </Page.Content>
        </SiteWrapper>
    );
}

export default withI18n()(PotsPage);