import React, {useState, useEffect} from "react";

import DateService from '../../services/utils/DateService';
import CreditCardsService from '../../services/domain/CreditCardsService';
import {
  Button, Badge, Card, Loader, Grid, Form
} from "tabler-react";
import {withI18n} from 'react-i18next';
import EmptyContent from "../common/empty-content/EmptyContent";
import LinksProvider from "../../services/http/LinksProvider";
import {
  MinifiedCreditCard,
  CreditCardStatus,
  CARD_OPPOSED_REASON,
  CreditCardBlockStatus
} from "../../models/CreditCard";
import Amount from "../common/amount/Amount";
import {useToasts} from "react-toast-notifications";
import ErrorHandler from '../../services/error/ErrorHandler';
import StrongAuthService from "../../services/utils/StrongAuthService";
import {PotCategory} from "../../models/Pot";

function CreditCard({credit_card, t, user}): React.Node {
  const [blockButtonLoading, setBlockButtonLoading] = useState(false);
  const [model, setModel] = useState(credit_card);
  const {addToast} = useToasts();

  const creditCardStatusBadge = (model: MinifiedCreditCard) => {
    switch (model.status) {
      case CreditCardStatus.ORDERED :
        return (<Badge color="primary">{t('credit-cards.status-ordered')}</Badge>);
      case CreditCardStatus.SENT:
        return (<Badge color="warning">{t('credit-cards.status-sent')}</Badge>);
      case CreditCardStatus.EXPIRED:
        return (<Badge color="danger">{t('credit-cards.status-expired')}</Badge>);
      case CreditCardStatus.OPPOSED:
        return (<Badge color="danger">{t('credit-cards.status-opposed')}</Badge>);
      case CreditCardStatus.FAILED:
        return (<Badge color="danger">{t('credit-cards.status-failed')}</Badge>);
      case CreditCardStatus.DEACTIVATED:
        return (<Badge color="danger">{t('credit-cards.status-deactivated')}</Badge>);
      case CreditCardStatus.ACTIVATED:
        return (<Badge color="success">{t('credit-cards.status-activated')}</Badge>);
    }
  };

  const creditCardOpposedReason = (model: MinifiedCreditCard) => {
    if (model.status === CreditCardStatus.OPPOSED) {
      if ((typeof model.opposed_reason !== 'undefined') && model.opposed_reason > 0) {
        return (<Badge color="danger">{t(`credit-cards.opposed-reason-${model.opposed_reason}`)}</Badge>);
      } else {
        return (<Badge color="primary">{t('credit-cards.opposed-reason-not-specified')}</Badge>);
      }
    } else {
      return (<Badge color="primary">{t('credit-cards.opposed-reason-0')}</Badge>);
    }
  };

  const changeCreditCardBlockStatus = (model: MinifiedCreditCard) => {
    setBlockButtonLoading(true);
    CreditCardsService.toggleCreditCardBlock(model.reference)
      .then(credit_card => {
        setModel({
          ...credit_card,
          xpollens_account_authorization_balance: model.xpollens_account_authorization_balance
        });
      })
      .catch(e => {
        ErrorHandler.handleError(addToast, t, e);
      })
      .finally(() => setBlockButtonLoading(false));
  };

  return (
    <React.Fragment>
      <Grid.Row width={12}>
        <Grid.Col width={4}>
          {user && user.xpollens_external_reference && (
            <Form.Group label={t('credit-cards.user-xpollens-external-reference')}>
              <a target={'_blank'}
                 href={`${LinksProvider.formatUrl(LinksProvider.XPOLLENS.BACK_OFFICE_URL, {id: user.xpollens_external_reference})}`}
              >{user.xpollens_external_reference}</a>
            </Form.Group>
          )}
        </Grid.Col>
        <Grid.Col width={4}>
          <Form.Group label={t('credit-cards.reference')}>
            {model.reference}
          </Form.Group>
        </Grid.Col>
        <Grid.Col width={4}>
          <Form.Group label={t('credit-cards.type')}>
            {model.type}
          </Form.Group>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row width={12}>
        <Grid.Col width={4}>
          <Form.Group label={t('credit-cards.order-date')}>
            {DateService.formatDate(model.order_date)}
          </Form.Group>
        </Grid.Col>
        <Grid.Col width={4}>
          <Form.Group label={t('credit-cards.account-balance')}>
              <Amount amount={model.xpollens_account_authorization_balance}></Amount>
          </Form.Group>
        </Grid.Col>
        <Grid.Col width={4}>
          <Form.Group label={t('credit-cards.status')}>
            {creditCardStatusBadge(model)}
          </Form.Group>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row width={12}>
        <Grid.Col width={4}>
          <Form.Group label={t('credit-cards.owner-identity')}>
            {`${user.first_name} ${user.last_name}`}
          </Form.Group>
        </Grid.Col>
        <Grid.Col width={4}>
          <Form.Group label={t('credit-cards.pan-hint')}>
            {model.pan_hint}
          </Form.Group>
        </Grid.Col>
        <Grid.Col width={4}>
          <Form.Group label={t('credit-cards.expiration-date')}>
            {DateService.formatDateAsMonthAndYear(model.expiration_date)}
          </Form.Group>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col width={4}>
          <Form.Group label={t('credit-cards.opposed-status')}>
            {creditCardOpposedReason(model)}
          </Form.Group>
        </Grid.Col>
        <Grid.Col width={4}>
          {''}
        </Grid.Col>
        <Grid.Col width={4}>
          <Form.Group label={t('credit-cards.block-unblock')}>
            <Button disabled={blockButtonLoading}
                         onClick={() => changeCreditCardBlockStatus(model)}
                         color={model.card_block === CreditCardBlockStatus.ACTIVE ? 'danger' : 'primary'}>
                {t(`credit-cards.${model.card_block === CreditCardBlockStatus.ACTIVE ? 'block' : 'unblock'}`)}</Button>
          </Form.Group>
        </Grid.Col>
      </Grid.Row>
    </React.Fragment>
  );
}

export default withI18n()(CreditCard);
