// @flow
import * as React from "react";
import SiteWrapper from "../../SiteWrapper";
import {Button, Card, Grid, Loader, Page} from "tabler-react";
import {withI18n} from "react-i18next";
import {useEffect, useState} from "react";
import SearchForm from "../../components/common/search-form/SearchForm";
import LinksProvider from "../../services/http/LinksProvider";
import PromoCodesService from "../../services/domain/PromoCodesService";
import PromoCodesList from "../../components/promo-codes/promo-codes-list/PromoCodesList";
import EmptyContent from "../../components/common/empty-content/EmptyContent";
import {Fragment} from "react";
import PromoCodeCreationModal from "../../components/modals/promo-code-creation-modal/PromoCodeCreationModal";


function PromoCodesPage({t}): React.Node {
  const [promoCodes, setPromoCodes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [creationModalShown, setCreationModalShown] = useState(false);
  const [queryParams, setQueryParams] = useState(new URLSearchParams(window.location.search));

  const loadData = () => {
    console.log("loadData");
    const searchQuery = queryParams.get('search');
    setLoading(true);
    if (searchQuery) {
      PromoCodesService.search(searchQuery)
        .then(promoCodes => setPromoCodes(promoCodes))
        .finally(() => setLoading(false));
    } else {
      PromoCodesService.getAll()
        .then(promoCodes => {setPromoCodes(promoCodes)})
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    document.title = t('navbar.promo-codes');
    loadData()
  }, [t]);

  return (
    <SiteWrapper>
      <Page.Content>
        <PromoCodeCreationModal afterCreation={(promoCode) => {
          promoCodes.push(promoCode);
          window.location.reload()
        }} show={creationModalShown} onHide={() => setCreationModalShown(false)}/>
        <Page.Header
          title={t('navbar.promo-codes')}
          options={(
            <Grid.Row>
              <Grid.Col>
                <Button icon="plus" onClick={() => setCreationModalShown(true)} color="primary"
                >{t('promo-codes.create-promo-code')}</Button>
              </Grid.Col>
              <Grid.Col>
                <SearchForm placeholder={t('promo-codes.search-placeholder')}
                            route={LinksProvider.ROUTES.PROMO_CODES}
                            injectParam={true}
                            value={queryParams.get('search')}
                />
              </Grid.Col>
            </Grid.Row>)}
        />

        {loading && <Loader/>}

        {(!loading && promoCodes && promoCodes.length > 0) && (
          <PromoCodesList promoCodes={promoCodes}/>
        )}

        {(!loading && (!promoCodes || !promoCodes.length)) && (
          <EmptyContent text={t('promo-codes.no-results')}/>
        )}

      </Page.Content>
    </SiteWrapper>
  );
}

export default withI18n()(PromoCodesPage);
