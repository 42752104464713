/**
 * ResponseHandler
 * @module Services/http/ResponseHandler
 * @description Handlers a http response
 */

const self = {};

/**
 * Handlers every successful http request and returns its data
 * @author Sameh Bellez
 * @alias handleSuccess
 * @memberof module:Services/http/ResponseHandler
 * @param   {object} response The http response
 * @returns {object}  The response data
 */
self.handleSuccess = (response) => {
  if (response.config.keepOriginalResponse) {
    return response;
  }
  return response.data.data;
};


/**
 * Handlers every failed http request, handles the errors and reject the error
 * @author Sameh Bellez
 * @alias handleError
 * @memberof module:Services/http/ResponseHandler
 * @param   {object} error The http error
 * @returns {promise}  A promise rejecting the error
 */
self.handleError = (error) => {
  const response = {...error}.response;
  return Promise.reject(response && response.data ? response.data : error);
};

export default self;
