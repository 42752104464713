/**
 * RequestInformationService
 * @module Services/domain/RequestInformationService
 * @description Loads request information data from the server
 */

import HttpService from "../http/HttpService";
import LinksProvider from "../http/LinksProvider";
import {RequestInformation} from "../../models/RequestInformation";

const self = {};


/**
 * Gets a request information by it's uuid
 * @author Sameh Bellez
 * @alias search
 * @memberof module:Services/domain/RequestInformationService
 * @param   {string} query The search query
 * @returns {Promise<RequestInformation>}  A promise containing the result
 */
self.get = (uuid : string): Promise<RequestInformation> => {
  return HttpService
      .get(LinksProvider.formatFullUrl(LinksProvider.API.REQUEST_INFORMATION.REQUEST_INFORMATION, {uuid: uuid}))
      .then(data => data.request_information);
};



export default self;
