import React from "react";
import {Form} from "tabler-react";
import {Field} from "formik";
import DateService from "../../../services/utils/DateService";

const DateInput = (props) => {

  return (<Field name={props.name} id={props.id}>
        {({field: {value}, form: {setFieldValue}}) => (
            <Form.MaskedInput
                onChange={(e) => {
                  props.onChange && props.onChange(e);
                  setFieldValue(props.name, DateService.parseDate(e.target.value));
                }}
                onBlur={props.onBlur}
                name={props.name}
                placeholder={props.placeholder}
                mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}/>
        )}</Field>

  );
};

export default DateInput;