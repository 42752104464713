/**
 * PotsService
 * @module Services/domain/PotsService
 * @description Loads pots data from the server
 */

import HttpService from "../http/HttpService";
import LinksProvider from "../http/LinksProvider";
import {CreditCard} from "../../models/CreditCard";

const self = {};

/**
 * Blocks or unblocks a credit card
 * @author Ghassen Manai
 * @alias toggleCreditCardBlock
 * @memberof module:Services/http/CreditCardsService
 * @param   {string} reference the reference of the credit card
 * @returns {Promise<CreditCard>}  A promise containing the credit card object returned in the http response
 */
self.toggleCreditCardBlock = (reference: string): Promise<CreditCard> => {
  return HttpService
    .post(LinksProvider.formatFullUrl(LinksProvider.API.CREDIT_CARDS.TOGGLE_BLOCK, {reference: reference}))
    .then(data => data);
};

export default self;
