// @flow

export const ProductType = {
  GIFT: 0,
  MARRIAGE: 1,
  GIFT_MARRIAGE: 2
};

export const ProductStatus = {
  DRAFT: 0,
  ACTIF: 1,
  DEACTIVATED: 2
};

export const ProductGender = {
  NOT_ASSIGNED: null,
  MALE: 0,
  FEMALE: 1,
  UNISEX: 2
};

export const ProductPlacement = {
  FIRST_LINE: 0,
  UPPER_PAGE: 1,
  MIDDLE_PAGE: 2,
  LOWER_PAGE: 3
};
export const ProductCategory = [
  {
    id: 1,
    name: "Meubles", // Category name
    subCategories: [
      { id: 11, name: "Salon" },
      { id: 12, name: "Salle à manger" },
      { id: 13, name: "Cuisine" },
      { id: 14, name: "Meuble de rangement" },
      { id: 15, name: "Bureau" },
      { id: 16, name: "Chambre" },
      { id: 17, name: "Salle de bain" },
      { id: 18, name: "Meuble de jardin" },
      { id: 19, name: "Chambre enfant bébé" },
    ],
  },
  {
    id: 2,
    name: "Déco",
    subCategories: [
      { id: 21, name: "Luminaire" },
      { id: 22, name: "Objet de décoration" },
      { id: 23, name: "Tapis" },
      { id: 24, name: "Bougies et senteurs" },
      { id: 25, name: "Décoration murale" },
      { id: 26, name: "Miroir" },
      { id: 27, name: "Coussin" },
    ],
  },
  {
    id: 3,
    name: "Maison",
    subCategories: [
      { id: 31, name: "Linge de lit" },
      { id: 32, name: "Linge de toilette" },
      { id: 33, name: "Peignoirs" },
      { id: 34, name: "Linge de table" },
      { id: 35, name: "Linge de cuisine" },
      { id: 36, name: "Couettes et oreillers" },
      { id: 37, name: "Rideaux et stores" },
    ],
  },
  {
    id: 4,
    name: "Table",
    subCategories: [
      { id: 41, name: "Vaisselle" },
      { id: 42, name: "Verres et carafes" },
      { id: 43, name: "Œnologie" },
    ],
  },
  {
    id: 5,
    name: "Culinaire",
    subCategories: [
      { id: 51, name: "Ustensiles" },
      { id: 52, name: "Casseroles, poêles et woks" },
      { id: 53, name: "Plats en fonte" },
      { id: 54, name: "Faits-tout et marmites" },
    ],
  },
  {
    id: 6,
    name: "Voyage"
  },
  {
    id: 7,
    name: "Électroménager",
    subCategories: [
      { id: 71, name: "Gros électroménager" },
      { id: 72, name: "Petit électroménager" },
    ],
  },
  {
    id: 8,
    name: "Multimédia",
    subCategories: [
      { id: 81, name: "Smartphone et GPS" },
      { id: 82, name: "TV, image et son" },
      { id: 83, name: "Jeux vidéo" },
    ],
  },
  {
    id: 9,
    name: "Loisirs"
  },
  {
    id: 10,
    name: "Mode",
    subCategories: [
      { id: 101, name: "Femme" },
      { id: 102, name: "Homme" },
    ],
  },
];




export const ProductStore = {
  GL: 1,
  BHV: 2,
  LA_REDOUTE: 3,
  LOUIS_PION: 4,
  GUERIN_JOAILLERIE: 5,
  BAZAR_CHIC: 6,
  EATALY_PARIS_MARAIS: 7,
  ROYAL_QUARTZ: 8,
  MAUBOUSSIN: 9
};

export const ProductAgeRange = {
  ZERO_FIVE: 1,
  FIVE_FIFTEEN: 2,
  FIFTEEN_TWENTYFIVE: 3,
  TWENTYFIVE_THIRTYFIVE: 4,
  THIRTYFIVE_FOURTYFIVE: 5,
  FOURTYFIVE_SIXTY: 6,
  SIXTY_PLUS: 7
};


export type Products = {
  reference: string,
  name: string,
  brand: string,
  type: number,
  status: number,
  url: string,
};

export
type
MinifiedProduct = {
  reference: string,
  name: string,
  brand: string,
  type: number,
  price: number,
  url: string,
  gender: number,
  placement: number,
  status: number,
  creation_date: Date,
  validity_start_date: Date,
  validity_end_date: Date,
  included_in_inspiration_list: boolean,
  is_favorite: boolean,
  deletion_date?: Date,
};
