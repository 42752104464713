import React, {useState, useEffect} from "react";
import {Badge, Card, Dimmer, Form, Grid} from "tabler-react";
import {withI18n} from 'react-i18next';
import Editable from '../../common/editable/Editable';
import UserService from "../../../services/domain/UsersService";
import DateService from "../../../services/utils/DateService";
import ErrorHandler from "../../../services/error/ErrorHandler";
import {useToasts} from 'react-toast-notifications';
import UsersService from "../../../services/domain/UsersService";
import EmptyContent from "../../common/empty-content/EmptyContent";
import {ListSpouseStatus} from "../../../models/Pot";
import AuthService from '../../../services/domain/AuthService';

const SpouseInfo = ({t, userEmail}) => {

    const [spouseModel, setSpouseModel] = useState({});
    const [loading, setLoading] = useState(false);
    const {addToast} = useToasts();
    const can_edit = AuthService.userAuthorizations.includes('AUTH_BO_POTS');


    useEffect(() => {
        getUserSpouse();
    }, []);


    const getUserSpouse = () => {
        setLoading(true);
        UsersService.getUserSpouse(userEmail)
            .then(spouse => setSpouseModel(spouse))
            .catch(error => ErrorHandler.handleError(addToast, t, error))
            .finally(() => setLoading(false));
    };


    const updateSpouse = (request) => {
        setLoading(true);
        return UserService
            .updateUserSpouse(userEmail, request)
            .then(() => getUserSpouse())
            .catch(error => ErrorHandler.handleError(addToast, t, error))
            .finally(() => setLoading(false));
    };

    const updateAddSpouseUserAction = (request) => {
        setLoading(true);
        return UserService
            .updateUserAddSpouseUserAction(userEmail, request)
            .then(()=> getUserSpouse())
            .catch(error => ErrorHandler.handleError(addToast, t, error))
            .finally(() => setLoading(false));
    }

    const editSpouseInfo = (field) => {
        return (val) => {
            const request = {};
            if (field.indexOf('.') !== - 1) {
                const array = field.split('.');
                spouseModel[array[0]][array[1]] = val;
                request[array[0]] = {};
                request[array[0]][array[1]] = val;
            } else if (field === "birthdate") {
                request[field] = DateService.formatDateISO(val);
            } else {
                spouseModel[field] = val;
                request[field] = val;
            }
            setSpouseModel({...spouseModel});
            if (spouseModel.status === ListSpouseStatus.PENDING) {
                updateAddSpouseUserAction(request);
            } else if (spouseModel.status === ListSpouseStatus.ADDED) {
                updateSpouse(request);
            }
        };
    };

    const getSpouseStatus = (spouse) => {
        if (spouse.status === ListSpouseStatus.PENDING) {
            return (<Badge color="warning">{t(`users.spouse-status-${spouse.status}`)}</Badge>)
        } else if (spouse.status === ListSpouseStatus.ADDED) {
            return (<Badge color="success">{t(`users.spouse-status-${spouse.status}`)}</Badge>)
        }
    }


    return (

        <>
            <Card>
                <Card.Header>
                    <Card.Title>
                        {t('users.spouse')}
                    </Card.Title>
                </Card.Header>

                <Card.Body>

                    {((!spouseModel || !Object.keys(spouseModel).length) && !loading) ? (
                        <EmptyContent text={t('users.no-spouse')}/>) : ''}

                    <Dimmer active={loading}
                            loader
                    >

                        {(Object.keys(spouseModel).length > 0 && !loading) &&
                        <div>
                            <Grid.Row>
                                <Grid.Col width={6}>
                                    <Form.Group label={t('users.first-name')}>
                                        <Editable
                                            initialValue={spouseModel.first_name}
                                            undefinedText={t('globals.no-value')}
                                            isValueClickable={true}
                                            onSubmit={editSpouseInfo('first_name')}
                                            type="textfield"
                                            disabled={!can_edit}
                                        />
                                    </Form.Group>
                                </Grid.Col>
                                <Grid.Col width={6}>
                                    <Form.Group label={t('users.last-name')}>
                                        <Editable
                                            initialValue={spouseModel.last_name}
                                            isValueClickable={true}
                                            undefinedText={t('globals.no-value')}
                                            onSubmit={editSpouseInfo('last_name')}
                                            type="textfield"
                                            disabled={!can_edit}
                                        />
                                    </Form.Group>
                                </Grid.Col>
                            </Grid.Row>

                        </div>}

                    </Dimmer>

                </Card.Body>

            </Card>

        </>
    );
}

export default withI18n()(SpouseInfo);
