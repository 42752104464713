// @flow
// @formatter:off

import {TARGET_APP} from "./RequestInformation";

export type Rib = {
  reference: string,
  owner_name: string,
  bic: string,
  iban: string,
  is_banned: boolean,
  cng_is_deleted: boolean,
  m1l_is_deleted: boolean,
  creation_date: Date,
  assigned_to_user_date: Date
};



export const IsRibDeletedForAppOrigin = (rib: Rib, app_origin: TARGET_APP) => {
  if (app_origin === TARGET_APP.M1L && rib.m1l_is_deleted) {
    return true;
  } else return app_origin === TARGET_APP.CNT && rib.cng_is_deleted;
};
