import {Route} from "react-router-dom";
import * as React from "react";
import {useEffect, useState} from "react";
import AuthService from '../../services/domain/AuthService';
import KeycloakService from '../../services/domain/KeycloakService';
import LinksProvider from '../../services/http/LinksProvider';
import Error403 from "../../pages/403/Error403Page";

function ProtectedRoute({component: Component, authorization, ...rest}) {

  const [authorized, setAuthorized] = useState(true);

  useEffect(async () => {
    if (!AuthService.isAuthenticated()) {
      window.location.href = LinksProvider.ROUTES.WELCOME;
    } else if (authorization && !AuthService.userAuthorizations.includes(authorization)) {
      setAuthorized(false);
    } else {
      const principal = AuthService.getPrincipal();
      const signinOption = KeycloakService.siginOptions.find((option) => principal.roles.some((r) => option.roles.includes(r.reference)));
      if (!signinOption) {
        setAuthorized(false);
      } else {
        const authenticated = await KeycloakService.signinClient(signinOption.keyCloakParams);
        if (!authenticated) {
          window.location.href = LinksProvider.ROUTES.WELCOME;
        }
      }
    }
  }, []);

  return (authorized ?
    <Route {...rest} render={(props) => <Component {...props} />}/> :
    <Error403 />);
}

export default ProtectedRoute;
