import * as React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import HomePage from "./pages/home/HomePage";
import PotsPage from "./pages/pots/PotsPage";
import UsersPage from "./pages/users/UsersPage";
import DashboardPage from "./pages/dashboard/DashboardPage";
import ParticipationsPage from "./pages/participations/ParticipationsPage";
import PromoCodesPage from "./pages/promo-codes/PromoCodesPage";
import ProductsPage from "./pages/products/ProductsPage";
import CommercialBannersPage from "./pages/commercial-banners/CommercialBannersPage";
import ReportsPage from "./pages/reports/ReportsPage";
import PublicationsPage from "./pages/publications/PublicationsPage";
import InspirationListsPage from "./pages/inspiration-lists/InspirationListsPage";
import CommercialPartnersPage from "./pages/commercial-partners/CommercialPartnersPage";
import Error404 from "./pages/404/Error404Page";
import WelcomePage from "./pages/welcome/WelcomePage";
import KycPage from "./pages/kyc/KycPage";

import {ToastProvider} from 'react-toast-notifications';

import "tabler-react/dist/Tabler.css";
import "./styles/App.scss";

import LinksProvider from './services/http/LinksProvider';
import AuthService from './services/domain/AuthService';

import {withNamespaces} from 'react-i18next';
import PotDetailsPage from "./pages/pots/PotDetailsPage";
import ParticipationDetailsPage from "./pages/participations/ParticipationDetailsPage";
import UserDetailsPage from "./pages/users/UserDetailsPage";
import PromoCodesDetailsPage from "./pages/promo-codes/PromoCodesDetailsPage";
import ProductDetailsPage from "./pages/products/ProductsDetailsPage";
import InspirationListsDetailsPage from "./pages/inspiration-lists/InspirationListsDetailsPage";
import ProtectedRoute from "./components/auth/ProtectedRoute";

function App(props): React.Node {
  const [isAuthLoaded, setAuthLoaded] = React.useState(false);

  React.useEffect(() => {
    (async function anyNameFunction() {
      await AuthService.getAuthorizationsFromRole();
      setAuthLoaded(true);
    })();
  }, []);

  return (<ToastProvider>
    <React.StrictMode>
      {isAuthLoaded && <Router>
        <Switch>

          <Route exact path={LinksProvider.ROUTES.WELCOME} component={WelcomePage}/>

          <ProtectedRoute exact path={LinksProvider.ROUTES.HOME} component={HomePage}/>

          <ProtectedRoute exact path={LinksProvider.ROUTES.DASHBOARD} component={DashboardPage}
                          authorization={'AUTH_BO_DASHBOARD'}/>

          <ProtectedRoute exact path={LinksProvider.ROUTES.POTS} component={PotsPage}
                          authorization={'AUTH_BO_POTS_CONSULTER'}/>
          <ProtectedRoute exact path={LinksProvider.ROUTES.POT} component={PotDetailsPage}
                          authorization={'AUTH_BO_POTS_CONSULTER'}/>

          <ProtectedRoute exact path={LinksProvider.ROUTES.USERS} component={UsersPage}
                          authorization={'AUTH_BO_USERS_CONSULTER'}/>
          <ProtectedRoute exact path={LinksProvider.ROUTES.USER} component={UserDetailsPage}
                          authorization={'AUTH_BO_USERS_CONSULTER'}/>

          <ProtectedRoute exact path={LinksProvider.ROUTES.PARTICIPATIONS}
                          component={ParticipationsPage}
                          authorization={'AUTH_BO_PARTICIPATIONS'}/>
          <ProtectedRoute exact path={LinksProvider.ROUTES.PARTICIPATION}
                          component={ParticipationDetailsPage}
                          authorization={'AUTH_BO_PARTICIPATIONS'}/>

          <ProtectedRoute exact path={LinksProvider.ROUTES.PROMO_CODES}
                          component={PromoCodesPage}
                          authorization={'AUTH_BO_PROMO_CODES'}/>
          <ProtectedRoute exact path={LinksProvider.ROUTES.PROMO_CODE}
                          component={PromoCodesDetailsPage}
                          authorization={'AUTH_BO_PROMO_CODES'}/>

          <ProtectedRoute exact path={LinksProvider.ROUTES.PRODUCTS} component={ProductsPage}
                          authorization={'AUTH_BO_PRODUCTS'}/>
          <ProtectedRoute exact path={LinksProvider.ROUTES.PRODUCT}
                          component={ProductDetailsPage}
                          authorization={'AUTH_BO_PRODUCTS'}/>

          <ProtectedRoute exact path={LinksProvider.ROUTES.COMMERCIAL_BANNERS}
                          component={CommercialBannersPage}
                          authorization={'AUTH_BO_COMMERCIAL_BANNERS'}/>

          <ProtectedRoute exact path={LinksProvider.ROUTES.REPORTS}
                          component={ReportsPage}
                          authorization={'AUTH_REPORT_MANAGER'}/>

          <ProtectedRoute exact path={LinksProvider.ROUTES.PUBLICATIONS}
                          component={PublicationsPage}
                          authorization={'AUTH_EDITORIAL_MANAGER'}/>

          <ProtectedRoute exact path={LinksProvider.ROUTES.INSPIRATION_LISTS}
                          component={InspirationListsPage}
                          authorization={'AUTH_INSPIRATION_LIST_ADMIN'}/>

          <ProtectedRoute exact path={LinksProvider.ROUTES.INSPIRATION_LIST}
                          component={InspirationListsDetailsPage}
                          authorization={'AUTH_INSPIRATION_LIST_ADMIN'}/>

          <ProtectedRoute exact path={LinksProvider.ROUTES.COMMERCIAL_PARTNERS}
                          component={CommercialPartnersPage}
                          authorization={'AUTH_PARTNER_ADMIN'}/>
          {(!process.env.REACT_APP_KYC_IFRAME || process.env.REACT_APP_KYC_IFRAME === 'false') &&
          <ProtectedRoute exact path={LinksProvider.ROUTES.KYC}
                          component={KycPage}
                          authorization={'AUTH_BO_USERS'}/>
          }
          <Route component={Error404}/>
        </Switch>
      </Router>}
    </React.StrictMode>
  </ToastProvider>);
}

export default withNamespaces()(App);
