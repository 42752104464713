// @flow

export const PotType = {
    POT: 0,
    LIST: 1
};

export const PotCategory = {
    BIRTHDAY: 0,
    FAREWELL: 1,
    COMMON_GIFT: 2,
    HOUSE_WARMING: 3,
    BACHELOR_PARTY: 4,
    TRIP: 5,
    BIRTH: 6,
    WEDDING_LIST: 7,
    OTHER: 8
};

export const PotStatus = {
    RUNNING: 0,
    DEACTIVATED: 1,
    DELETED: 2,
    ARCHIVED: 3
};

export const PotPromoCodeStatus = {
    DEFINED: 0,
    VALIDATED: 1,
    USED: 2,
    EXPIRED: 3
};

export const ListSpouseStatus = {
    NONE : 0,
    PENDING : 1,
    ADDED : 2
};

export type Pots = {
    reference: string,
    name: string,
    event_date: Date,
    organizer_first_name: string,
    organizer_last_name: string,
    collected_amount: number
};

export const PotsCategories = {
    BIRTHDAY: 0,
    FAREWELL: 1,
    COMMON_GIFT: 3,

};

export const PotVisibility = {
    PUBLIC: 0,
    PRIVATE: 1
};

// Statut de Compte de Paiment
export const XpollensAccountStatus = {
    INITIALIZED: 'Initialized',
    ACTIVATED : 'Activated', 
    FROZEN : 'Frozen',
    PENDING_CLOSURE : 'PendingClosure',
    CLOSURE_REQUESTED: 'ClosureRequested',
    CLOSED    : 'Closed'
}

export type MinifiedPot = {
    reference: string,
    name: string,
    category: number,
    status: number,
    creation_date: Date,
    hide_participants_identity: boolean,
    hide_participations_amount: boolean,
    collected_amount: number,
    deletion_date?: Date,
    is_deleted: boolean
}
