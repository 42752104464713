import React, { useEffect, useState } from "react";

import css from "./UserInfo.module.scss";
import {
  Alert,
  Badge,
  Button,
  Card,
  Dimmer,
  Form,
  Grid,
  Icon,
  Tooltip,
} from "tabler-react";
import { withI18n } from "react-i18next";
import Editable from "../../common/editable/Editable";
import UserService from "../../../services/domain/UsersService";
import DateService from "../../../services/utils/DateService";
import ConfirmModal from "../../modals/confirm-modal/ConfirmModal";
import {
  AddressCountry,
  Civility,
  UserVerificationDocumentStatus,
  OriginApp,
  XPOLLENS_USER_PROCESS_CHOICE,
} from "../../../models/User";
import { XpollensAccountStatus } from "../../../models/Pot";
import ErrorHandler from "../../../services/error/ErrorHandler";
import { useToasts } from "react-toast-notifications";
import _ from "lodash";
import LinksProvider from "../../../services/http/LinksProvider";
import SpouseInfo from "../spouse-info/SpouseInfo";
import Separator from "../../common/seperator/Separator";
import Sponsorship from "../sponsorship/Sponsorship";
import AuthService from "../../../services/domain/AuthService";
import FormatService from "../../../services/utils/FormatService";

const noJddMode = !!(
  process.env.NO_JJD_MODE && process.env.NO_JJD_MODE === "true"
);

function UserInfo({ t, user }): React.Node {
  const [initialUser] = useState(_.cloneDeep(user));
  const [modelBeforeUpdate, setModelBeforeUpdate] = useState(_.cloneDeep(user));
  const [model, setModel] = useState(user);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [confirmationModalShown, setConfirmationModalShown] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const [pot,setPot] = useState()
  const { addToast } = useToasts();
  const can_edit = AuthService.userAuthorizations.includes("AUTH_BO_USERS");

  const updateUser = (request) => {
    setLoading(true);
    return UserService.update(initialUser.email, request)
      .then((user) => {
        setModel(user);
        return user;
      })
      .catch((error) => {
        UserService.search(modelBeforeUpdate.email).then((rollbackUser) =>
          setModel(rollbackUser)
        );
        ErrorHandler.handleError(addToast, t, error);
      })
      .finally(() => setLoading(false));
  };
  const loadPot= ()=> {
    setLoading(true)
    UserService.ownedWeddingLists(initialUser.email).then(
      res => {
        setPot(res)
      }
    ).catch((error)=> {
    }).finally(()=> setLoading(false))
  }
  useEffect(()=>{
    loadPot()
  },[])

  const editUserInfo = (field) => {
    return (val) => {
      setModelBeforeUpdate(model);
      const request = {};
      if (field.indexOf(".") !== -1) {
        const array = field.split(".");
        model[array[0]][array[1]] = val;
        request[array[0]] = {};
        request[array[0]][array[1]] = val;
      } else if (field === "birthdate") {
        request[field] = DateService.formatDateISO(val);
      } else {
        model[field] = val;
        request[field] = val;
      }
      setModel({ ...model });
      updateUser(request);
    };
  };

  const changeActivity = () => {
    const request = { is_active: !!model.is_deleted };
    return updateUser(request).then((data) => {
      setConfirmationModalShown(false);
      return data;
    });
  };

  const enable = () => {
    setConfirmationText(t("users.enable-confirm"));
    setConfirmationModalShown(true);
  };

  const disable = () => {
    setConfirmationText(t("users.disable-confirm"));
    setConfirmationModalShown(true);
  };

  const getUserKycStatusBadge = (isXpollensBadge = false) => {
    const okBadge = (
      <Badge color="success" className="mr-1">
        <Icon prefix="fa" name="file" /> &nbsp;
        {isXpollensBadge ? t("users.xpollens-kyc-ok") : t("users.kyc-ok")}
      </Badge>
    );

    const waitingBadge = (
      <Badge color="warning" className="mr-1">
        <Icon prefix="fa" name="file" /> &nbsp;
        {isXpollensBadge
          ? t("users.xpollens-kyc-waiting")
          : t("users.kyc-waiting")}
      </Badge>
    );

    const waitingManualReviewBadge = (
      <Badge color="warning" className="mr-1">
        <Icon prefix="fa" name="file" /> &nbsp;
        {t("users.xpollens-kyc-waiting-manual-review")}
      </Badge>
    );

    const incompleteBadge = (
      <Badge color="warning" className="mr-1">
        <Icon prefix="fa" name="file" /> &nbsp;
        {isXpollensBadge
          ? t("users.xpollens-kyc-incomplete")
          : t("users.kyc-incomplete")}
      </Badge>
    );

    const refusedBadge = (
      <Badge color="danger" className="mr-1">
        <Icon prefix="fa" name="file" /> &nbsp;
        {isXpollensBadge
          ? t("users.xpollens-kyc-refused")
          : t("users.kyc-refused")}
      </Badge>
    );

    const frozenBadge = (
      <Badge color="danger" className="mr-1">
        <Icon prefix="fa" name="file" /> &nbsp;
        {isXpollensBadge
          ? t("users.xpollens-kyc-frozen")
          : t("users.kyc-frozen")}
      </Badge>
    );

    const notSentBadge = (
      <Badge color="warning" className="mr-1">
        <Icon prefix="fa" name="file" /> &nbsp;
        {isXpollensBadge ? t("users.xpollens-no-kyc") : t("users.no-kyc")}
      </Badge>
    );

    const needsKycBadge = (
      <Badge color="danger" className="mr-1">
        <Icon prefix="fa" name="file" /> &nbsp;{t("users.needs-kyc")}
      </Badge>
    );

    if (isXpollensBadge) {
      if (model.is_xpollens_kyc_ok) {
        return okBadge;
      } else if (model.is_xpollens_kyc_refused || model.is_id_card_refused) {
        return refusedBadge;
      } else if (model.is_xpollens_kyc_pending) {
        if (model.is_xpollens_kyc_pending_manual_review) {
          return waitingManualReviewBadge;
        }
        return waitingBadge;
      } else {
        return notSentBadge;
      }
    } else {
      if (!!noJddMode) {
        if (model.is_kyc_ok) {
          return okBadge;
        } else if (model.is_kyc_refused || model.is_user_kyc_treated) {
          return refusedBadge;
        } else if (model.is_kyc_frozen || model.is_user_kyc_frozen) {
          return frozenBadge;
        } else if (!model.kyc_id_status) {
          return model.has_reached_kyc_amount ? needsKycBadge : notSentBadge;
        } else if (
          model.kyc_id_status === UserVerificationDocumentStatus.REJECTED ||
          model.kyc_id_status === UserVerificationDocumentStatus.TREATED
        ) {
          return refusedBadge;
        } else {
          return waitingBadge;
        }
      } else {
        if (model.is_kyc_ok) {
          return okBadge;
        } else if (model.is_kyc_refused || model.is_user_kyc_treated) {
          return refusedBadge;
        } else if (model.is_kyc_frozen || model.is_user_kyc_frozen) {
          return frozenBadge;
        } else if (!model.kyc_id_status && !model.kyc_jdd_status) {
          return model.has_reached_kyc_amount ? needsKycBadge : notSentBadge;
        } else if (
          model.kyc_id_status === UserVerificationDocumentStatus.REJECTED ||
          model.kyc_jdd_status === UserVerificationDocumentStatus.REJECTED
        ) {
          return refusedBadge;
        } else if (!model.kyc_id_status || !model.kyc_jdd_status) {
          return incompleteBadge;
        } else {
          if (
            model.kyc_id_status === UserVerificationDocumentStatus.TREATED ||
            model.kyc_jdd_status === UserVerificationDocumentStatus.TREATED
          ) {
            return refusedBadge;
          } else if (
            model.kyc_id_status === UserVerificationDocumentStatus.PENDING ||
            model.kyc_jdd_status === UserVerificationDocumentStatus.PENDING
          ) {
            return waitingBadge;
          }
        }
      }
    }
  };
  const getIDStatusBadge = (status) => {
    if (!status) return "danger";
    if (status == "Validated" || status == "OK") return "success";
    if (status == "Refused") return "danger";
    if (status == "Refused" || status == "NOK") return "danger";
    else return "warning";
  };

  const getAccountStatusBadge = (status) => {
    if (status == XpollensAccountStatus.INITIALIZED || status == XpollensAccountStatus.PENDING_CLOSURE)
      return "warning";
    if (status == XpollensAccountStatus.ACTIVATED)
      return "success";
    return "danger";
  };

  const getVerificationMethod = () => {
    if (model.xpollens_kyc_verification_method) {
      return t(
        `kyc-verification-method.${model?.xpollens_kyc_verification_method}`
      );
    } else if (model.xpollens_kyc_diligence_selfie_status == "Refused") {
      return t(`kyc-verification-method.Identity`);
    } else if (model.xpollens_kyc_diligence_selfie_status == "Validated") {
      return t(`kyc-verification-method.Electronic_Sign`);
    }
    return null;
  };

  const comptePaiment = pot?.xpollens_account_status;
  return (
    <Grid.Row cards deck>
      <Grid.Col width={4}>
        <Card className="card-profile">
          <Card.Header
            className={
              model.civility === Civility.FEMALE ? css.pinkish : css.blueish
            }
          />
          <Card.Body className="text-center">
            <div className={css.profile__pic}>
              <span
                style={{
                  backgroundImage: `url("${
                    model.profile_image_link ||
                    (model.civility === Civility.FEMALE
                      ? "/assets/images/female.png"
                      : "/assets/images/male.png")
                  }")`,
                }}
              >
                {model.is_account_confirmed && (
                  <Tooltip content="Compte confirmé" placement="left">
                    <Icon className={css.success} prefix="fa" name="check" />
                  </Tooltip>
                )}
                {!model.is_account_confirmed && (
                  <Tooltip content="Compte non confirmé" placement="left">
                    <Icon className={css.danger} prefix="fa" name="remove" />
                  </Tooltip>
                )}
              </span>
            </div>
            <div className={css.user__content}>
              <h4 className="m-0">
                {model.first_name} {model.last_name}
              </h4>
              <p>{model.email}</p>

              {AuthService.userAuthorizations.includes("AUTH_BO_USERS") && (
                <React.Fragment>
                  {getUserKycStatusBadge()}

                  <Grid.Row className={css.user__additional_information}>
                    <Grid.Col width={6}>
                      <span className={css.user__additional_information__title}>
                        {t("users.created")}
                      </span>
                      <span>{DateService.formatTime(model.creation_date)}</span>
                    </Grid.Col>
                    {model.is_account_confirmed && (
                      <Grid.Col width={6}>
                        <span
                          className={css.user__additional_information__title}
                        >
                          {t("users.activated")}
                        </span>
                        <span>
                          {DateService.formatTime(
                            model.account_confirmation_date
                          )}
                        </span>
                      </Grid.Col>
                    )}
                    {model.is_kyc_ok && (
                      <Grid.Col width={6}>
                        <span
                          className={css.user__additional_information__title}
                        >
                          {t("users.kyc-ok-date")}
                        </span>
                        <span>
                          {DateService.formatTime(model.kyc_verification_date)}
                        </span>
                      </Grid.Col>
                    )}
                  </Grid.Row>

                  <Grid.Row className={css.user__additional_information}>
                    <Grid.Col width={12}>
                      <span className={css.user__additional_information__title}>
                        {t("users.external-reference")}
                      </span>
                      <a
                        target={"_blank"}
                        href={`${LinksProvider.formatUrl(
                          LinksProvider.S_MONEY.BACK_OFFICE_URL,
                          { id: model.external_reference }
                        )}`}
                      >
                        {model.external_reference}
                      </a>
                    </Grid.Col>
                  </Grid.Row>
                  <Separator />
                </React.Fragment>
              )}

              {AuthService.userAuthorizations.includes("AUTH_BO_USERS") && (
                <>
                  {getUserKycStatusBadge(true)}
                  <br />
                  <br />
                  {model.xpollens_external_reference && (
                    <Grid.Row>
                      <Grid.Col width={12}>
                        <div
                          className={css.user__additional_information__title}
                        >
                          {t("users.xpollens-process-choice")}
                        </div>
                        {getVerificationMethod() ? (
                          <Badge color="success" className="mr-1">
                            {getVerificationMethod()}
                          </Badge>
                        ) : (
                          "-"
                        )}
                        <div
                          className={css.user__additional_information__title}
                        >
                          {t("users.xpollens-diligence-id")}
                        </div>

                        {model?.xpollens_kyc_diligence_id_card_status ? (
                          <Badge
                            color={getIDStatusBadge(
                              model?.xpollens_kyc_diligence_id_card_status
                            )}
                            className="mr-1"
                          >
                            {t(
                              `xpo-diligence.${model?.xpollens_kyc_diligence_id_card_status}`
                            )}
                          </Badge>
                        ) : (
                          "-"
                        )}

                        <div
                          className={css.user__additional_information__title}
                        >
                          {t("users.diligence-comp")}
                        </div>

                        {model?.xpollens_kyc_verification_method &&
                        (model?.xpollens_kyc_diligence_sct_in_status ||
                          model?.xpollens_kyc_diligence_selfie_status) ? (
                          <Badge
                            color={getIDStatusBadge(
                              model.xpollens_kyc_verification_method ===
                                XPOLLENS_USER_PROCESS_CHOICE.SCT_IN
                                ? model?.xpollens_kyc_diligence_sct_in_status
                                : model?.xpollens_kyc_diligence_selfie_status
                            )}
                            className="mr-1"
                          >
                            {t(
                              `xpo-diligence.${
                                model.xpollens_kyc_verification_method ===
                                XPOLLENS_USER_PROCESS_CHOICE.SCT_IN
                                  ? model?.xpollens_kyc_diligence_sct_in_status
                                  : model?.xpollens_kyc_diligence_selfie_status
                              }`
                            )}
                          </Badge>
                        ) : (
                          "-"
                        )}
                        <div
                          className={css.user__additional_information__title}
                        >
                          {t("users.xpollens-account-status")}
                        </div>
                        {pot?.[0]?.xpollens_account_status ?
                        <Badge
                          color={getAccountStatusBadge(
                            pot?.[0]?.xpollens_account_status
                          )}
                          className="mr-1"
                        >
                          {t(`account-status.${pot?.[0]?.xpollens_account_status}`)}
                        </Badge> :(
                          "-"
                        )}
                      </Grid.Col>
                    </Grid.Row>
                  )}
                      {console.log(pot)}
                 {model.xpollens_external_reference &&  [XpollensAccountStatus.PENDING_CLOSURE, XpollensAccountStatus.CLOSED, XpollensAccountStatus.CLOSURE_REQUESTED].includes(pot?.[0].xpollens_account_status) &&
                  <Grid.Row className={css.user__additional_information}>
                      <Grid.Col width={6}>
                          <h6>{t("pots.reason")}</h6>
                          <div
                          className={css.user__additional_information__title}
                        >
                          {pot[0]["xpollens_account_closure_reason"] ? t(`pots.reasons.${pot[0]["xpollens_account_closure_reason"]}`) :'-'}
                        </div>
                      </Grid.Col>
                      <Grid.Col width={6}>
                        <h6>{t("pots.initiative")}</h6>
                        <div
                          className={css.user__additional_information__title}
                        >
                          {pot[0]["xpollens_account_closure_initiative"] ? t(`pots.initiatives.${pot[0]["xpollens_account_closure_initiative"]}`) : "-"}
                        </div>
                      </Grid.Col>     
                    </Grid.Row>
                  }

                  <Grid.Row className={css.user__additional_information}>
                    {!!model.xpollens_account_last_creation_attempt_date && (
                      <Grid.Col width={6}>
                        <span
                          className={css.user__additional_information__title}
                        >
                          {t("users.created-xpollens")}
                        </span>
                        <span>
                          {DateService.formatTime(
                            model.xpollens_account_last_creation_attempt_date
                          )}
                        </span>
                      </Grid.Col>
                    )}
                    {model.is_xpollens_kyc_ok &&
                      model.xpollens_user_record_verification_date && (
                        <Grid.Col width={6}>
                          <span
                            className={css.user__additional_information__title}
                          >
                            {t("users.kyc-ok-date-xpollens")}
                          </span>
                          <span>
                            {DateService.formatTime(
                              model.xpollens_user_record_verification_date
                            )}
                          </span>
                        </Grid.Col>
                      )}
                  </Grid.Row>

                  <Grid.Row className={css.user__additional_information}>
                    <Grid.Col width={12}>
                      <span className={css.user__additional_information__title}>
                        {t("users.xpollens-external-reference")}
                      </span>
                      {!!model.xpollens_external_reference ? (
                        <a
                          target={"_blank"}
                          href={`${LinksProvider.formatUrl(
                            LinksProvider.XPOLLENS.BACK_OFFICE_URL,
                            { id: model.xpollens_external_reference }
                          )}`}
                        >
                          {model.xpollens_external_reference}
                        </a>
                      ) : (
                        <p>-</p>
                      )}
                    </Grid.Col>
                  </Grid.Row>
                </>
              )}

              {AuthService.userAuthorizations.includes("AUTH_BO_USERS") &&
                model.has_xpollens_activation_code && (
                  <Grid.Row className={css.user__additional_information}>
                    <Grid.Col width={12}>
                      <Button
                        loading={btnLoading}
                        onClick={() => {
                          setBtnLoading(true);
                          UserService.generateQrCode(user.email)
                            .then(() => {
                              addToast(t("users.qr-code-request-sent"), {
                                appearance: "success",
                                autoDismiss: true,
                              });
                            })
                            .catch((error) => {
                              ErrorHandler.handleError(addToast, t, error);
                            })
                            .finally(() => setBtnLoading(false));
                        }}
                      >
                        {t("users.request-new-qr-code")}
                      </Button>
                    </Grid.Col>
                  </Grid.Row>
                )}
            </div>
            {user.xpollens_external_reference &&
              AuthService.userAuthorizations.includes("AUTH_BO_USERS") && (
                <Grid.Row>
                  <Grid.Col className="mt-3 mb-3" width={12}>
                    <Button
                      disabled={
                        !user.xpollens_external_reference ||
                        user.m1l_kyc_step === 5
                      }
                      loading={btnLoading}
                      onClick={() => {
                        setBtnLoading(true);
                        UserService.update(user.email, { m1l_kyc_step: 5 })
                          .then(() => {
                            addToast(t("users.renvoi-backup-sent"), {
                              appearance: "success",
                              autoDismiss: true,
                            });
                          })
                          .catch((error) => {
                            ErrorHandler.handleError(addToast, t, error);
                          })
                          .finally(() => {
                            setBtnLoading(false);
                          });
                      }}
                    >
                      {t("users.renvoi-backup-button")}
                    </Button>
                  </Grid.Col>
                </Grid.Row>
              )}
          </Card.Body>
        </Card>
      </Grid.Col>

      <Grid.Col width={8}>
        <ConfirmModal
          confirmationText={confirmationText}
          onConfirm={changeActivity}
          show={confirmationModalShown}
          onHide={() => setConfirmationModalShown(false)}
        />

        <Card>
          <Card.Header>
            <Card.Title>
              {model.first_name} {model.last_name}
              {(model.origin_app === OriginApp.M1L ||
                model.origin_app === OriginApp.CNT) && (
                <img
                  src={`/assets/images/logo${
                    model.origin_app === OriginApp.M1L
                      ? "-m1l"
                      : model.origin_app === OriginApp.CNT
                      ? "-cnt"
                      : ""
                  }.svg`}
                  className="w-25 ml-4"
                />
              )}
            </Card.Title>
            <Card.Options>
              {!model.is_deleted &&
                AuthService.userAuthorizations.includes("AUTH_BO_USERS") && (
                  <Button color="danger" size="sm" onClick={disable}>
                    {t("users.disable")}
                  </Button>
                )}
            </Card.Options>
          </Card.Header>
          <Card.Body>
            <Dimmer active={loading} loader>
              {model.is_deleted && (
                <Alert type="danger">
                  <span className="float-left">
                    {t("users.account-disabled", {
                      date: DateService.formatTime(model.deletion_date),
                    })}
                  </span>
                  <Button
                    color="danger"
                    className="float-right clearfix"
                    onClick={enable}
                    size="sm"
                  >
                    {t("users.re-enable")}
                  </Button>
                  <span className="clearfix"></span>
                </Alert>
              )}
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Group label={t("users.first-name")}>
                    <Editable
                      initialValue={model.first_name}
                      undefinedText={t("globals.no-value")}
                      isValueClickable={true}
                      onSubmit={editUserInfo("first_name")}
                      type="textfield"
                      disabled={!can_edit}
                    />
                  </Form.Group>
                </Grid.Col>

                <Grid.Col width={6}>
                  <Form.Group label={t("users.last-name")}>
                    <Editable
                      initialValue={model.last_name}
                      isValueClickable={true}
                      undefinedText={t("globals.no-value")}
                      onSubmit={editUserInfo("last_name")}
                      type="textfield"
                      disabled={!can_edit}
                    />
                  </Form.Group>
                </Grid.Col>

                <Grid.Col width={6}>
                  <Form.Group label={t("users.email")}>
                    <Editable
                      initialValue={model.email}
                      isValueClickable={true}
                      undefinedText={t("globals.no-value")}
                      onSubmit={editUserInfo("email")}
                      type="textfield"
                      disabled={!can_edit}
                    />
                  </Form.Group>
                </Grid.Col>

                <Grid.Col width={6}>
                  <Form.Group label={t("users.civility")}>
                    <Editable
                      initialValue={model.civility}
                      isValueClickable={true}
                      undefinedText={t("globals.no-value")}
                      onSubmit={editUserInfo("civility")}
                      options={[
                        { label: t("users.male"), value: Civility.MALE },
                        {
                          label: t("users.female"),
                          value: Civility.FEMALE,
                        },
                      ]}
                      type="select"
                      disabled={!can_edit}
                    />
                  </Form.Group>
                </Grid.Col>

                <Grid.Col width={6}>
                  <Form.Group label={t("users.birth-date")}>
                    <Editable
                      initialValue={model.birthdate}
                      isValueClickable={true}
                      undefinedText={t("globals.no-value")}
                      onSubmit={editUserInfo("birthdate")}
                      keepInitialValueIfFalsy={true}
                      type="date"
                      disabled={!can_edit}
                    />
                  </Form.Group>
                </Grid.Col>

                <Grid.Col width={6}>
                  <Form.Group label={t("users.phone")}>
                    <Editable
                      initialValue={model.phone}
                      isValueClickable={true}
                      undefinedText={t("globals.no-value")}
                      onSubmit={editUserInfo("phone")}
                      keepInitialValueIfFalsy={true}
                      type="textfield"
                      disabled={!can_edit}
                    />
                  </Form.Group>
                </Grid.Col>

                <Grid.Col width={6}>
                  <Form.Group label={t("users.address")}>
                    <Editable
                      initialValue={model.address.street}
                      isValueClickable={true}
                      undefinedText={t("globals.no-value")}
                      onSubmit={editUserInfo("address.street")}
                      type="textfield"
                      disabled={!can_edit}
                    />
                  </Form.Group>
                </Grid.Col>

                <Grid.Col width={6}>
                  <Form.Group label={t("users.address-additional")}>
                    <Editable
                      initialValue={model.address.additional}
                      isValueClickable={true}
                      undefinedText={t("globals.no-value")}
                      onSubmit={editUserInfo("address.additional")}
                      type="textfield"
                      disabled={!can_edit}
                    />
                  </Form.Group>
                </Grid.Col>

                <Grid.Col width={6}>
                  <Form.Group label={t("users.city")}>
                    <Editable
                      initialValue={model.address.city}
                      isValueClickable={true}
                      undefinedText={t("globals.no-value")}
                      onSubmit={editUserInfo("address.city")}
                      type="textfield"
                      disabled={!can_edit}
                    />
                  </Form.Group>
                </Grid.Col>

                <Grid.Col width={6}>
                  <Form.Group label={t("users.zip-code")}>
                    <Editable
                      initialValue={model.address.zip_code}
                      isValueClickable={true}
                      undefinedText={t("globals.no-value")}
                      onSubmit={editUserInfo("address.zip_code")}
                      type="textfield"
                      disabled={!can_edit}
                    />
                  </Form.Group>
                </Grid.Col>

                <Grid.Col width={6}>
                  <Form.Group label={t("users.country")}>
                    <Editable
                      initialValue={model.address.country}
                      isValueClickable={true}
                      undefinedText={t("globals.no-value")}
                      onSubmit={editUserInfo("address.country")}
                      type="select"
                      disabled={!can_edit}
                      //options={[{label : 'France', value : 'FR'}]}
                      options={Object.entries(AddressCountry).map(
                        ([key, value]) => ({
                          label: value,
                          value: key,
                        })
                      )}
                    />
                  </Form.Group>
                </Grid.Col>

                <Grid.Col width={6}>
                  <Form.Group
                    label={t("users.opt_in_1")}
                    className={"text-left"}
                  >
                    <Form.Checkbox
                      isInline
                      onChange={(e) =>
                        editUserInfo("opt_in_1")(e.target.checked)
                      }
                      label=" "
                      name="opt_in_1"
                      checked={model.opt_in_1}
                      disabled={loading || !can_edit}
                      value={model.opt_in_1}
                    />
                  </Form.Group>
                </Grid.Col>

                <Grid.Col width={6}>
                  <Form.Group
                    label={t("users.opt_in_2")}
                    className={"text-left"}
                  >
                    <Form.Checkbox
                      isInline
                      onChange={(e) =>
                        editUserInfo("opt_in_2")(e.target.checked)
                      }
                      label=" "
                      name="opt_in_2"
                      checked={model.opt_in_2}
                      disabled={loading || !can_edit}
                      value={model.opt_in_2}
                    />
                  </Form.Group>
                </Grid.Col>

                <Grid.Col width={12}>
                  <Form.Group label={t("users.comment")}>
                    <Editable
                      initialValue={model.comment}
                      undefinedText={t("globals.no-value")}
                      isValueClickable={true}
                      onSubmit={editUserInfo("comment")}
                      type="textarea"
                      disabled={!can_edit}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            </Dimmer>
          </Card.Body>
        </Card>

        <SpouseInfo userEmail={user.email} />

        {AuthService.userAuthorizations.includes("AUTH_BO_USERS") && (
          <Sponsorship userEmail={user.email} />
        )}
      </Grid.Col>
    </Grid.Row>
  );
}

export default withI18n()(UserInfo);
