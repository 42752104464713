import React from "react";
import CreatableSelect from 'react-select/creatable';

export default class CreatableSelected extends React.Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <React.Fragment>
                <CreatableSelect
                    isDisabled={this.props.isDisabled}
                    isLoading={this.props.isLoading} 
                    value={this.props.selectValue} 
                    onChange={this.props.onChange}
                    onCreateOption={this.props.onCreateOption} 
                    options={this.props.options} 
                    isMulti={this.props.isMulti}
                    isClearable={this.props.isClearable}
                    styles={this.props.styles}
                />
                {this.props.controls}
            </React.Fragment>
        );
    }
}