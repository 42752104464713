// @flow
import * as React from "react";
import {useState} from "react";
import {Modal} from "react-bootstrap";
import {Alert, Button, Form, Text, Loader} from "tabler-react";
import {withI18n} from 'react-i18next';
import PotsService from "../../../services/domain/PotsService";
import ErrorHandler from "../../../services/error/ErrorHandler";
import {useToasts} from "react-toast-notifications";

function RefundAllParticipationsModal(props): React.Node {
  const {addToast} = useToasts();
  const [refunding, setRefunding] = useState(false);
  const [refundDone, setRefundDone] = useState(false);
  const [count, setCount] = useState(null);
  const [refunds, setRefunds] = useState([]);
  const [model, setModel] = useState({
    notify_organizer: false, notify_participant: false, requested_by_organizer: false
  });

  const onRefundEnd = () => {
    setRefunding(false);
    setRefundDone(true);

    props.afterRefund && props.afterRefund();
  };

  const onRefundError = (error) => {
    setRefunding(false);
    ErrorHandler.handleError(addToast, props.t, error.jsonBody);
    setRefundDone(false);
  };


  const refund = () => {
    setRefunding(true);
    PotsService.refund(props.reference, model)
        .done(onRefundEnd)
        .fail(onRefundError)
        .node("count", (value) => {
          setCount(value);
        })
        .node("refunds.*", (value) => {
          const array = refunds;
          array.push(value);
          setRefunds(array);
        })
  };

  return (<Modal {...props} size="lg" backdrop="static">
    <Modal.Header>
      <Modal.Title>
        {(!refundDone && count == null) && (
            <React.Fragment>{props.t('pots.refund-all-participations',{reference:props.reference})}</React.Fragment>
        )}
        {(count != null && refunding && !refundDone) && (
            <React.Fragment>{props.t('pots.refunding-all-participations', {count : count})}</React.Fragment>
        )}
        {(refundDone) && (
            <React.Fragment>{props.t('pots.refunding-done')}</React.Fragment>
        )}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {(!refundDone && !refunding && (!refunds || !refunds.length)) ? (
        <Form.Group label={props.t('participations.refund-options')}>
          <Form.SwitchStack>
            <Form.Switch
                label={props.t('participations.notify-organizer')}
                name="notify_organizer"
                onChange={() => setModel({...model, notify_organizer : !model.notify_organizer})}
                type="checkbox"
                value={model.notify_organizer}/>
            <Form.Switch
                label={props.t('participations.notify-participant')}
                name="notify_participant"
                onChange={() => setModel({...model, notify_participant : !model.notify_participant})}
                type="checkbox"
                value={model.notify_participant}/>
            {model.notify_participant && <Form.Switch
                label={props.t('participations.request-by-organizer')}
                name="requested_by_organizer"
                onChange={() => setModel({...model, requested_by_organizer : !model.requested_by_organizer})}
                type="checkbox"
                value={model.requested_by_organizer}/>}
          </Form.SwitchStack>
        </Form.Group>
      ) : ''}

      {(refunds && refunds.length) ? (
          <React.Fragment>
            {
              refunds.map(function(refund) {
                return (
                    <React.Fragment>
                      {refund.error && (
                        <Alert type="danger" icon="alert-triangle">
                          <div dangerouslySetInnerHTML={{__html: props.t('participations.participation-refund-error', {reference : refund.reference})}}></div>
                          <Text.Small>{props.t(`errors.${refund.code}`)}</Text.Small>
                        </Alert>
                      )}
                      {!refund.error && (
                        <Alert type="success" icon="check">
                          <div dangerouslySetInnerHTML={{__html: props.t('participations.participation-refund-success', {reference : refund.reference})}}></div>
                        </Alert>
                      )}
                    </React.Fragment>
                )
              })
            }
          </React.Fragment>
      ) : ''}

      {refunding && (<React.Fragment>
        <Loader/>
        <div className="text-center mt-2">
          Remboursement en cours...
        </div>
      </React.Fragment>)}
    </Modal.Body>
    <Modal.Footer>
      {!refunding && (
          <Button onClick={props.onHide} color="warning"
                  closeButton>{props.t('modals.close')}</Button>
      )}
      {!refundDone && (
          <Button loading={refunding} onClick={refund}
                  color="primary">{props.t('participations.refund')}</Button>
      )}
    </Modal.Footer>
  </Modal>);
}

export default withI18n()(RefundAllParticipationsModal);