// @flow
import * as React from "react";
import SiteWrapper from "../../SiteWrapper";
import {Button, Loader, Page, Grid} from "tabler-react";
import {withI18n} from "react-i18next";
import {useEffect, useState} from "react";
import EmptyContent from "../../components/common/empty-content/EmptyContent";
import {useToasts} from 'react-toast-notifications';
import PublicationsList from "../../components/publications/publications-list/PublicationsList";
import PublicationCreate from "../../components/publications/publication-create/PublicationCreate";
import PublicationUpdate from "../../components/publications/publication-update/PublicationUpdate";
import PublicationsService from "../../services/domain/PublicationsService";
import {PublicationPageView} from "../../models/Publication";


function PublicationsPage({t}): React.Node {
  const {addToast} = useToasts();

  const [publications, setPublications] = useState(null);
  const [publicationReference, setPublicationReference] = useState(null);
  const [publicationsCount, setPublicationsCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const [pageView, setPageView] = useState(PublicationPageView.LIST);

  const publicationsPerPage = 8;

  const getParam = (paramName) => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get(paramName)
  };

  useEffect(() => {
    document.title = t('navbar.publications');
    const publicationReference = getParam('reference');
    if (publicationReference) {
      setPageView(PublicationPageView.MODIFY);
      setPublicationReference(publicationReference)
    }
    if (pageView === PublicationPageView.LIST) {
      loadData();
    }
  }, [t]);

  const loadData = () => {
    setLoading(true);
    PublicationsService.getAll(publicationsPerPage, 0)
      .then(data => {
        if (data) {
          setPublications(data.publications);
          setPublicationsCount(data.original_result_count);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <SiteWrapper>
      <Page.Content>
        <Page.Header
          title={t('navbar.publications')}
          options={(<Grid.Row>
            <Grid.Col>
              {(pageView === PublicationPageView.CREATE || pageView === PublicationPageView.MODIFY) && (
                <Button className="mr-1" icon="loader" onClick={() => setPageView(PublicationPageView.LIST)} color="primary"
                >{t('publications.list')}</Button>
              )}
              {(pageView === PublicationPageView.LIST || pageView === PublicationPageView.MODIFY) && (
                <Button className="mr-1" icon="plus" onClick={() => setPageView(PublicationPageView.CREATE)} color="primary"
                >{t('publications.create')}</Button>
              )}
            </Grid.Col>
          </Grid.Row>)}
        />
        <React.Fragment>
          {(pageView === PublicationPageView.LIST) && (
            <React.Fragment>
              {loading && <Loader/>}

              {(!loading && publications && publications.length > 0) && (
                <PublicationsList publications={publications} searchQueryCount={publicationsCount}/>
              )}

              {(!loading && (!publications || !publications.length)) && (
                <EmptyContent text={t('publications.no-results')}/>
              )}
            </React.Fragment>
          )}
          {(pageView === PublicationPageView.CREATE) && (
            <React.Fragment>
              {loading && <Loader/>}

              {(!loading) && (
                <PublicationCreate/>
              )}
            </React.Fragment>
          )}

          {(pageView === PublicationPageView.MODIFY) && (
            <React.Fragment>
              {loading && <Loader/>}

              {(!loading) && (
                <PublicationUpdate reference={publicationReference}/>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      </Page.Content>
    </SiteWrapper>
  );
}

export default withI18n()(PublicationsPage);
