// @flow

export type PromoCode = {
  reference: string,
  amount: number,
  quantity: number,
  activation_date: Date,
  expiration_date: Date,
  min_participants_number: number,
  min_collected_amount: number,
  duration_before_reaching_threshold: Date,
  pool_type: number,
  actual_uses: number,
  eligible_uses: number,
  non_eligible_uses: number,
  offered_pot_uses : number
}

export const PoolType = {
  POT : 0,
  LIST : 1
};

export const PromoCodeStatus = {
  RUNNING: 0,
  DEACTIVATED: 1
};
