/**
 * CommercialPartnersService
 * @module Services/domain/CommercialPartnersService
 * @description Loads commercial partner data from the server
 */

import HttpService from "../http/HttpService";
import LinksProvider from "../http/LinksProvider";
import {parseRegion} from "../../models/CommercialPartner";

const self = {};



/**
 * get commercial partner(s)
 * @author Seif Khiari
 * @alias getAll
 * @memberof module:Services/http/CommercialPartnersService
 * @param   {string} limit The search query limit
 * @param   {string} offset The search query offset
 * @returns {Promise<CommercialPartner[]>}  A promise containing the search result
 */
self.getAll = (limit: string, offset: string): Promise<CommercialPartner> => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.COMMERCIAL_PARTNERS.ALL, {
      limit: limit,
      offset: offset
    }));
};

/**
 * creates commercial partner
 * @author Seif Khiari
 * @alias create
 * @memberof module:Services/http/CommercialPartnersService
 * @param   {object} values the commercial partner info
 * @returns {Promise<CommercialPartner>}  A promise containing the created commercial partner
 */
self.create = (values : any): Promise<CommercialPartner> => {
  return HttpService
    .post(LinksProvider.formatFullUrl(LinksProvider.API.COMMERCIAL_PARTNERS.COMMERCIAL_PARTNERS), values)
    .then(data => data.commercial_partner);
};

/**
 * returns commercial partner info
 * @author Seif Khiari
 * @alias get
 * @memberof module:Services/http/CommercialPartnersService
 * @param   {string} reference the commercial partner reference
 * @returns {Promise<CommercialPartner>}  A promise containing the commercial partner
 */
self.get = (reference : string): Promise<CommercialPartner> => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.COMMERCIAL_PARTNERS.COMMERCIAL_PARTNER, {reference}))
    .then(data => {
      data.commercial_partner.region = parseRegion(data.commercial_partner.region);
      return data.commercial_partner;
    });
};

/**
 * updates commercial partner
 * @author Seif Khiari
 * @alias update
 * @memberof module:Services/http/CommercialPartnersService
 * @param   {string} reference the commercial partner reference
 * @param   {object} values the commercial partner info to update
 * @returns {Promise<CommercialPartner>}  A promise containing the updated commercial partner
 */
self.update = (reference : string, values : any): Promise<CommercialPartner> => {
  return HttpService
    .put(LinksProvider.formatFullUrl(LinksProvider.API.COMMERCIAL_PARTNERS.COMMERCIAL_PARTNER, {reference}), values)
    .then(data => data.commercial_partner);
};



export default self;
