import * as React from "react";
import {useState} from "react";
import {Modal} from "react-bootstrap";
import {Container, Col, Row, FormGroup, Label, Input} from 'reactstrap';
import {Button, Form} from "tabler-react";
import {withI18n} from 'react-i18next';
import {Formik} from "formik";
import {commercialBannerSchema} from '@gl/validation';
import CommercialBannersService from "../../../services/domain/CommercialBannersService";
import {CommercialBannerType, CommercialBannerStatus} from "../../../models/CommercialBanner";
import DropZone from "../../common/dropzone/DropZone";
import LinksProvider from "../../../services/http/LinksProvider";

function CreateCommercialBannerModal(props): React.Node {
  const [bannerPhoto, setBannerPhoto] = useState(null);
  const [showPhotoError, setShowPhotoError] = useState(false);
  const listCommercialBannersTypes = [CommercialBannerType.LIST, CommercialBannerType.LIST_PAYMENT_CONFIRMATION, CommercialBannerType.LIST_GIFT_ENGINE];

  const componentConfig = {
    iconFiletypes: [".jpg", ".png", ".jpeg"],
    showFiletypeIcon: true,
    postUrl: (process.env.REACT_APP_BACKEND_BASE_URL || 'http://localhost:1337') + LinksProvider.API.COMMERCIAL_BANNERS.UPLOAD_PHOTO
  };

  const dzConfig = {
    eventHandlers: {
      success: (file, response) => {
        if (response && response.data && response.data.image) {
          setBannerPhoto(response.data.image.url);
        }
      }
    },
    djsConfig: {
      maxFilesize: 10,
      maxFiles: 1,
      dictDefaultMessage: props.t('commercial-banners.upload-photo'),
      dictRemoveFile: props.t('commercial-banners.remove-photo'),
      addRemoveLinks: true,
      acceptedFiles: "image/jpeg,image/jpg,image/png",
      maxfilesexceeded: function (file) {
        this.removeAllFiles();
        this.addFile(file);
      },
      sending: function (file, xhr, formData) {
        formData.append("image", file);
      }
    }
  };

  const validate = (values) => {
    let errors = {};
    if (!bannerPhoto) {
      errors.temp_photo_url = 'PHOTO_REQUIRED';
    }
    return errors;
  };

  const onSubmit = (values, actions) => {
    values.temp_photo_url = bannerPhoto;
    actions.setSubmitting(false);
    values.temp_photo_url = bannerPhoto;
    CommercialBannersService.create(values)
      .then(commercialBanner => {
        props.onHide();
        window.location = LinksProvider.formatUrl(LinksProvider.ROUTES.COMMERCIAL_BANNERS);
      })
      .catch(error => {
        props.onError(error);
        actions.setSubmitting(true);
      });
  };

  return (<Modal {...props} size="md">
    <Formik
      validationSchema={commercialBannerSchema.create}
      validate={validate}
      onSubmit={onSubmit}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        title: props.initialBanner && props.initialBanner.title ? props.initialBanner.title : '',
        text: props.initialBanner && props.initialBanner.text ? props.initialBanner.text : '',
        type: props.initialBanner && props.initialBanner.type ? props.initialBanner.type : "0",
        status: props.initialBanner && props.initialBanner.status ? props.initialBanner.status : 1,
        url: props.initialBanner && props.initialBanner.url ? props.initialBanner.url : '',
        end_date: null,
        start_date: null,
      }}
    >
      {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
          touched,
          isValid,
          errors,
        }) => (
        <form onSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title> {props.t('commercial-banners.create-banner')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col xs="6" sm="6">
                  <FormGroup>
                    <Label>{props.t('commercial-banners.banner-title')}</Label>
                    <Input
                      id="title"
                      placeholder={props.t('commercial-banners.banner-title')}
                      type="text"
                      value={values.title || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.title && errors.title ?
                      <p className="text-danger">{props.t('commercial-banners.validation.' + errors.title)}</p> : null}

                  </FormGroup>
                </Col>
                <Col xs="6" sm="6">
                  <FormGroup>
                    <Label>{props.t('commercial-banners.banner-url')}</Label>
                    <Input
                      id="url"
                      placeholder={props.t('commercial-banners.banner-url')}
                      type="text"
                      value={values.url || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.url && errors.url ?
                      <p className="text-danger">{props.t('commercial-banners.validation.' + errors.url)}</p> : null}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12" sm="12">
                  <FormGroup>
                    <Label for="text">{props.t('commercial-banners.banner-text')} <small>{props.t('commercial-banners.banner-text-warning')}</small></Label>
                    <Input
                      id="text"
                      type="textarea"
                      name="text"
                      value={values.text || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormGroup>
                  {touched.text && errors.text ?
                    <p className="text-danger">{props.t('commercial-banners.validation.' + errors.text)}</p> : null}
                  {values.text && values.text.length > 80 ?
                    <small className="text-warning mb-1">{props.t('commercial-banners.banner-text-warning-length')}</small> : null}
                </Col>
              </Row>
              <Row>
                <Col xs="6" sm="6">
                  <FormGroup>
                    <Label for="typeSelect">{props.t('commercial-banners.banner-type')}</Label>
                    <Input
                      type="select"
                      name="type"
                      id="typeSelect"
                      value={values.type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {Object.keys(CommercialBannerType).map(key =>
                        <option value={CommercialBannerType[key]}>{props.t('commercial-banners.types.' + key)}</option>
                      )}
                    </Input>
                  </FormGroup>
                </Col>
                <Col xs="6" sm="6">
                  <FormGroup>
                    <Label for="statusSelect">{props.t('commercial-banners.banner-status')}</Label>
                    <Input
                      type="select"
                      name="status"
                      id="statusSelect"
                      value={values.status}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled
                    >
                      <option
                        value={CommercialBannerStatus.ACTIF}>{props.t('commercial-banners.statuses.ACTIF')}</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <br/>
              <Row>
                <Col xs="6" sm="6">
                  <FormGroup>
                    <Label>{props.t('commercial-banners.banner-start-date')}</Label>
                    <Input
                      id="start_date"
                      type="date"
                      value={values.start_date || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormGroup>
                  {touched.start_date && errors.start_date ? <p
                    className="text-danger">{props.t('commercial-banners.validation.' + errors.start_date)}</p> : null}
                </Col>
                <Col xs="6" sm="6">
                  <FormGroup>
                    <Label>{props.t('commercial-banners.banner-end-date')}</Label>
                    <Input
                      id="end_date"
                      type="date"
                      value={values.end_date || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormGroup>
                  {touched.end_date && errors.end_date ?
                    <p className="text-danger">{props.t('commercial-banners.validation.' + errors.end_date)}</p> : null}
                </Col>
              </Row>
              <Row>
                <Col xs="12" sm="12">
                  <DropZone
                    componentConfig={componentConfig}
                    dropZoneConfig={dzConfig.djsConfig}
                    eventHandlers={dzConfig.eventHandlers}
                  />
                  {errors.temp_photo_url ? <span
                    className="text-danger">{props.t('commercial-banners.validation.' + errors.temp_photo_url)}</span> : null}
                  <p className="mt-2">
                    <small>
                      {props.initialBanner && props.initialBanner.type && listCommercialBannersTypes.includes(props.initialBanner.type) ?
                        props.t('commercial-banners.banner-photo-disclaimer-m1l') :
                        props.t('commercial-banners.banner-photo-disclaimer')}
                    </small>
                  </p>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" onClick={props.onHide} color="warning"
                    closeButton>{props.t('modals.cancel')}</Button>
            <Button type="submit" color="primary">{props.t('modals.save')}</Button>
          </Modal.Footer>
        </form>
      )}
    </Formik>
  </Modal>);
}

export default withI18n()(CreateCommercialBannerModal);
