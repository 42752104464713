// @flow
import React, {Fragment, useEffect, useState} from "react";

import DateService from '../../../services/utils/DateService';
import {Button, Card, Loader, Table,} from "tabler-react";
import {withI18n} from 'react-i18next';
import EmptyContent from "../../common/empty-content/EmptyContent";
import PotsService from "../../../services/domain/PotsService";
import Amount from "../../common/amount/Amount";
import CreateTransferModal from "../../modals/create-transfer-modal/CreateTransferModal";
import StrongAuthService from "../../../services/utils/StrongAuthService";

function PotCashOuts({reference, t, onTransferAdded}): React.Node {

  const [loading, setLoading] = useState(false);
  const [pot, setPot] = useState(null);
  const [cashOuts, setCashOuts] = useState(null);
  const [createModalShown, setCreateModalShown] = useState(false);

  const headerItems = [{content: t('cash-outs.reference')}, {content: t('cash-outs.amount')}, {content: t('cash-outs.fee')}, {content: t('cash-outs.transferred-amount')}, {content: t('cash-outs.status')}, {content: t('cash-outs.iban')}, {content: t('cash-outs.creation-date')}];

  const loadPot = () => {
    return PotsService.get(reference)
      .then(pot => {
        setPot(pot);
        return pot;
      });
  };

  const loadCashOuts = () => {
    return PotsService.getCashOuts(reference)
      .then(cashOuts => {
        setCashOuts(cashOuts);
        return cashOuts;
      });
  };

  const loadData = () => {
    setLoading(true);
    loadPot()
      .then(pot => {
        loadCashOuts()
          .finally(() => setLoading(false));
      });
  };

  const oneRow = (cashOut: cashOut) => {
    return {
      key: cashOut.reference, item: [{
        content: (<span>{cashOut.reference}</span>)
      }, {
        content: (<Amount amount={cashOut.amount}/>)
      }, {
        content: (<Amount amount={cashOut.fee_amount}/>)
      }, {
        content: (<Amount amount={cashOut.amount - cashOut.fee_amount}/>)
      }, {
        content: (<div>
          <span className={`badge ${cashOut.status
          == 1 ? 'badge-danger' : 'badge-success'}`}>{t(`cash-outs.status-${cashOut.status}`)}</span>
        </div>)
      }, {
        content: (<React.Fragment>
          <span>{cashOut.rib_iban}</span>
          {cashOut.is_banned && (<span className="badge badge-danger">{t('ribs.banned')}</span>)}
        </React.Fragment>)
      }, {
        content: DateService.formatTime(cashOut.creation_date)
      }]
    };
  };

  useEffect(() => {
    loadData();
  }, []);

  return (<Fragment>


    <Card>
      <Card.Header>
        <Card.Title>{t('cash-outs.title')}</Card.Title>

        <Card.Options>
          {(pot && pot.can_cashout) && (<React.Fragment>
            <Button color="primary" size="sm" onClick={() => setCreateModalShown(true)}>
              {t('cash-outs.request-a-transfer')}
            </Button>
            {createModalShown && <CreateTransferModal
              pot={pot}
              onSuccess={() => onTransferAdded && onTransferAdded()}
              show={createModalShown}
              onHide={() => setCreateModalShown(false)}/>}
          </React.Fragment>)}
        </Card.Options>
      </Card.Header>
      <Card.Body className="fixed-height-card-body">
        {((!cashOuts || !cashOuts.length) && !loading) ? (
          <EmptyContent text={t('pots.no-cash-outs')}/>) : ''}
        {((cashOuts && cashOuts.length) && !loading) ? (<Table responsive
                                                               className="card-table"
                                                               headerItems={headerItems}
                                                               bodyItems={cashOuts.map(oneRow)}/>) : ''}
        {loading && <Loader/>}
      </Card.Body>
    </Card>
  </Fragment>);
}

export default withI18n()(PotCashOuts);
