import React, {Fragment} from "react";
import {Card, Loader, Page, Table, Tag, Badge, Grid} from "tabler-react";
import {Image, Button, ButtonToolbar, Figure} from "react-bootstrap";
import {withI18n} from 'react-i18next';
import DateService from "../../../services/utils/DateService";
import {useEffect, useState} from "react";
import TablePagination from "../../common/table-pagination.js/TablePagination";
import InspirationListsService from "../../../services/domain/InspirationListsService";
import ErrorHandler from "../../../services/error/ErrorHandler";
import {useToasts} from "react-toast-notifications";
import LinksProvider from "../../../services/http/LinksProvider";
import ConfirmModal from "../../modals/confirm-modal/ConfirmModal";

const InspirationListsList = ({lists, searchQueryCount, t}) => {

  const {addToast} = useToasts();

  const listsPerPage = 10;
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [listLists, setListLists] = useState(lists);
  const [queryCount, setQueryCount] = useState(searchQueryCount);
  const [pageCount, setPageCount] = useState(Math.ceil(queryCount / listsPerPage));
  const [confirmationModalShown, setConfirmationModalShown] = useState(false);
  const [toDeleteReference, setToDeleteReference] = useState(null);

  const handlePageClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
    loadData(listsPerPage, index * listsPerPage)
  };

  const loadData = (limit, offset) => {
    setLoading(true);
    InspirationListsService.getAll(limit, offset, undefined)
      .then(data => {
        setListLists(data.lists);
        setQueryCount(data.original_result_count);
        setPageCount(Math.ceil(queryCount / listsPerPage));
      })
      .finally(() => setLoading(false));
  };

  const handleDelete = () => {
    if (toDeleteReference) {
      setLoading(true);
      return InspirationListsService.update(toDeleteReference, {is_deleted: true})
        .then(data => {
          window.location = LinksProvider.formatUrl(LinksProvider.ROUTES.INSPIRATION_LISTS);
        })
        .catch(error => ErrorHandler.handleError(addToast, t, error))
        .finally(() => {
          setLoading(false);
          setToDeleteReference(null);
        });
    }
  };

  const headerItems = [
    {content: t('inspiration-lists.name')},
    {content: t('inspiration-lists.start-date')},
    {content: t('inspiration-lists.end-date')},
    {content: t('inspiration-lists.status')},
    {content: null}
  ];

  const itemRow = (list) => {
    return {
      key: list.reference,
      item: [
        {content: (<span>{list.name}</span>)},
        {content: (<span>{DateService.formatDate(list.validity_start_date)}</span>)},
        {content: (<span>{DateService.formatDate(list.validity_end_date)}</span>)},
        {
          content: (<span><Tag
            color={list.status === 0 ? 'orange' : 'green'}>&emsp;</Tag></span>)
        },
        {
          content: (
            <Grid.Row className="flex-column">
              {confirmationModalShown && (<ConfirmModal
                confirmationText={t('publications.delete-sure')}
                onConfirm={() => {
                  setConfirmationModalShown(false);
                  handleDelete();
                }}
                show={confirmationModalShown}
                onHide={() => {
                  setConfirmationModalShown(false);
                  setToDeleteReference(null)
                }}
              />)}
              <Button className="mb-1" variant="primary" onClick={() => {window.location = LinksProvider.formatUrl(LinksProvider.ROUTES.INSPIRATION_LIST, {id: list.reference})}}>
                {t('inspiration-lists.modify')}
              </Button>
              <Button className="mt-1" variant="danger" onClick={()=> {setToDeleteReference(list.reference); setConfirmationModalShown(true);}}>
                {t('inspiration-lists.delete')}
              </Button>
            </Grid.Row>)
        }
      ]
    };
  };


  return (
    <Fragment>
      <Card title={t('inspiration-lists.list', {count: queryCount})}>
        {loading && <Loader/>}

        {(!loading) && (
          <Card.Body>
            <Table responsive
                   className="card-table"
                   headerItems={headerItems}
                   verticalAlign="center"
                   bodyItems={listLists.map(itemRow)}/>
          </Card.Body>
        )}
        <Card.Footer>
          <TablePagination
            pageCount={pageCount}
            currentPage={currentPage}
            handlePageClick={handlePageClick}
          />
        </Card.Footer>
      </Card>
    </Fragment>

  )
};

export default withI18n()(InspirationListsList);
