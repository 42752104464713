// @flow
import * as React from "react";
import {Button, Form, Loader, Page} from "tabler-react";
import { withI18n } from 'react-i18next';
import {useEffect, useState} from "react";
import Select from "react-select";
import {useToasts} from "react-toast-notifications";
import AuthService from "../../services/domain/AuthService";
import LinksProvider from '../../services/http/LinksProvider';
import KeycloakService from '../../services/domain/KeycloakService';

function WelcomePage({t}): React.Node {

  const [signinOption, setSigninOption] = useState(null);
  const [loading, setLoading] = useState(false);
  const [keyCloakParams, setKeyCloakParams] = useState(null);
  const {addToast} = useToasts();

  useEffect(async () => {
    document.title = t('navbar.welcome');

    const signinOption = localStorage.getItem('signin_option');
    localStorage.removeItem('signin_option');
    if (signinOption){
      setLoading(true);
      const authenticated = await KeycloakService.signinClient(KeycloakService.siginOptions.find((option) => option.value === signinOption).keyCloakParams);
      if (authenticated) {
        window.location.href = LinksProvider.ROUTES.HOME;
      } else {
        setLoading(false);
      }
    } else if (AuthService.isAuthenticated()) {
      setLoading(true);
      window.location.href = LinksProvider.ROUTES.HOME;
    }
  }, []);

  const handleChange = (option) => {
    setSigninOption(option.value);
    setKeyCloakParams({...option.keyCloakParams});
  }

  const submit = () => {
    localStorage.setItem('signin_option', signinOption);

    if (!keyCloakParams) {
        addToast(t(`roles.not-found`), {
          appearance: 'error', autoDismiss: true
        });
    } else {
      KeycloakService.signinClient(keyCloakParams);
    }
  }

  return (
    loading ? <Loader className="mt-5"/>:
        <Page.Content>
          <img src="/assets/images/logo.svg"/>
          <Form.Group label={t('welcome')} className={"text-left"}>
              <Select
                name="role"
                options={KeycloakService.siginOptions}
                onChange={handleChange}
                className="basic-multi-select my-3"
                classNamePrefix="select"
              />
              <Button color="primary" className="float-right" disabled={!signinOption || !keyCloakParams} onClick={submit}>{t('roles.confirm')}</Button>
          </Form.Group>
        </Page.Content>
  );
}


export default withI18n()(WelcomePage);
