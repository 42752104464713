import React, {useEffect, useState} from "react";
import {Button, Card, Grid, Loader} from "tabler-react";
import SimpleLineChart from "../../common/simple-line-chart/SimpleLineChart";
import ReportService from "../../../services/domain/ReportService";
import ErrorHandler from "../../../services/error/ErrorHandler";
import EmptyContent from "../../common/empty-content/EmptyContent";
import {withI18n} from "react-i18next";
import {useToasts} from "react-toast-notifications";
import {Input} from 'reactstrap';
import LinksProvider from "../../../services/http/LinksProvider";
import DateService from "../../../services/utils/DateService";
import FormatService from "../../../services/utils/FormatService";
import {OriginApp} from "../../../models/User";

const VolumeTransformedInCC = ({origin = OriginApp.CNT, t}) => {
  const {addToast} = useToasts();
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState(null);
  const [aggregation, setAggregation] = useState(ReportService.TIME_FILTER_VALUES.MONTH);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    loadData();
  }, [aggregation]);

  const loadData = () => {
    setLoading(true);
    ReportService.VolumeTransformedInCC(aggregation, false, origin)
      .then(data => {
        setResult(data);
      })
      .catch(error => {
        console.log("error on loading volume transformed in credit card report data : ", error);
        ErrorHandler.handleError(addToast, t, error);
      })
      .finally(() => setLoading(false));
  };

  const downloadCSV = () => {
    ReportService.downloadReport(LinksProvider.formatFullUrl(LinksProvider.API.REPORT.TOTAL_COLLECTED_AMOUNT, {
      aggregation,
      download: 1,
      origin
    }))
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', t('dashboard.volume-transformed-in-cc-title') + '_' + DateService.formatDateISO(new Date()) + '.csv');
        document.body.appendChild(link);
        link.click();
      });
  };

  return (
    <React.Fragment>
      <Card>
        <Card.Header>
          <Card.Title>{t('dashboard.volume-transformed-in-cc-title')}</Card.Title>
          <Card.Options>
            <Grid.Row>
              <Grid.Col>
                <Button color="info" className="w-100"
                        onClick={downloadCSV}>{t('dashboard.download')}</Button>
              </Grid.Col>
              <Grid.Col>
                <Input
                  type="select"
                  name="type"
                  id="typeSelect"
                  value={aggregation}
                  onChange={(e) => setAggregation(e.target.value)}
                >
                  {Object.keys(ReportService.TIME_FILTER_VALUES).map(key =>
                    <option
                      key={ReportService.TIME_FILTER_VALUES[key]}
                      value={ReportService.TIME_FILTER_VALUES[key]}>{t('dashboard.time-filters.' + key)}</option>
                  )}
                </Input>
              </Grid.Col>
            </Grid.Row>
          </Card.Options>
        </Card.Header>
        <Card.Body>
          {loading && <Loader/>}
          {!loading && result &&
          <SimpleLineChart
            x_columns={result.x_values}
            y_columns={
              [
                {
                  values: result.y_values,
                  color: "#D281D2",
                  name: t('dashboard.volume-transformed-in-cc-title')
                }
              ]
            }
            label_formatter={FormatService.reportTickLabelFormatter(' €')}
            y_axis_label_formatter={FormatService.reportAxisLabelFormatter(' €')}
          />}
          {!loading && !result && (<EmptyContent text={t('dashboard.no-results')}/>)}
        </Card.Body>
      </Card>
    </React.Fragment>);
};

export default withI18n()(VolumeTransformedInCC);
