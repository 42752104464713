// @flow
import * as React from "react";
import {Card, StampCard, Text} from "tabler-react";

function InfoWidget({content, text, icon, color}): React.Node {

  return (
      <StampCard color={color} icon={icon}
          header={<span>{content}</span>}
                 footer={<span>{text}</span>}/>
  );
}

export default InfoWidget;