import React, {useEffect, useState} from "react";
import {withI18n} from 'react-i18next';
import KycService from "../../../services/domain/KycService";
import {DocumentType} from "../../../models/VerificationDocument";
import {
  Card, Loader
} from "tabler-react";
import { Button, ListGroup, ListGroupItem} from "react-bootstrap";
import UserDocumentDetails from "../user-document-details/UserDocumentDetails";
import EmptyContent from "../../common/empty-content/EmptyContent";
import UploadDocumentModal from "../../modals/upload-document-modal/UploadDocumentModal";

function UserKyc({email, t}): React.Node {

  const [loading, setLoading] = useState(true);
  const [model, setModel] = useState(null);
  const [documentsModel, setDocumentsModel] = useState(null);
  const [uploadDocumentType, setUploadDocumentType] = useState(0);
  const [showUploadDocumentModal, setUploadDocumentModal] = useState(false);

  useEffect(() => {
    loadUser()
  }, [email])

  const filterDocuments = (docs) => {
    console.log('docs : ', docs.filter(
      document => document.type == DocumentType.IDENTITY
    ))
    setDocumentsModel(docs.filter(
      document => document.type == DocumentType.IDENTITY
    ))
  };

  const loadUser = async () => {
    setLoading(true);
    await KycService.getUserWithAllDocuments(email)
        .then((user) => {
          console.log('user : ', user)
          setModel(user);
          filterDocuments(user.verification_documents)
        })
        .finally(() => setLoading(false));
  };

  return (
  <React.Fragment>
    <Card>
        <Card.Header>
          <Card.Title>
            {t('kyc-user.user-documents')}
          </Card.Title>
          <Card.Options>
            <Button size="sm" onClick={() => {
              setUploadDocumentType(KycService.KycFileType.IDENTITY);
              setUploadDocumentModal(true);
            }} color="primary">
              {t('kyc-document.upload-identity-document')}
            </Button>
          </Card.Options>
        </Card.Header>
        <Card.Body className="fixed-height-card-body">
          {loading ? <Loader/> :
            (documentsModel && documentsModel.length > 0 ? (
              <ListGroup className="list-group-flush">
                {documentsModel.map((document, index) => (
                    <ListGroupItem key={index}>
                      <UserDocumentDetails
                          refreshData={() => loadUser()}
                          document={document}
                          user={model}/>
                    </ListGroupItem>
                ))}
              </ListGroup>
            ) : <EmptyContent text={t('kyc-user.no-documents')}/>
          )}
        </Card.Body>
      </Card>

      <UploadDocumentModal
              user={model}
              type={uploadDocumentType}
              onFinish={() => {
                setUploadDocumentModal(false);
                loadUser();
              }}
              onHide={() => setUploadDocumentModal(false)}
              show={showUploadDocumentModal}/>
    </React.Fragment>);
}

export default withI18n()(UserKyc);
