import React, {useEffect, useState} from "react";
import {Card, Dimmer, Form, Grid, Table} from "tabler-react";
import {withI18n} from 'react-i18next';
import ErrorHandler from "../../../services/error/ErrorHandler";
import {useToasts} from 'react-toast-notifications';
import UsersService from "../../../services/domain/UsersService";
import EmptyContent from "../../common/empty-content/EmptyContent";
import LinksProvider from "../../../services/http/LinksProvider";
import Amount from "../../common/amount/Amount";
import DateService from "../../../services/utils/DateService";

const Sponsorship = ({t, userEmail}) => {

  const [sponsorshipModel, setSponsorshipModel] = useState({});
  const [sponsorPromoCode, setSponsorPromoCode] = useState({});
  const [sponsoredPromoCodes, setSponsoredPromoCodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const {addToast} = useToasts();


  useEffect(() => {
    getSponsorship();
  }, []);


  const getSponsorship = () => {
    setLoading(true);
    UsersService.getSponsorship(userEmail)
      .then(sponsorship => {
        setSponsorshipModel(sponsorship ? sponsorship : {});
        setSponsorPromoCode(sponsorship ? sponsorship.sponsorPromoCode ? sponsorship.sponsorPromoCode : {} : {});
        setSponsoredPromoCodes(sponsorship ? sponsorship.sponsoredPromoCodes ? sponsorship.sponsoredPromoCodes : [] : []);
        console.log(sponsorship);
      })
      .catch(error => ErrorHandler.handleError(addToast, t, error))
      .finally(() => setLoading(false));
  };

  const headerItems = [
    {content: t('promo-codes.reference')},
    {content: t('promo-codes.activation-date')},
    {content: t('promo-codes.amount')}
  ];


  const itemRow = (prc) => {
    return {
      key: prc.reference,
      item: [
        {
          content: (<a
            href={LinksProvider.formatUrl(LinksProvider.ROUTES.PROMO_CODE, {id: prc.reference})}>{prc.reference}</a>)
        },
        {content: DateService.formatTime(prc.creation_date)},
        {content: (<Amount amount={prc.amount}/>)}
      ]
    };
  };


  return (

    <>
      <Card>
        <Card.Header>
          <Card.Title>
            {t('users.sponsorship')}
          </Card.Title>
        </Card.Header>

        <Card.Body>
          {((!sponsorshipModel || !Object.keys(sponsorshipModel).length) && !loading) ? (
            <EmptyContent text={t('users.no-sponsorship')}/>) : ''}

          <Dimmer active={loading}
                  loader
          >

            {(Object.keys(sponsorshipModel).length > 0 && !loading) &&
            <div>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Group label={t('users.sponsor-code')}>
                    <a
                      href={LinksProvider.formatUrl(LinksProvider.ROUTES.PROMO_CODE, {id: sponsorPromoCode.reference})}>{sponsorPromoCode.reference}</a>
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            </div>}

            {(Object.keys(sponsoredPromoCodes).length > 0 && !loading) &&
            <Card title={t('users.sponsored-codes', {count: sponsoredPromoCodes.length})}
                  className={`no_padding`}>
              <Card.Body className={`no_padding`}>
                <Table responsive
                       className="card-table"
                       headerItems={headerItems}
                       bodyItems={sponsoredPromoCodes.map(itemRow)}
                />
              </Card.Body>
            </Card>}

          </Dimmer>
        </Card.Body>
      </Card>

    </>
  );

};

export default withI18n()(Sponsorship);
