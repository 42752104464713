import React from 'react';
import {Pagination, PaginationItem, PaginationLink} from 'reactstrap';

function TablePagination({pageCount, currentPage, handlePageClick}){

  const handlePreviousClick = (e) => {
    if (currentPage) {
      handlePageClick(e, currentPage - 1);
    }
  };

  const handleNextClick = (e) => {
    if (currentPage < pageCount - 1) {
      handlePageClick(e, currentPage + 1);
    }
  };

  const handleLastClick = (e) => {
    if (currentPage < pageCount - 1) {
      handlePageClick(e, pageCount - 1);
    }
  };

  const handleFirstClick = (e) => {
    if (currentPage) {
      handlePageClick(e, 0);
    }
  };
  const renderPageNumbers = () => {
    const neighborsRange = 4;
    return [...Array(pageCount)].map((page, i) => {
      if (i === 0 || i === pageCount - 1 || (i >= currentPage - neighborsRange && i <= currentPage + neighborsRange)) {
        return (
          <PaginationItem active={i === currentPage} key={i}>
            <PaginationLink onClick={e => handlePageClick(e, i)} href="#">
              {i + 1}
            </PaginationLink>
          </PaginationItem>
        );
      }
    });
  };

  return (
    <Pagination aria-label="table navigation">
      <PaginationItem disabled={currentPage <= 0}>
        <PaginationLink onClick={e => handleFirstClick(e)} first href="#"/></PaginationItem>
      <PaginationItem disabled={currentPage <= 0}>
        <PaginationLink previous onClick={e => handlePreviousClick(e)} href="#"/>
      </PaginationItem>
      {renderPageNumbers()}
      <PaginationItem>
        <PaginationLink next disabled={currentPage === pageCount - 1} onClick={e => handleNextClick(e)} href="#"/>
      </PaginationItem>
      <PaginationItem disabled={currentPage === pageCount - 1}>
        <PaginationLink last onClick={e => handleLastClick(e)}/>
      </PaginationItem>
    </Pagination>
  );
}

export default TablePagination;
