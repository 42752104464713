/**
 * FormatService
 * @module Services/utils/FormatService
 * @description Offers utility functions to handle string formatting
 */

const self = {};

/**
 * Returns formatting function for chart tick labeling
 * @author Seif Khiari
 * @alias reportTickLabelFormatter
 * @memberof module:Services/utils/FormatService
 * @param   {string} label tick added label
 * @returns {function}  The formatted date
 */
self.reportTickLabelFormatter = (label) => {
    return (value) => { return value.yLabel + label};
};

/**
 * Returns formatting function for chart tick labeling
 * @author Seif Khiari
 * @alias reportTickLabelFormatter
 * @memberof module:Services/utils/FormatService
 * @param   {string} label tick added label
 * @returns {function}  The formatted date
 */
self.reportTickLabelFormatter = (label) => {
    return (value) => { return value.yLabel + label};
};

/**
 * Returns formatting function for chart axis labeling
 * @author Seif Khiari
 * @alias reportAxisLabelFormatter
 * @memberof module:Services/utils/FormatService
 * @param   {string} label tick added label
 * @returns {function}  The formatted date
 */
self.reportAxisLabelFormatter = (label) => {
    return (value, index, labels) => { return value + label};
};

/**
 * Returns key value Object as a reverse object of value , key
 * @author Amine Elouaer 
 * @alias flipObject
 * @memberof module:Services/utils/FormatService
 * @param   {Object} objectData 
 * @returns {Object}  The formatted object
 */

self.flipObject = (objectData) => {
    return Object.fromEntries(
        Object
        .entries(objectData)
        .map(([key , value]) => [value,key])
    );
}


/**
 * Returns an Uppercase string from a kebab case
 * @author Amine Elouaer 
 * @alias kebabToUpper
 * @memberof module:Services/utils/FormatService
 * @param   {Object} objectData 
 * @returns {Object}  The formatted object
 */
self.kebabToUpper = (str) => {
    return str ? str.replace(/^-*(.)|-+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase()) : "";
}

export default self;