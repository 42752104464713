// @flow
import * as React from "react";
import {useState} from "react";
import {Modal} from "react-bootstrap";
import {Button, Loader, Grid, Form, Badge} from "tabler-react";
import {withI18n} from 'react-i18next';
import ErrorHandler from "../../../services/error/ErrorHandler";
import PotsService from "../../../services/domain/PotsService";
import {useToasts} from "react-toast-notifications";
import {GiftCard, GiftCardStatus, GiftCardType, GiftCardParent} from "../../../models/GiftCard";
import Amount from "../../common/amount/Amount";
import DateService from "../../../services/utils/DateService";
import LinksProvider from "../../../services/http/LinksProvider";

function GiftCardModal(props): React.Node {
    const MODAL_CONTENT = {
        GIFT_CARD: 0,
        RESEND: 1,
        ACTIVATE: 2
    };

    const [modalContent, setModalContent] = useState(MODAL_CONTENT.GIFT_CARD);
    const [loading, setLoading] = useState(false);
    const {addToast} = useToasts();

    const setModalContentToResend = (event) => {
        event.preventDefault();
        setModalContent(MODAL_CONTENT.RESEND);
    };

    const setModalContentToGiftCard = (event) => {
        event.preventDefault();
        setModalContent(MODAL_CONTENT.GIFT_CARD);
    };

    const setModalContentToActivate = (event) => {
        event.preventDefault();
        setModalContent(MODAL_CONTENT.ACTIVATE);
    };

    const handleServiceResponse = (gift_card, toastText) => {
        props.onHide && props.onHide();
        addToast(toastText, {
            appearance: 'success',
            autoDismiss: true
        });
    };

    const handleServiceErrorResponse = (error) => {
        props.onHide && props.onHide();
        if (error.code && error.code === 'E_INTERNAL_SERVER_ERROR') {
            addToast(props.t(`errors.${error.code}`), {
                appearance: 'error',
                autoDismiss: true
            });
        } else {
            ErrorHandler.handleError(addToast, props.t, error);
        }
    };

    const handleServiceCallCleanup = () => {
        setLoading(false);
        setModalContent(MODAL_CONTENT.GIFT_CARD);
    };

    const resendGiftCard = () => {
        setLoading(true);
        PotsService.resendGiftCard(props.giftCard.reference).then(
            gift_card => {
                handleServiceResponse(gift_card, props.t('gift-cards.successfully-resent'));
            }
        ).catch(error => {
            handleServiceErrorResponse(error);
        }).finally(handleServiceCallCleanup);
    };

    const activateGiftCard = () => {
        setLoading(true);
        PotsService.activateGiftCard(props.giftCard.reference).then(
          gift_card => {
              handleServiceResponse(gift_card, props.t('gift-cards.successfully-activated'));
              props.reloadData && props.reloadData();
          }
        ).catch(error => {
            handleServiceErrorResponse(error);
        }).finally(handleServiceCallCleanup);
    };

    const GiftCardStatusBadge = (gift_card: GiftCard) => {
        switch (gift_card.status) {
            case GiftCardStatus.NEW :
                return (<Badge color="primary">{props.t('gift-cards.status-new')}</Badge>);
            case GiftCardStatus.EXPIRED:
                return (<Badge color="danger">{props.t('gift-cards.status-expired')}</Badge>);
            case GiftCardStatus.UNKNOWN:
                return (<Badge color="warning">{props.t('gift-cards.status-unknown')}</Badge>);
            case GiftCardStatus.ACTIVE:
                return (<Badge color="success">{props.t('gift-cards.status-active')}</Badge>)
        }
    };

    return (<Modal {...props} size="lg" backdrop="static">
        <Modal.Header>
            <Modal.Title>
                {props.t('gift-cards.detail-title')}: {props.giftCard.reference}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {loading && (<Loader/>)}
            {!loading && modalContent === MODAL_CONTENT.GIFT_CARD && (<React.Fragment>
                <Grid.Row width={12}>
                    <Grid.Col width={4}>
                        <Form.Group label={props.t('gift-cards.card-number')}>
                            {props.giftCard.card_number}
                        </Form.Group>
                    </Grid.Col>
                    <Grid.Col width={4}>
                        <Form.Group label={props.t('gift-cards.type')}>
                            {props.giftCard.type === GiftCardType.GL ? props.t('gift-cards.type-gl') : props.t('gift-cards.type-bhv')}
                        </Form.Group>
                    </Grid.Col>
                    <Grid.Col width={4}>
                        <Form.Group label={props.t('gift-cards.status')}>
                            {GiftCardStatusBadge(props.giftCard)}
                        </Form.Group>
                    </Grid.Col>
                </Grid.Row>
                <Grid.Row width={12}>
                    <Grid.Col width={4}>
                        <Form.Group label={props.t('gift-cards.amount')}>
                            <Amount amount={props.giftCard.amount}></Amount>
                        </Form.Group>
                    </Grid.Col>
                    <Grid.Col width={4}>
                        <Form.Group label={props.t('gift-cards.balance')}>
                            <Amount amount={props.giftCard.balance}></Amount>
                        </Form.Group>
                    </Grid.Col>
                    <Grid.Col width={4}>
                        <Form.Group label={props.t('gift-cards.bonification-amount')}>
                            <Amount amount={props.giftCard.bonus}></Amount>
                        </Form.Group>
                    </Grid.Col>
                </Grid.Row>
                <Grid.Row width={12}>
                    <Grid.Col width={4}>
                        <Form.Group label={props.t('gift-cards.creation-date')}>
                            {DateService.formatDate(props.giftCard.creation_date)}
                        </Form.Group>
                    </Grid.Col>
                    <Grid.Col width={4}>
                        <Form.Group label={props.t('gift-cards.activation-date')}>
                            {DateService.formatDate(props.giftCard.activation_date)}
                        </Form.Group>
                    </Grid.Col>
                    <Grid.Col width={4}>
                        <Form.Group label={props.t('gift-cards.expiration-date')}>
                            {DateService.formatDate(props.giftCard.expiration_date)}
                        </Form.Group>
                    </Grid.Col>
                </Grid.Row>
                <Grid.Row width={12}>
                    <Grid.Col width={4}>
                        <Form.Group
                            label={props.parent === GiftCardParent.POT ? props.t('gift-cards.buyer-email') : props.t('gift-cards.pot-reference')}>
                            {props.parent === GiftCardParent.POT && (
                                <a href={LinksProvider.formatUrl(LinksProvider.ROUTES.USER, {id: props.giftCard.buyer_email})}>{props.giftCard.buyer_email}</a>)}
                            {props.parent === GiftCardParent.USER && (
                                <a href={LinksProvider.formatUrl(LinksProvider.ROUTES.POT, {id: props.giftCard.pot_reference})}>{props.giftCard.pot_reference}</a>)}
                        </Form.Group>
                    </Grid.Col>
                </Grid.Row>
            </React.Fragment>)}
            {!loading && modalContent === MODAL_CONTENT.RESEND && props.t('gift-cards.resend-confirm-text', {email: props.giftCard.buyer_email})}
            {!loading && modalContent === MODAL_CONTENT.ACTIVATE && props.t('gift-cards.activate-confirm-text')}
        </Modal.Body>
        <Modal.Footer>
            <Button disabled={loading} onClick={(event) => {
                setModalContentToGiftCard(event);
                props.onHide();
            }} color="warning"
                    closeButton>{props.t('modals.close')}</Button>
            {modalContent === MODAL_CONTENT.GIFT_CARD && (
                <Button disabled={loading} onClick={setModalContentToResend} color="primary">
                    {props.t('gift-cards.resend')}</Button>
            )}
            {modalContent === MODAL_CONTENT.GIFT_CARD && props.giftCard.status === GiftCardStatus.NEW && (
            <Button disabled={loading} onClick={setModalContentToActivate} color="primary">
              {props.t('gift-cards.activate')}</Button>
            )}
            {modalContent !== MODAL_CONTENT.GIFT_CARD && (
                <Button disabled={loading}
                        onClick={modalContent === MODAL_CONTENT.RESEND ? resendGiftCard : activateGiftCard}
                        color="success">
                    {props.t('modals.confirm')}</Button>
            )}
            {modalContent !== MODAL_CONTENT.GIFT_CARD && (
                <Button disabled={loading} onClick={setModalContentToGiftCard} color="danger">
                    {props.t('modals.cancel')}</Button>
            )}
        </Modal.Footer>
    </Modal>);
}

export default withI18n()(GiftCardModal);
