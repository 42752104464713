import * as React from 'react'

import {Button, Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {withI18n} from 'react-i18next';
import ConfirmModal from "../../modals/confirm-modal/ConfirmModal";
import KycService from "../../../services/domain/KycService";
import DateService from "../../../services/utils/DateService";
import FileService from "../../../services/domain/FileService";
import DocumentStatus from "../../kyc/document/document-status/DocumentStatus";
import DocumentType from "../../kyc/document/document-type/DocumentType";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faTrash, faUpload} from "@fortawesome/free-solid-svg-icons";
import {useToasts} from 'react-toast-notifications';

const UserDocumentDetails = ({t, document, user, refreshData}) => {

  const [confirmDeleteShow, setConfirmDeleteShow] = React.useState(false);
  const [confirmResendShow, setConfirmResendShow] = React.useState(false);
  const {addToast} = useToasts();

  const deleteDocument = () => {
    return KycService.deleteDocument(document.reference)
        .then(() => {
          setConfirmDeleteShow(false);
          addToast(t('kyc-document.delete-success'), {
            appearance: 'success', autoDismiss: true
          });
          refreshData();
        })
        .catch(error => {
          setConfirmDeleteShow(false);

          if (error && error.error) {
            addToast(t(`errors.${error.error}`), {
                appearance: 'error', autoDismiss: true
            });
          }
        })

  };

  const getFileType = (type) => {
    switch (type) {
        case "1":
        case "IDENTITY":
        return KycService.KycFileType.IDENTITY;
        case "2":
        case "ADDRESS":
        return KycService.KycFileType.ADDRESS;
        default:
            return KycService.KycFileType.OTHER;
    }
  };

  const resendDocument = () => {
    return FileService.resend(user.email, document.reference, getFileType(document.type))
        .then(() => {
          setConfirmResendShow(false);
          refreshData();
          addToast(t('kyc-document.resend-success'), {
            appearance: 'success', autoDismiss: true
          });
        })
        .catch(error => {
          setConfirmDeleteShow(false);

          if (error && error.error) {
            addToast(t(`errors.${error.error}`), {
                appearance: 'error', autoDismiss: true
            });
          }
        })
  };

  return (
      <React.Fragment>
        <ConfirmModal
            confirmationText={t('kyc-document.sure-delete')}
            onConfirm={deleteDocument}
            show={confirmDeleteShow}
            onHide={() => setConfirmDeleteShow(false)}/>

        <ConfirmModal
            confirmationText={t('kyc-document.sure-resend')}
            onConfirm={resendDocument}
            show={confirmResendShow}
            onHide={() => setConfirmResendShow(false)}/>

        <div className={'float-right'}>

          <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id={`tooltip-${document.reference}-view`}>
                  {t('kyc-document.view')}
                </Tooltip>
              }>
            <Button onClick={() => window.open(document.url, "_blank")} size="sm"
                    variant="outline-primary">
              <FontAwesomeIcon icon={faEye}/>
            </Button>
          </OverlayTrigger>


          {!document.deletion_date && (
              <React.Fragment>
                &nbsp;
                &nbsp;

                <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id={`tooltip-${document.reference}-view`}>
                        {t('kyc-document.delete')}
                      </Tooltip>
                    }>
                  <Button onClick={() => setConfirmDeleteShow(true)} size="sm"
                          variant="outline-danger">
                    <FontAwesomeIcon icon={faTrash}/>
                  </Button>
                </OverlayTrigger>
              </React.Fragment>
          )}

          &nbsp;
          &nbsp;

          <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id={`tooltip-${document.reference}-resend`}>
                  {t('kyc-document.resend')}
                </Tooltip>
              }>
            <Button onClick={() => setConfirmResendShow(true)} size="sm"
                    variant="outline-info">
              <FontAwesomeIcon icon={faUpload}/>
            </Button>
          </OverlayTrigger>

        </div>

        <Row className={'text-left'}>
          <Col>
            <Row>
              <Col sm={6} className="mb-3">
                {t('kyc-document.type')} : <DocumentType type={document.type}/>
              </Col>
              <Col sm={6} className="mb-3">
                {t('kyc-document.status')} : <DocumentStatus status={document.status}/>
              </Col>
              {document.rejection_reason && (
                  <Col sm={6} className="mb-3">
                    {t('kyc-document.message')} : <span
                      className={'text-danger'}>{document.rejection_reason}</span>
                  </Col>
              )}
              <Col sm={6} className="mb-3">
                {t('kyc-document.document-id')} : {document.external_id}
              </Col>
              <Col sm={6} className="mb-3">
                {t('kyc-document.date-upload-client')} : {DateService.formatTime(
                  document.creation_date)}
              </Col>
              {document.deletion_date && (
                  <Col sm={6} className="mb-3 text-danger">
                    {t('kyc-document.deletion-date')} : {DateService.formatTime(
                      document.deletion_date)}
                  </Col>
              )}

              <Col sm={6} className="mb-3">
                {t('kyc-document.date-upload-smoney')} : {DateService.formatTime(
                  document.sent_date)}
              </Col>
              <Col sm={6} className="mb-3">
                {t('kyc-document.date-treatment-smoney')} : {DateService.formatTime(
                  document.treatment_date)}
              </Col>
            </Row>
          </Col>

        </Row>
      </React.Fragment>
  );

};

export default withI18n()(UserDocumentDetails);
