import React, {useState, useEffect} from "react";
import {Button, Card, Grid, Form} from "tabler-react";
import ReportService from "../../../services/domain/ReportService";
import {withI18n} from "react-i18next";
import LinksProvider from "../../../services/http/LinksProvider";
import DateService from "../../../services/utils/DateService";
import DatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const PromoCodeCardReport = ({t}) => {
    const today = new Date();
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date(today.getFullYear(), today.getMonth(), 1));
    const [endDate, setEndDate] = useState(today);

    useEffect(() => {

    }, [t]);

    const downloadReport = () => {
        setLoading(true);
        ReportService.downloadReport(LinksProvider.formatFullUrl(LinksProvider.API.REPORT.PROMO_CODE_REPORT, {
            start_date : moment(startDate).toISOString(),
            end_date: moment(endDate).toISOString()
        }))
            .then((response) => {
                console.log(response);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', t('reports.promo-code-card-report') + ' ' + DateService.formatDateISO(startDate) + '__' + DateService.formatDateISO(endDate) + '.csv');
                document.body.appendChild(link);
                link.click();
            })
            .finally(() => setLoading(false));
    };

    return (
        <React.Fragment>
            <Card>
                <Card.Header>
                    <Card.Title>{t('reports.promo-code-card-report')}</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Grid.Row>
                        <Grid.Col width={4}>
                            <Form.Group label={t('reports.start-date')} className={"text-left"}>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date)=> setStartDate(date)}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </Form.Group>
                        </Grid.Col>
                        <Grid.Col width={4}>
                            <Form.Group label={t('reports.end-date')} className={"text-left"}>
                                <DatePicker
                                    selected={endDate}
                                    onChange={(date)=> setEndDate(date)}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </Form.Group>
                        </Grid.Col>
                        <Grid.Col width={4} className="d-flex align-items-center">
                            <Button color="primary" onClick={downloadReport} loading={loading}>{t('reports.download')}</Button>
                        </Grid.Col>
                    </Grid.Row>
                </Card.Body>
            </Card>
        </React.Fragment>)
};

export default withI18n()(PromoCodeCardReport);