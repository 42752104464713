import * as React from 'react'

import {Button, Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {withI18n} from 'react-i18next';
import ConfirmModal from "../../../modals/confirm-modal/ConfirmModal";
import KycService from "../../../../services/domain/KycService";
import ShowDocumentPreviewModal
  from "../../../modals/show-document-preview-modal/ShowDocumentPreviewModal";
import {DocumentAdditionalStatus} from "../../../../models/VerificationDocument";
import FileService from "../../../../services/domain/FileService";
import DocumentStatus from "../../document/document-status/DocumentStatus";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faTrash, faUpload} from '@fortawesome/free-solid-svg-icons';
import DateService from "../../../../services/utils/DateService";
import {useToasts} from "react-toast-notifications";
import ErrorHandler from "../../../../services/error/ErrorHandler";

const UserDocument = ({t, document, user, refreshData}) => {

  const [confirmDeleteShow, setConfirmDeleteShow] = React.useState(false);
  const [showDocumentPreviewModal, setShowDocumentPreviewModal] = React.useState(false);
  const [confirmResendModal, setConfirmResendModal] = React.useState(false);
  const {addToast} = useToasts();

  const deleteDocument = () => {
    return KycService.deleteDocument(document.reference)
        .then(() => {
          refreshData();
          addToast(t(`kyc-document.delete-success`), {
            appearance: 'success', autoDismiss: true
          });
        })
        .catch(error => {
            ErrorHandler.handleError(addToast, t, error);
        })
        .finally(() => setConfirmDeleteShow(false))
  };

  const getFileType = (type) => {
    return type === "1" || type === "IDENTITY" ? KycService.KycFileType.IDENTITY : type
    === "2"
    || type
    == "ADDRESS" ? KycService.KycFileType.ADDRESS : KycService.KycFileType.OTHER;
  };

  const resendDocument = () => {
    return FileService.resend(user.email, document.reference, getFileType(document.type))
        .then(() => {
          refreshData();
          addToast(t(`kyc-document.resend-success`), {
            appearance: 'success', autoDismiss: true
          });
        })
        .finally(() => setConfirmResendModal(false))
  };

  return (
      <React.Fragment>
        <ConfirmModal
            confirmationText={t('kyc-document.sure-delete')}
            onConfirm={deleteDocument}
            show={confirmDeleteShow}
            onHide={() => setConfirmDeleteShow(false)}
        />
        <ConfirmModal
            confirmationText={t('kyc-document.sure-resend')}
            onConfirm={resendDocument}
            show={confirmResendModal}
            onHide={() => setConfirmResendModal(false)}
        />
        <ShowDocumentPreviewModal
            user={user}
            onUserUpdated={() => {
              refreshData();
            }}
            document={document}
            onHide={() => setShowDocumentPreviewModal(false)}
            show={showDocumentPreviewModal}
        />
        <Row>
          <Col sm={9}>
            <Row className={'text-left'}>
              <Col sm={12} className="mb-3">
                <strong>{t('kyc-document.status')}</strong> : <DocumentStatus
                  status={document.status}/>
              </Col>
              <Col sm={12} className="mb-3">
                <strong>{t(
                    'kyc-document.date-upload-client')}</strong> : {DateService.formatTime(
                  document.creation_date)}
              </Col>
              <Col sm={12} className="mb-3">
                <strong>{t(
                    'kyc-document.date-upload-smoney')}</strong> : {DateService.formatTime(
                  document.sent_date)}
              </Col>
              <Col sm={12} className="mb-3">
                <strong>{t(
                    'kyc-document.date-treatment-smoney')}</strong> : {DateService.formatTime(
                  document.treatment_date)}
              </Col>
              {(document.additional_status != null && document.additional_status != undefined) && (
                  <Col sm={12} className="mb-3">
                    <strong>{t('kyc-document.rejection-reason')}</strong> :
                    <span
                        className={'badge badge-sm badge-danger'}>{DocumentAdditionalStatus[document.additional_status]}</span>
                  </Col>
              )}
              {document.rejection_reason && (
                  <Col sm={12} className="mb-3">
                    <strong>{t('kyc-document.message')}</strong> : <span
                      className={'text-danger'}>{document.rejection_reason}</span>
                  </Col>
              )}
              {document.deletion_date && (
                  <Col sm={12} className="mb-3 text-danger">
                    <strong>{t(
                        'kyc-document.deletion-date')}</strong> : {DateService.formatTime(
                      document.deletion_date)}
                  </Col>
              )}
            </Row>
          </Col>
          <Col sm={3}>
            <div className={'float-right'}>
              <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id={`tooltip-${document.reference}-view`}>
                      {t('kyc-document.view')}
                    </Tooltip>
                  }>
                <Button onClick={() => setShowDocumentPreviewModal(true)} size="sm"
                        variant="outline-primary">
                  <FontAwesomeIcon icon={faEye}/>
                </Button>
              </OverlayTrigger>
              {!document.deletion_date && (
                  <React.Fragment>
                    &nbsp;
                    &nbsp;
                    <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id={`tooltip-${document.reference}-delete`}>
                            {t('kyc-document.delete')}
                          </Tooltip>
                        }>
                      <Button onClick={() => setConfirmDeleteShow(true)} size="sm"
                              variant="outline-danger">
                        <FontAwesomeIcon icon={faTrash}/>
                      </Button>
                    </OverlayTrigger>
                  </React.Fragment>
              )}
              &nbsp;
              &nbsp;
              <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id={`tooltip-${document.reference}-resend`}>
                      {t('kyc-document.resend')}
                    </Tooltip>
                  }>
                <Button onClick={() => setConfirmResendModal(true)} size="sm"
                        variant="outline-info">
                  <FontAwesomeIcon icon={faUpload}/>
                </Button>
              </OverlayTrigger>
            </div>
          </Col>
        </Row>
      </React.Fragment>);

};

export default withI18n()(UserDocument);
