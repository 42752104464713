// @flow
import * as React from "react";
import SiteWrapper from "../../SiteWrapper";
import {Page} from "tabler-react";
import {withI18n} from "react-i18next";
import SearchForm from "../../components/common/search-form/SearchForm";
import LinksProvider from "../../services/http/LinksProvider";
import {useEffect} from "react";

function ParticipationsPage({t}): React.Node {

  useEffect(() => {
    document.title = t('navbar.participations')
  }, [t]);

  return (
      <SiteWrapper>
        <Page.Content>
          <Page.Header
              title={t('navbar.participations')}
              options={(<SearchForm placeholder={t('participations.search-placeholder')} route={LinksProvider.ROUTES.PARTICIPATION}/>)}/>
        </Page.Content>
      </SiteWrapper>
  );
}

export default withI18n()(ParticipationsPage);