// @flow
import * as React from "react";
import {useEffect, useState} from "react";
import {withI18n} from 'react-i18next';
import css from './KycWidget.module.scss';
import {Container} from "react-bootstrap";
import UserDocumentsRecord from "./user/user-documents-record/UserDocumentsRecord";
import KycService from "../../services/domain/KycService";
import {Loader} from "tabler-react";
import {useToasts} from "react-toast-notifications";

function KycWidget({t, url}): React.Node {

  const [usersModel, setUsersModel] = useState(null);
  const [loading, setLoading] = useState(true);

  const [iframeRef] = useState(React.createRef());
  const {addToast} = useToasts();

  const onKycIframeMessage = (event) => {

    if (!event.data) {
      return;
    }

    switch (event.data.type) {
      case "HEIGHT_CHANGE":
        if (iframeRef.current) {
          const currentHeight = iframeRef.current.style.height;
          const newHeight = `${event.data.height}px`;

          if (newHeight != currentHeight) {
            iframeRef.current.style.height = newHeight;
          }
        }
        break;
      case "NOTIFICATION_MESSAGE":
        addToast(event.data.message, {
          appearance: event.data.appearance, autoDismiss: true
        });
        break;

    }

    if (iframeRef.current && event.data && event.data.height) {

    }
  };

  useEffect(() => {
    if (process.env.REACT_APP_KYC_IFRAME === 'true') {
      setLoading(false);
      window.addEventListener("message", onKycIframeMessage);

      return () => window.removeEventListener("message", onKycIframeMessage)
    } else {
      KycService.getRefused().then((users) => {
        setUsersModel(users);
        setLoading(false);
      });
    }
  }, []);

  return (loading ? <Loader /> :
  <section className={css.kyc_widget__container}>
    {process.env.REACT_APP_KYC_IFRAME === 'true' ?
      <iframe ref={iframeRef} className={css.kyc_widget__iframe} src={url}></iframe> :
      <React.Fragment>
      {((!usersModel || !usersModel.length)) ? (
          <h1> {t('globals.no-results')}</h1>
      ) : (
          <Container fluid={false}>
            {usersModel.map((user, index) => (
                <UserDocumentsRecord
                    onKycProcessed={(u) => {
                      const result = usersModel.filter((v) => v.email != u.email);
                      setUsersModel([]);
                      setUsersModel([...result]);
                    }}
                    user={user} key={index}/>
            ))}
          </Container>
      )}
      </React.Fragment>
    }
  </section>);
}

export default withI18n()(KycWidget);
