import React, {Fragment} from "react";
import {Grid, Form} from "tabler-react";
import {Image, Button, ButtonToolbar, Figure} from "react-bootstrap";
import {withI18n} from 'react-i18next';
import {useState} from "react";
import DropZone from "../../common/dropzone/DropZone";
import LinksProvider from "../../../services/http/LinksProvider";
import CommercialPartnersService from "../../../services/domain/CommercialPartnersService";
import ErrorHandler from "../../../services/error/ErrorHandler";
import {useToasts} from "react-toast-notifications";

const CommercialPartnerPhotoUpdate = ({partnerReference, url, t}) => {

  const {addToast} = useToasts();

  const [photoUrl, setPhotoUrl] = useState(url);
  const [loading, setLoading] = useState(false);
  const [displayDropzone, setDisplayDropZone] = useState(false);
  const [uploadedPhotoUrl, setUploadedPhotoUrl] = useState(null);

  const componentConfig = {
    iconFiletypes: [".jpg", ".png", ".jpeg"],
    showFiletypeIcon: true,
    postUrl: (process.env.REACT_APP_BACKEND_BASE_URL || 'http://localhost:1337') + LinksProvider.API.COMMERCIAL_PARTNERS.UPLOAD_PHOTO
  };

  const dzConfig = {
    eventHandlers: (setUrl, removeUrl) => {
      return {
        success: (file, response) => {
          if (response && response.data && response.data.image) {
            setUrl(response.data.image.url);
          }
        },
        removedfile: (file) => {
          removeUrl();
        }
      }
    },
    djsConfig: {
      maxFilesize: 10,
      maxFiles: 1,
      dictDefaultMessage: t('partners.upload-photo'),
      dictRemoveFile: t('partners.remove-photo'),
      addRemoveLinks: true,
      acceptedFiles: "image/jpeg,image/jpg,image/png",
      maxfilesexceeded: function (file) {
        this.removeAllFiles();
        this.addFile(file);
      },
      sending: function (file, xhr, formData) {
        formData.append("image", file);
      },
      canceled: function (file) {
        console.log("delete photo")
      }
    }
  };

  const handleUpdatePhoto = () => {
    //updatePhoto
    if (uploadedPhotoUrl) {
      const values = {
        temp_photo_url : uploadedPhotoUrl ? uploadedPhotoUrl : undefined
      };
      setLoading(true);
      CommercialPartnersService.update(partnerReference, values)
        .then(photo => {
          window.location = LinksProvider.formatUrl(LinksProvider.ROUTES.COMMERCIAL_PARTNERS) + '?reference=' + partnerReference
        })
        .catch(error => {
          ErrorHandler.handleError(addToast, t, error)
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Fragment>
      <p className="w-100 text-center font-weight-bold">{t('partners.photo')}</p>
      <Form onSubmit={(e) => {
        e.preventDefault();
        handleUpdatePhoto();
      }}>
        <Form.Group>
          {(photoUrl !== null) ? (
            <div className="d-flex justify-content-center">
              {(!displayDropzone) ? (<div>
                  <Grid.Row>
                    <Grid.Col width={12}>
                      <Figure>
                        <Figure.Image
                          alt={t('partners.photo')}
                          width={300}
                          height={250}
                          src={photoUrl}/>
                      </Figure>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row className="mb-3">
                    <Grid.Col width={12} className="d-flex justify-content-center">
                      <Button variant="primary" onClick={(e) => {
                        setDisplayDropZone(true)
                      }}>{t('products.update')}</Button>
                    </Grid.Col>
                  </Grid.Row>
                </div>) :
                (
                  <div>
                    <Grid.Row>
                      <Grid.Col width={12}>
                        <DropZone
                          componentConfig={componentConfig}
                          dropZoneConfig={dzConfig.djsConfig}
                          eventHandlers={dzConfig.eventHandlers(
                            url => {
                              setUploadedPhotoUrl(url);
                            },
                            () => {
                              setUploadedPhotoUrl(null);
                            }
                          )}
                        />
                        <br/>
                        <div className="d-flex justify-content-center">
                          <React.Fragment>
                            <Button color="primary" onClick={(e) => {
                              setDisplayDropZone(false)
                              setUploadedPhotoUrl(null)
                            }}>{t('partners.cancel')}</Button>
                          </React.Fragment>
                          {(!!uploadedPhotoUrl) ? (
                            <React.Fragment >
                              <Button disabled={loading} type="submit" color="success" className="ml-1" icon="check">{t('partners.validate')}</Button>
                            </React.Fragment>
                          ) : null}
                        </div>
                      </Grid.Col>
                    </Grid.Row>
                  </div>
                )}
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              <Grid.Row>
                <Grid.Col width={12}>
                  <DropZone
                    componentConfig={componentConfig}
                    dropZoneConfig={dzConfig.djsConfig}
                    eventHandlers={dzConfig.eventHandlers(
                      url => {
                        setUploadedPhotoUrl(url);
                      },
                      () => {
                        setUploadedPhotoUrl(null);
                      }
                    )}
                  />
                  <br/>
                  {(!!uploadedPhotoUrl) ? (
                    <div className="d-flex justify-content-center">
                      <React.Fragment>
                        <Button disabled={loading} type="submit" color="success" icon="check">{t('partners.validate')}</Button>
                      </React.Fragment>
                    </div>
                  ) : null}
                </Grid.Col>
              </Grid.Row>
            </div>
          )}
        </Form.Group>
      </Form>
    </Fragment>
  );
};

export default withI18n()(CommercialPartnerPhotoUpdate);
