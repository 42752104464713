// @flow
import * as React from "react";
import {useState} from "react";
//import {useState} from "react";
import {Button, Col, ListGroup, Modal, Row} from "react-bootstrap";
import css from './UploadDocumentModal.scss';
import FileUpload from "../../forms/file-upload/FileUpload";
import FileService from "../../../services/domain/FileService";
import {withI18n} from "react-i18next";
import KycService from "../../../services/domain/KycService";
import {useToasts} from "react-toast-notifications";
import ErrorHandler from "../../../services/error/ErrorHandler";

const UploadDocumentModal = ({t, type, user, onHide, show, onFinish}) => {

  const [localDocuments, setLocalDocuments] = useState([]);
  const [loading, setLoading] = useState(false);

  const {addToast} = useToasts();

  const modalTitle = () => {
    switch (type) {
      case KycService.KycFileType.IDENTITY:
        return t('kyc-document.upload-identity-document');
      case KycService.KycFileType.ADDRESS:
        return t('kyc-document.upload-jdd-document');
      default:
        return t('kyc-document.upload-document');
    }
  };

  const deleteDocument = (document) => {
    FileService.delete(document.name)
        .then(() => {
          const files = localDocuments.filter((file) => file.name !== document.name);
          setLocalDocuments(files);
        })
        .catch(error => {
            ErrorHandler.handleError(addToast, t, error);
        });
  };

  const send = () => {
    setLoading(true);
    FileService.send(user.email, localDocuments)
        .then((u) => {
          onFinish(u);
          addToast(t('kyc-document.send-success'), {
            appearance: 'success', autoDismiss: true
          });
        })
        .catch(e => {
          if (e && e.error) {
            addToast(t(`errors.${e.error}`), {
              appearance: 'error', autoDismiss: true
            });
          }
        })
        .finally(() => setLoading(false));
  };

  const hideModal = () => {
    if (localDocuments && localDocuments.length > 0) {
      localDocuments.map((doc) => deleteDocument(doc));
    }
    onHide();
  };

  return (
      <Modal show={show} onHide={hideModal} size="lg">
        <Modal.Header closeButton className={css.close}>
          <Modal.Title>{modalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <FileUpload type={type}
                        user={user}
                        onFileUploaded={(file) => {
                          const newDocuments = Object.assign([], localDocuments);
                          newDocuments.push(file);
                          setLocalDocuments(newDocuments);
                        }} id="document_upload"/>

            {(localDocuments && localDocuments.length > 0) && (
                <ListGroup variant="flush">
                  {localDocuments.map((document) => {
                    return (
                        <ListGroup.Item>
                          {document.original_name}&nbsp;
                          <button className="btn close ml-2"
                                  onClick={() => deleteDocument(document)}>X
                          </button>
                        </ListGroup.Item>
                    )
                  })}
                </ListGroup>
            )}
          </Row>
          <Row className="text-right">
            <Col>
              <Button type="button" className="btn-primary" disabled={loading} onClick={send}>{t(
                  'file-upload.validate')}</Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
  );
};
export default withI18n()(UploadDocumentModal);
