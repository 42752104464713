/**
 * ProductsService
 * @module Services/domain/ProductsService
 * @description Loads products data from the server
 */

import HttpService from "../http/HttpService";
import LinksProvider from "../http/LinksProvider";
import { Products } from "../../models/Product";
import type { MinifiedPot } from "../../models/Pot";

const self = {};


/**
 * Gets product details from its reference
 * @author Seif Khiari
 * @alias get
 * @memberof module:Services/http/ProductsService
 * @param   {string} reference the product's reference
 * @returns {Promise<Products>}  A promise containing the found product
 */


self.get = (reference: string): Promise<Product> => {
    return HttpService
        .get(LinksProvider.formatFullUrl(LinksProvider.API.PRODUCTS.PRODUCT, { id: reference }))
        .then(data => data.product);
};

/**
 * Searches for product(s) with a specific query
 * @author Seif Khiari
 * @alias search
 * @memberof module:Services/http/ProductsService
 * @param   {string} query The search query
 * @returns {Promise<Products>}  A promise containing the search result
 */
self.search = (searchCriteria: any, limit: string, offset: string): Promise<Products> => {
    return HttpService
        .get(LinksProvider.formatFullUrl(LinksProvider.API.PRODUCTS.SEARCH, {
            ...searchCriteria,
            limit: limit,
            offset: offset
        }))
        .then(data => {
            return data
        })
        .catch(err => console.log("error: " + err));
};

/**
 * Create a Product with photos
 * @author Seif Khiari
 * @alias create
 * @memberof module:Services/http/ProductsService
 * @param   {any} data The prosuxt information
 * @returns {Promise<Product>}  A promise containing the created product
 */
self.create = (data: any): Promise<Product> => {
    return HttpService
        .post(LinksProvider.formatFullUrl(LinksProvider.API.PRODUCTS.CREATE), data)
        .then(data => data.product);
};

/**
 * Update a Product
 * @author Hassen Charef
 * @alias update
 * @memberof module:Services/http/ProductsService
 * @param   {string} reference The reference of the product
 * @param   {any} data The prosuxt information
 * @returns {Promise<Product>}  A promise containing the created product
 */
self.update = (reference: string, data: any): Promise<Product> => {
    return HttpService
        .put(LinksProvider.formatFullUrl(LinksProvider.API.PRODUCTS.UPDATE, { reference: reference }), data)
        .then(data => data.product);
};


/**
 * Get wedding list products info with admin privileges.
 * @author Antoine Sauvage
 * @alias get
 * @memberof module:Services/http/ProductsService
 * @param   {string} reference The reference of the List
 * @returns {Promise<Products[]>}  A promise containing the products from the list
 */
self.getListProducts = (reference): Promise<Products[]> => {
    return HttpService
        .get(LinksProvider.formatFullUrl(LinksProvider.API.PRODUCTS.LIST_PRODUCTS, { reference: reference }))
        .then(data => data.products);
};

/**
 * Get wedding list products participants info with admin privileges.
 * @author Seif Khiari
 * @alias getListProductsParticipants
 * @memberof module:Services/http/ProductsService
 * @param   {string} listReference The reference of the List
 * @param   {string} productReference The reference of the product
 * @returns {Promise<[]>}  A promise containing the products participants
 */
self.getListProductsParticipants = (listReference, productReference): Promise<[]> => {
    return HttpService
        .get(LinksProvider.formatFullUrl(LinksProvider.API.PRODUCTS.LIST_PRODUCTS_PARTICIPANTS, { reference: listReference, productReference: productReference }))
        .then(data => data.participants);
};

/**
 * Get wedding list product's participation items
 * @author Ghassen Manai
 * @alias getListProductsParticipationItems
 * @memberof module:Services/http/ProductsService
 * @param   {string} listReference The reference of the List
 * @param   {string} productReference The reference of the product
 * @returns {Promise<[]>}  A promise containing the product's participation items
 */
self.getListProductsParticipationItems = (listReference, productReference): Promise<[]> => {
    return HttpService
        .get(LinksProvider.formatFullUrl(LinksProvider.API.PRODUCTS.LIST_PRODUCTS_PARTICIPATION_ITEMS, { reference: listReference, productReference: productReference }))
        .then(data => data.participants);
};

/**
 * Get inspiration lists that product is attached to
 * @author Seif Khiari
 * @alias getAttachedInspirationLists
 * @memberof module:Services/http/ProductsService
 * @param   {string} reference The reference of the product
 * @returns {Promise<InspirationList[]>}  A promise containing the product's inspiration lists
 */
self.getAttachedInspirationLists = (reference): Promise<InspirationList[]> => {
    return HttpService
        .get(LinksProvider.formatFullUrl(LinksProvider.API.PRODUCTS.INSPIRATION_LISTS, { reference }))
        .then(data => data.lists);
};

/**
 * Searches for product(s) by type
 * @author Seif Khiari
 * @alias getInspirationListEligibleProductsByType
 * @memberof module:Services/http/ProductsService
 * @param   {string} type The product type
 * @returns {Promise<Products>}  A promise containing the search result
 */
self.getInspirationListEligibleProductsByType = (type: number): Promise<Products> => {
    return HttpService
        .get(LinksProvider.formatFullUrl(LinksProvider.API.PRODUCTS.INSPIRATION_LIST_ELIGIBLE_PRODUCTS_BY_TYPE, { type }))
        .then(data => {
            return data
        })
        .catch(err => console.log("error: " + err));
};

/**
 * Get List of Categories and SubCategories
 * @author Amine Elouaer
 * @alias getCategoriesAndSubCategories
 * @memberof module:Services/http/ProductsService
 * @returns {Promise<Products>}  A promise containing the search results
 */
self.getCategoriesAndSubCategories = () => {
    return HttpService
        .get(LinksProvider.API.PRODUCTS.CATEGORIES)
        .then((data) => data)
        .catch(error => console.log("error : " + error))
}


export default self;
