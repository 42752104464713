import * as React from "react";
import {useTranslation, withI18n} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome, faQuestion, faUser} from "@fortawesome/free-solid-svg-icons";

const DocumentType = ({t, type}) => {
  switch (type.toUpperCase()) {
    case 'ADDRESS' :
      return (<span className={'badge badge-md badge-secondary'}>
          <FontAwesomeIcon icon={faHome}/>
        &nbsp;&nbsp;
        {t(`kyc-document.type-${type.toLowerCase()}`)}
        </span>);
    case 'IDENTITY' :
      return (<span className={'badge badge-md badge-primary'}>
          <FontAwesomeIcon icon={faUser}/>
        &nbsp;&nbsp;
        {t(`kyc-document.type-${type.toLowerCase()}`)}
        </span>);
    default:
      return (<span className={'badge badge-md badge-dark'}>
          <FontAwesomeIcon icon={faQuestion}/>
        &nbsp;&nbsp;
        {t(`kyc-document.type-${type.toLowerCase()}`)}
        </span>);
  }
};

export default withI18n()(DocumentType);
