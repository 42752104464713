/**
 * HttpService
 * @module Services/http/HttpService
 * @name HttpService
 * @description Returns a HttpService object after configuring an axios instance
 */


import axios from "axios";
import RequestInterceptor from "./RequestInterceptor";
import ResponseHandler from "./ResponseHandler";

const client = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  timeout: 60000
});

client.interceptors.request.use(RequestInterceptor);
client.interceptors.response.use(ResponseHandler.handleSuccess, ResponseHandler.handleError);

export default client;