// @flow
import React, {Fragment, useEffect, useState} from "react";

import DateService from '../../../services/utils/DateService'
import {Button, Card, Loader, Table,} from "tabler-react";
import {withI18n} from 'react-i18next';
import EmptyContent from "../../common/empty-content/EmptyContent";
import UsersService from "../../../services/domain/UsersService";
import {Rib, IsRibDeletedForAppOrigin} from "../../../models/Rib";
import ConfirmModal from "../../modals/confirm-modal/ConfirmModal";
import ErrorHandler from "../../../services/error/ErrorHandler";
import {useToasts} from "react-toast-notifications";
import CreateRibModal from "../../modals/create-rib-modal/CreateRibModal";
import {TARGET_APP} from "../../../models/RequestInformation";
import StrongAuthService from "../../../services/utils/StrongAuthService";

function UserRibs({user, t}): React.Node {

  const [loading, setLoading] = useState(false);
  const [cngRibs, setCngRibs] = useState(null);
  const [m1lRibs, setM1lRibs] = useState(null);
  const [confirmationText, setConfirmationText] = useState("");
  const [ribAction, setRibAction] = useState(null);
  const [confirmationModalShown, setConfirmationModalShown] = useState(false);
  const [model, setModel] = useState(null);
  const [createModalShown, setCreateModalShown] = useState(false);
  const [modalAppOrigin, setModalAppOrigin] = useState(undefined);

  const {addToast} = useToasts();

  const headerItems = [
    {content: t('ribs.reference')},
    {content: t('ribs.owner_name')},
    {content: t('ribs.iban')},
    {content: t('ribs.bic')},
    {content: t('ribs.creation_date')},
    {content: t('ribs.delete')},
    {content: t('ribs.ban')}
  ];

  const loadData = () => {
    setLoading(true);
    UsersService.getRibs(user.email)
      .then(ribs => {
        setCngRibs(ribs.cng_ribs);
        setM1lRibs(ribs.m1l_ribs);
      })
      .finally(() => setLoading(false));
  };

  const banRib = () => {
    setLoading(true);
    const request = {reference: model.reference};
    return UsersService.banIban(request)
      .then(() => {
        loadData()
      })
      .catch(error => ErrorHandler.handleError(addToast, t, error))
      .finally(() => setLoading(false));
  };

  const deleteRib = (app_origin: string) => {
    setLoading(true);
    return UsersService.deleteIban(user.email, model.reference, app_origin)
      .then(() => {
        loadData()
      })
      .catch(error => ErrorHandler.handleError(addToast, t, error))
      .finally(() => setLoading(false));
  };

  const banAction = () => {
    setRibAction("ban");
    setConfirmationText(t('ribs.ban_confirm'));
    setConfirmationModalShown(true);
  };

  const deleteAction = (app_origin: string) => {
    setRibAction("delete");
    setConfirmationText(t('ribs.delete_confirm'));
    setModalAppOrigin(app_origin);
    setConfirmationModalShown(true);
  };

  const changeRibStatus = () => {
    if (ribAction === "ban") {
      return banRib().then((data) => {
        setConfirmationModalShown(false);
        return data;
      });
    } else if (ribAction === "delete") {
      return deleteRib(modalAppOrigin).then((data) => {
        setConfirmationModalShown(false);
        setModalAppOrigin(undefined);
        return data;
      });
    }
  };

  const oneRowWrapper = (app_origin: string) => {
    return (rib: Rib) => {
      return {
        key: rib.reference, item: [{
          //    {content: t('ribs.reference')},
          content: (<span>{rib.reference}</span>)
        }, {
          //    {content: t('ribs.owner_name')},
          content: (<span>{rib.owner_name}</span>)
        }, {//    {content: t('ribs.iban')},

          content: (<span>{rib.iban}</span>)
        }, //    {content: t('ribs.bic')},
          {
            content: (<span>{rib.bic}</span>)

          }, //    {content: t('ribs.creation_date')},
          {
            content: DateService.formatTime(rib.creation_date)
          }
          , //    {content: t('ribs.delete')},
          {
            content: (
              <div>
                {!IsRibDeletedForAppOrigin(rib, app_origin) && (
                  <Button color="danger" size="sm" onClick={() => {
                    setModel(rib);
                    deleteAction(app_origin);
                  }}>
                    {t('ribs.delete')}
                  </Button>)}
                {IsRibDeletedForAppOrigin(rib, app_origin) && (<span>{t('ribs.deleted')}</span>)}
              </div>
            )
          }
          , // {content: t('ribs.ban')},
          {
            content: (
              <div>
                {!rib.is_banned && (<Button color="danger" size="sm" onClick={() => {
                  setModel(rib);
                  banAction();
                }}>
                  {t('ribs.ban')}
                </Button>)}

                {rib.is_banned && (<span>{t('ribs.banned')}</span>)}
              </div>
            )
          }
          // {content: t('ribs.status')}
        ]
      };
    };
  };

  const ribsList = (ribs: Array<Rib>, app_origin: string = TARGET_APP.M1L) => {
    return (<Card>
      <Card.Header>
        <Card.Title>{app_origin === TARGET_APP.M1L ? t('users.m1l-ribs') : t('users.cng-ribs')}</Card.Title>
        <Card.Options>
          <Button size="sm" onClick={() => {
            setCreateModalShown(true);
            setModalAppOrigin(app_origin);
          }}
                  icon="plus" color="primary">
            {t('ribs.add_rib')}
          </Button>
        </Card.Options>
      </Card.Header>
      <Card.Body className="fixed-height-card-body">
        {((!ribs || !ribs.length) && !loading) ? (
          <EmptyContent text={app_origin === TARGET_APP.M1L ? t('users.no-ribs-m1l') : t('users.no-ribs-cng')}/>) : ''}
        {((ribs && ribs.length) && !loading) ? (<Table responsive
                                                       className="card-table"
                                                       headerItems={headerItems}
                                                       bodyItems={ribs.map(oneRowWrapper(app_origin))}/>) : ''}
        {loading && <Loader/>}
      </Card.Body>
    </Card>);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (<Fragment>
    <ConfirmModal
      confirmationText={confirmationText}
      onConfirm={changeRibStatus}
      show={confirmationModalShown}
      onHide={() => {
        setConfirmationModalShown(false);
        setModalAppOrigin(undefined);
      }}/>
    <CreateRibModal
      show={createModalShown}
      dialogClassName="modal-lg"
      email={user.email}
      successHandler={loadData}
      onHide={() => {
        setCreateModalShown(false);
        setModalAppOrigin(undefined);
      }}
      onError={(error) => ErrorHandler.handleError(addToast, t, error)}
      targetApp={modalAppOrigin}
    />
    {ribsList(cngRibs, TARGET_APP.CNT)}
    {ribsList(m1lRibs, TARGET_APP.M1L)}
  </Fragment>);
}

export default withI18n()(UserRibs);
