/**
 * LinksProvider
 * @module Services/http/LinksProvider
 * @description Offers methods to handle routes and API endpoints
 */

const self = {};

self.ROUTES = {
  LOGIN: '/login',

  HOME: '/',

  WELCOME: '/welcome',

  DASHBOARD: '/dashboard',

  USERS: '/users',

  USER: '/users/:id',

  POTS: '/pots',

  POT: '/pots/:id',

  PROMO_CODES: '/promo-codes',

  PROMO_CODE: '/promo-codes/:id',

  PARTICIPATIONS: '/participations',

  PARTICIPATION: '/participations/:id',

  PRODUCTS: '/products',

  PRODUCT: '/products/:id',

  COMMERCIAL_BANNERS: '/commercial-banners',

  KYC: '/kyc',

  REPORTS: '/reports',

  PUBLICATIONS: '/publications',

  INSPIRATION_LISTS: '/inspiration-lists',

  INSPIRATION_LIST: '/inspiration-lists/:id',

  COMMERCIAL_PARTNERS: '/partners',

};

self.API = {
  AUTH: {
    GET_AUTH_TOKEN: '/admin/auth/signin',
    AUTHORIZATIONS: '/admin/auth/authorizations'
  },
  USERS: {
    USER: '/admin/users/:search',
    USER_SPOUSE: '/admin/users/:email/spouse',
    USER_SPOUSE_USER_ACTION: '/admin/users/:email/add-spouse-user-action',
    OWNED_POTS: '/admin/users/:email/owned-pots',
    INVITED_POTS: '/admin/users/:email/invited-pots',
    OFFERED_POTS: '/admin/users/:email/offered-pots',
    OWNED_WEDDING_LISTS: '/admin/users/:email/owned-wedding-lists',
    PARTICIPATIONS: '/admin/users/:email/participations',
    CASH_OUTS: '/admin/users/:email/cash-outs',
    GIFT_CARDS: '/admin/users/:id/gift-cards',
    CLOSE_ACCOUNT: '/admin/users/:email/close-account',
    ACCOUNT_MOVEMENT: '/admin/users/:email/account-movement',
    RIBS: {
      GET: '/admin/users/:email/ribs',
      CREATE: '/admin/users/:email/add-rib',
      BAN: '/admin/users/ban-rib',
      DELETE: '/admin/users/:email/delete-rib/:reference/:target_app'
    },
    USER_CREDIT_CARDS: '/admin/users/:email/credit-cards',
    SPONSORSHIP: '/admin/users/:email/sponsorship',
    USER_KYC: '/admin/users/:email/kyc',
    REJECT_USER: '/admin/users/:email/reject',
    QR_CODE: '/admin/users/qrcode',
    STRONG_AUTH: '/admin/users/:email/strong-auth-data'
  },
  POTS: {
    SEARCH: '/admin/pots?search=:search',
    POT: '/admin/pots/:id',
    REFUNDS: '/admin/pots/:id/refunds',
    PARTICIPATIONS: '/admin/pots/:id/participations',
    GIFT_CARDS: '/admin/pots/:id/gift-cards',
    CASH_OUTS: '/admin/pots/:id/cash-outs'
  },
  PROMO_CODES: {
    SEARCH: '/admin/promo-codes?search=:search',
    PROMO_CODE: '/admin/promo-codes/:id',
    ALL: '/admin/promo-codes/all',
    PROMO_CODES: '/admin/promo-codes',
    BANNER: '/admin/promo-codes/:id/add-as-banner',
    UNMARK_BANNER: '/admin/promo-codes/:id/unmark-as-banner',
  },
  PARTICIPATIONS: {
    SEARCH: '/admin/participations?search=:search',
    PARTICIPATION: '/admin/participations/:id',
    REFUNDS: '/admin/participations/:id/refunds'
  },
  REQUEST_INFORMATION: {
    REQUEST_INFORMATION: '/admin/request-information/:uuid'
  },
  PRODUCTS: {
    LIST_PRODUCTS: '/admin/lists/:reference/products',
    LIST_PRODUCTS_PARTICIPANTS: '/admin/lists/:reference/products/:productReference/participations',
    LIST_PRODUCTS_PARTICIPATION_ITEMS: '/admin/lists/:reference/products/:productReference/participations',
    PRODUCT: '/admin/products/:id',
    CREATE: '/admin/products',
    UPDATE: '/admin/products/:reference',
    UPLOAD_PHOTO: '/admin/products/tmp-photo',
    SEARCH: '/admin/products?search=:search&categories=:categories&sub_categories=:sub_categories&placement=:placement&type=:type&status=:status&min_price=:min_price&max_price=:max_price&validity_start_date=:validity_start_date&validity_end_date=:validity_end_date&creation_date_sort=:creation_date_sort&order=:order&limit=:limit&offset=:offset',
    INSPIRATION_LIST_ELIGIBLE_PRODUCTS_BY_TYPE: '/admin/products?included_in_inspiration_list=1&type=:type',
    INSPIRATION_LISTS: '/admin/products/:reference/inspiration-lists',
    CATEGORIES: '/admin/products/categories'
  },
  COMMERCIAL_BANNERS: {
    CREATE: '/admin/commercial-banners',
    UPLOAD_PHOTO: '/admin/commercial-banners/tmp-photo',
    FIND: '/admin/commercial-banners/all',
    HISTORY: '/admin/commercial-banners/history?sort=:sort',
    FIND_HISTORY: '/admin/commercial-banners/find-history?search=:search&type=:type',
    DISABLE: '/admin/commercial-banners/:id/disable'
  },
  GIFT_CARDS: {
    RESEND: '/admin/gift-cards/:id/resend',
    ACTIVATE: '/admin/gift-cards/:id/activate'
  },
  REPORT: {
    CREATED_POTS: '/admin/report/created-pots?aggregation=:aggregation&download=:download&origin=:origin',
    ACTIVATED_POTS: '/admin/report/activated-pots?aggregation=:aggregation&download=:download&origin=:origin',
    TOTAL_COLLECTED_AMOUNT: '/admin/report/total-collected-volume?aggregation=:aggregation&download=:download&origin=:origin',
    VOLUME_TRANSFORMED_IN_CC: '/admin/report/volume-transformed-in-cc?aggregation=:aggregation&download=:download&origin=:origin',
    TOTAL_GIFT_CARD_GENERATED_AMOUNT: '/admin/report/total-gift-card-generated-volume?aggregation=:aggregation&download=:download&origin=:origin',
    PARTICIPANTS_NUMBER: '/admin/report/participants-number?aggregation=:aggregation&download=:download&origin=:origin',
    GIFT_CARD_REPORT: '/admin/report/gift-card?start_date=:start_date&end_date=:end_date',
    PROMO_CODE_REPORT: '/admin/report/promo-code?start_date=:start_date&end_date=:end_date',
    CRE_REPORT: '/admin/cre?start_date=:start_date&end_date=:end_date',
    CRE_REPORT_M1L: '/admin/cre-m1l?start_date=:start_date&end_date=:end_date',
    CRE_REPORT_M1L_SEND_BY_EMAIL: '/admin/cre-m1l-by-email?start_date=:start_date&end_date=:end_date',
    CAPITAL: '/admin/report/working-capital',
  },
  CREDIT_CARDS: {
    TOGGLE_BLOCK: '/admin/credit-cards/:reference/toggle-block'
  },
  PUBLICATIONS: {
    ALL_PUBLICATIONS: '/admin/publications?limit=:limit&offset=:offset',
    PUBLICATIONS: '/admin/publications',
    PUBLICATION: '/admin/publications/:reference',
    UPLOAD_PHOTO: '/admin/publications/tmp-photo',
    PHOTO: '/admin/publications/:reference/photos/:rank'
  },
  INSPIRATION_LISTS: {
    ALL_LISTS: '/admin/inspiration-lists?limit=:limit&offset=:offset&type=:type',
    LISTS: '/admin/inspiration-lists',
    LIST: '/admin/inspiration-lists/:reference',
    ADD_PRODUCT: '/admin/inspiration-lists/:reference/add-product/:productReference',
    DELETE_PRODUCT: '/admin/inspiration-lists/:reference/delete-product/:productReference',
    UPDATE_PRODUCTS: '/admin/inspiration-lists/:reference/products',
  },
  COMMERCIAL_PARTNERS: {
    ALL: '/admin/commercial-partners?limit=:limit&offset=:offset',
    COMMERCIAL_PARTNERS: '/admin/commercial-partners',
    COMMERCIAL_PARTNER: '/admin/commercial-partners/:reference',
    UPLOAD_PHOTO: '/admin/commercial-partners/tmp-photo'
  },
  KYC: {
      REFUSED_DOCUMENTS: '/admin/kyc/refusal',
      DELETE: '/admin/kyc/:reference',
      USER_REFUSED_DOCUMENTS: '/admin/kyc/refusal/:email',
      USER_ALL_DOCUMENTS: '/admin/kyc/all/:email',
      REMEDIATION: '/admin/kyc/remediation',
  },
  FILE: {
    FILES: '/files',
    FILE: '/files/:file',
    SEND: '/admin/send/:email',
    RESEND: '/admin/files/:email/:reference?type=:type'
  }
};

self.FRONT = {
  POT: {
    HOME_PAGE: '/cagnotte/:reference'
  },
  LIST: {
    HOME_PAGE: '/liste/:reference',
    PUBLICATION: '/actualites/:reference'
  }
};

self.S_MONEY = {
  BACK_OFFICE_URL: process.env.REACT_APP_SMONEY_BASE_URL + '/Users/EditUser?appUserId=:id'
};

self.XPOLLENS = {
  BACK_OFFICE_URL: process.env.REACT_APP_XPOLLENS_BASE_URL + '/customers/:id/details'
};

/**
 * Gets a link after injecting its parameters
 * @author Sameh Bellez
 * @alias formatUrl
 * @memberof Services/http/LinksProvider
 * @param   {string} route The endpoint string
 * @param   {object} params The params list
 * @returns {string} The formatted endpoint url
 */
self.formatUrl = (route, params): string => {
  if (!params) {
    return route;
  }
  let result = route;
  for (let field in params) {
    if (params[field] || params[field] === 0) {
      result = result.replace(`:${field}`, encodeURIComponent(params[field]));
    } else {
      result = result.replace(`${field}=:${field}`, '');
      result = result.replace(`:${field}`, '');
    }
  }

  return result;
};


/**
 * Gets a full url link after injecting its parameters
 * @author Sameh Bellez
 * @alias formatFullUrl
 * @memberof Services/http/LinksProvider
 * @param   {string} route The endpoint string
 * @param   {object} params The params list
 * @returns {string} The formatted endpoint full url
 */
self.formatFullUrl = (route, params): string => {
  return process.env.REACT_APP_BACKEND_BASE_URL + self.formatUrl(route, params);
};

/**
 * Gets a full url link after injecting its parameters
 * @author Hassen Charef
 * @alias formatCagnotteFrontFullUrl
 * @memberof Services/http/LinksProvider
 * @param   {string} route The endpoint string
 * @param   {object} params The params list
 * @returns {string} The formatted endpoint full url
 */
self.formatCagnotteFrontFullUrl = (route, params): string => {
  return process.env.REACT_APP_CAGNOTTE_FRONTEND_URL + self.formatUrl(route, params);
};
/**
 * Gets a full url link after injecting its parameters
 * @author Hassen Charef
 * @alias formatListeFrontFullUrl
 * @memberof Services/http/LinksProvider
 * @param   {endpoint} The endpoint string
 * @param   {object} The params list
 * @returns {string} The formatted endpoint full url
 */
self.formatListeFrontFullUrl = (route, params): string => {
  return process.env.REACT_APP_LISTE_FRONTEND_URL + self.formatUrl(route, params);
};

export default self;
