import React, {useRef, useState} from "react";
import {Button, ButtonToolbar, Card, Col, Modal, Row} from "react-bootstrap";
import {withI18n} from 'react-i18next';
import css from './UserRejectionMessageModal.scss';
import {DocumentType} from "../../../models/VerificationDocument";
import KycService from "../../../services/domain/KycService";
import {useToasts} from "react-toast-notifications";

const UserRejectionMessageModal = ({t, user, onHide, onConfirm, show}) => {

  const idRef = useRef();
  const [loading, setLoading] = useState(false);
  const {addToast} = useToasts();

  const appendMessage = (key, type) => {
    if (type == DocumentType.IDENTITY && idRef && idRef.current) {
      idRef.current.value = idRef.current.value + t('kyc-document.' + key + '-text') + '\n';
    }
  };

  const sendMessage = () => {
    const request = {
      rejection_messages: {}
    };

    const idValue = idRef && idRef.current ? idRef.current.value : null;

    if (!idValue) {
      return;
    }

    request.rejection_messages[DocumentType.IDENTITY] = idValue;

    setLoading(true);
    KycService.reject(user.email, request)
        .then((user) => {
          (idRef && idRef.current) && (idRef.current.value = '');
          onConfirm(user);
          addToast(t(`kyc-user.email-sent`), {
            appearance: 'success', autoDismiss: true
          });

        })
        .catch(e => {
          addToast(t(`errors.global.${e.error}`), {
            appearance: 'error', autoDismiss: true
          });
        })
        .finally(() => setLoading(false));

  };

  return (
      <React.Fragment>
        <Modal show={show} onHide={onHide} size="lg" className="user-rejection__modal">
          <Modal.Header closeButton className={css.close}>
            <Modal.Title>{t('kyc-user.rejection-message-modal-title')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card className="card-profile mb-3">
              <Card.Header className="h-25">{t('kyc-document.identity-documents')}</Card.Header>
              <Card.Body className="text-center pt-1">
                <ButtonToolbar className="mb-4">
                  <Button variant="light" className="ml-3 mr-3 mt-3"
                          onClick={() => appendMessage('missing-backside',
                              DocumentType.IDENTITY)}> + {t(
                      'kyc-document.missing-backside')}</Button>
                  <Button variant="light" className="ml-3 mr-3 mt-3"
                          onClick={() => appendMessage('unreadable-mrz',
                              DocumentType.IDENTITY)}> + {t(
                      'kyc-document.unreadable-mrz')}</Button>
                  <Button variant="light" className="ml-3 mr-3 mt-3"
                          onClick={() => appendMessage('expired-cni', DocumentType.IDENTITY)}> + {t(
                      'kyc-document.expired-cni')}</Button>
                  <Button variant="light" className="ml-3 mr-3 mt-3"
                          onClick={() => appendMessage('unreadable-cni',
                              DocumentType.IDENTITY)}> + {t(
                      'kyc-document.unreadable-cni')}</Button>
                </ButtonToolbar>
                <div className={'form-group'}>
                  <textarea
                      rows={5}
                      ref={idRef}
                      className={'form-control'}></textarea>
                </div>
              </Card.Body>
            </Card>
            <Row>
              <Col sm={12} className="text-center">
                <Button className="w-50"
                        variant="outline-danger"
                        disabled={loading}
                        onClick={sendMessage}
                >{t('kyc-document.send-message')}</Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </React.Fragment>
  );
};
export default withI18n()(UserRejectionMessageModal);
