// @flow
import * as React from "react";
import SiteWrapper from "../../SiteWrapper";
import {Page, Loader} from "tabler-react";
import {withI18n} from "react-i18next";
import SearchForm from "../../components/common/search-form/SearchForm";
import LinksProvider from "../../services/http/LinksProvider";
import {useEffect, useState} from "react";
import ParticipationsService from "../../services/domain/ParticipationsService";
import EmptyContent from "../../components/common/empty-content/EmptyContent";
import ParticipationsList from "../../components/participations/participations-list/ParticipationsList";
import ParticipationInfo from "../../components/participations/participation-info/ParticipationInfo";


function ParticipationDetailsPage({t, match}): React.Node {
  const [participations, setParticipations] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = `${t('navbar.participations')} : ${decodeURIComponent(match.params.id)}`;
    loadData();
  }, [t, match.params.id]);

  const loadData = () => {
    setLoading(true);
    ParticipationsService.search(match.params.id)
      .then(participations => setParticipations(participations))
      .finally(() => setLoading(false));
  };


  return (
    <SiteWrapper>
      <Page.Content>
        <Page.Header
          title={t('navbar.participations')}
          options={(<SearchForm value={match.params.id} placeholder={t('participations.search-placeholder')}
                                route={LinksProvider.ROUTES.PARTICIPATION}/>)}/>

        <Page.Main>

          {loading && <Loader/>}

          {(!loading && participations && participations.length == 1) && (
            <ParticipationInfo reference={participations[0].reference}/>
          )}

          {(!loading && participations && participations.length > 1) && (
            <ParticipationsList participations={participations}/>
          )}

          {(!loading && (!participations || !participations.length)) && (
            <EmptyContent text={t('participations.no-results')}/>
          )}
        </Page.Main>
      </Page.Content>
    </SiteWrapper>
  );
}

export default withI18n()(ParticipationDetailsPage);
