import * as React from "react";
import {Col, Modal, Row} from "react-bootstrap";
import {withI18n} from 'react-i18next';
import UserInfo from "../../kyc/user/user-info/UserInfo";
import DocumentPreview from "../../kyc/document/document-preview/DocumentPreview";
import css from './ShowDocumentPreviewModal.scss';

const ShowDocumentPreviewModal = ({t, user, document, onHide, show, onUserUpdated}) => {

  return (
      <Modal show={show} onHide={onHide} size="lg" className="show-document__modal">
        <Modal.Header closeButton className={css.close}>
          <Modal.Title>{t('kyc-document.document-preview-title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={4}>
              <UserInfo onUpdate={(user) => {
                onUserUpdated(user);
              }} user={user}></UserInfo>
            </Col>
            <Col sm={8} className="mh-100">
              <DocumentPreview document={document}></DocumentPreview>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
  );
};
export default withI18n()(ShowDocumentPreviewModal);
