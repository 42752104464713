import React from "react";


import DateService from '../../../services/utils/DateService'
import {Badge, Card, Icon, Table} from "tabler-react";
import {withI18n} from 'react-i18next';
import Amount from "../../common/amount/Amount";
import {ParticipationStatus} from "../../../models/Participation";
import LinksProvider from "../../../services/http/LinksProvider";
import StrongAuthService from "../../../services/utils/StrongAuthService";

const ParticipationsList = ({participations, t}) => {

  const headerItems = [
    {content : t('participations.reference')},
    {content : t('participations.amount')},
    {content : t('participations.status')},
    {content : t('participations.treatment_date')},
    {content: t('participations.card-info-country')},
    {content: t('participations.request-information-country')},
    {content : t('participations.require_3ds')}
  ];

  const participationStatusBadge = (participations) => {
    switch (participations.status) {
      case ParticipationStatus.NOT_PAYED :
        return (<Badge color="danger">{t('participations.not-payed')}</Badge>);
      case ParticipationStatus.BANK_ERROR :
        return (
            <Badge color="danger">
              {participations.card_error_code ?
                  t(`participations.error-code-${participations.card_error_code}`) : t('participations.bank-error')}
            </Badge>
        );
      case ParticipationStatus.BANK_OK :
        return (<Badge color="success">{t('participations.bank-ok')}</Badge>);
      case ParticipationStatus.REFUNDED :
        return (<Badge color="success">{t('participations.bank-refunded')}</Badge>);
      case ParticipationStatus.REFUNDED_AFTER_3DS_FAIL :
        return (<Badge color="success">{t('participations.bank-refunded-3ds')}</Badge>)
    }
  };

  const itemRow = (participations) => {
    return {
      key : participations.reference,
      item : [
        {content : (<a href={LinksProvider.formatUrl(LinksProvider.ROUTES.PARTICIPATION, {id : participations.reference})}>{participations.reference}</a>)},
        {content : <Amount amount={participations.amount}></Amount>},
        {content : participationStatusBadge(participations)},
        {content : DateService.formatTime(participations.treatment_date)},
        {content: participations.card_country ? (<Icon prefix="flag" name={participations.card_country.toLowerCase()}/>) : '-'},
        {content: participations.ri_country ? (<Icon prefix="flag" name={participations.ri_country.toLowerCase()}/>) : t('request-information.unknown')},
        {content : (<span>{participations.require_3ds ? t('globals.yes') : t('globals.no')}</span>)}
      ]
    };
  };


  return (
    <Card title={t('participations.list-participations')}>
      <Card.Body>
        <Table responsive
               className="card-table"
               headerItems={headerItems}
               bodyItems={participations.map(itemRow)}/>
      </Card.Body>
    </Card>
  )
};

export default withI18n()(ParticipationsList);
