// @flow
import * as React from "react";
import {useEffect} from "react";
import SiteWrapper from "../../SiteWrapper";
import {Page} from "tabler-react";
import {withI18n} from 'react-i18next';
import KycWidget from "../../components/kyc/KycWidget";

function KycPage({t, users}): React.Node {

  useEffect(() => {
    document.title = t('navbar.kyc')
  }, [t]);

  return (<SiteWrapper>
    <Page.Content>
      <Page.Header
          title={t('navbar.kyc')}/>

      <KycWidget url={`${process.env.REACT_APP_KYC_BO_URL}/kyc`}/>
    </Page.Content>
  </SiteWrapper>);
}

export default withI18n()(KycPage);
