import React from "react"
import {Input} from "reactstrap";

export default class Number extends React.Component {
  constructor(props){
    super(props)
  }
  render(){
    return (
      <React.Fragment>
        <Input invalid={!!this.props.validationText}
               value={this.props.value? this.props.value : ""}
               onChange={e => this.props.setNewValue(e.target.value)}
               type="number"
               size="sm"
               step="0.01"
               className="mr-1"
               min="0"
        />
        {this.props.controls}
      </React.Fragment>
    )
  }
}