import React, {useState, useEffect} from "react";
import {
  Badge, Card, Dimmer, Form, Grid, Icon, Loader, Button
} from "tabler-react";
import Editable from "../../common/editable/Editable";
import {withI18n} from "react-i18next";
import ParticipationsService from "../../../services/domain/ParticipationsService";
import LinksProvider from "../../../services/http/LinksProvider";
import Amount from "../../common/amount/Amount";
import type {MinifiedParticipation} from "../../../models/Participation";
import {CardError, ParticipationStatus, CardExecCode} from "../../../models/Participation";
import RequestInformation from "../../common/request-information/RequestInformation";
import RefundOneParticipationModal
  from "../../modals/refund-one-participation-modal/RefundOneParticipationModal";
import DateService from '../../../services/utils/DateService';
import StrongAuthService from "../../../services/utils/StrongAuthService";
import {PotCategory} from "../../../models/Pot";

const ParticipationInfo = ({t, reference}) => {

  const [model, setModel] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingParticipation, setLoadingParticipation] = useState(false);
  const [refundModalShown, setRefundModalShown] = useState(false);

  useEffect(() => loadParticipation(), []);

  const loadParticipation = () => {
    setLoadingParticipation(true);
    ParticipationsService.get(reference)
      .then(participation => setModel(participation))
      .finally(() => setLoadingParticipation(false));
  };

  const updateParticipation = (request) => {
    setLoading(true);
    return ParticipationsService.update(model.reference, request)
      .then(async participation => {
        await loadParticipation();
      })
      .finally(() => setLoading(false));
  };

  const editParticipationInfo = (field) => {
    return (val) => {
      const request = {};
      if (field.indexOf('.') != -1) {
        const array = field.split('.');
        model[array[0]][array[1]] = val;
        request[array[1]] = val;
      } else {
        model[field] = val;
        request[field] = val;
      }
      setModel({...model});
      updateParticipation(request);
    };
  };

  const participationStatusBadge = (participation: MinifiedParticipation) => {
    switch (model.status) {
      case ParticipationStatus.NOT_PAYED :
        return (<Badge color="danger">{t('participations.not-payed')}</Badge>);
      case ParticipationStatus.BANK_ERROR:
        return (<Badge color="danger">{t('participations.bank-error')}</Badge>);
      case ParticipationStatus.REFUNDED :
        return (<Badge color="warning">{t('participations.refunded')}</Badge>);
      case ParticipationStatus.REFUNDED_AFTER_3DS_FAIL :
        return (<Badge color="warning">{t('participations.refunded-after-3ds-fail')}</Badge>);
      case ParticipationStatus.BANK_OK :
        return (<Badge color="success">{t('participations.bank-ok')}</Badge>);
    }
  };

  const cardCodeError = (error: number) => {
    if (error === CardError.NO_ERROR) {
      return (<Badge color="success">{t('participations.error-code-0')}</Badge>);
    } else if (error === CardError.UNKNOWN) {
      return (<Badge color="waring">{t(`participations.error-code-99`)}</Badge>);
    } else {
      return (<Badge color="danger">{t(`participations.error-code-${error}`)}</Badge>);
    }
  };

  const cardCodeExecError = (error: string) => {
    switch (error) {
      case undefined:
      case null:
        return (<Badge color="danger">{t(`participations.exec-code-message-error-unknown`)}</Badge>);
      case CardExecCode.SUCCESSFUL:
      case CardExecCode.THREE_DS_REQUIRED:
      case CardExecCode.REDIRECT_TRANSACTION:
      case CardExecCode.PENDING_TRANSACTION:
      case CardExecCode.PARTIALLY_ACCEPTED:
        return (<Badge color="success">{t('participations.exec-code-message-unknown')}</Badge>);

      case CardExecCode.TRANSACTION_DECLINED_BY_BANKING_NETWORK:
      case CardExecCode.CARD_DECLINED_BY_BANKING_NETWORK:
      case CardExecCode.FRAUD_SUSPICION:
      case CardExecCode.INVALID_TRANSACTION:
      case CardExecCode.DUPLICATED_TRANSACTION:
      case CardExecCode.INVALID_CARD_DATA:
      case CardExecCode.TRANSACTION_DECLINED_BY_BANKING_NETWORK_HOLDER:
      case CardExecCode.EXPIRED_TRANSACTION:
      case CardExecCode.TRANSACTION_DECLINED_PAYMENT_TERMINAL:
      case CardExecCode.RECURRING_PAYMENT:
      case CardExecCode.BANK_WILL_DECLINE_TRANSACTIONS:
        return (<Badge color="danger">{t(`participations.exec-code-message-bank-auth-unknown`)}</Badge>);

      case CardExecCode.INSUFFICIENT_FUNDS:
        return (<Badge color="danger">{t(`participations.exec-code-message-insufficient-balance`)}</Badge>);

      case CardExecCode.THREE_DS_FAILED:
      case CardExecCode.THREE_DS_EXPIRED:
        return (<Badge color="danger">{t(`participations.exec-code-message-3ds-failed`)}</Badge>);

      case CardExecCode.CARD_NOT_ENROLLED_THREE_DS:
      case CardExecCode.THREE_DS_UNAVAILABLE:
        return (<Badge color="danger">{t(`participations.exec-code-message-3ds-unsupported`)}</Badge>);

      case CardExecCode.OPERATION_NOT_ALLOWED:
        return (<Badge color="danger">{t(`participations.exec-code-message-card-not-supported`)}</Badge>);

      case CardExecCode.EXCHANGE_PROTOCOL_FAILURE:
      case CardExecCode.BANKING_NETWORK_ERROR:
      case CardExecCode.TIME_OUT:
      case CardExecCode.THREE_DS_DISPLAY_ERROR:
      case CardExecCode.UNEXPECTED_RESPONSE:
        return (<Badge color="danger">{t(`participations.exec-code-message-error-temporary`)}</Badge>);

      case CardExecCode.DECLINED_BY_MERCHANT:
      case CardExecCode.TRANSITION_DECLINED:
      case CardExecCode.CARD_HOLDER_DISPUTED:
      case CardExecCode.DECLINED_BY_PLATFORM:
      case CardExecCode.OPERATION_PROHIBITED:
        return (<Badge color="danger">{t(`participations.exec-code-message-card-country-not-supported`)}</Badge>);

      default:
        return (<Badge color="danger">{t(`participations.exec-code-message-error-unknown`)}</Badge>);
    }
  };

  const afterRefund = (participation) => {
    model.refund_date = participation.refund_date;
    model.status = participation.status;
    setModel({...model});
    setRefundModalShown(false);
  };

  return (

    <React.Fragment>
      {(model && model.status == ParticipationStatus.BANK_OK) && (
        <RefundOneParticipationModal
          reference={model.reference}
          show={refundModalShown}
          afterRefund={afterRefund}
          onHide={() => {
            setRefundModalShown(false);
            loadParticipation();
          }}/>)}


      {loadingParticipation && <Loader/>}
      {(model && !loadingParticipation) && (<Grid.Row cards deck>
          <Grid.Col width={12}>
            <Card>
              <Card.Header>
                <Card.Title>
                  {t('participations.general-info')}
                </Card.Title>
                <Card.Options>
                  {model.status == ParticipationStatus.BANK_OK && (<Button
                    type="button"
                    onClick={() => setRefundModalShown(true)}
                    size="sm"
                    color="danger">
                    {t('participations.refund')}
                  </Button>)}
                </Card.Options>
              </Card.Header>
              <Card.Body>
                <Dimmer active={loading} loader>
                  <Grid.Row>
                    <Grid.Col width={3}>
                      <Form.Group label={t('participations.amount')}>
                        <Amount amount={model.amount}></Amount>
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col width={3}>
                      <Form.Group label={t('participations.status')}>
                        {participationStatusBadge(model.status)}
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col width={3}>
                      <Form.Group label={t('participations.validation-date')}>
                        {DateService.formatTime(model.treatment_date)}
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col width={3}>
                      <Form.Group label={t('participations.require_3ds')}>
                        {model.require_3ds ? t('globals.yes') : t('globals.no')}
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col width={3}>
                      <Form.Group label={t('participations.hide-id')}>
                        <Editable
                          initialValue={model.hide_identity}
                          undefinedText={t('globals.no-value')}
                          isValueClickable={true}
                          onSubmit={editParticipationInfo("hide_identity")}
                          type="select" options={[{
                          label: t('globals.yes'), value: true
                        }, {label: t('globals.no'), value: false}]}/>
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col width={3}>
                      <Form.Group label={t('participations.hide-amount')}>
                        <Editable
                          initialValue={model.hide_amount}
                          isValueClickable={true}
                          undefinedText={t('globals.no-value')}
                          onSubmit={editParticipationInfo("hide_amount")}
                          type="select" options={[{
                          label: t('globals.yes'), value: true
                        }, {label: t('globals.no'), value: false}]}/>
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col width={3}>
                      <Form.Group label={t('participations.refund-date')}>
                        {DateService.formatTime(model.refund_date)}
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col width={3}>
                      <Form.Group label={model.pot.category === PotCategory.WEDDING_LIST ? t('participations.wedding-list-reference') : t('participations.pot-reference')}>
                        <a
                          href={LinksProvider.formatUrl(LinksProvider.ROUTES.POT, {id: model.pot.reference})}> {model.pot.reference}</a>
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>
                </Dimmer>
              </Card.Body>
            </Card>
          </Grid.Col>
          {model.credit_card_payment_info && (<Grid.Col>
            <Card title={t('participations.card-info')}>
              <Card.Body>
                <Grid.Row>
                  <Grid.Col width={6}>
                    <Form.Group label={t('participations.card-number')}>
                      {model.credit_card_payment_info.card_number || '-'}
                      {model.credit_card_payment_info.card_number && (
                        <i className={`ml-1 payment payment-${model.credit_card_payment_info.card_brand
                        === 'cb' ? 'visa' : model.credit_card_payment_info.card_brand}`}></i>
                      )}
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col width={6}>
                    <Form.Group label={t('participations.card-info-country')}>
                      {model.credit_card_payment_info.card_country ? (<Icon prefix="flag"
                                                                            name={model.credit_card_payment_info.card_country.toLowerCase()}/>) : '-'}
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col width={6}>
                    <Form.Group label={t('participations.card-info-expiration-date')}>
                      {DateService.formatDate(model.credit_card_payment_info.card_expiration_date)}
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col width={6}>
                    <Form.Group label={t('participations.card-info-error')}>
                      {cardCodeError(model.credit_card_payment_info.error_code)}
                    </Form.Group>
                  </Grid.Col>
                  {model.credit_card_payment_info.error_code ?
                    <Grid.Col width={12}>
                      <Form.Group label={t('participations.card-info-error-message')}>
                        {cardCodeExecError(model.credit_card_payment_info.exec_code)}
                      </Form.Group>
                    </Grid.Col>
                    : null
                  }
                </Grid.Row>
              </Card.Body>
            </Card>
          </Grid.Col>)}
          <Grid.Col>
            <Card title={t('participations.participant')}>
              <Card.Body>
                <Dimmer active={loading} loader>
                  <Grid.Row>
                    <Grid.Col width={6}>
                      <Form.Group label={t('participations.participant-firstname')}>
                        <Editable
                          initialValue={model.first_name}
                          isValueClickable={true}
                          undefinedText={t('globals.no-value')}
                          onSubmit={editParticipationInfo('first_name')}
                          type="textfield"/>
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col width={6}>
                      <Form.Group label={t('participations.participant-lastname')}>
                        <Editable
                          initialValue={model.last_name}
                          undefinedText={t('globals.no-value')}
                          isValueClickable={true}
                          onSubmit={editParticipationInfo('last_name')}
                          type="textfield"/>
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col width={12}>
                      <Form.Group label={t('participations.participant-email')}>
                        <a
                          href={LinksProvider.formatUrl(LinksProvider.ROUTES.USER, {id: model.payer.email})}>{model.payer.email}</a>
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>
                </Dimmer>
              </Card.Body>
            </Card>
          </Grid.Col>
          {model.request_information && (<Grid.Col width={12}>
            <Card title={t('participations.session')}>
              <Card.Body>
                <RequestInformation id={model.request_information.uuid}/>
              </Card.Body>
            </Card>
          </Grid.Col>)}
        </Grid.Row>

      )}
    </React.Fragment>);
};

export default withI18n()(ParticipationInfo);
