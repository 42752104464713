// @flow
import * as React from "react";
import SiteWrapper from "../../SiteWrapper";
import {Loader, Page} from "tabler-react";
import {withI18n} from "react-i18next";
import {useEffect, useState} from "react";
import PromoCodesService from "../../services/domain/PromoCodesService";
import PromoCodeInfo from "../../components/promo-codes/promo-code-info/PromoCodeInfo";

function PromoCodesDetailsPage({t, match}): React.Node {

    const [promoCode, setPromoCode] = useState(null);
    const [loadingCode, setLoadingCode] = useState(false);

    useEffect(() => {
        document.title = `${t('navbar.promo-code')} : ${match.params.id}`;
        loadData();
    }, [t, match.params.id]);

    const loadData = () => {
        setLoadingCode(true);
        return PromoCodesService.get(match.params.id)
            .then(promoCode => setPromoCode(promoCode))
            .finally(() => setLoadingCode(false))
    };

    return (
        <SiteWrapper>
            <Page.Content>
                <Page.Header
                    title={t('navbar.promo-code')}
                />
                <Page.Main>

                    {loadingCode && <Loader/>}

                    {(!loadingCode && promoCode) && (
                        <PromoCodeInfo promoCode={promoCode} />
                    )}
                </Page.Main>
            </Page.Content>
        </SiteWrapper>
    );
}

export default withI18n()(PromoCodesDetailsPage);
