import React, {Fragment} from "react";
import {Card, Loader, Page, Table, Tag, Badge, Grid} from "tabler-react";
import {Image, Button, ButtonToolbar, Figure} from "react-bootstrap";
import {withI18n} from 'react-i18next';
import DateService from "../../../services/utils/DateService";
import {useEffect, useState} from "react";
import TablePagination from "../../common/table-pagination.js/TablePagination";
import PublicationsService from "../../../services/domain/PublicationsService";
import ErrorHandler from "../../../services/error/ErrorHandler";
import {useToasts} from "react-toast-notifications";
import LinksProvider from "../../../services/http/LinksProvider";
import ConfirmModal from "../../modals/confirm-modal/ConfirmModal";
import {PublicationStatus} from "../../../models/Publication";

const PublicationsList = ({publications, searchQueryCount, t}) => {

  const {addToast} = useToasts();

  const publicationsPerPage = 8;
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [listPublications, setListPublications] = useState(publications);
  const [queryCount, setQueryCount] = useState(searchQueryCount);
  const [pageCount, setPageCount] = useState(Math.ceil(queryCount / publicationsPerPage));
  const [confirmationModalShown, setConfirmationModalShown] = useState(false);
  const [toDeleteReference, setToDeleteReference] = useState(null);

  const handlePageClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
    loadData(publicationsPerPage, index * publicationsPerPage)
  };

  const loadData = (limit, offset) => {
    setLoading(true);
    PublicationsService.getAll(limit, offset)
      .then(data => {
        setListPublications(data.publications);
        setQueryCount(data.original_result_count);
        setPageCount(Math.ceil(queryCount / publicationsPerPage));
      })
      .finally(() => setLoading(false));
  };

  const handleDelete = () => {
    if (toDeleteReference) {
      setLoading(true);
      return PublicationsService.delete(toDeleteReference)
        .then(data => {
          window.location = LinksProvider.formatUrl(LinksProvider.ROUTES.PUBLICATIONS);
        })
        .catch(error => ErrorHandler.handleError(addToast, t, error))
        .finally(() => {
          setLoading(false);
          setToDeleteReference(null);
        });
    }
  };

  const headerItems = [
    {content: t('publications.preview-photo')},
    {content: t('publications.title')},
    {content: t('publications.update-date')},
    {content: t('publications.creation-date')},
    {content: t('publications.status')},
    {content: null}
  ];

  const itemRow = (publication) => {
    return {
      key: publication.reference,
      item: [
        {
          content: (
            <span>
              {(publication.first_photo) ?
                (
                  <Figure>
                    <Figure.Image
                      alt={t('publications.preview-photo')}
                      width={171}
                      height={180}
                      src={publication.first_photo}/>
                  </Figure>
                ) : (
                  <div>&emsp;N/A</div>
                )}
            </span>)
        },
        {content: (<span>{publication.title}</span>)},
        {content: (<span>{DateService.formatDate(publication.update_date)}</span>)},
        {content: (<span>{DateService.formatDate(publication.creation_date)}</span>)},
        {
          content: (<span><Tag
            color={publication.status === 0 ? 'orange' : 'green'}>&emsp;</Tag></span>)
        },
        {
          content: (
            <Grid.Row className="flex-column">
              {confirmationModalShown && (<ConfirmModal
                confirmationText={t('publications.delete-sure')}
                onConfirm={() => {
                  setConfirmationModalShown(false);
                  handleDelete();
                }}
                show={confirmationModalShown}
                onHide={() => {
                  setConfirmationModalShown(false);
                  setToDeleteReference(null)
                }}
              />)}
              {publication.status === PublicationStatus.INACTIVE && (
                <Button className="mt-1" variant="primary" onClick={() => {window.open(LinksProvider.formatListeFrontFullUrl(LinksProvider.FRONT.LIST.PUBLICATION, {reference: publication.reference}), '_blank')}}>
                  {t('publications.preview')}
                </Button>
              )}
              <Button className="mt-1" variant="primary" onClick={() => {window.location = LinksProvider.formatUrl(LinksProvider.ROUTES.PUBLICATIONS) + '?reference=' + publication.reference}}>
                {t('publications.modify')}
              </Button>
              <Button className="mt-1" variant="danger" onClick={()=> {setToDeleteReference(publication.reference); setConfirmationModalShown(true);}}>
                {t('publications.delete')}
              </Button>
            </Grid.Row>)
        }
      ]
    };
  };


  return (
    <Fragment>
      <Card title={t('publications.list-publications', {count: queryCount})}>
        {loading && <Loader/>}

        {(!loading) && (
          <Card.Body>
            <Table responsive
                   className="card-table"
                   headerItems={headerItems}
                   verticalAlign="center"
                   bodyItems={listPublications.map(itemRow)}/>
          </Card.Body>
        )}
        <Card.Footer>
          <TablePagination
            pageCount={pageCount}
            currentPage={currentPage}
            handlePageClick={handlePageClick}
          />
        </Card.Footer>
      </Card>
    </Fragment>

  )
};

export default withI18n()(PublicationsList);
