import React, {Fragment} from "react";
import {Grid, Form} from "tabler-react";
import {Image, Button, ButtonToolbar, Figure} from "react-bootstrap";
import {Input} from 'reactstrap';
import {withI18n} from 'react-i18next';
import {useEffect, useState} from "react";
import DropZone from "../../common/dropzone/DropZone";
import LinksProvider from "../../../services/http/LinksProvider";
import PublicationsService from "../../../services/domain/PublicationsService";
import ErrorHandler from "../../../services/error/ErrorHandler";
import {useToasts} from "react-toast-notifications";

const PublicationUpdatePhoto = ({publicationReference, url, rank, external_url, t}) => {

  const {addToast} = useToasts();

  const [photoUrl, setPhotoUrl] = useState(url);
  const [externalUrl, setExternalUrl] = useState(external_url || '');
  const [loading, setLoading] = useState(false);
  const [displayDropzone, setDisplayDropZone] = useState(false);
  const [uploadedPhotoUrl, setUploadedPhotoUrl] = useState(null);

  const componentConfig = {
    iconFiletypes: [".jpg", ".png", ".jpeg"],
    showFiletypeIcon: true,
    postUrl: (process.env.REACT_APP_BACKEND_BASE_URL || 'http://localhost:1337') + LinksProvider.API.PUBLICATIONS.UPLOAD_PHOTO
  };

  const dzConfig = {
    eventHandlers: (setUrl, removeUrl) => {
      return {
        success: (file, response) => {
          if (response && response.data && response.data.image) {
            setUrl(response.data.image.url);
          }
        },
        removedfile: (file) => {
          removeUrl();
        }
      }
    },
    djsConfig: {
      maxFilesize: 10,
      maxFiles: 1,
      dictDefaultMessage: t('publications.upload-photo'),
      dictRemoveFile: t('publications.remove-photo'),
      addRemoveLinks: true,
      acceptedFiles: "image/jpeg,image/jpg,image/png",
      maxfilesexceeded: function (file) {
        this.removeAllFiles();
        this.addFile(file);
      },
      sending: function (file, xhr, formData) {
        formData.append("image", file);
      },
      canceled: function (file) {
        console.log("delete photo")
      }
    }
  };

  const handleUpdatePhotoUrl = () => {
    //updatePhotoUrl
    if (externalUrl && externalUrl !== external_url && !!photoUrl) {
      const values = {
        external_url : externalUrl ? externalUrl : undefined,
      };
      setLoading(true);
      PublicationsService.updatePhoto(publicationReference, "" + rank, values)
        .then(photo => {
          console.log("photo update");
          window.location = LinksProvider.formatUrl(LinksProvider.ROUTES.PUBLICATIONS) + '?reference=' + publicationReference
        })
        .catch(error => {
          console.log("error : ", JSON.stringify(error));
          ErrorHandler.handleError(addToast, t, error)
        })
        .finally(() => setLoading(false));
    }
  };

  const handleUpdatePhoto = (is_deleted) => {
    //updatePhoto
    if (uploadedPhotoUrl || is_deleted) {
      const values = {
        storage_service_url : uploadedPhotoUrl ? uploadedPhotoUrl : undefined,
        is_deleted: is_deleted ? is_deleted : undefined
      };
      setLoading(true);
      PublicationsService.updatePhoto(publicationReference, "" + rank, values)
        .then(photo => {
          console.log("photo update");
          window.location = LinksProvider.formatUrl(LinksProvider.ROUTES.PUBLICATIONS) + '?reference=' + publicationReference
        })
        .catch(error => {
          console.log("error : ", JSON.stringify(error));
          ErrorHandler.handleError(addToast, t, error)
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Fragment>
      <p className="w-100 mt-3 mb-1 text-center">{t('publications.photo-' + rank)}</p>
      <Form onSubmit={(e) => {
        e.preventDefault();
        handleUpdatePhoto();
      }}>
        <Form.Group>
          {(photoUrl !== null) ? (
            <div>
              {(!displayDropzone) ? (<div>
                  <Grid.Row>
                    <Grid.Col width={12}>
                      <Figure>
                        <Figure.Image
                          alt={t('publications.photo-' + rank)}
                          width={300}
                          height={250}
                          src={photoUrl}/>
                      </Figure>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row className="mb-3">
                    <Grid.Col width={12} className="d-flex justify-content-around">
                      <Button variant="primary" onClick={(e) => {
                        setDisplayDropZone(true)
                      }}>{t('products.update')}</Button>
                      <Button variant="danger" onClick={(e) => {
                        handleUpdatePhoto(true)
                      }}>{t('publications.delete')}</Button>
                    </Grid.Col>
                  </Grid.Row>
                </div>) :
                (
                  <div>
                    <Grid.Row>
                      <Grid.Col width={12}>
                        <DropZone
                          componentConfig={componentConfig}
                          dropZoneConfig={dzConfig.djsConfig}
                          eventHandlers={dzConfig.eventHandlers(
                            url => {
                              setUploadedPhotoUrl(url);
                            },
                            () => {
                              setUploadedPhotoUrl(null);
                            }
                          )}
                        />
                        <br/>
                        <div className="d-flex justify-content-around">
                          <React.Fragment>
                            <Button color="primary" onClick={(e) => {
                              setDisplayDropZone(false)
                              setUploadedPhotoUrl(null)
                            }}>{t('publications.cancel')}</Button>
                          </React.Fragment>
                          {(!!uploadedPhotoUrl) ? (
                              <React.Fragment>
                                <Button disabled={loading} type="submit" color="success" icon="check">{t('publications.validate')}</Button>
                              </React.Fragment>
                          ) : null}
                        </div>
                      </Grid.Col>
                    </Grid.Row>
                  </div>
                )}
            </div>
          ) : (
            <div>
              <Grid.Row>
                <Grid.Col width={12}>
                  <DropZone
                    componentConfig={componentConfig}
                    dropZoneConfig={dzConfig.djsConfig}
                    eventHandlers={dzConfig.eventHandlers(
                      url => {
                        setUploadedPhotoUrl(url);
                      },
                      () => {
                        setUploadedPhotoUrl(null);
                      }
                    )}
                  />
                  <br/>
                  {(!!uploadedPhotoUrl) ? (
                    <div className="d-flex justify-content-center">
                      <React.Fragment>
                        <Button disabled={loading} type="submit" color="success" icon="check">{t('publications.validate')}</Button>
                      </React.Fragment>
                    </div>
                    ) : null}
                </Grid.Col>
              </Grid.Row>
            </div>
          )}
          <Grid.Row>
            <Grid.Col width={12} className="d-flex justify-content-around">
              <Input
                id={"external_url"}
                placeholder={t('publications.photo-url')}
                type="text"
                value={externalUrl || ''}
                onChange={(e) => {
                  setExternalUrl(e.target.value);
                }}
              />
              {(!!externalUrl && externalUrl !== external_url && !!photoUrl) ? (
                <div className="d-flex justify-content-center">
                  <React.Fragment>
                    <Button disabled={loading} onClick={handleUpdatePhotoUrl}>{t('publications.validate')}</Button>
                  </React.Fragment>
                </div>
              ) : null}
            </Grid.Col>
          </Grid.Row>
        </Form.Group>
      </Form>
    </Fragment>
  );
};

export default withI18n()(PublicationUpdatePhoto);
