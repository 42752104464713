// @flow
import React, {useEffect, useState} from "react";
import SiteWrapper from "../../SiteWrapper";
import {Loader, Page} from "tabler-react";
import {withI18n} from 'react-i18next';
import SearchForm from "../../components/common/search-form/SearchForm";
import LinksProvider from "../../services/http/LinksProvider";
import UsersService from '../../services/domain/UsersService';
import EmptyContent from "../../components/common/empty-content/EmptyContent";
import UserInfo from "../../components/users/user-info/UserInfo";
import UserOwnedPots from "../../components/users/user-owned-pots/UserOwnedPots";
import UserStrongAuths from "../../components/users/user-strong-auths/UserStrongAuths";
import UserOfferedPots from "../../components/users/user-offered-pots/UserOfferedPots";
import UserRibs from "../../components/users/user-ribs/UserRibs";
import {useToasts} from 'react-toast-notifications';
import ErrorHandler from '../../services/error/ErrorHandler';
import UserParticipations from "../../components/users/user-participations/UserParticipations";
import KycWidget from "../../components/kyc/KycWidget";
import UserCashOuts from "../../components/users/user-cash-outs/UserCashOuts";
import GiftCards from "../../components/gift-cards/GiftCards";
import {GiftCardParent} from "../../models/GiftCard";
import UserOwnedWeddingLists from "../../components/users/user-owned-wedding-lists/UserOwnedWeddingLists";
import CreditCards from "../../components/credit-cards/CreditCards";
import {CreditCardParent} from "../../models/CreditCard";
import AuthService from '../../services/domain/AuthService';
import UserKyc from "../../components/users/user-kyc/UserKyc";

function UserDetailsPage({t, match}): React.Node {

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = `${t('navbar.users')} : ${decodeURIComponent(match.params.id)}`;
    loadData();
  }, [t, match.params.id]);

  const {addToast} = useToasts();

  const loadData = () => {
    setLoading(true);
    setUser(null);
    UsersService.search(match.params.id)
      .then(user => setUser(user))
      .catch(error => ErrorHandler.handleError(addToast, t, error))
      .finally(() => setLoading(false));
  };

  return (<SiteWrapper>
    <Page.Content>
      <Page.Header title={t('navbar.users')}
                   options={(<SearchForm value={match.params.id}
                                         placeholder={t('users.search-placeholder')}
                                         route={LinksProvider.ROUTES.USER}/>)}/>
      <Page.Main>
        {loading ? <Loader/> : (user ? (<React.Fragment>
          <UserInfo user={user}/>
          {AuthService.userAuthorizations.includes('AUTH_BO_USERS') &&
            <React.Fragment>
              <UserOwnedPots email={user.email}/>
              <UserOfferedPots email={user.email}/>
            </React.Fragment>
          }
          <UserOwnedWeddingLists email={user.email}/>
          {AuthService.userAuthorizations.includes('AUTH_BO_USERS') &&
            <React.Fragment>
              <UserParticipations email={user.email}/>
              <UserRibs user={user}/>
              <UserStrongAuths email={user.email}/>
              <UserCashOuts email={user.email}/>
              <GiftCards reference={user.email} parent={GiftCardParent.USER}/>
              <CreditCards userEmail={user.email} parent={CreditCardParent.USER}/>
              {process.env.REACT_APP_KYC_IFRAME === 'true' ?
                <KycWidget url={`${process.env.REACT_APP_KYC_BO_URL}/user?email=${user.email}`}/> :
                <UserKyc email={user.email} />
              }
            </React.Fragment>
          }
        </React.Fragment>) : <EmptyContent text={t('users.no-results')}/>)}
      </Page.Main>
    </Page.Content>
  </SiteWrapper>);
}

export default withI18n()(UserDetailsPage);
