import React, { useState, useEffect } from "react";
import { Button, Card, Grid, Form } from "tabler-react";
import ReportService from "../../../services/domain/ReportService";
import KycService from "../../../services/domain/KycService";
import { withI18n } from "react-i18next";
import LinksProvider from "../../../services/http/LinksProvider";
import DateService from "../../../services/utils/DateService";
import DatePicker from "react-datepicker";
import { CreType } from "../../../models/Cre";
import FileUpload from "../../forms/file-upload/FileUpload";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ErrorHandler from "../../../services/error/ErrorHandler";
import { useToasts } from "react-toast-notifications";

const KycRemediationUpload = ({ t, creType }) => {
  const today = new Date();

  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dueDate, setDueDate] = useState(
    new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)
  );

  const { addToast } = useToasts();

  const sendFile = () => {
    if (selectedFile && dueDate) {
      setLoading(true);
      KycService.kycRemediation({
        file: selectedFile,
        dueDate,
      })
        .then((response) => {
          addToast(t(`kyc-remediation.sending`), {
            appearance: "success",
            autoDismiss: true,
          });
        })
        .catch((err) => {
          ErrorHandler.handleError(addToast, t, err);
        })
        .finally(() => {
          document.getElementById("submitForm").reset();

          setLoading(false);
        });
    }
  };

  return (
    <React.Fragment>
      <Card>
        <Card.Header>
          <Card.Title>{t("kyc-remediation.name")}</Card.Title>
        </Card.Header>
        <Card.Body>
          <form id="submitForm">
            <Grid.Row>
              <Grid.Col width={4}>
                <FileUpload
                  accept=".csv"
                  onFileUploaded={(file) => {
                    setSelectedFile(file);
                  }}
                />
              </Grid.Col>

              <Grid.Col width={4}>
                <Form.Group
                  label={t("kyc-remediation.due-date")}
                  className={"text-left"}
                >
                  <DatePicker
                    selected={dueDate}
                    onChange={(date) => setDueDate(date)}
                    dateFormat="dd/MM/yyyy"
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col width={4} className="d-flex align-items-center">
                <Button
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    sendFile();
                  }}
                  loading={loading}
                >
                  {t("kyc-remediation.send")}
                </Button>
              </Grid.Col>
            </Grid.Row>
          </form>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default withI18n()(KycRemediationUpload);
