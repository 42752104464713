import type {Principal} from "../../models/Principal";
import jwt_decode from "jwt-decode";
import HttpService from "../http/HttpService";
import LinksProvider from "../http/LinksProvider";
import cookies from 'js-cookie';
import KeycloakService from '../domain/KeycloakService';

let self = {};
self.userAuthorizations = [];

/**
 * Checks if the current user is authenticated
 * @author Sameh Bellez
 * @alias isAuthenticated
 * @memberof module:Services/domain/AuthService
 * @returns {boolean}  Whether the current user is connected or not
 */
self.isAuthenticated = () => {
  return !!localStorage.getItem(process.env.REACT_APP_BO_ACCESS_TOKEN_TAG);
};

/**
 * Checks if the current user is authenticated
 * @author Sameh Bellez
 * @alias isAuthenticated
 * @memberof module:Services/domain/AuthService
 * @returns {boolean}  Whether the current user is connected or not
 */
self.isKycAuthenticated = () => {
  return !!cookies.get(process.env.REACT_APP_BO_KYC_ACCESS_TOKEN_TAG);
};

/**
 * Gets the current connected principal
 * @author Sameh Bellez
 * @alias getPrincipal
 * @memberof module:Services/domain/AuthService
 * @returns {Principal}  The current connected principal
 */
self.getPrincipal = (): Principal => {
  if (!self.isAuthenticated()) {
    console.log('isAuthenticated***', false)
    return null;
  }
  const data = jwt_decode(localStorage.getItem(process.env.REACT_APP_BO_ACCESS_TOKEN_TAG));

  if (!data || !data.user) {
    return null;
  }

  const user = {...data.user};

  return (user: Principal);
};

/**
 * Gets the current access token from localStorage
 * @author Sameh Bellez
 * @alias getToken
 * @memberof module:Services/domain/AuthService
 * @returns {string}  The access token
 */
self.getToken = () => {
  return localStorage.getItem(process.env.REACT_APP_BO_ACCESS_TOKEN_TAG);
};

/**
 * Gets the current access token from localStorage
 * @author Sameh Bellez
 * @alias getToken
 * @memberof module:Services/domain/AuthService
 * @returns {string}  The access token
 */
self.getKycToken = () => {
  return cookies.get(process.env.REACT_APP_BO_KYC_ACCESS_TOKEN_TAG);
};

/**
 * Saves item in localStorage
 * @author Hassen Charef
 * @alias saveInLocalStorage
 * @memberof module:Services/domain/AuthService
 * @returns {void}
 */
self.saveInLocalStorage = (name, value) => {
  localStorage.setItem(name, value);
};

/**
 * Saves kyc cookie
 * @author Sameh Bellez
 * @alias saveKycCookie
 * @memberof module:Services/domain/AuthService
 * @returns {void}
 */
self.saveKycCookie = (value) => {
  const cookieOptions = {
    path: '/', domain: process.env.REACT_APP_KYC_DOMAIN, expires: 365
  };

  cookies.set(process.env.REACT_APP_BO_KYC_ACCESS_TOKEN_TAG, value, cookieOptions);
};

/**
 * Checks if the current principal has the authorization given in params
 * @author Sameh Bellez
 * @alias hasAuthorization
 * @memberof module:Services/domain/AuthService
 * @returns {boolean}  Whether the principal is authorized or not
 */
self.hasAuthorization = (authorization) => {
  if (!authorization) {
    return true;
  }

  const principal = self.getPrincipal();

  if (!principal) {
    return false;
  }

  return self.userAuthorizations.includes(authorization);
};

/**
 * Get Server Token from Keycloak token
 * @author Hassen Charef
 * @alias getServerTokenFromKeycloakToken
 * @memberof module:Services/domain/AuthService
 * @returns {promise} A promise containing the request result
 */
self.getServerTokenFromKeycloakToken = async (keyCloak) => {
  if (!self.isAuthenticated()) {
    return HttpService
      .post(LinksProvider.formatFullUrl(LinksProvider.API.AUTH.GET_AUTH_TOKEN), keyCloak)
      .then(data => data.admin_tokens);
  }
};

/**
 * Authenticated user and save his token
 * @author Hassen Charef
 * @alias getAndSaveServerTokenFromKeycloakToken
 * @memberof module:Services/domain/AuthService
 * @returns {void}
 */
self.getAndSaveServerTokenFromKeycloakToken = async (keyCloak) => {
  if (!self.isAuthenticated() && keyCloak && keyCloak.token && keyCloak.tokenParsed) {
    return self.getServerTokenFromKeycloakToken(keyCloak).then(data => {
      self.saveInLocalStorage(process.env.REACT_APP_BO_ACCESS_TOKEN_TAG, data.local_token);
      self.saveKycCookie(data.kyc_token);
      return true;
    }).catch(e => {
      console.log(e);
      throw new Error('Failed server validation of Keycloak token');
    });
  }
  return false;
};

/**
 * Logs out the current user
 * @author Sameh Bellez
 * @alias logout
 * @memberof module:Services/domain/AuthService
 * @returns {void}
 */
self.logout = (authorization) => {

  const cookieOptions = {
    path: '/', domain: process.env.REACT_APP_KYC_DOMAIN, expires: 365
  };
  self.userAuthorizations = [];
  localStorage.removeItem(process.env.REACT_APP_BO_ACCESS_TOKEN_TAG);
  cookies.remove(process.env.REACT_APP_BO_KYC_ACCESS_TOKEN_TAG, cookieOptions);
  KeycloakService.logOut();
};

/**
 * Returns authorization list from a list of roles
 * @author Ahmed Laouini
 * @alias getAuthorizationsFromRole
 * @memberof module:Services/domain/AuthService
 * @returns {void}
 */
self.getAuthorizationsFromRole = async () => {
  const principal = self.getPrincipal();
  if (principal) {
    self.userAuthorizations = await HttpService.get(LinksProvider.API.AUTH.AUTHORIZATIONS, {params: {q: self.getPrincipal().roles.map(r => r.reference).join(',')}});
  }
};


export default self;
