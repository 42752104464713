import React, {Component} from 'react';
import {convertFromRaw, convertToRaw, EditorState} from 'draft-js';
import editorStyles from './Editor.scss';

import {Field} from "formik";
import _ from 'lodash';
import EditorToolBarOptions from "./EditorToolBarOptions";
import { Editor } from 'react-draft-wysiwyg';
import {Button} from "tabler-react";

const MAX_LENGTH = 10000;


const isEditorStateEmpty = (editorContent) => {
  const rawState = convertToRaw(editorContent);
  if (!rawState || _.isEmpty(rawState)) {
    return true;
  }
  const contentState = convertFromRaw(rawState);

  return !(contentState.hasText() && (contentState.getPlainText().trim() !== ''));
};

export default class DraftEditor extends Component {

  constructor(props) {
    super(props);

    this.state = {
      editorState: EditorState.createEmpty(), editor: false, value: this.props.value
    };
  }

  componentDidMount() {
    let state = {
      editor: true
    };
    if (this.props.value) {
      state.editorState = EditorState.createWithContent(convertFromRaw(this.props.value));
      state.value = this.props.value;
    }
    this.setState(state);
  };

  onChange = (setFieldValue) => {
    return (editorState) => {
      this.setState({
        editorState,
      });

      if (editorState && !isEditorStateEmpty(editorState.getCurrentContent())) {
        setFieldValue && setFieldValue(this.props.editorName, convertToRaw(editorState.getCurrentContent()));
        this.setState({value: convertToRaw(editorState.getCurrentContent())})
      } else {
        setFieldValue && setFieldValue(this.props.editorName, null);
        this.setState({value: null})
      }
    };
  };

  getLengthOfSelectedText = () => {
    const currentSelection = this.state.editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = this.state.editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      if (isStartAndEndBlockAreTheSame) {
        length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }
          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  };

  handleBeforeInput = () => {
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = this.getLengthOfSelectedText();

    if (currentContentLength - selectedTextLength > MAX_LENGTH - 1) {
      return 'handled';
    }
  };

  handlePastedText = (pastedText) => {
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = this.getLengthOfSelectedText();

    if (currentContentLength + pastedText.length - selectedTextLength > MAX_LENGTH) {
      return 'handled';
    }
  };

  render() {
    const {editor} = this.state;
    return (<React.Fragment>
      <div style={{backgroundColor: 'white', border: '1px solid rgba(0, 40, 100, 0.12)'}}>
      {editor && this.props.onSubmit && (<div name={this.props.editorName} id={this.props.editorId}>
        <div className={editorStyles.editor_wrapper}>
          <div className={editorStyles.editor} ref={this.props.anchorRef}>
            <div style={{margin: '10px 0 10px 10px'}} onClick={this.focus}>
              <Editor
                editorState={this.state.editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={this.onChange().bind(this)}
                ref={(element) => {
                  this.editor = element;
                }}
                toolbarOnFocus
                toolbar={EditorToolBarOptions}
                toolbarCustomButtons={this.props.onSubmit && [<div style={{verticalAlign: 'top', display: 'inline-block', marginLeft: '10px'}}><Button onClick={(e) => {e.preventDefault(); this.props.onSubmit(this.state.value)}} color="success">&#10003;</Button></div>]}
              />
            </div>
          </div>
        </div></div>)}
      {editor && !this.props.onSubmit && (<Field name={this.props.editorName} id={this.props.editorId}>
        {({field: {value}, form: {setFieldValue}}) => (<div className={editorStyles.editor_wrapper}>


          <div className={editorStyles.editor} onClick={this.focus} ref={this.props.anchorRef}>
            <div style={{margin: '10px 0 10px 10px'}}>
              <Editor
                editorState={this.state.editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={this.onChange(setFieldValue).bind(this)}
                ref={(element) => {
                  this.editor = element;
                }}
                toolbarOnFocus
                toolbar={EditorToolBarOptions}
                toolbarCustomButtons={this.props.onSubmit && [<div style={{verticalAlign: 'top', display: 'inline-block', marginLeft: '10px'}}><Button onClick={(e) => {e.preventDefault(); this.props.onSubmit(this.state.value)}} color="success">&#10003;</Button></div>]}
              />
            </div>
          </div>
        </div>)}</Field>)}
      </div>
    </React.Fragment>);
  }
}
