import * as React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import { Button, Form } from "tabler-react";
import { withI18n } from 'react-i18next';
import { Formik } from "formik";
import { productSchema } from '@gl/validation';
import ProductsService from "../../../services/domain/ProductsService";
import {
  ProductAgeRange, ProductCategory, ProductGender, ProductStore
} from "../../../models/Product";
import DropZone from "../../common/dropzone/DropZone";
import LinksProvider from "../../../services/http/LinksProvider";
import AmountInput from "../../forms/amount-input/AmountInput";
import _ from 'lodash';
import FormatService from "../../../services/utils/FormatService"

function CreateProductModal(props) {
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [productPhotos, setProductPhotos] = useState([]);

  const componentConfig = {
    iconFiletypes: [".jpg", ".png", ".jpeg"],
    showFiletypeIcon: true,
    postUrl: process.env.REACT_APP_BACKEND_BASE_URL + LinksProvider.API.PRODUCTS.UPLOAD_PHOTO
  };

  const coverConfig = {
    eventHandlers: {
      success: (file, response) => {
        if (response && response.data && response.data.image) {
          setCoverPhoto(response.data.image.url);
        }
      }
    }, djsConfig: {
      maxFilesize: 10,
      maxFiles: 1,
      dictDefaultMessage: props.t('products.upload-cover-photo'),
      dictRemoveFile: props.t('products.remove-photo'),
      addRemoveLinks: true,
      acceptedFiles: "image/jpeg,image/jpg,image/png",
      maxfilesexceeded: function (file) {
        this.removeAllFiles();
        this.addFile(file);
      },
      sending: function (file, xhr, formData) {
        formData.append("image", file);
      }
    }
  };

  const productPhotosConfig = {
    eventHandlers: {
      success: (file, response) => {
        const oldPhotos = productPhotos;
        if (response && response.data && response.data.image) {
          oldPhotos.push(response.data.image.url);
          setProductPhotos(oldPhotos);
        }
      }
    }, djsConfig: {
      maxFilesize: 10,
      maxFiles: 4,
      dictDefaultMessage: props.t('products.upload-product-photos'),
      dictRemoveFile: props.t('products.remove-photo'),
      addRemoveLinks: true,
      acceptedFiles: "image/jpeg,image/jpg,image/png",
      maxfilesexceeded: function (file) {
        this.removeFile(file);
      },
      sending: function (file, xhr, formData) {
        formData.append("image", file);
      }
    }
  };

  const onSubmit = (values, actions) => {
    actions.setSubmitting(false);
    values.cover_photo = coverPhoto;
    values.photos = productPhotos;

    ProductsService.create(values)
      .then(product => {
        actions.setSubmitting(true);
        props.onHide();
        window.location.reload()
      })
      .catch(error => {
        if (error.data.code && error.data.code === "E_PRODUCT_MISSING_COVER_PHOTO") {
          actions.setErrors({ temp_cover_photo_url: 'PHOTO_REQUIRED' });
          actions.isTouched = true;
        } else {
          props.onError(error);
        }
        actions.setSubmitting(true);
      });
  };
  const [categoriesList, setCategoriesList] = useState();
  const [subCategories, setsubCategories] = useState();
  React.useEffect(() => {
    ProductsService.getCategoriesAndSubCategories()
      .then((res) => {
        const result = Object.groupBy(res, ({ reference }) => reference);
        setCategoriesList(Object.keys(result));
        setsubCategories(result);
      })
      .catch((err) => {
        console.error(err)
      })
  }, []);




  return (<Modal {...props} size="md">
    <Formik
      validationSchema={productSchema.create}
      onSubmit={onSubmit}
      initialValues={{
        gender: null,
        type: "0",
        placement: "0",
        status: "0",
        brand: '',
        name: '',
        price: '',
        first_url: '',
        second_url: '',
        included_in_inspiration_list: false,
        validity_end_date: null,
        validity_start_date: null,
        is_favorite: false,
        categories_type: [],
        categories: [],
        age_ranges: [null, null, null, null, null, null, null],
        stores: [null, null, null, null, null, null, null, null, null],
      }}
    >
      {({
        handleSubmit, handleChange, handleBlur, values, setFieldValue, touched, isValid, errors,
      }) => {
        return <form onSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title> {props.t('products.create-product')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col xs="6" sm="4">
                  <FormGroup>
                    <Label>Nom du produit</Label>
                    <Input
                      id="name"
                      placeholder="Nom du produit"
                      type="text"
                      value={values.name || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.name && errors.name ? <p
                      className="text-danger">{props.t('products.validation.'
                        + errors.name)}</p> : null}

                  </FormGroup>
                </Col>
                <Col xs="6" sm="4">
                  <FormGroup>
                    <Label>Marque du produit</Label>
                    <Input
                      id="brand"
                      placeholder="Marque du produit"
                      type="text"
                      value={values.brand || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.brand && errors.brand ? <p
                      className="text-danger">{props.t('products.validation.'
                        + errors.brand)}</p> : null}
                  </FormGroup>
                </Col>
                <Col xs="6" sm="4">
                  <FormGroup>
                    <Label>Prix en €</Label>
                    <AmountInput
                      id="price"
                      placeholder="Prix"
                      name="price"
                      value={values.price || ''}
                      onChange={handleChange}
                      onBlur={handleBlur} />
                    {touched.price && errors.price ? <p
                      className="text-danger">{props.t('products.validation.'
                        + errors.price)}</p> : null}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="6" sm="4">
                  <Form.Group label="Sexe">
                    <Form.SelectGroup
                      pills
                    >
                      <Form.SelectGroupItem
                        label="Femme"
                        name="gender"
                        value={ProductGender.FEMALE}
                        checked={values.gender === ProductGender.FEMALE}
                        onClick={() => setFieldValue("gender", values.gender
                          === ProductGender.FEMALE ? null : ProductGender.FEMALE)}
                      />
                      <Form.SelectGroupItem
                        label="Homme"
                        name="gender"
                        value={ProductGender.MALE}
                        checked={values.gender === ProductGender.MALE}
                        onClick={() => setFieldValue("gender", values.gender
                          === ProductGender.MALE ? null : ProductGender.MALE)}
                      />
                      <Form.SelectGroupItem
                        label="Mixte"
                        name="gender"
                        value={ProductGender.UNISEX}
                        checked={values.gender === ProductGender.UNISEX}
                        onClick={() => setFieldValue("gender", values.gender
                          === ProductGender.UNISEX ? null : ProductGender.UNISEX)}
                      />
                    </Form.SelectGroup>
                  </Form.Group>
                </Col>
                <Col xs="6" sm="4">
                  <FormGroup>
                    <Label for="typeSelect">Choix Cadeau/List</Label>
                    <Input
                      type="select"
                      name="type"
                      id="typeSelect"
                      value={values.type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="0">Cadeau</option>
                      <option value="1">Liste</option>
                      <option value="2">Cadeau/Liste</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col xs="6" sm="4">
                  <FormGroup>
                    <Label for="placementSelect">Emplacement</Label>
                    <Input
                      type="select"
                      name="placement"
                      id="placementSelect"
                      value={values.placement}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="0">1ere ligne</option>
                      <option value="1">Haut de page</option>
                      <option value="2">Milieu de page</option>
                      <option value="3">Bas de page</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="6" sm="4">
                  <Form.Group label="Liste d'inspiration">
                    <Form.Radio
                      isInline
                      label="Oui"
                      name="included_in_inspiration_list"
                      checked={values.included_in_inspiration_list}
                      // checked={values.included_in_inspiration_list || ["1","2"].includes(values.type) }
                      onChange={() => setFieldValue("included_in_inspiration_list", true)}
                    />
                    <Form.Radio
                      isInline
                      label="Non"
                      name="included_in_inspiration_list"
                      checked={!values.included_in_inspiration_list}
                      // checked={!values.included_in_inspiration_list && !(["1","2"].includes(values.type))}
                      onChange={() => setFieldValue("included_in_inspiration_list", false)}
                    />
                  </Form.Group>
                </Col>
                <Col xs="6" sm="4">
                  <Form.Group label="Coup de coeur">
                    <Form.Radio
                      isInline
                      label="Oui"
                      name="is_favorite"
                      checked={values.is_favorite}
                      onChange={() => setFieldValue("is_favorite", true)}
                    />
                    <Form.Radio
                      isInline
                      label="Non"
                      name="is_favorite"
                      checked={!values.is_favorite}
                      onChange={() => setFieldValue("is_favorite", false)}
                    />
                  </Form.Group>
                  {touched.is_favorite && errors.is_favorite ? <p
                    className="text-danger">{props.t('products.validation.'
                      + errors.is_favorite)}</p> : null}
                </Col>
              </Row>
              <Row>
                <Col xs="6" sm="6">
                  <Form.Group label="Catégories">
                    <Form.SelectGroup
                      canSelectMultiple
                      pills
                    >
                      {categoriesList?.map((el, index) => (
                        <Form.SelectGroupItem
                          key={`cat_key_${el}`}
                          label={el.replaceAll("_", " ")}
                          name="categories"
                          value={el}
                          onChange={(event) => {
                            const updatedCategories = [...values.categories_type];
                            const index = updatedCategories.indexOf(el);

                            if (index === -1) {
                              updatedCategories.push(el);
                            } else {
                              updatedCategories.splice(index, 1);

                              values.categories.map(subCategory => {

                                const relatedCategoryName = Object.values(subCategories).find(category => category.some(item => item.id === subCategory))?.find(subcategory => subcategory.id == subCategory).reference;
                                if (relatedCategoryName === el) {
                                  values.categories.splice(values.categories.indexOf(subCategory),1)
                                }
                              })
                            }
                            setFieldValue('categories_type', updatedCategories);
                          }}
                          checked={values.categories_type.includes(el)}
                        />
                      ))}
                    </Form.SelectGroup>

                  </Form.Group>
                </Col>

                {
                  values.categories_type && values.categories_type.length > 0 &&
                  <Col xs="6" sm="6" style={{maxHeight: '300px' ,overflow: 'scroll'}}>
                    <Form.Group label="Sous Catégories">
                      {
                        values?.categories_type.filter(item => item)?.map((category) => (
                          <>
                            {subCategories[categoriesList.find(cl => cl === category)]?.map((el, index) => (
                              el.subcategory_reference && <div key={`subcat_key_${el.id}`} style={{padding :"10px"}}>
                                <Input
                                  type="checkbox"
                                  name="categories"
                                  checked={values.categories.includes(el.id)}
                                  onChange={(event) => {
                                    const updatedCategories = [...values.categories];
                                    const index = updatedCategories.indexOf(el.id);

                                    if (index === -1) {
                                      updatedCategories.push(el.id);
                                    } else {
                                      updatedCategories.splice(index, 1);
                                    }
                                    setFieldValue('categories', updatedCategories);
                                  }}
                                />
                                {FormatService.kebabToUpper(el.subcategory_name)}
                                <br />
                              </div>
                            ))}

                          </>
                        ))}




                    </Form.Group>
                  </Col>}
                <Col xs="6" sm="6">
                  <Form.Group label="Tranche d’âge">
                    <Form.SelectGroup
                      canSelectMultiple
                      pills
                    >
                      <Form.SelectGroupItem
                        label="0-5"
                        name="age_ranges"
                        value={ProductAgeRange.ZERO_FIVE}
                        onChange={(event) => {
                          const value = values.age_ranges[0] ? null : ProductAgeRange.ZERO_FIVE;
                          setFieldValue('age_ranges.0', value)
                        }}
                        checked={values.age_ranges.includes(ProductAgeRange.ZERO_FIVE)}
                      />
                      <Form.SelectGroupItem
                        label="5-15"
                        name="age_ranges"
                        value={ProductAgeRange.FIVE_FIFTEEN}
                        onChange={(event) => {
                          const value = values.age_ranges[1] ? null : ProductAgeRange.FIVE_FIFTEEN;
                          setFieldValue('age_ranges.1', value)
                        }}
                        checked={values.age_ranges.includes(ProductAgeRange.FIVE_FIFTEEN)}
                      />
                      <Form.SelectGroupItem
                        label="15-25"
                        name="age_ranges"
                        value={ProductAgeRange.FIFTEEN_TWENTYFIVE}
                        onChange={(event) => {
                          const value = values.age_ranges[2] ? null : ProductAgeRange.FIFTEEN_TWENTYFIVE;
                          setFieldValue('age_ranges.2', value)
                        }}
                        checked={values.age_ranges.includes(ProductAgeRange.FIFTEEN_TWENTYFIVE)}
                      />
                      <Form.SelectGroupItem
                        label="25-35"
                        name="age_ranges"
                        value={ProductAgeRange.TWENTYFIVE_THIRTYFIVE}
                        onChange={(event) => {
                          const value = values.age_ranges[3] ? null : ProductAgeRange.TWENTYFIVE_THIRTYFIVE;
                          setFieldValue('age_ranges.3', value)
                        }}
                        checked={values.age_ranges.includes(ProductAgeRange.TWENTYFIVE_THIRTYFIVE)}
                      />
                      <Form.SelectGroupItem
                        label="35-45"
                        name="age_ranges"
                        value={ProductAgeRange.THIRTYFIVE_FOURTYFIVE}
                        onChange={(event) => {
                          const value = values.age_ranges[4] ? null : ProductAgeRange.THIRTYFIVE_FOURTYFIVE;
                          setFieldValue('age_ranges.4', value)
                        }}
                        checked={values.age_ranges.includes(ProductAgeRange.THIRTYFIVE_FOURTYFIVE)}
                      />
                      <Form.SelectGroupItem
                        label="45-60"
                        name="age_ranges"
                        value={ProductAgeRange.FOURTYFIVE_SIXTY}
                        onChange={(event) => {
                          const value = values.age_ranges[5] ? null : ProductAgeRange.FOURTYFIVE_SIXTY;
                          setFieldValue('age_ranges.5', value)
                        }}
                        checked={values.age_ranges.includes(ProductAgeRange.FOURTYFIVE_SIXTY)}
                      />
                      <Form.SelectGroupItem
                        label="60+"
                        name="age_ranges"
                        value={ProductAgeRange.SIXTY_PLUS}
                        onChange={(event) => {
                          const value = values.age_ranges[6] ? null : ProductAgeRange.SIXTY_PLUS;
                          setFieldValue('age_ranges.6', value)
                        }}
                        checked={values.age_ranges.includes(ProductAgeRange.SIXTY_PLUS)}
                      />
                    </Form.SelectGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs="12" sm="12">
                  <Form.Group label="Vendu par">
                    <Form.SelectGroup
                      canSelectMultiple
                      pills
                    >
                      <Form.SelectGroupItem
                        label="Galeries Lafayette"
                        name="stores"
                        value={ProductStore.GL}
                        onChange={(event) => {
                          const value = values.stores[0] ? null : ProductStore.GL;
                          setFieldValue('stores.0', value)
                        }}
                        checked={values.stores.includes(ProductStore.GL)}
                      />
                      <Form.SelectGroupItem
                        label="LE BHV MARAIS"
                        name="stores"
                        value={ProductStore.BHV}
                        onChange={(event) => {
                          const value = values.stores[1] ? null : ProductStore.BHV;
                          setFieldValue('stores.1', value)
                        }}
                        checked={values.stores.includes(ProductStore.BHV)}
                      />
                      <Form.SelectGroupItem
                        label="La Redoute"
                        name="stores"
                        value={ProductStore.LA_REDOUTE}
                        onChange={(event) => {
                          const value = values.stores[2] ? null : ProductStore.LA_REDOUTE;
                          setFieldValue('stores.2', value)
                        }}
                        checked={values.stores.includes(ProductStore.LA_REDOUTE)}
                      />
                      <Form.SelectGroupItem
                        label="Louis Pion"
                        name="stores"
                        value={ProductStore.LOUIS_PION}
                        onChange={(event) => {
                          const value = values.stores[3] ? null : ProductStore.LOUIS_PION;
                          setFieldValue('stores.3', value)
                        }}
                        checked={values.stores.includes(ProductStore.LOUIS_PION)}
                      />
                      <Form.SelectGroupItem
                        label="Guérin Joaillerie"
                        name="stores"
                        value={ProductStore.GUERIN_JOAILLERIE}
                        onChange={(event) => {
                          const value = values.stores[4] ? null : ProductStore.GUERIN_JOAILLERIE;
                          setFieldValue('stores.4', value)
                        }}
                        checked={values.stores.includes(ProductStore.GUERIN_JOAILLERIE)}
                      />
                      <Form.SelectGroupItem
                        label="BazarChic"
                        name="stores"
                        value={ProductStore.BAZAR_CHIC}
                        onChange={(event) => {
                          const value = values.stores[5] ? null : ProductStore.BAZAR_CHIC;
                          setFieldValue('stores.5', value)
                        }}
                        checked={values.stores.includes(ProductStore.BAZAR_CHIC)}
                      />
                      <Form.SelectGroupItem
                        label="EatalyParisMarais"
                        name="stores"
                        value={ProductStore.EATALY_PARIS_MARAIS}
                        onChange={(event) => {
                          const value = values.stores[6] ? null : ProductStore.EATALY_PARIS_MARAIS;
                          setFieldValue('stores.6', value)
                        }}
                        checked={values.stores.includes(ProductStore.EATALY_PARIS_MARAIS)}
                      />
                      <Form.SelectGroupItem
                        label="Royal Quartz"
                        name="stores"
                        value={ProductStore.ROYAL_QUARTZ}
                        onChange={(event) => {
                          const value = values.stores[7] ? null : ProductStore.ROYAL_QUARTZ;
                          setFieldValue('stores.7', value)
                        }}
                        checked={values.stores.includes(ProductStore.ROYAL_QUARTZ)}
                      />
                      <Form.SelectGroupItem
                        label="Mauboussin"
                        name="stores"
                        value={ProductStore.MAUBOUSSIN}
                        onChange={(event) => {
                          const value = values.stores[8] ? null : ProductStore.MAUBOUSSIN;
                          setFieldValue('stores.8', value)
                        }}
                        checked={values.stores.includes(ProductStore.MAUBOUSSIN)}
                      />
                    </Form.SelectGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs="6" sm="6">
                  <FormGroup>
                    <Label>Url GL du produit</Label>
                    <Input
                      id="first_url"
                      placeholder="Url GL du produit"
                      type="text"
                      value={values.first_url || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    {touched.first_url && errors.first_url ? <p
                      className="text-danger">{props.t('products.validation.'
                        + errors.first_url)}</p> : null}
                  </FormGroup>
                </Col>
                <Col xs="6" sm="6">
                  <FormGroup>
                    <Label>Url BHV du produit</Label>
                    <Input
                      id="second_url"
                      placeholder="Url BHV du produit"
                      type="text"
                      value={values.second_url || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    {touched.second_url && errors.second_url ? <p
                      className="text-danger">{props.t('products.validation.'
                        + errors.second_url)}</p> : null}
                  </FormGroup>
                </Col>
              </Row>
              <br />
              <Row>
                <Col xs="6" sm="4">
                  <FormGroup>
                    <Label>Date début de disponibilté</Label>
                    <Input
                      id="validity_start_date"
                      type="date"
                      value={values.validity_start_date || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormGroup>
                  {touched.validity_start_date && errors.validity_start_date ? <p
                    className="text-danger">{props.t('products.validation.'
                      + errors.validity_start_date)}</p> : null}
                </Col>
                <Col xs="6" sm="4">
                  <FormGroup>
                    <Label>Date fin de disponibilté</Label>
                    <Input
                      id="validity_end_date"
                      type="date"
                      value={values.validity_end_date || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormGroup>
                  {touched.validity_end_date && errors.validity_end_date ? <p
                    className="text-danger">{props.t('products.validation.'
                      + errors.validity_end_date)}</p> : null}
                </Col>
                <Col xs="6" sm="4">
                  <FormGroup>
                    <Label for="statusSelect">Statut</Label>
                    <Input
                      type="select"
                      name="status"
                      id="statusSelect"
                      value={values.status}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="0">Brouillon</option>
                      <option value="1">Actif</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12" sm="12">
                  <FormGroup>
                    <Label for="descriptionText">Description</Label>
                    <Input
                      id="descriptionText"
                      type="textarea"
                      name="description"
                      value={values.description || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormGroup>
                  {touched.description && errors.description ? <p
                    className="text-danger">{props.t('products.validation.'
                      + errors.description)}</p> : null}
                </Col>
              </Row>
              <Row>
                <Col xs="12" sm="4">
                  <DropZone
                    componentConfig={componentConfig}
                    dropZoneConfig={coverConfig.djsConfig}
                    eventHandlers={coverConfig.eventHandlers}
                  />
                  {errors.temp_cover_photo_url ? <p
                    className="text-danger text-center">{props.t('products.validation.'
                      + errors.temp_cover_photo_url)}</p> : null}
                </Col>
                <Col xs="12" sm="8">
                  <DropZone
                    componentConfig={componentConfig}
                    dropZoneConfig={productPhotosConfig.djsConfig}
                    eventHandlers={productPhotosConfig.eventHandlers}
                  />
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" onClick={props.onHide} color="warning"
              closeButton>{props.t('modals.cancel')}</Button>
            <Button onClick={(e) => console.log("formik errors", errors)} type="submit"
              color="primary">{props.t('modals.save')}</Button>
          </Modal.Footer>
        </form>
      }}
    </Formik>
  </Modal>);
}

export default withI18n()(CreateProductModal);
