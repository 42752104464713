import React from "react";

import DateService from '../../../services/utils/DateService'
import {Badge, Card, Table, Icon} from "tabler-react";
import {withI18n} from 'react-i18next';
import Amount from "../../common/amount/Amount";
import {PromoCodeStatus} from "../../../models/PromoCode";
import LinksProvider from "../../../services/http/LinksProvider";

const PromoCodesList = ({promoCodes, t}) => {
  const headerItems = [{content: t('promo-codes.reference')}, {content: t('promo-codes.amount')},
    {content: t('promo-codes.quantity')}, {content: t('promo-codes.quantity-left')},
    {content: t('promo-codes.activation-date')}, {content: t('promo-codes.expiration-date')},
    {content: t('promo-codes.status')}];

  const promoCodeStatusBadge = (promoCode) => {
    switch (promoCode.status) {
      case PromoCodeStatus.DEACTIVATED :
        return (<Badge color="danger">{t('promo-codes.deactivated')}</Badge>);
      case PromoCodeStatus.RUNNING :
        return (<Badge color="success">{t('promo-codes.running')}</Badge>)
    }
  };

  const itemRow = (promoCode) => {
    return {
      key: promoCode.reference, item: [
        {
          content: (<a
            href={LinksProvider.formatUrl(LinksProvider.ROUTES.PROMO_CODE, {id: promoCode.reference})}>{promoCode.reference}</a>)
        },
        {content: (<Amount amount={promoCode.amount}/>)},
        {content: (<span>{promoCode.quantity}</span>)},
        {content: (<span>{promoCode.quantity_left}</span>)},
        {content: DateService.formatTime(promoCode.activation_date)},
        {content: DateService.formatTime(promoCode.expiration_date)},
        {
          content: (
            <div>
              {promoCodeStatusBadge(promoCode)}
              {(promoCode.is_banner != null) ? (
                <span><Icon name="flag"/> {t('promo-codes.banner-of-' + promoCode.is_banner)}</span>) : null}
            </div>
          )
        },
      ]
    };
  };

  return (<React.Fragment>
    <Card>
      <Card.Header>
        <Card.Title>{t('promo-codes.list-codes')}</Card.Title>
      </Card.Header>
      <Card.Body>
        <Table responsive
               className="card-table"
               headerItems={headerItems}
               bodyItems={promoCodes.map(itemRow)}/>
      </Card.Body>
    </Card>
  </React.Fragment>)
};

export default withI18n()(PromoCodesList);
