/**
 * CommercialBannersService
 * @module Services/domain/CommercialBannersService
 * @description Loads commercial banners data from the server
 */

import HttpService from "../http/HttpService";
import LinksProvider from "../http/LinksProvider";

let self = {};

self.KycFileType = {
  OTHER: 0,
  IDENTITY: 1,
  ADDRESS: 2,
};

self.getRefused = () => {
  return HttpService.get(
    LinksProvider.formatUrl(LinksProvider.API.KYC.REFUSED_DOCUMENTS)
  ).then((data) => data.users);
};

self.getRefusedUser = (email) => {
  return HttpService.get(
    LinksProvider.formatUrl(LinksProvider.API.KYC.USER_REFUSED_DOCUMENTS, {
      email,
    })
  );
};

self.getUserWithAllDocuments = (email) => {
  return HttpService.get(
    LinksProvider.formatUrl(LinksProvider.API.KYC.USER_ALL_DOCUMENTS, { email })
  );
};

self.updateUser = (email, data) => {
  return HttpService.put(
    LinksProvider.formatUrl(LinksProvider.API.USERS.USER_KYC, { email }),
    data
  ).then((data) => data.user);
};

self.deleteDocument = (reference) => {
  return HttpService.delete(
    LinksProvider.formatUrl(LinksProvider.API.KYC.DELETE, { reference })
  );
};

self.reject = (email, request) => {
  return HttpService.put(
    LinksProvider.formatUrl(LinksProvider.API.USERS.REJECT_USER, { email }),
    request
  ).then((result) => {
    return result.user;
  });
};

self.kycRemediation = (data) => {
  return HttpService.post(LinksProvider.API.KYC.REMEDIATION, data);
};

export default self;
