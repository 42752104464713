/**
 * PublicationsService
 * @module Services/domain/PublicationsService
 * @description Loads publications data from the server
 */

import HttpService from "../http/HttpService";
import LinksProvider from "../http/LinksProvider";

const self = {};



/**
 * get publications(s)
 * @author Seif Khiari
 * @alias getAll
 * @memberof module:Services/http/PublicationsService
 * @param   {string} limit The search query limit
 * @param   {string} offset The search query offset
 * @returns {Promise<Publications>}  A promise containing the search result
 */
self.getAll = (limit: string, offset: string): Promise<Publications> => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.PUBLICATIONS.ALL_PUBLICATIONS, {
      limit: limit,
      offset: offset
    }));
};

/**
 * delete publication
 * @author Seif Khiari
 * @alias delete
 * @memberof module:Services/http/PublicationsService
 * @param   {string} reference the publication reference
 * @returns {Promise<>}  A promise
 */
self.delete = (reference: string): Promise<> => {
  return HttpService
    .delete(LinksProvider.formatFullUrl(LinksProvider.API.PUBLICATIONS.PUBLICATION, {reference}));
};

/**
 * creates publication
 * @author Seif Khiari
 * @alias create
 * @memberof module:Services/http/PublicationsService
 * @param   {object} values the publication info
 * @returns {Promise<Publication>}  A promise containing the created publication
 */
self.create = (values : any): Promise<Publication> => {
  return HttpService
    .post(LinksProvider.formatFullUrl(LinksProvider.API.PUBLICATIONS.PUBLICATIONS), values)
    .then(data => data.publication);
};

/**
 * returns publication info
 * @author Seif Khiari
 * @alias get
 * @memberof module:Services/http/PublicationsService
 * @param   {string} reference the publication reference
 * @returns {Promise<Publication>}  A promise containing the publication
 */
self.get = (reference : string): Promise<Publication> => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.PUBLICATIONS.PUBLICATION, {reference}))
    .then(data => data.publication);
};

/**
 * updates publication
 * @author Seif Khiari
 * @alias update
 * @memberof module:Services/http/PublicationsService
 * @param   {string} reference the publication reference
 * @param   {object} values the publication info to update
 * @returns {Promise<Publication>}  A promise containing the updated publication
 */
self.update = (reference : string, values : any): Promise<Publication> => {
  return HttpService
    .put(LinksProvider.formatFullUrl(LinksProvider.API.PUBLICATIONS.PUBLICATION, {reference}), values)
    .then(data => data.publication);
};

/**
 * updates publication photo
 * @author Seif Khiari
 * @alias updatePhoto
 * @memberof module:Services/http/PublicationsService
 * @param   {string} reference the publication reference
 * @param   {string|number} rank the publication photo rank
 * @param   {object} values the publication info to update
 * @returns {Promise<PublicationPhoto>}  A promise containing the updated publication photo
 */
self.updatePhoto = (reference : string, rank : string|number, values: any): Promise<Publication> => {
  return HttpService
    .put(LinksProvider.formatFullUrl(LinksProvider.API.PUBLICATIONS.PHOTO, {reference, rank}), values)
    .then(data => data.publication_photo);
};



export default self;
