import React, {useState, useEffect} from "react";

import DateService from '../../../services/utils/DateService'
import {
    Badge, Card, Loader, Table,
} from "tabler-react";
import {withI18n} from 'react-i18next';
import EmptyContent from "../../common/empty-content/EmptyContent";
import UsersService from "../../../services/domain/UsersService";
import LinksProvider from "../../../services/http/LinksProvider";
import type {MinifiedPot} from "../../../models/Pot";
import {PotCategory, PotStatus} from "../../../models/Pot";
import Amount from "../../common/amount/Amount";
import StrongAuthService from "../../../services/utils/StrongAuthService";

function UserOwnedWeddingLists({email, t}): React.Node {

    const [loading, setLoading] = useState(false);
    const [lists, setLists] = useState(null);

    const headerItems = [
        {content: t('pots.reference')},
        {content: t('pots.name')},
        {content: t('pots.collected-amount')},
        {content: t('pots.creation-date')},
        {content: t('pots.status')}
    ];

    const loadData = () => {
        setLoading(true);
        UsersService.ownedWeddingLists(email)
            .then(lists => {
                setLists(lists);
            })
            .finally(() => setLoading(false));
    };

    const listStatusBadge = (list: MinifiedPot) => {
        switch (list.status) {
            case PotStatus.ARCHIVED :
                return (<Badge color="warning">{t('pots.archived')}</Badge>);
            case PotStatus.DELETED :
                return (<Badge color="danger">{t('pots.deleted')}</Badge>);
            case PotStatus.DEACTIVATED :
                return (<Badge color="danger">{t('pots.deactivated')}</Badge>);
            case PotStatus.RUNNING :
                return (<Badge color="success">{t('pots.running')}</Badge>)
        }
    };

    const oneListLine = (list: MinifiedPot) => {
        return {
            key: list.reference,
            item: [
                {
                    content: (
                        <a href={LinksProvider.formatUrl(LinksProvider.ROUTES.POT, {id: list.reference})}>{list.reference}</a>)
                },
                {content: list.name},
                {content: StrongAuthService.showSecureSensitiveDataWithOTPForM1L() ? (<Amount amount={list.collected_amount}></Amount>) : '-'},
                {content: DateService.formatTime(list.creation_date)},
                {content: (listStatusBadge(list))}
            ]
        };
    };

    useEffect(() => {
        loadData();
    }, []);

    return (

        <Card title={t('users.created-lists')}>
            <Card.Body className="fixed-height-card-body">
                {((!lists || !lists.length) && !loading) ? (
                    <EmptyContent text={t('users.no-created-lists')}/>
                ) : ''}
                {((lists && lists.length) && !loading) ? (
                    <Table responsive
                           className="card-table"
                           headerItems={headerItems}
                           bodyItems={lists.map(oneListLine)}/>
                ) : ''}
                {loading && <Loader/>}
            </Card.Body>
        </Card>
    );
}

export default withI18n()(UserOwnedWeddingLists);
