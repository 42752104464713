// @flow
import * as React from "react";
import SiteWrapper from "../../SiteWrapper";
import {Button, Loader, Page, Grid} from "tabler-react";
import {withI18n} from "react-i18next";
import {useEffect, useState} from "react";
import EmptyContent from "../../components/common/empty-content/EmptyContent";
import {useToasts} from 'react-toast-notifications';
import InspirationListsList from "../../components/inspiration-lists/inspiration-lists-list/InspirationListsList";
import InspirationListsService from "../../services/domain/InspirationListsService";
import ErrorHandler from "../../services/error/ErrorHandler";
import CreateInspirationListModal
  from "../../components/modals/create-inspiration-list-modal/CreateInspirationListModal";


function InspirationListsPage({t}): React.Node {
  const {addToast} = useToasts();

  const [lists, setLists] = useState(null);
  const [listCount, setListCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [createModalShown, setCreateModalShown] = useState(false);

  const listsPerPage = 10;

  useEffect(() => {
    document.title = t('navbar.inspiration-lists');
    loadData();
  }, [t]);

  const loadData = () => {
    setLoading(true);
    InspirationListsService.getAll(listsPerPage, 0, undefined)
      .then(data => {
        if (data) {
          setLists(data.lists);
          setListCount(data.original_result_count);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <SiteWrapper>
      <Page.Content>
        <Page.Header
          title={t('navbar.inspiration-lists')}
          options={(<Grid.Row>
            <Grid.Col>
              <CreateInspirationListModal
                show={createModalShown}
                dialogClassName="modal-lg"
                onHide={() => setCreateModalShown(false)}
                onError={(error) => ErrorHandler.handleError(addToast, t, error)}
              />
              <Button className="mr-1" icon="plus" onClick={() => {setCreateModalShown(true)}} color="primary"
              >{t('inspiration-lists.create')}</Button>
            </Grid.Col>
          </Grid.Row>)}
        />
        <React.Fragment>
          <React.Fragment>
            {loading && <Loader/>}

            {(!loading && lists && lists.length > 0) && (
              <InspirationListsList lists={lists} searchQueryCount={listCount}/>
            )}

            {(!loading && (!lists || !lists.length)) && (
              <EmptyContent text={t('inspiration-lists.no-results')}/>
            )}
          </React.Fragment>
        </React.Fragment>
      </Page.Content>
    </SiteWrapper>
  );
}

export default withI18n()(InspirationListsPage);
