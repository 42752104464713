/**
 * UsersService
 * @module Services/http/PromoCodesService
 * @description Loads users data from the server
 */

import HttpService from "../http/HttpService";
import LinksProvider from "../http/LinksProvider";
import {Participation} from "../../models/Participation";

const self = {};

/**
 * Searches for participation(s) with a specific query
 * @author Romain Paris
 * @alias search
 * @memberof module:Services/http/ParticipationService
 * @param   {string} query The search query
 * @returns {Promise<Participation[]>}  A promise containing the search result
 */


self.search = (query : string): Promise<Participation[]> => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.PARTICIPATIONS.SEARCH, {search: query}))
    .then(data => data.participations);
};

/**
 * Gets a participation from its reference
 * @author Romain Paris
 * @alias get
 * @memberof module:Services/http/ParticipationService
 * @param   {string} reference the participation's reference
 * @returns {Promise<Participation>}  A promise containing the found participation
 */


self.get = (reference : string): Promise<Participation> => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.PARTICIPATIONS.PARTICIPATION, {id: reference}))
    .then(data => data.participation);
};

/**
 * Updates promo-codes information
 * @author Romain Paris
 * @alias update
 * @memberof module:Services/http/PromoCodesService
 * @param   {string} reference The code reference
 * @param   {any} data The information to update
 * @returns {Promise<PromoCodes>}  A promise containing the updated promo-code
 */
self.update = (reference : string, data : any): Promise<Participation> => {
  return HttpService
    .put(LinksProvider.formatFullUrl(LinksProvider.API.PARTICIPATIONS.PARTICIPATION, {id: reference}), data)
    .then(data => data.participation);
};

/**
 * Refunds a participation
 * @author Sameh Bellez
 * @alias refund
 * @memberof module:Services/http/ParticipationsService
 * @param   {string} reference of the participation
 * @param   {any} data The refund options
 * @returns {Promise<Participation>}  A promise containing the updated participation
 */
self.refund = (reference : string, data : any): Promise<Participation> => {
  return HttpService
    .post(LinksProvider.formatFullUrl(LinksProvider.API.PARTICIPATIONS.REFUNDS, {id: reference}), data)
    .then(data => data.participation);
};

export default self;