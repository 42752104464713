// @flow

export type InspirationList = {
  reference: string,
  name: string,
  type: number,
  status: number,
  validity_start_date: Date,
  validity_end_date: Date,
  creation_date: Date,
  deletion_date: Date,
}

export const InspirationListType = {
  GIFT: 0,
  MARRIAGE: 1,
  GIFT_MARRIAGE: 2
};

export const InspirationListStatus = {
  INACTIVE: 0,
  ACTIVE: 1
};
