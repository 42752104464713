import React, {useState} from 'react';
import FileService from "../../../services/domain/FileService";
import {withI18n} from 'react-i18next';
import {Col, Row, Form} from "react-bootstrap";
import {useToasts} from "react-toast-notifications";
import ErrorHandler from "../../../services/error/ErrorHandler";

const FileUpload = ({t, id, user, onFileUploaded, type, subtype, accept}) => {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const {addToast} = useToasts();

  const onFileAdded = (e) => {
    if (!e || !e.target || !e.target.files || !e.target.files.length) {
      return;
    }

    setLoading(true);
    setError(null);
    if (e.target.files[0]) {
      FileService.upload(e.target.files[0])
      .then((file) => {
        onFileUploaded(file);
        const input = document.querySelector(`#${id}`);
        if (input) {
          input.value = "";
        }
      })
      .catch(err => {
          setError(err.message)
      })
      .finally(() => setLoading(false));
    }

  };

  return (
      <Row className="mr-3 ml-3">
        <Col sm={12} className="mt-2 mb-2">
          {t('file-upload.accepted-formats')} {accept}
        </Col>
        <Col sm={12} className="mt-2 mb-2">
          {t('file-upload.max-size')}
        </Col>
        <Col sm={12} className="mt-2 mb-2">
          <Form.Group controlId={id}>
            <Form.Label>{t('file-upload.choose-file')}</Form.Label>
            <input disabled={loading} accept={accept}
                   id={id} type="file" onChange={onFileAdded}/>
            {error && <p className="file_error">
              {error}
            </p>}
          </Form.Group>
        </Col>
      </Row>
  );
};

FileUpload.defaultProps = {
  accept: 'image/png, image/jpg, image/jpeg, application/pdf',
}

export default withI18n()(FileUpload);
