// @flow
import * as React from "react";
import {useState} from "react";
import {Modal} from "react-bootstrap";
import {Button, Form} from "tabler-react";
import {withI18n} from 'react-i18next';
import ParticipationsService from "../../../services/domain/ParticipationsService";
import ErrorHandler from "../../../services/error/ErrorHandler";
import {useToasts} from "react-toast-notifications";


function RefundOneParticipationModal(props): React.Node {
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState({
    notify_organizer: false, notify_participant: false, requested_by_organizer: false
  });
  const {addToast} = useToasts();

  const refund = () => {
    setLoading(true);
    ParticipationsService.refund(props.reference, model)
        .then((participation) => {
          if (props.afterRefund) {
            props.afterRefund(participation);
          }
        }).catch(error => {
            ErrorHandler.handleError(addToast, props.t, error);
            props.onHide();
        }).finally(() => setLoading(false));
  };

  return (<Modal {...props} size="md">
    <Modal.Header>
      <Modal.Title>{props.t('refund-participation', {reference : props.reference})}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form.Group label={props.t('participations.refund-options')}>
        <Form.SwitchStack>
          <Form.Switch
              label={props.t('participations.notify-organizer')}
              name="notify_organizer"
              onChange={() => setModel({...model, notify_organizer : !model.notify_organizer})}
              type="checkbox"
              value={model.notify_organizer}/>
          <Form.Switch
              label={props.t('participations.notify-participant')}
              name="notify_participant"
              onChange={() => setModel({...model, notify_participant : !model.notify_participant})}
              type="checkbox"
              value={model.notify_participant}/>
          {model.notify_participant && <Form.Switch
              label={props.t('participations.request-by-organizer')}
              name="requested_by_organizer"
              onChange={() => setModel({...model, requested_by_organizer : !model.requested_by_organizer})}
              type="checkbox"
              value={model.requested_by_organizer}/>}
        </Form.SwitchStack>
      </Form.Group>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={props.onHide} color="warning"
              closeButton>{props.t('modals.close')}</Button>
      <Button loading={loading} onClick={refund}
              color="primary">{props.t('participations.refund')}</Button>
    </Modal.Footer>
  </Modal>);
}

export default withI18n()(RefundOneParticipationModal);