// @flow

export const CommercialBannerType = {
  POT: 0,
  LIST: 1,
  POT_PAYMENT_CONFIRMATION: 2,
  LIST_PAYMENT_CONFIRMATION: 3,
  POT_GIFT_ENGINE: 4,
  LIST_GIFT_ENGINE: 5
};

export const CommercialBannerStatus = {
  INACTIF: 0,
  ACTIF: 1
};


export type CommercialBanners = {
  reference: string,
  title: string,
  text: string,
  type: number,
  status: number,
  url: string,
  start_date: Date,
  end_date: Date
};