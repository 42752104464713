// @flow

import React from "react";
import ReactDOM from "react-dom";

import "./styles/App.scss";
import "./c3jscustom.scss";

import "./i18n"

import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
import App from "./App";

const rootElement = document.getElementById("root");
const packageJson = require('../package.json');

Sentry.init({
  dsn: process.env.REACT_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  release: packageJson.version,
  debug: true,
  attachStacktrace: process.env.SENTRY_ENABLE,
  enabled: process.env.NODE_ENV !== 'development'
  // integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  // tracesSampleRate: 1.0,
});


if (rootElement) {
  ReactDOM.render(<App />, rootElement);
} else {
  throw new Error("Could not find root element to mount to!");
}
