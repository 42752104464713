import React, {useEffect, useState} from 'react';
import {withI18n} from "react-i18next";
import ProductsService from "../../../services/domain/ProductsService";
import {Button, Card, Loader, Table,} from "tabler-react";
import {Figure} from "react-bootstrap";
import LinksProvider from "../../../services/http/LinksProvider";
import Amount from "../../common/amount/Amount";
import EmptyContent from "../../common/empty-content/EmptyContent";
import ListProductParticipantsListModal from "../../modals/list-product-participants-list-modal/ListProductParticipantsListModal";
import StrongAuthService from "../../../services/utils/StrongAuthService";
import {PotCategory} from "../../../models/Pot";
import AddCustomProductModal from "../../modals/add-custom-product-modal/AddCustomProductModal";
import AddCatalogProductModal from "../../modals/add-catalog-product-modal/AddCatalogProductModal";
import ErrorHandler from "../../../services/error/ErrorHandler";
import {useToasts} from 'react-toast-notifications';
import AuthService from '../../../services/domain/AuthService';

const ListProducts = ({t, reference}) => {

    const [addCustomModalShown, setAddCustomModalShown] = useState(false);
    const [addCatalogModalShown, setAddCatalogModalShown] = useState(false);
    const {addToast} = useToasts();

    const [loading, setLoading] = useState(false);
    const [listProducts, setListProducts] = useState([]);
    const [selectedProductReference, setSelectedProductReference] = useState(null);

    useEffect(() => {
        getProducts();
    }, []);

    const getProducts = () => {
        setLoading(true);
        ProductsService.getListProducts(reference)
            .then(products => setListProducts(products))
            .catch(error => error)
            .finally(() => setLoading(false));
    };

    const headerItems = [
        {content: t('products.reference')},
        {content: t('products.name')},
        {content: t('products.cover-photo')},
        {content: t('products.brand')},
        {content: t('products.price')},
        {content: t('products.collected-amount-on-pot')},
        {content: t('products.participants-number-on-pot')},
    ];

    const itemRow = (product) => {
        return {
            key: product.reference,
            item: [
                {
                    content: (<a
                        href={AuthService.userAuthorizations.includes('AUTH_BO_PRODUCTS') ? LinksProvider.formatUrl(LinksProvider.ROUTES.PRODUCT, {id: encodeURIComponent(product.reference)}) : 'javascript:void(0)'}
                    >{product.reference}</a>)
                },
                {content: (<span>{product.name}</span>)},
                {
                    content: (
                        <span>
              {(product.cover_photo && product.cover_photo.storage_service_url) ?
                  (
                      <Figure>
                          <Figure.Image
                              alt={t('products.cover-photo')}
                              width={47.5}
                              height={50}
                              src={product.cover_photo.storage_service_url}
                          />
                      </Figure>
                  ) : (
                      <div>&emsp;N/A</div>
                  )}
            </span>)
                },
                {content: (<span>{product.brand}</span>)},
                {content: (<Amount amount={product.price}/>)},
                {content: (<div className="d-flex justify-content-around">
                        {StrongAuthService.showSecureSensitiveDataWithOTPForM1L() ? (<Amount amount={product.collected_amount_on_pot}/>) : '-'}
                </div>)},
                {content: (<div className="d-flex justify-content-around">
                        {StrongAuthService.showSecureSensitiveDataWithOTPForM1L() ? product.collected_amount_on_pot && (<Button color="success" size="sm" onClick={() => setSelectedProductReference(product.reference)}>{t('products.participants-list')}</Button>) : '-'}
                    </div>)},
            ]
        };
    };

    return (
        <>
            {selectedProductReference && (
                <ListProductParticipantsListModal
                    listReference={reference}
                    productReference={selectedProductReference}
                    show={!!selectedProductReference}
                    onHide={() => setSelectedProductReference(null)}/>
            )}

            <Card>
                <Card.Header>
                  <Card.Title>
                    {t('products.list-products', {count: listProducts.length})}
                  </Card.Title>
                  <Card.Options>
                    <Button color="info" className="mr-3" onClick={() => setAddCatalogModalShown(true)}>
                      {t('products.add-catalog')}
                    </Button>
                    <Button color="success" onClick={() => setAddCustomModalShown(true)}>
                      {t('products.add-custom')}
                    </Button>
                  </Card.Options>
                </Card.Header>
                <Card.Body>

                    {!loading && (!listProducts || listProducts.length === 0) &&
                    <EmptyContent text={t('pots.no-products')}
                    />}


                    {!loading && (listProducts && listProducts.length > 0) &&
                    <Table responsive
                           className="card-table"
                           headerItems={headerItems}
                           bodyItems={listProducts.map(itemRow)}
                    />}

                    {loading && <Loader/>}

                </Card.Body>

            </Card>

            <AddCustomProductModal
              reference={reference}
              show={addCustomModalShown}
              dialogClassName="modal-lg"
              onSuccess={getProducts}
              onHide={(toastMessage) => {
                setAddCustomModalShown(false);
                if (toastMessage && toastMessage.message && toastMessage.options) {
                  addToast(toastMessage.message, toastMessage.options);
                }
              }}
              onError={(error) => ErrorHandler.handleError(addToast, t, error)}
            />
            {!loading && <AddCatalogProductModal
              show={addCatalogModalShown}
              listReference={reference}
              listProducts={listProducts}
              onSuccess={getProducts}
              onHide={(toastMessage) => {
                setAddCatalogModalShown(false);
                if (toastMessage) {
                  addToast(toastMessage.message, toastMessage.options);
                }
              }}
            />}


        </>


    );
};

export default withI18n()(ListProducts);
