import React, {useEffect, useState} from "react";


import {
  Alert,
  Badge, Button,
  Card, Dimmer, Form, Grid, Icon, ProgressCard, StatsCard, Tooltip
} from "tabler-react";
import Editable from "../../common/editable/Editable";
import {withI18n} from "react-i18next";
import InspirationListsService from "../../../services/domain/InspirationListsService";
import ConfirmModal from "../../modals/confirm-modal/ConfirmModal";
import InspirationListProductsModal from "../../modals/inspiration-list-products-modal/InspirationListProductsModal";
import {useToasts} from "react-toast-notifications";
import DateService from "../../../services/utils/DateService";
import ErrorHandler from "../../../services/error/ErrorHandler";
import {InspirationListType, InspirationListStatus} from "../../../models/InspirationList";
import LinksProvider from "../../../services/http/LinksProvider";


const InspirationListInfo = ({t, inspirationList}) => {

  const [model, setModel] = useState(inspirationList);
  const [loading, setLoading] = useState(false);
  const [confirmationModalShown, setConfirmationModalShown] = useState(false);
  const [listProductsShown, setListProductsShown] = useState(false);
  const {addToast} = useToasts();


  const loadData = () => {
    setLoading(true);
    InspirationListsService.get(inspirationList.reference)
      .then(list => {
        setModel(list)
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadData();
  }, [t]);

  const updateInspirationList = (request) => {
    console.log("reuest : ", request);
    setLoading(true);
    return InspirationListsService.update(inspirationList.reference, request)
      .then(list => {
        loadData();
      }).catch(error => {
        ErrorHandler.handleError(addToast, t, error);
      })
      .finally(() => setLoading(false));
  };

  const editInspirationListInfo = (field) => {
    return (val) => {
      const request = {};

      if (field == "validity_start_date" || field == "validity_end_date") {
        request[field] = DateService.formatDateISO(val);
      } else if (field == "status") {
        request[field] = parseInt(val);
        model[field] = val;
      } else {
        request[field] = val;
      }
      setModel({...model});
      updateInspirationList(request);
    }
  };

  const deleteInspirationList = () => {
    const request = {is_deleted: true};
    return updateInspirationList(request).then((data) => {
      window.location = LinksProvider.formatUrl(LinksProvider.ROUTES.INSPIRATION_LISTS)
    });
  };

  return (
    <Grid.Row cards deck>
      <ConfirmModal
        confirmationText={t('inspiration-lists.delete-sure')}
        onConfirm={deleteInspirationList}
        show={confirmationModalShown}
        onHide={() => setConfirmationModalShown(false)}/>
        {listProductsShown &&
        <InspirationListProductsModal
          listReference={model.reference}
          listProducts={model.products}
          listType={model.type}
          show={listProductsShown}
          onHide={(toastMessage) => {
            setListProductsShown(false);
            if (toastMessage) {
              addToast(toastMessage.message, toastMessage.options);
            }
          }}/>
        }
      <Grid.Col width={12}>
        <Card>
          <Card.Header>
            <Card.Title>
              {model.reference}
            </Card.Title>
            <Card.Options>
              <Grid.Col>
                <Button color="danger" size="sm" onClick={() => setConfirmationModalShown(true)}>
                  {t('inspiration-lists.delete')}
                </Button>
              </Grid.Col>
              <Grid.Col>
                <Button color="primary" size="sm" onClick={() => setListProductsShown(true)}>
                  {t('inspiration-lists.products')}
                </Button>
              </Grid.Col>
            </Card.Options>
          </Card.Header>
          <Card.Body>
            <Dimmer active={loading} loader>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Group label={t('inspiration-lists.name')}
                              className={"text-left"}
                  >
                    <Editable
                      initialValue={model.name}
                      undefinedText={t('globals.no-value')}
                      isValueClickable={true}
                      onSubmit={editInspirationListInfo('name')}
                      type="textfield"
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Group label={t('inspiration-lists.status')}
                              className={"text-left"}
                  >
                    <Editable
                      initialValue={model.status}
                      undefinedText={t('globals.no-value')}
                      isValueClickable={true}
                      onSubmit={editInspirationListInfo('status')}
                      type="select"
                      options={Object.keys(InspirationListStatus).map(key => {
                        return {
                          label: t(`inspiration-lists.status_${InspirationListStatus[key]}`),
                          value: InspirationListStatus[key]
                        };
                      })}
                    />
                  </Form.Group>
                </Grid.Col>

                <Grid.Col width={6}>
                  <Form.Group label={t('inspiration-lists.start-date')}>
                    <Editable
                      initialValue={model.validity_start_date}
                      isValueClickable={true}
                      undefinedText={t('globals.no-value')}
                      onSubmit={editInspirationListInfo('validity_start_date')}
                      type="date"/>
                  </Form.Group>
                </Grid.Col>

                <Grid.Col width={6}>
                  <Form.Group label={t('inspiration-lists.end-date')}>
                    <Editable
                      initialValue={model.validity_end_date}
                      isValueClickable={true}
                      undefinedText={t('globals.no-value')}
                      onSubmit={editInspirationListInfo('validity_end_date')}
                      type="date"/>
                  </Form.Group>
                </Grid.Col>

                <Grid.Col width={6}>
                  <Form.Group label={t('inspiration-lists.type')}>
                    {t('inspiration-lists.type_' + model.type)}
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            </Dimmer>
          </Card.Body>
        </Card>
      </Grid.Col>
    </Grid.Row>
  )
};

export default withI18n()(InspirationListInfo);
