import React, {useState, useEffect} from "react";

import DateService from '../../../services/utils/DateService'
import {Badge, Button, GalleryCard, Grid, Card, BlogCard, Loader} from "tabler-react";
import {withI18n} from 'react-i18next';
import {CommercialBannerType} from "../../../models/CommercialBanner";
import ErrorHandler from "../../../services/error/ErrorHandler";
import CreateCommercialBannerModal from "../../modals/create-commercial-banner-modal/CreateCommercialBannerModal";
import CommercialBannersService from "../../../services/domain/CommercialBannersService";
import {useToasts} from "react-toast-notifications";
import LinksProvider from "../../../services/http/LinksProvider";

const CommercialBannersActiveList = ({banners, t}) => {

  const [bannerObject, setBannerObject] = useState({});
  const [bannerList, setBannerList] = useState(banners && banners.length ? banners : []);
  const [loading, setLoading] = useState(true);
  const [createModalShown, setCreateModalShown] = useState(false);
  const [initialBanner, setInitialBanner] = useState(null);

  const {addToast} = useToasts();


  const getBannerObject = (b) => {
    //console.log("banners : " + JSON.stringify(b));
    let obj = {};
    Object.keys(CommercialBannerType).map(type => {
      return obj[type] = b.find(banner => banner.type == CommercialBannerType[type]);
    });
    return obj;
  };

  const disableBanner = (reference) => {
    CommercialBannersService.disableBanner(reference)
      .then(commercialBanner => {
        window.location = LinksProvider.formatUrl(LinksProvider.ROUTES.COMMERCIAL_BANNERS)
      })
      .catch(error => {
        console.log('error disabling commercial banner : ', error);
        ErrorHandler.handleError(addToast, t, error)
      });
  };

  useEffect(() => {
    setLoading(true);
    setBannerObject(getBannerObject(bannerList));
    setLoading(false);
  }, []);


  return (<React.Fragment>
    {loading && <Loader/>}
    {(!loading) && (
      <Grid.Row>
        {Object.keys(CommercialBannerType).map(type =>
          (bannerObject[type]) ? (<Grid.Col sm={6} lg={4}>
                <Card>
                  <a>
                    <img className="card-img-top"
                         src={bannerObject[type].photo_url}></img>
                  </a>
                  <Card.Body className="d-flex flex-column">
                    <div className="d-flex align-items-center px-1">
                      <h4>{CommercialBannerType[type] + 1}. Encart {t('commercial-banners.types.' + type)}</h4>
                    </div>
                    <div className="d-flex align-items-center px-1">
                      <div>
                        <h5>{bannerObject[type].title}</h5>
                        <div>{DateService.formatDate(bannerObject[type].start_date)} - {DateService.formatDate(bannerObject[type].end_date)}</div>
                      </div>
                      <div className="ml-auto">
                        {DateService.nowIsBetween(bannerObject[type].start_date, bannerObject[type].end_date) ? (
                          <Badge color="success" className="mr-1">
                            {t('commercial-banners.visible')}
                          </Badge>
                        ) : (
                          <Badge color="secondary" className="mr-1">
                            {t('commercial-banners.not-visible')}
                          </Badge>
                        )}
                      </div>
                    </div>
                  </Card.Body>
                  <Card.Body className="d-flex flex-column">
                    <div className="ml-auto">
                      <Button.List>
                        <Button size="sm" color="info" onClick={(e) => {
                          e.preventDefault();
                          setInitialBanner(bannerObject[type]);
                          setCreateModalShown(true)
                        }}> {t('commercial-banners.replace')}</Button>
                        <Button size="sm" color="warning" onClick={(e) => {
                          e.preventDefault();
                          disableBanner(bannerObject[type].reference)
                        }}> {t('commercial-banners.disable')}</Button>
                      </Button.List>
                    </div>
                  </Card.Body>
                </Card>
              </Grid.Col>
            ) :
            (<Grid.Col sm={6} lg={4}>
              <Card>
                <Card.Body className="d-flex flex-column">
                  <div className="d-flex align-items-center px-1">
                    <div className="ml-auto mr-auto text-center">
                      <h4>{t('commercial-banners.no-banner-for-type')} <br/> {CommercialBannerType[type] + 1}. {t('commercial-banners.types.' + type)}</h4>
                    </div>
                  </div>
                </Card.Body>
                <Card.Body className="d-flex flex-column">
                  <div className="d-flex align-items-center px-1">
                    <div className="ml-auto mr-auto">
                      <Button size="sm" color="success" onClick={(e) => {
                        e.preventDefault();
                        setInitialBanner({type: CommercialBannerType[type]});
                        setCreateModalShown(true)
                      }}> {t('commercial-banners.create')}</Button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Grid.Col>)
        )}
      </Grid.Row>
    )}
    <CreateCommercialBannerModal
      show={createModalShown}
      dialogClassName="modal-lg"
      initialBanner={initialBanner}
      onHide={() => setCreateModalShown(false)}
      onError={(error) => ErrorHandler.handleError(addToast, t, error)}
    />
  </React.Fragment>)
};

export default withI18n()(CommercialBannersActiveList);