// @flow
import {withRouter} from "react-router-dom";
import * as React from "react";
import AuthService from './services/domain/AuthService';

import type {NotificationProps} from "tabler-react";
import {Grid, List, RouterContextProvider, Site,} from "tabler-react";
import {withI18n} from 'react-i18next';
import {Civility} from "./models/User";
import NavBarService from './services/data/NavBarService';
// @formatter:off
type Props = {|
  +children: React.Node,
|};

type State = {|
  notificationsObjects: Array<NotificationProps>,
|};

// @formatter:on

const getAccountDropdownProps = (t) => {
  const principal = AuthService.getPrincipal();

  if (!principal) {
    return {};
  }

  return {
    avatarURL: `${principal.profile_image_link ? principal.profile_image_link : (principal.civility
    == Civility.FEMALE ? '/assets/images/female.png' : '/assets/images/male.png')}`,
    name: `${principal.first_name} ${principal.last_name}`,
    options: [{icon: "log-out", value: t("navbar.logout"), onClick: () => AuthService.logout()}],
  };
};


class SiteWrapper extends React.Component<Props, State> {
  state = {};

  componentWillMount() {
    if (AuthService.userAuthorizations.length === 0) {
      AuthService.getAuthorizationsFromRole().then(() => {
        this.setState({ready: true});
      });
    } else {
      this.setState({ready: true});
    }
  }

  componentDidMount(): * {
    if (this.state.ready) {
      const navBar = document.querySelector('.nav-tabs');

      navBar.childNodes.forEach(node => {
        if (node.textContent.trim() == this.props.t('navbar.kyc').trim()) {
          node.childNodes[0].setAttribute('target', '_blank');
        }
      });
    }
  }

  render(): React.Node {
    return this.state.ready ? (<Site.Wrapper
      headerProps={{
        href: "/",
        alt: "Galeries Lafayette",
        imageURL: "/assets/images/logo.svg",
        accountDropdown: getAccountDropdownProps(this.props.t),
      }}
      navProps={{itemsObjects: NavBarService.getNavBarItems(this.props.t)}}
      routerContextComponentType={withRouter(RouterContextProvider)}
      footerProps={{
        copyright: (<React.Fragment>
          {this.props.t('footer.copyright')}
        </React.Fragment>), nav: (<React.Fragment>
          <Grid.Col auto={true}>
            <List className="list-inline list-inline-dots mb-0">
              <List.Item className="list-inline-item">
                <a href="https://www.google.com" target="_blank"
                   rel="noopener noreferrer">{this.props.t('footer.pots-website')}</a>
              </List.Item>
              <List.Item className="list-inline-item">
                <a href="https://www.google.com" target="_blank"
                   rel="noopener noreferrer">{this.props.t('footer.lists-website')}</a>
              </List.Item>
            </List>
          </Grid.Col>
        </React.Fragment>),
      }}
    >
      {this.props.children}
    </Site.Wrapper>) : '';
  }
}

export default withI18n()(SiteWrapper);
