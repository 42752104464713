// @flow
import * as React from "react";
import {useState} from "react";
import {Form} from "tabler-react";
import {Field} from "formik";

const AmountInputContent = ({
  field: {name, value, onChange, onBlur}, form: {errors, touched, setFieldValue, setFieldTouched}, id, label, placeholder, maxlength, disabled, initialValue, ...props
}) => {
  const [displayValue, setDisplayValue] = useState(initialValue ? initialValue / 100 : '');

  return (<React.Fragment>
    <Form.Input type="text"
                name={`duplicate_${name}`}
                id={`duplicate_${id}`}
                placeholder={placeholder}
                value={displayValue}
                onChange={e => {
                  let cleanedVal = e.target.value
                      .replace(/[^0-9,.]/g, '')
                      .replace(/,/g, '.');
                  const val = (isNaN(cleanedVal)
                      || cleanedVal
                      === '') ? 'Invalid Value' : cleanedVal * 100;
                  setDisplayValue(cleanedVal);
                  setFieldValue(name, val);
                  setFieldTouched(name, true);
                }}
                maxLength={maxlength}
                disabled={disabled}/>

    <input
        type="hidden"
        name={name}
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        maxLength={maxlength}
        onBlur={onBlur}/>
  </React.Fragment>)
};

function AmountInput(props): React.Node {

  return (<Field
      component={AmountInputContent}
      name={props.name}
      placeholder={props.placeholder}
      initialValue={!props.value && !isNaN(props.value) ? undefined : props.value}
      value={props.value}
      maxLength={props.maxLength}
      disabled={props.disabled}
      id={props.id}>
  </Field>);
}

export default AmountInput;
