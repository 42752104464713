import React, {useState, useEffect} from "react";

import DateService from '../../../services/utils/DateService'
import {
  Badge, Card, Loader, Table,
} from "tabler-react";
import {withI18n} from 'react-i18next';
import EmptyContent from "../../common/empty-content/EmptyContent";
import UsersService from "../../../services/domain/UsersService";
import StrongAuthService from "../../../services/utils/StrongAuthService";
import LinksProvider from "../../../services/http/LinksProvider";
import {MinifiedParticipation, ParticipationStatus} from "../../../models/Participation";
import Amount from "../../common/amount/Amount";

function UserParticipations({email, t}): React.Node {

  const [loading, setLoading] = useState(false);
  const [participations, setParticipations] = useState(null);

  const headerItems = [
    {content : t('participations.reference')},
    {content : t('participations.pot')},
    {content : t('participations.amount')},
    {content : t('participations.creation-date')},
    {content : t('participations.validation-date')},
    {content : t('participations.status')}
  ];

  const loadData = () => {
    setLoading(true);
    UsersService.participations(email)
      .then(participations => {
        setParticipations(participations);
      })
      .finally(() => setLoading(false));
  };

  const participationStatusBadge = (participation : MinifiedParticipation) => {
    switch (participation.status) {
      case ParticipationStatus.NOT_PAYED :
        return (<Badge color="primary">{t('users.not-payed')}</Badge>);
      case ParticipationStatus.BANK_ERROR:
        return (<Badge color="danger">{t('users.bank-error')}</Badge>);
      case ParticipationStatus.REFUNDED :
        return (<Badge color="warning">{t('users.refunded')}</Badge>);
      case ParticipationStatus.REFUNDED_AFTER_3DS_FAIL :
        return (<Badge color="warning">{t('users.refunded-after-3ds-fail')}</Badge>);
      case ParticipationStatus.BANK_OK :
        return (<Badge color="success">{t('users.bank-ok')}</Badge>)
    }
  };

  const oneRow = (participation : MinifiedParticipation) => {
    return {
      key : participation.reference,
      item : [
        {content : (<a href={LinksProvider.formatUrl(LinksProvider.ROUTES.PARTICIPATION, {id : participation.reference})}>{participation.reference}</a>)},
        {content : (<a href={LinksProvider.formatUrl(LinksProvider.ROUTES.POT, {id :participation.pot.reference})}>{participation.pot.reference}</a>)},
        {content : <Amount amount={participation.amount}></Amount>},
        {content : DateService.formatTime(participation.creation_date)},
        {content : DateService.formatTime(participation.validation_date)},
        {content : (participationStatusBadge(participation))}
      ]
    };
  };

  useEffect(() => {
    loadData();
  }, []);

  return (

    <Card title={t('users.participations')}>
      <Card.Body className="fixed-height-card-body">
        {((!participations || !participations.length) && !loading) ? (
          <EmptyContent text={t('users.no-participated-pots')}/>
        ) : ''}
        {((participations && participations.length) && !loading) ? (
          <Table responsive
                 className="card-table"
                 headerItems={headerItems}
                 bodyItems={participations.map(oneRow)}/>
        ) : ''}
        {loading && <Loader/>}
      </Card.Body>
    </Card>
  );
}

export default withI18n()(UserParticipations);
