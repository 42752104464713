import React, {Fragment} from "react";
import {Card, Loader, Form} from "tabler-react";
import {Image, Button, ButtonToolbar, Figure, Modal} from "react-bootstrap";
import {withI18n} from 'react-i18next';
import {useEffect, useState} from "react";
import {Col, Container, FormGroup, Input, Label, Row} from 'reactstrap';
import LinksProvider from "../../../services/http/LinksProvider";
import {
  PublicationCategory,
  PublicationStatus, PublicationTemplate
} from "../../../models/Publication";
import Editable from "../../common/editable/Editable";
import PublicationsService from "../../../services/domain/PublicationsService";
import PublicationUpdatePhoto from "../publication-update-photo/PublicationUpdatePhoto";
import ErrorHandler from "../../../services/error/ErrorHandler";
import {useToasts} from "react-toast-notifications";
import ConfirmModal from "../../modals/confirm-modal/ConfirmModal";
import DraftEditor from "../../common/editor/DraftEditor";

const PublicationUpdate = ({reference, t}) => {

  const {addToast} = useToasts();

  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState(null);
  const [photos, setPhotos] = useState([null, null, null, null, null, null, null]);
  const [photoUrls, setPhotoUrls] = useState([null, null, null, null, null, null, null]);
  const [confirmationModalShown, setConfirmationModalShown] = useState(false);

  const getPhotos = (modelPhotos) => {
    for (let photo of modelPhotos) {
      photos[photo.rank] = photo.url;
      photoUrls[photo.rank] = photo.external_url;
    }
    setPhotos(photos);
    setPhotoUrls(photoUrls);
  };

  const loadData = () => {
    setLoading(true);
    PublicationsService.get(reference)
      .then(data => {
        setModel(data);
      })
      .catch(error => {
        window.location = LinksProvider.formatUrl(LinksProvider.ROUTES.PUBLICATIONS);
        ErrorHandler.handleError(addToast, t, error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadData()
  }, []);


  useEffect(() => {
    model && model.photos && model.photos.length > 0 && (getPhotos(model.photos));
  }, [model]);

  const handleDelete = (reference) => {
    setLoading(true);
    PublicationsService.delete(reference)
      .then(data => {
        window.location = LinksProvider.formatUrl(LinksProvider.ROUTES.PUBLICATIONS);
      })
      .catch(error => ErrorHandler.handleError(addToast, t, error))
      .finally(() => setLoading(false));
  };

  const updatePublication = (request) => {
    setLoading(true);
    return PublicationsService.update(model.reference, request)
      .then(publication => {
        setModel({...model, ...publication});
        return publication;
      })
      .catch(err => {
        ErrorHandler.handleError(addToast, t, err);
        loadData();
      })
      .finally(() => setLoading(false));
  };

  const editInfoPublication = (field) => {
    return (val) => {

      if (field !== "category" && field !== "status") {
        val = val ?? '';
      }

      const request = {};
      if (field.indexOf('.') != -1) {
        const array = field.split('.');
        model[array[0]][array[1]] = val;
        request[array[0]] = {};
        request[array[0]][array[1]] = val;
      } else {
        model[field] = val;
        request[field] = val;
        if (field === "category" || field === "status") {
          model[field] = parseInt(val);
          request[field] = parseInt(val);
        }
      }

      setModel({...model});
      updatePublication(request);
    }
  };

  return (<Fragment>
            {model &&
              (<ConfirmModal
              confirmationText={t('publications.delete-sure')}
              onConfirm={() => handleDelete(model.reference)}
              show={confirmationModalShown}
              onHide={() => setConfirmationModalShown(false)}
            />)}
            <Card.Header>
              <Modal.Title> {t('publications.update')}</Modal.Title>
              <Card.Options>
                {model && model.status === PublicationStatus.INACTIVE && (
                  <Button className="mr-1" variant="primary" onClick={() => {window.open(LinksProvider.formatListeFrontFullUrl(LinksProvider.FRONT.LIST.PUBLICATION, {reference: model.reference}), '_blank')}}>
                    {t('publications.preview')}
                  </Button>
                )}
                {model && !model.is_headline && model.category === PublicationCategory.HONEYMOON && (
                  <Button className="mr-1" variant="primary" onClick={() => editInfoPublication('is_headline')(true)}>
                    {t('publications.make-headline')}
                  </Button>
                )}
                {model && model.is_headline && (
                  <Button className="mr-1" variant="warning" onClick={() => editInfoPublication('is_headline')(false)}>
                    {t('publications.remove-headline')}
                  </Button>
                )}
                <Button variant="danger" onClick={()=> setConfirmationModalShown(true)} icon="plus" color="primary">
                  {t('publications.delete')}
                </Button>
              </Card.Options>
            </Card.Header>
            <Card.Body>
              {loading && (<Container><Loader/></Container>)}
              {!loading && model && (<Container>
                <Row>
                  <Col xs="12" sm="12">
                    <Form.Group label={t('publications.meta_title')} className={"text-left"}>
                      <Editable
                        initialValue={model.meta_title}
                        undefinedText={t('globals.no-value')}
                        isValueClickable={true}
                        onSubmit={editInfoPublication('meta_title')}
                        type="textfield"/>
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="12">
                    <Form.Group label={t('publications.meta_description')} className={"text-left"}>
                      <Editable
                        initialValue={model.meta_description}
                        undefinedText={t('globals.no-value')}
                        isValueClickable={true}
                        onSubmit={editInfoPublication('meta_description')}
                        type="textarea"/>
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="12">
                    <Form.Group label={t('publications.title')} className={"text-left"}>
                      <Editable
                        initialValue={model.title}
                        undefinedText={t('globals.no-value')}
                        isValueClickable={true}
                        onSubmit={editInfoPublication('title')}
                        type="textfield"/>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6" sm="6">
                    <Col xs="12" sm="12">
                      <Form.Group label={t('publications.template')} className={"text-left"}>
                        <p>{t('publications.template-' + (model.template || 0))}</p>
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12">
                      <Form.Group label={t('publications.status')} className={"text-left"}>
                        <Editable
                          initialValue={model.status}
                          undefinedText={t('globals.no-value')}
                          isValueClickable={true}
                          onSubmit={editInfoPublication('status')}
                          type="select" options={Object.keys(PublicationStatus).map(key => {
                          return {
                            label: t(`publications.status-${PublicationStatus[key]}`), value: PublicationStatus[key]
                          }
                        })}/>
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12">
                      <Form.Group label={t('publications.category')} className={"text-left"}>
                        <Editable
                          initialValue={model.category}
                          undefinedText={t('globals.no-value')}
                          isValueClickable={true}
                          onSubmit={editInfoPublication('category')}
                          type="select" options={Object.keys(PublicationCategory).map(key => {
                          return {
                            label: t(`publications.category-${PublicationCategory[key]}`), value: PublicationCategory[key]
                          }
                        })}/>
                      </Form.Group>
                    </Col>
                  </Col>
                  <Col xs="6" sm="6">
                    <div className="d-flex ">
                      <img src={`/assets/images/publication-template-${model.template}.png`} alt={'template'} height="200px"/>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" sm="12">
                    <Form.Group label={t('publications.first-text')} className={"text-left"}>
                      <DraftEditor value={model['first_text']}
                                         editorName="first_text"
                                         editorId="first_text"
                                         onValueChange={(value) => model['first_text'] = value}
                                         onSubmit={editInfoPublication('first_text')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" sm="12">
                    <Form.Group label={t('publications.second-text')} className={"text-left"}>
                      <DraftEditor value={model['second_text']}
                                         editorName="second_text"
                                         editorId="second_text"
                                         onValueChange={(value) => model['second_text'] = value}
                                         onSubmit={editInfoPublication('second_text')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {model.template === PublicationTemplate.SECONDARY && (<Row>
                  <Col xs="12" sm="12">
                    <Form.Group label={t('publications.third-text')} className={"text-left"}>
                      <DraftEditor value={model['third_text']}
                                         editorName="third_text"
                                         editorId="third_text"
                                         onValueChange={(value) => model['third_text'] = value}
                                         onSubmit={editInfoPublication('third_text')}
                      />
                    </Form.Group>
                  </Col>
                </Row>)}
                {model.template === PublicationTemplate.SECONDARY && (<Row>
                  <Col xs="12" sm="12">
                    <Form.Group label={t('publications.fourth-text')} className={"text-left"}>
                      <DraftEditor value={model['fourth_text']}
                                         editorName="fourth_text"
                                         editorId="fourth_text"
                                         onValueChange={(value) => model['fourth_text'] = value}
                                         onSubmit={editInfoPublication('fourth_text')}
                      />
                    </Form.Group>
                  </Col>
                </Row>)}
              </Container>)}
            </Card.Body>
            <Card.Header>
              <Modal.Title> {t('publications.photos')}</Modal.Title>
            </Card.Header>
            <Card.Body>
              {(loading) && (<Container><Loader/></Container>)}
              {!loading && model && (<Container>
                <Row>
                  {[...Array(7).keys()].map((element, index) => {
                    return (
                      <Col xs="6" sm="6" className="mt-2">
                        <PublicationUpdatePhoto
                          publicationReference={model.reference}
                          rank={index}
                          url={photos[index]}
                          external_url={photoUrls[index]}
                        />
                      </Col>
                    )
                  })}
                </Row>
              </Container>)}
            </Card.Body>
  </Fragment>);
};

export default withI18n()(PublicationUpdate);
